import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { REMOVE_ALERT } from '../../redux/alert/alertTypes';
import CloseIcon from '../icons/CloseIcon';
import ErrorIcon from '../icons/ErrorIcon';
import SuccessTickIcon from '../icons/SuccessTickIcon';
import './alert.styles.scss';

const Alert = ({ alert }) => {
  const dispatch = useDispatch();
  const removeAlert = (id) => {
    dispatch({
      type: REMOVE_ALERT,
      payload: id,
    });
  };

  const renderIcon = (alertType) => {
    switch (alertType) {
      case 'danger':
        return <ErrorIcon className="icon-sm" fill="#fff" />;
      case 'success':
        return <SuccessTickIcon className="icon-sm" fill="#fff" />;
      case 'info':
        return <ErrorIcon className="icon-sm" fill="#fff" />;
      default:
        return null;
    }
  };

  return (
    alert &&
    Object.keys(alert).length > 0 && (
      <div className="alert-container">
        <div className={`alert alert-${alert.alertType}`}>
          <div className="flex-vc">
            <i className="mr-24">{renderIcon(alert.alertType)}</i>
            {alert.msg}
          </div>
          <i className="ml-24" onClick={() => removeAlert(alert.id)}>
            <CloseIcon className="icon-sm" fill="#fff" />
          </i>
        </div>
      </div>
    )
  );
};

Alert.propTypes = {
  alert: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    // alert: state.alert,
    alert: state.alert.alert,
  };
};
export default connect(mapStateToProps)(Alert);
