import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import TextError from '../formik/TextError';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import { loadUserSpeakers } from '../../redux/user-profile/userProfileAction';
import LocalAlert from '../alert/LocalAlert';
import '../speaker/speakerForm.styles.scss';
import SelectFormType1 from '../reselect/SelectFormType1';
import ModalX from '../modal/ModalX';
import SpeakerForm from '../speaker/SpeakerForm';
import CustomTimepicker from '../react-datepicker/CustomTimePicker';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import Dropzone from 'react-dropzone';
import Loader from '../loader/Loader';
import DocumentIcon from '../icons/DocumentIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import PlusIcon from '../icons/PlusIcon';
import CloseIcon from '../icons/CloseIcon';
import { modalStyleformType1 } from '../reselect/selectUtil';
import { getValue } from '../../utility/commonUtil';
import Select from 'react-select';
import AddSpeakerForm from './AddSpeakerForm';
import { format, setHours, setMinutes, setSeconds, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const validationSchema = yup.object({
  title: yup.string().trim().max(250).required('Required'),
  endDateTime: yup
    .date()
    .nullable()
    .required('Required')
    .typeError('Invalid date')
    .min(new Date(), 'End date and time must be in the future'),
  startDateTime: yup
    .date()
    .nullable()
    .required('Required')
    .typeError('Invalid date format')
    .min(new Date(), 'Start date and time must be in the future'),
  speakers: yup.array().of(yup.object()).min(1, 'Required').compact(),
});

export default function AddLectureForm({
  conference,
  session,
  setSessions,
  setShowLectureForm,
  setRowData,
  rowData,
}) {
  const [showSpkrForm, setShowSpkrForm] = useState(false);
  const [speakerList, setSpeakerList] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const spkrResArray = rowData?.speakers?.map((speaker) => ({
    _id: speaker._id,
    label: `${speaker.speaker.firstName} ${speaker.speaker.lastName}`,
    value: speaker._id,
    images: speaker.speaker?.images,
    designation: speaker.speaker?.designation,
    degree: speaker.speaker?.degree,
  }));

  const initialValues = {
    session: session?.title,
    title: rowData?.title ? rowData?.title : '',
    description: rowData?.info ? rowData?.info : '',
    startDateTime: rowData?.startDate ? new Date(rowData?.startDate) : null,
    endDateTime: rowData?.endDate ? new Date(rowData?.endDate) : null,
    speakers: spkrResArray ? spkrResArray : [],
    resources: rowData?.resources ? rowData?.resources : [],
    powerPointFile: rowData?.powerPointFile ? rowData?.powerPointFile : [],
    isResourceLoading: false,
    fixedDate: new Date(session?.startDate),
    fixedEndDate: new Date(session?.endDate),
  };

  async function onSubmit(values, actions) {
    try {
      const formData = {
        episodeDetails: {
          session: session._id,
          title: values?.title,
          speakers: values?.speakers,
          startDate: zonedTimeToUtc(
            values?.startDateTime,
            conference?.timezone
          ).toISOString(),
          endDate: zonedTimeToUtc(
            values?.endDateTime,
            conference?.timezone
          ).toISOString(),
          resources: values?.resources,
          powerPointFile: values?.powerPointFile,
          conference: conference?.value,
          episodeId: rowData?._id,
        },
      };

      const response = await api.post('session/episodes', formData);

      setSessions(response?.data?.data?.sessions);
      setShowLectureForm(false);
      setRowData('');
    } catch (err) {
      if (err) {
        dispatch(
          localAlertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const deleteFile = async (key, fieldName, fieldValue, setFieldValue) => {
    try {
      const response = await api.delete('courses/files', {
        data: {
          files: [
            {
              Key: key,
            },
          ],
        },
      });
      setFieldValue(
        fieldName,
        fieldValue?.filter((item) => item.Key !== response.data.data.Key)
      );
    } catch (err) {
      dispatch(alertAction('File failed to delet', 'danger'));
    }
  };

  const loadSpeakers = async () => {
    const url = `speakers/users/${user?._id}/conference/${conference?.value}`;

    try {
      const response = await api.get(url);
      if (response) {
        setSpeakerList(response.data.data.speakers);
      }
    } catch (err) {
      if (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    }
  };

  useEffect(() => {
    loadSpeakers();
  }, [user?._id]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      formik.values.images?.forEach((file) =>
        URL.revokeObjectURL(file.Location)
      );
      setRowData('');
    };
  }, [formik.values.images]);

  return (
    <>
      <h2 className="color-primary text-align-center mb-8">
        {rowData ? 'Update Lecture' : 'Add Lecture'}
      </h2>
      <LocalAlert />
      <form
        className="form-type-1"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div>
          <div>
            <div className="material-textfield mb-10">
              <input
                id="firstName"
                type="text"
                name="session"
                disabled
                value={formik.values.session}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Session</label>
            </div>
            <div className="material-textfield mb-10">
              <input
                id="firstName"
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Title*</label>
            </div>
            <div>
              {formik.touched.title && Boolean(formik.errors.title) && (
                <TextError>{formik.errors.title}</TextError>
              )}
            </div>
          </div>

          <div>
            <>
              <CustomTimepicker
                id="startDate"
                name="startDateTime"
                selected={formik.values.startDateTime}
                onChange={(date) => {
                  const updatedDate = setSeconds(
                    setMinutes(
                      setHours(formik.values.fixedDate, date.getHours()),
                      date.getMinutes()
                    ),
                    date.getSeconds()
                  );

                  formik.setFieldValue('startDateTime', updatedDate);
                }}
                minDate={new Date()}
                maxDate={formik.values.endDateTime}
                placeholder="Pick start time*"
                disabled={false}
              />
              <div className="mb-10">
                {formik.touched.startDateTime &&
                  Boolean(formik.errors.startDateTime) && (
                    <TextError>{formik.errors.startDateTime}</TextError>
                  )}
              </div>
            </>
          </div>
          <div>
            <div>
              <>
                <CustomTimepicker
                  id="startDate"
                  name="endDateTime"
                  selected={formik.values.endDateTime}
                  onChange={(date) => {
                    const updatedDate = setSeconds(
                      setMinutes(
                        setHours(formik.values.fixedDate, date.getHours()),
                        date.getMinutes()
                      ),
                      date.getSeconds()
                    );
                    formik.setFieldValue('endDateTime', updatedDate);
                  }}
                  minDate={new Date()}
                  maxDate={formik.values.endDateTime}
                  placeholder="Pick end time*"
                  disabled={false}
                />
                <div className="mb-10">
                  {formik.touched.endDateTime &&
                    Boolean(formik.errors.endDateTime) && (
                      <TextError>{formik.errors.endDateTime}</TextError>
                    )}
                </div>
              </>
            </div>
          </div>

          <div>
            <div>
              <>
                <Select
                  value={getValue(speakerList, formik.values.speakers, true)}
                  options={speakerList}
                  placeholder="Choose speakers*"
                  name="filter"
                  onChange={(option) => {
                    formik.setFieldValue('speakers', option);
                  }}
                  menuPortalTarget={document.body}
                  styles={modalStyleformType1}
                  isMulti={true}
                />
              </>

              <div className="mb-20 mt-10 ">
                <span
                  type="button"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="conf-add-newcredit-btn caption-1-heavy-primary m-4 mt-4"
                  onClick={() => setShowSpkrForm(true)}
                >
                  Add new Speaker
                </span>
                {formik.touched.speakers && Boolean(formik.errors.speakers) && (
                  <TextError>{formik.errors.speakers}</TextError>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="flex-vc mb-16">
              <p className="caption-1-regular-gray2 mt-28 px-8">*</p>
              <p className="caption-1-regular-gray2  mt-28">
                File and powerpoint presentation will be available in Confemy
                app and website to registered users only.
              </p>
            </div>
            <div className="mb-24">
              {formik.values.resources?.map((resource) => (
                <div
                  key={resource?.Key}
                  className="flex-vc-sb uc-uploadfile-input mb-8 "
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    className="flex-vc"
                  >
                    <i className="mr-8">
                      <DocumentIcon className="icon-sm" />
                    </i>
                    <p className="caption-1-regular-gray2">
                      {resource?.fileNameWithExtension || 'Resource file'}
                    </p>
                  </div>
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      deleteFile(
                        resource?.Key,
                        'resources',
                        formik.values.resources,
                        formik.setFieldValue
                      )
                    }
                  >
                    <CrossCancelIcon
                      className=" icon-size"
                      // fill="#000000"
                    />
                  </i>
                </div>
              ))}
              <div>
                {formik.values.isResourceLoading ? (
                  <div className="uc-uploadfile-input flex-vc position-relative">
                    <Loader />
                  </div>
                ) : (
                  <Dropzone
                    accept={{
                      'image/*': ['.png', '.gif', '.jpeg'],
                      'application/pdf': ['.pdf'],
                      // "image/*": [], // All images
                      // "text/html": [".html", ".htm"],
                    }}
                    // accept="image/*,audio/*,video/*"
                    multiple={true}
                    // maxSize={524288000}
                    maxFiles={3}
                    onDrop={async (acceptedFiles) => {
                      // props.setFieldValue("resources", acceptedFiles);
                      if (acceptedFiles?.length > 0) {
                        const formDataObj = new FormData();
                        let alreadySavedOnS3 = [];

                        acceptedFiles.map((item) =>
                          !item.Key
                            ? formDataObj.append('file', item)
                            : alreadySavedOnS3.push(item)
                        );

                        if (formDataObj.has('file')) {
                          formik.setFieldValue('isResourceLoading', true);
                          try {
                            const s3Response = await api.post(
                              'media/upload',
                              formDataObj
                            );

                            if (s3Response) {
                              formik.setFieldValue('resources', [
                                ...alreadySavedOnS3,
                                ...formik.values.resources,
                                ...s3Response.data.data,
                              ]);
                              formik.setFieldValue('isResourceLoading', false);
                            }
                          } catch (err) {
                            formik.setFieldValue('isResourceLoading', false);
                            dispatch(
                              alertAction('Files(s) failed to save', 'danger')
                            );
                          }
                        }
                      }
                      //
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          style={{ cursor: 'pointer' }}
                          {...getRootProps({
                            className: 'uc-uploadfile-input flex-vc',
                          })}
                        >
                          <div
                            className="flex-vc"
                            style={{
                              whiteSpace: 'nowrap',
                              overflowX: 'hidden',
                            }}
                          >
                            <i className="position-relative mr-8">
                              <PlusIcon className="icon-size" />
                            </i>
                            <p className="caption-1-medium-primary">
                              Add one or more files for this lecture
                            </p>
                          </div>
                          <input {...getInputProps()} />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
          <div className="mb-16">
            {formik.values.powerPointFile?.length > 0 ? (
              <>
                <div className="flex-vc-sb uc-uploadfile-input pb-24 caption-1-regular-gray2">
                  <div
                    style={{
                      overflow: 'hidden',
                      width: '90%',
                      mr: '2.5rem',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    className="flex-vc"
                  >
                    <i className="mr-8">
                      <DocumentIcon className="icon-sm" />
                    </i>
                    <p>
                      {formik.values.powerPointFile[0]?.name ||
                        'PowerPointFile'}
                    </p>
                  </div>
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (formik.values.powerPointFile[0]?.key) {
                        formik.setFieldValue(
                          'deletedPowerPointFile',
                          formik.values.powerPointFile[0]?.key
                        );
                      }
                      formik.setFieldValue('powerPointFile', []);
                    }}
                  >
                    <CloseIcon
                      style={{
                        position: 'absolute',
                        right: '1.4rem',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        transformOrigin: 'left top',
                      }}
                      className="icon-sm"
                      fill="#000000"
                    />
                  </i>
                </div>
              </>
            ) : (
              <div>
                <Dropzone
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                  multiple={false}
                  // maxSize={524288000}
                  maxFiles={1}
                  onDrop={async (acceptedFiles) => {
                    // props.setFieldValue("resources", acceptedFiles);
                    if (acceptedFiles?.length > 0) {
                      const dataObj = new FormData();
                      let oldMedia = [];
                      acceptedFiles.map((item) =>
                        !item.Key
                          ? dataObj.append('file', item)
                          : oldMedia.push(item)
                      );

                      if (dataObj.has('file')) {
                        try {
                          const mediaResponse = await api.post(
                            'media/upload',
                            dataObj
                          );
                          if (mediaResponse) {
                            formik.setFieldValue(
                              'powerPointFile',
                              mediaResponse.data.data
                            );
                          }
                        } catch (err) {
                          dispatch(
                            alertAction('Document failed to save', 'danger')
                          );
                        }
                      }
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        style={{ cursor: 'pointer' }}
                        {...getRootProps({
                          className: 'uc-uploadfile-input flex-vc',
                        })}
                      >
                        <div
                          className="flex-vc"
                          style={{
                            whiteSpace: 'nowrap',
                            overflowX: 'hidden',
                          }}
                        >
                          <i className="position-relative mr-8">
                            <PlusIcon className="icon-size" />
                          </i>
                          <p className="caption-1-medium-primary">
                            Add powerpoint presentation for this lecture
                          </p>
                        </div>
                        <input {...getInputProps()} />
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
          <div className="mb-10">
            <SubmitCancelButtonWithLoader
              isSubmitting={formik.isSubmitting}
              text={'Submit'}
              className="button button-green"
              fullWidth={true}
              onCancel={() => setShowLectureForm(false)}
            />
          </div>
        </div>
      </form>
      {showSpkrForm && (
        <ModalX onDismiss={() => setShowSpkrForm(false)} open={showSpkrForm}>
          <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
            <AddSpeakerForm
              conference={conference}
              header={'Add Speaker'}
              setShowSpeakerForm={setShowSpkrForm}
              setSpeakerList={setSpeakerList}
            />
          </div>
        </ModalX>
      )}
    </>
  );
}
