import { useDispatch } from 'react-redux';
import PlusIcon from '../icons/PlusIcon';
import { alertAction } from '../../redux/alert/alertAction';

export default function AddChapterButton({
  section,
  setSelectedSection,
  setShowChapter,
}) {
  const dispatch = useDispatch();
  return (
    <div className="ce-episode-list">
      <div className="L-indent"></div>
      <div
        onClick={() => {
          if (!section?._id) {
            return dispatch(alertAction('First add a section', 'info'));
          } else {
            setSelectedSection(section);
            setShowChapter(true);
          }
        }}
        className="add-chapter-button"
      >
        <i className="position-relative pt-5 mr-8">
          <PlusIcon className="icon-size" fill="#ced9de" />
        </i>
        <span>Add Chapter</span>
      </div>
      <div>{/* leave this empty */}</div>
    </div>
  );
}
