export default function AddFileIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>icon/add file</title>
      <defs>
        <filter id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.031373 0 0 0 0 0.254902 0 0 0 0 0.360784 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="icon/add-file"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="post_add_black_24dp">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
          <g filter="url(#filter-1)" id="Group">
            <g transform="translate(5.000000, 8.333333)">
              <path
                d="M25,11.6666667 C24.0833333,11.6666667 23.3333333,12.4166667 23.3333333,13.3333333 L23.3333333,22.0333333 C23.3333333,22.95 22.5833333,23.7 21.6666667,23.7 L5,23.7 C4.08333333,23.7 3.33333333,22.95 3.33333333,22.0333333 L3.33333333,5 C3.33333333,4.08333333 4.08333333,3.33333333 5,3.33333333 L13.3333333,3.33333333 C14.25,3.33333333 15,2.58333333 15,1.66666667 C15,0.75 14.25,0 13.3333333,0 L3.33333333,0 C1.5,0 0,1.5 0,3.33333333 L0,23.3333333 C0,25.1666667 1.5,26.6666667 3.33333333,26.6666667 L23.3333333,26.6666667 C25.1666667,26.6666667 26.6666667,25.1666667 26.6666667,23.3333333 L26.6666667,13.3333333 C26.6666667,12.4166667 25.9166667,11.6666667 25,11.6666667 Z"
                id="Path"
                fill="#08415C"
                fillRule="nonzero"
              ></path>
              <path
                d="M18.3333333,6.66666667 L8.33333333,6.66666667 C7.41666667,6.66666667 6.66666667,7.41666667 6.66666667,8.33333333 C6.66666667,9.25 7.41666667,10 8.33333333,10 L18.3333333,10 C19.25,10 20,9.25 20,8.33333333 C20,7.41666667 19.25,6.66666667 18.3333333,6.66666667 Z"
                id="Path"
                fill="#08415C"
                fillRule="nonzero"
              ></path>
              <path
                d="M18.3333333,11.6666667 L8.33333333,11.6666667 C7.41666667,11.6666667 6.66666667,12.4166667 6.66666667,13.3333333 C6.66666667,14.25 7.41666667,15 8.33333333,15 L18.3333333,15 C19.25,15 20,14.25 20,13.3333333 C20,12.4166667 19.25,11.6666667 18.3333333,11.6666667 Z"
                id="Path"
                fill="#08415C"
                fillRule="nonzero"
              ></path>
              <path
                d="M18.3333333,16.6666667 L8.33333333,16.6666667 C7.41666667,16.6666667 6.66666667,17.4166667 6.66666667,18.3333333 C6.66666667,19.25 7.41666667,20 8.33333333,20 L18.3333333,20 C19.25,20 20,19.25 20,18.3333333 C20,17.4166667 19.25,16.6666667 18.3333333,16.6666667 Z"
                id="Path"
                fill="#08415C"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
          <path
            d="M35.0333333,8.33333333 L31.6666667,8.33333333 L31.6666667,4.96666667 C31.6666667,4.06666667 30.9333333,3.33333333 30.0333333,3.33333333 L29.9833333,3.33333333 C29.0666667,3.33333333 28.3333333,4.06666667 28.3333333,4.96666667 L28.3333333,8.33333333 L24.9833333,8.33333333 C24.0833333,8.33333333 23.35,9.06666667 23.3333333,9.96666667 C23.3333333,9.98333333 23.3333333,10 23.3333333,10.0166667 C23.3333333,10.9333333 24.0666667,11.6666667 24.9833333,11.6666667 L28.3333333,11.6666667 L28.3333333,15.0166667 C28.3333333,15.9166667 29.0666667,16.6666667 29.9833333,16.65 C30,16.65 30.0166667,16.65 30.0333333,16.65 C30.9333333,16.65 31.6666667,15.9166667 31.6666667,15.0166667 L31.6666667,11.6666667 L35.0333333,11.6666667 C35.9333333,11.6666667 36.6666667,10.9333333 36.6666667,10.0333333 L36.6666667,9.96666667 C36.6666667,9.06666667 35.9333333,8.33333333 35.0333333,8.33333333 Z"
            id="Path"
            fill="#4CB944"
          ></path>
        </g>
      </g>
    </svg>
  );
}
