import {
  areDatesSame,
  getFormattedDateInTz2,
  getFormattedTimeOnyInTz,
} from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';

export default function ScheduleTimeColoumn({ item, conference }) {
  return (
    <div className="flex-vc">
      <div>
        <DateIcon className="icon-xxs mr-12" />
      </div>
      <div>
        <p>
          {item?.startDate && item?.endDate && conference?.timezone
            ? areDatesSame(item?.startDate, item?.endDate, conference?.timezone)
              ? `${getFormattedDateInTz2(
                  item?.startDate,
                  conference?.timezone
                )}`
              : `${getFormattedDateInTz2(
                  item?.startDate,
                  conference?.timezone
                )} ${getFormattedTimeOnyInTz(
                  item?.startDate,
                  conference?.timezone
                )}`
            : ''}
        </p>
        {!areDatesSame(item?.startDate, item?.endDate, conference?.timezone) &&
          item?.startDate &&
          item?.endDate && (
            <p>
              {`${getFormattedDateInTz2(
                item?.endDate,
                conference?.timezone
              )} ${getFormattedTimeOnyInTz(
                item?.endDate,
                conference?.timezone
              )}`}
            </p>
          )}
        {item?.startDate &&
          item?.endDate &&
          areDatesSame(
            item?.startDate,
            item?.endDate,
            conference?.timezone
          ) && (
            <p
              style={{ whiteSpace: 'nowrap' }}
              className="caption-2-regular-gray3"
            >
              {`${getFormattedTimeOnyInTz(
                item?.startDate,
                conference?.timezone
              )} - ${getFormattedTimeOnyInTz(
                item?.endDate,
                conference?.timezone
              )}`}
            </p>
          )}
      </div>
    </div>
  );
}
