import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import CameraIcon from '../icons/CameraIcon';

import './singleImageUploader.styles.scss';
import ImageGalleryIcon from '../icons/ImageGalleryIcon';
import { useDispatch } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';

export default function SingleImageUploader({
  dropzoneContentType,
  setFormikFieldValue,
  fieldName,
}) {
  const dispatch = useDispatch();
  const myDropZone = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    onDrop: (acceptedFiles, errors) => {
      errors.forEach((err) => {
        err?.errors.forEach((e) => {
          if (e.code.toString() == 'file-too-large') {
            dispatch(
              alertAction(`File is too large. Maximum size is 10MB.`, 'danger')
            );
          } else if (err.code === 'file-invalid-type') {
            dispatch(alertAction(`Valid file type required.`, 'danger'));
          }
        });
      });
      const acceptedFilesWithUrl = acceptedFiles.map((file) =>
        Object.assign(file, {
          Location: URL.createObjectURL(file),
        })
      );

      setFormikFieldValue(fieldName, acceptedFilesWithUrl);
    },
  });

  const { getRootProps, getInputProps } = myDropZone;

  const forConfbanner = (
    <div className="singleimage-upload-textbox">
      <ImageGalleryIcon className="icon-xxl mb-16" />
      <div style={{ textAlign: 'center' }}>
        <span>Drag & drop image or click to</span>
        <span>Browse</span>
        {/* <span>to choose a file</span> */}
      </div>
    </div>
  );
  const forSpeakerImage = <div>forSpeakerImage</div>;

  const forDefault = (
    <div className="default-si-dz-content">
      <CameraIcon className="icon-size mb-8" />
      <p>Drag and drop file(s) or click to Browse</p>
    </div>
  );

  const getDropzoneContent = (type) => {
    switch (type) {
      case 'speakerImage':
        return forSpeakerImage;

      case 'confbanner':
        return forConfbanner;

      default:
        return forDefault;
    }
  };

  // dropzone size and shape is controlled by its container declared in parent component
  // dropzone content comes from the switch statement above

  return (
    <div {...getRootProps({ className: 'singleimage-dropzone' })}>
      <input {...getInputProps()} />
      {getDropzoneContent(dropzoneContentType)}
    </div>
  );
}

SingleImageUploader.propTypes = {
  fieldName: PropTypes.string.isRequired,
  setFormikFieldValue: PropTypes.func.isRequired,
  dropzoneContentType: PropTypes.string,
};
