import AccountSettings from '../../components/user-settings/AccountSettings';
export default function AccountSettingsPage() {
  return (
    <>
      <div className="container pt-64">
        <AccountSettings />
      </div>
    </>
  );
}
