import { useState } from 'react';
import '../create-video-course/CourseCardWithOptions.scss';
import playIcon from '../video-course/video-course-icons/play-button.png';
import ShareModal from '../share-modal/ShareModal';
import ShareIcon from '../icons/ShareIcon';

const CourseCardWithOptions = (props) => {
  const { isOptions = false, options, data, openPage } = props;

  const { media, courseCoveredPercent = 0 } = data;
  const [menuBox, setMenuBox] = useState(false);
  const [modal, setModal] = useState(false);

  let stars = [];

  const [showPopUp, setShowPopUp] = useState(false);

  for (let i = 0; i < 5; i++) {
    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 32 32"
        className="courseCardOptions-star"
      >
        <defs>
          {/* <linearGradient id="grad">
            <stop offset="50%" stop-color="gold" />
            <stop offset="50%" stop-color="white" />
          </linearGradient> */}
        </defs>
        <path
          fill="url(#grad)"
          d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
l11.547-1.2L16.026,0.6L20.388,10.918z"
          stroke-width="3"
          stroke="gold"
        />
      </svg>
    );
  }

  return (
    <>
      <div className="courseCardOptions-outerDiv">
        {isOptions ? (
          <div
            className="courseCardOptions-optionsBox"
            onClickCapture={(e) => e.preventDefault()}
          >
            <button
              id={`cwo-${media?._id}`}
              type="button"
              className="courseCardOptions-menuBtn"
              onClick={() => setShowPopUp((prev) => !prev)}
            >
              {/* <img
                src={dotsIcon}
                className="courseCardOptions-menuIcon"
                id={`cwo-${media?._id}`}
              /> */}
              <ShareIcon className="icon-sm" />
              {menuBox ? (
                <div className="courseCardOptions-menuBox">
                  {options?.map((Option, id) => {
                    return (
                      <div
                        className="courseCardOptions-optionComp"
                        key={Option?._id}
                      >
                        {Option}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </button>
          </div>
        ) : null}

        <div
          style={{ cursor: 'pointer' }}
          onClick={() => openPage()}
          className="courseCardOptions-imgBox"
        >
          <img
            src={media.banner[0].Location}
            alt="course image"
            className="courseCardOptions-img"
          />
          <div className="courseCardOptions-hovImgBox">
            <img
              src={playIcon}
              alt="play icon"
              className="courseCardOptions-hovImg"
            />
          </div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => openPage()}
          className="courseCardOptions-bdy"
        >
          <div className="courseCardOptions-ttl">{media?.title}</div>
          <div className="courseCardOptions-author">
            {media?.hostedBy?.organization
              ? media?.hostedBy.organization?.name
              : `${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`}
          </div>
          {media.type !== 'PODCAST' && (
            <>
              <progress
                value={media?.watchPercentage}
                max="100"
                className="courseCardOptions-progressBar"
              />

              <div className="courseCardOptions-footerBox">
                <span className="courseCardOptions-txt">
                  {media?.watchPercentage}% complete
                </span>
                <span className="courseCardOptions-starsRatings">
                  <span></span>
                </span>
              </div>
            </>
          )}
        </div>
        {showPopUp && (
          <ShareModal
            setShowPopUp={setShowPopUp}
            showPopUp={showPopUp}
            confId={media?._id}
            cardClass="left-share-card"
            buttonClass="share-btn"
          />
        )}
      </div>
    </>
  );
};

export default CourseCardWithOptions;
