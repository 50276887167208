import React, { Fragment, useRef } from 'react';
import {
  areDatesSame,
  getFormattedDateInTz2,
  getFormattedTimeOnyInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';
import AddFileIcon from '../icons/AddFileIcon';
import html2pdf from 'html2pdf.js';
import './schedule.scss';

export default function PrintSchedule({ sessions, conference }) {
  return (
    <>
      <div className="schedule-print-container">
        <div className="schedule-print-scrollbar-grid-table-wrap">
          <ul className="schedule-grid-table-2-500 schedule-gridtable">
            <li>Time</li>
            <li>Sessions</li>

            {sessions.length > 0 &&
              sessions.map((item, indx) => (
                <Fragment key={item?._id}>
                  <li>
                    <div className="flex-vc lecture block">
                      <div>
                        <DateIcon className="icon-xxs mr-12" />
                      </div>
                      <div>
                        <p>
                          {item?.startDate && item?.endDate
                            ? areDatesSame(
                                item?.startDate,
                                item?.endDate,
                                conference?.timezone
                              )
                              ? `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference?.timezone
                                )}`
                              : `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference?.timezone
                                )} ${getFormattedTimeOnyInTz(
                                  item?.startDate,
                                  conference?.timezone
                                )}`
                            : ''}
                        </p>
                        {!areDatesSame(
                          item?.startDate,
                          item?.endDate,
                          conference?.timezone
                        ) &&
                          item?.startDate &&
                          item?.endDate && (
                            <p>
                              {`${getFormattedDateInTz2(
                                item?.endDate,
                                conference?.timezone
                              )} ${getFormattedTimeOnyInTz(
                                item?.endDate,
                                conference?.timezone
                              )}`}
                            </p>
                          )}
                        {item?.startDate &&
                          item?.endDate &&
                          areDatesSame(
                            item?.startDate,
                            item?.endDate,
                            conference?.timezone
                          ) && (
                            <p
                              style={{ whiteSpace: 'nowrap' }}
                              className="caption-2-regular-gray3"
                            >
                              {`${getFormattedTimeOnyInTz(
                                item?.startDate,
                                conference?.timezone
                              )} - ${getFormattedTimeOnyInTz(
                                item?.endDate,
                                conference?.timezone
                              )}`}
                            </p>
                          )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lecture-block">
                      <p
                        style={{
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          flex: 1,
                        }}
                        className="avenir-heavy-18 lecture-block"
                      >
                        {item?.title}
                      </p>
                      <div>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-1-regular-gray3 mr-4 lecture-block"
                        >
                          Venue:
                        </span>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-2-regular-gray3"
                        >
                          {item?.venue}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-1-regular-gray3 mr-4"
                        >
                          Convenors:
                        </span>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-2-regular-gray3"
                        >
                          {item?.convenors
                            ?.map(
                              (convenor) =>
                                `${convenor?.speaker?.firstName} ${convenor?.speaker?.lastName}`
                            )
                            .join(', ')}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-1-regular-gray3 mr-4"
                        >
                          Speakers:
                        </span>
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          className="caption-2-regular-gray3"
                        >
                          {item?.speakers
                            ?.map(
                              (speaker) =>
                                `${speaker?.speaker?.firstName} ${speaker?.speaker?.lastName}`
                            )
                            .join(', ')}
                        </span>
                      </div>

                      {item?.episodes?.length > 0 && (
                        <div>
                          <h4 className="avenir-heavy-16">Episodes:</h4>
                          {item?.episodes.map((episode, index) => (
                            <div key={index} className="episode-block">
                              <p
                                style={{
                                  wordWrap: 'break-word',
                                  overflowWrap: 'break-word',
                                  flex: 1,
                                }}
                                className="avenir-heavy-18"
                              >
                                Lecture {index + 1}: {episode?.title}
                              </p>
                              <div>
                                <DateIcon className="icon-xxs mr-12" />
                                <span
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="caption-2-regular-gray3"
                                >
                                  {`${getFormattedTimeOnyInTz(
                                    episode?.startDate,
                                    conference.timezone
                                  )} - ${getFormattedTimeOnyInTz(
                                    episode?.endDate,
                                    conference.timezone
                                  )}`}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="caption-1-regular-gray3 mr-4"
                                >
                                  Speakers:
                                </span>
                                <span
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="caption-2-regular-gray3"
                                >
                                  {episode?.speakers
                                    ?.map(
                                      (speaker) =>
                                        `${speaker?.speaker?.firstName} ${speaker?.speaker?.lastName}`
                                    )
                                    .join(', ')}
                                </span>
                              </div>
                              {episode?.resources?.length > 0 && (
                                <div className="flex-vc lecture-block">
                                  <div>
                                    <span
                                      style={{ whiteSpace: 'nowrap' }}
                                      className="caption-1-regular-gray3 mr-4"
                                    >
                                      Files:
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: '8px',
                                    }}
                                  >
                                    {episode?.resources.map((resource, idx) => (
                                      <p
                                        key={idx}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          maxWidth: '150px',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          color: '#08415c',
                                        }}
                                        className="caption-2-regular-gray3"
                                        title={resource?.fileNameWithExtension}
                                      >
                                        <AddFileIcon className="icon-xs mr-4" />
                                        <span
                                          onClick={() =>
                                            openInNewTab(resource?.Location)
                                          }
                                        >
                                          {resource?.fileNameWithExtension
                                            .length > 10
                                            ? `${resource?.fileNameWithExtension.substring(
                                                0,
                                                10
                                              )}...`
                                            : resource?.fileNameWithExtension}
                                        </span>
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </li>
                </Fragment>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
