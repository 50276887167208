import { Fragment } from "react";

import MaterialTextInput from "../formik/MaterialTextInput";

function EmailForm() {
  return (
    <Fragment>
      {/* 
      label prop can be passed as well. we are not using label here. 
      id prop can be passed as well for the label. we are not using id here. 
    */}
      <div className="material-textfield input-container">
        <MaterialTextInput
          name="email"
          type="email"
          label="Email*"
          placeholder=" "
          autoComplete="username"
        />
      </div>
    </Fragment>
  );
}

export default EmailForm;
