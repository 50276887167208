import React, { Fragment, useState } from 'react';
import { professions } from '../../utility/commonUtil';
import '../../utility/form.styles.scss';
import MaterialTextInput from '../formik/MaterialTextInput';
import SelectOne from '../formik/SelectOne';
import ClosedEyeIcon from '../icons/ClosedEyeIcon';
import DropdownIcon from '../icons/DropdownIcon';
import OpenEyeIcon from '../icons/OpenEyeIcon';

function NameForm() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword((prev) => !prev);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <Fragment>
      <div className="grid-col-2">
        <div className="material-textfield input-container">
          <MaterialTextInput
            label="First Name*"
            name="firstName"
            type="text"
            placeholder=" "
          />
        </div>

        <div className="material-textfield input-container">
          <MaterialTextInput
            label="Last Name*"
            name="lastName"
            type="text"
            placeholder=" "
          />
        </div>
      </div>
      <div className="input-container position-relative">
        <SelectOne name="profession">
          <option value="">Select a Profession*</option>
          {professions.map((profession) => (
            <option key={profession.value} value={profession.value}>
              {profession.label}
            </option>
          ))}
        </SelectOne>
        <DropdownIcon className=" right-input-icon icon-lg" />
      </div>
      <div>
        <div className="position-relative material-textfield input-container">
          <MaterialTextInput
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder=""
            autoComplete="new-password"
            label="Password*"
          />

          <i
            onClick={togglePassword}
            className={showPassword ? 'display-none' : 'right-input-icon'}
          >
            <ClosedEyeIcon className="icon-lg" />
          </i>

          <i
            onClick={togglePassword}
            className={showPassword ? 'right-input-icon' : 'display-none'}
          >
            <OpenEyeIcon className="icon-lg" />
          </i>
        </div>

        <div className="position-relative material-textfield input-container">
          <MaterialTextInput
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder=" "
            autoComplete="new-password"
            label="Confirm Password*"
          />

          <i
            onClick={toggleConfirmPassword}
            className={
              showConfirmPassword ? 'display-none' : 'right-input-icon'
            }
          >
            <ClosedEyeIcon className="icon-lg" />
          </i>

          <i
            onClick={toggleConfirmPassword}
            className={
              showConfirmPassword ? 'right-input-icon' : 'display-none'
            }
          >
            <OpenEyeIcon className="icon-lg" />
          </i>
        </div>
      </div>
    </Fragment>
  );
}

export default NameForm;
