import React, { useEffect } from 'react';
import MyVideoCourse from '../../components/video-course/my-video-courses/MyVideoCourse';
import { scrollToTop } from '../../utility/commonUtil';

export default function MyVideoCoursePage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <MyVideoCourse />;
}
