function ODashboardIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#08415C">
          <g>
            <g>
              <g>
                <path
                  d="M15.6 6.348V1.2c0-.66-.54-1.2-1.2-1.2H9.6c-.66 0-1.2.54-1.2 1.2v5.148c0 .156.06.312.18.42l3 3c.24.24.612.24.852 0l3-3c.108-.108.168-.252.168-.42zM6.348 8.4H1.2C.54 8.4 0 8.94 0 9.6v4.8c0 .66.54 1.2 1.2 1.2h5.148c.156 0 .312-.06.42-.18l3-3c.24-.24.24-.612 0-.852l-3-3c-.108-.108-.252-.168-.42-.168zM8.4 17.652V22.8c0 .66.54 1.2 1.2 1.2h4.8c.66 0 1.2-.54 1.2-1.2v-5.148c0-.156-.06-.312-.18-.42l-3-3c-.24-.24-.612-.24-.852 0l-3 3c-.108.108-.168.252-.168.42zm8.82-9.072l-3 3c-.24.24-.24.612 0 .852l3 3c.108.108.264.18.42.18h5.16c.66 0 1.2-.54 1.2-1.2v-4.8c0-.66-.54-1.2-1.2-1.2h-5.148c-.168-.012-.312.048-.432.168z"
                  transform="translate(-1139.000000, -159.000000) translate(1115.000000, 64.000000) translate(0.000000, 74.000000) translate(24.000000, 21.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ODashboardIcon;
