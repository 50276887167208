import HostYourConfSketch from "../icons/HostYourConfSketch";
const Help = () => {
  return (
    <div style={{ height: "100vh" }} className="flex-hc pt-80">
      <div className="text-align-center">
        <h1 className="color-primary  mb-80">
          We are working hard. Help coming Soon!!
        </h1>
        <HostYourConfSketch className="host-your-conference-sketch" />
      </div>
    </div>
  );
};

export default Help;
