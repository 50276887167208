import EmptyStarIcon from '../icons/EmptyStarIcon';
import FullStarIcon from '../icons/FullStarIcon';
import HalfStarIcon from '../icons/HalfStarIcon';

export default function FeedbackAction({ item }) {
  const fullStars = Math.floor(item?.averageRating); // Number of full stars
  const hasHalfStar = item?.averageRating % 1 !== 0; // Check if there’s a half star
  const emptyStars = 5 - Math.ceil(item?.averageRating); // Remaining empty stars
  return (
    <div>
      <div>
        <span
          style={{
            cursor: 'pointer',
          }}
          className="mr-4 flex-vc"
        >
          {Array.from({ length: fullStars }, (_, index) => (
            <FullStarIcon key={`full-${index}`} />
          ))}

          {/* Render half star if applicable */}
          {hasHalfStar && <HalfStarIcon />}

          {/* Render empty stars */}
          {Array.from({ length: emptyStars }, (_, index) => (
            <EmptyStarIcon key={`empty-${index}`} />
          ))}
        </span>
      </div>
    </div>
  );
}
