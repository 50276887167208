import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { scheduleSessionFilter } from '../../utility/commonUtil';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import '../schedule/schedule.scss';
import { alertAction } from '../../redux/alert/alertAction';
import ScheduleTimeColoumn from '../schedule/ScheduleTimeColumn';
import ScheduleSessionColoumn from '../schedule/ScheduleSessionColumn';
import EpisodeColoumn from '../schedule/EpisodeColumn';
import ScheduleFilter from '../schedule/ScheduleFilter';
import ScheduleHeader from '../schedule/ScheduleHeader';
import MyScheduleAction from './MyScheduleAction';
import AddFeedbackForm from './AddFeedbackForm';
import ModalX from '../modal/ModalX';

export default function MySchedule() {
  const [searchText, setSearchText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [selectedDate, setSelectedDate] = useState(null);
  const [type, setType] = useState('');

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);
  const [rowData, setRowData] = useState('');
  const [splicedList, setSplicedList] = useState([]);
  const [sessionDeleteDialogue, setSessionDeleteDialogue] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch();

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  const getConferences = async (userId) => {
    try {
      const response = await api.get(
        `session/attendee/allConferences/users/${userId}`
      );

      setConferences(response.data.data.conferences);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    const url = `session/savedActivity/users/${user?._id}?conferenceId=${conference?.value}&type=MYSCHEDULE`;
    try {
      const response = await api.get(url);

      if (response) {
        setConference(response.data.data.conference);
        setFilter1(response.data.data.conference.value);
        setSessions(response.data.data.sessions);
        if (response.data.data.conference.value) {
          getHalls(response.data.data.conference.value);
        }
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const unsaveSession = async (id, type) => {
    const url = `session/savedActivity/conference/${conference?.value}/users/${user?._id}?type=MYSCHEDULE&eventType=${type}&id=${id}`;

    try {
      const response = await api.delete(url);

      if (response) {
        setSessions(response.data.data.sessions);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getConferences(user?._id);
    getSessions();
  }, [user._id]);

  useEffect(() => {
    getSessions();
  }, [filter1]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    let finalFilterResult = scheduleSessionFilter(
      venueFilter,
      searchText,
      selectedDate
    );

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div>
      <>
        <div>
          <h2 className="color-primary mb-8">My Schedule</h2>
        </div>
        <div className="mb-32">
          <ScheduleHeader
            conferences={conferences}
            conference={conference}
            setConference={setConference}
            setFilter1={setFilter1}
            filter1={filter1}
            getSessions={getSessions}
          />
        </div>

        <div className="scheduleView-sort mb-16 mt-32">
          <ScheduleFilter
            setSearchText={setSearchText}
            searchText={searchText}
            filter2={filter2}
            setFilter2={setFilter2}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            halls={halls}
          />
        </div>

        <div className="schedule-scrollbar-grid-table-wrap">
          <ul className="schedule-grid-table-2-500 schedule-gridtable">
            <li>Time</li>
            <li>Sessions</li>

            {splicedList.length > 0 &&
              splicedList?.map((item, indx) => {
                let isRowHovered =
                  isHover && rowId.toString() === item?._id.toString();
                return (
                  <Fragment key={item?._id}>
                    <li
                      onMouseEnter={() => handleMouseEnter(item?._id)}
                      onMouseLeave={() => handleMouseLeave(item?._id)}
                      style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    >
                      <ScheduleTimeColoumn
                        conference={conference}
                        item={item}
                      />
                    </li>
                    <li
                      onMouseEnter={() => handleMouseEnter(item?._id)}
                      onMouseLeave={() => handleMouseLeave(item?._id)}
                      style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    >
                      <div className="flex-sb">
                        <ScheduleSessionColoumn
                          item={item}
                          conference={conference}
                        />
                        {item?.showButton && (
                          <MyScheduleAction
                            item={item}
                            setRowData={setRowData}
                            setShowModal={setShowModal}
                            setSessionDeleteDialogue={setSessionDeleteDialogue}
                            isRowHovered={isRowHovered}
                            setShowFeedback={setShowFeedback}
                            setType={setType}
                            unsaveSession={unsaveSession}
                            type="SESSION"
                            data={item}
                          />
                        )}
                      </div>
                      <div>
                        {item?.episodes?.map((episode, index) => {
                          return (
                            <>
                              <div className="flex-sb ml-32 mt-12">
                                <EpisodeColoumn
                                  episode={episode}
                                  item={item}
                                  index={index}
                                  conference={conference}
                                />
                                <MyScheduleAction
                                  item={episode}
                                  setRowData={setRowData}
                                  setShowModal={setShowModal}
                                  setSessionDeleteDialogue={
                                    setSessionDeleteDialogue
                                  }
                                  isRowHovered={isRowHovered}
                                  setShowFeedback={setShowFeedback}
                                  setType={setType}
                                  unsaveSession={unsaveSession}
                                  type="LECTURE"
                                  data={item}
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </li>
                  </Fragment>
                );
              })}
          </ul>
          {showFeedback && (
            <ModalX
              onDismiss={() => setShowFeedback(false)}
              open={showFeedback}
            >
              <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
                <AddFeedbackForm
                  setSessions={setSessions}
                  setShowFeedbackForm={setShowFeedback}
                  type={type}
                  data={rowData}
                />
              </div>
            </ModalX>
          )}
        </div>

        <div className="flex-vc-sb mt-8">
          <div>
            <CustomPagination
              currentPageNumber={activePage}
              setCurrentPage={setActivePage}
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={(data) => loadPaginationData(data)}
            />
          </div>
          <div>
            <PageLimitSelect
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              activePage={activePage}
              totalRecords={totalRecords}
              loadPaginationData={loadPaginationData}
            />
          </div>
        </div>
      </>
    </div>
  );
}
