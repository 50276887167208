import { useEffect, useRef } from 'react';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { prodUrl } from '../../utility/commonUtil';

const ShareModal = ({
  confId,
  showPopUp,
  setShowPopUp,
  cardClass,
  buttonClass,
}) => {
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) return;
      setShowPopUp(false);
    };
    document.body.addEventListener('click', onBodyClick, { capture: true });
    return () => {
      document.body.removeEventListener('click', onBodyClick, {
        capture: true,
      });
    };
  }, []);

  return (
    <div className={cardClass} ref={ref}>
      <span className={buttonClass}>
        <FacebookShareButton
          url={`${prodUrl}/search-conference/${confId}`}
          quote={'Discover the best Medical conferences'}
          hashtag={'#Confemy'}
        >
          <FacebookIcon size={36} round />
        </FacebookShareButton>
      </span>
      <span className={buttonClass}>
        <TwitterShareButton
          url={`${prodUrl}/search-conference/${confId}`}
          quote={'Discover the best Medical conferences'}
          hashtag="#Confemy"
        >
          <TwitterIcon size={36} round />
        </TwitterShareButton>
      </span>
      <span className={buttonClass}>
        <EmailShareButton
          url={`${prodUrl}/search-conference/${confId}`}
          quote={'Discover the best Medical conferences'}
          subject="Discover the best Medical conferences"
          body="Confemy"
          hashtag="#Confemy"
        >
          <EmailIcon size={36} round />
        </EmailShareButton>
      </span>
      <span className={buttonClass}>
        <WhatsappShareButton
          title="Confemy"
          url={`${prodUrl}/search-conference/${confId}`}
          quote={'Discover the best Medical conferences'}
          hashtag="#Confemy"
        >
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
      </span>
    </div>
  );
};

export default ShareModal;
