import { useSelector } from 'react-redux';
import { getPrice } from '../../utility/commonUtil';
import './podcastyearlyprice.scss';
export default function PodcastYearlyPrice() {
  const podcast = useSelector((state) => state.podcast.podcast);

  return (
    <div className="cep-card-bottombox">
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 podcast-price-text">Price</p>

        <div
          className="flex-vc caption-2-bold-cblack podcast-price-text "
          style={{
            fontSize: 16,
            gap: '8px',
            wordBreak: 'break-word',
          }}
        >
          {getPrice(
            podcast?.currency,
            podcast?.yearlyPodcastPrice?.actualPrice,
            podcast?.yearlyPodcastPrice?.price,
            podcast?.currencySymbol
          )}
        </div>
      </div>
      {podcast?.yearlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 podcast-price-text">Offer</p>
          <p className="avenir-heavy-18 podcast-price-text">
            {' '}
            <span style={{ color: '#fff' }}>
              {podcast?.yearlyPodcastPrice?.discount}
            </span>{' '}
            % Off
          </p>
        </div>
      )}
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 podcast-price-text">Service charge</p>
        <p className="avenir-heavy-18 podcast-price-text">
          {' '}
          {podcast?.currencySymbol} {podcast?.yearlyPodcastPrice?.serviceCharge}
        </p>
      </div>
      {podcast?.yearlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 podcast-price-text">Total</p>
          <p className="avenir-heavy-18 podcast-price-text">
            <span>{podcast?.currency} </span>
            <span>{podcast?.yearlyPodcastPrice?.totalPrice}</span>
          </p>
        </div>
      )}
    </div>
  );
}
