import { SEARCH_DONE, SEARCH_INITIATED } from "./searchTypes";

const initialState = {
  isLoading: false,
  error: false,
  searchResult: [],
  confTotal: null,
  videoTotal: null,
  audioTotal: null,
  podcastTotal: null,
};

function searchReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_INITIATED:
      return {
        ...state,
        isLoading: true,
      };

    case SEARCH_DONE:
      return {
        ...state,
        isLoading: false,
        error: false,
        searchResult: payload?.searchResult,
        confTotal: payload?.totalConfCount,
        videoTotal: payload?.videoCourseCount,
        audioTotal: payload?.audioCourseCount,
        podcastTotal: payload?.podcastCount,
      };
    default:
      return state;
  }
}

export default searchReducer;
