import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import { customSelectStyle3 } from '../reselect/selectUtil';

export default function ScheduleHeader({
  conferences,
  conference,
  setConference,
  setFilter1,
  filter1,
  getSessions,
}) {
  return (
    <>
      <h4 className="mb-24 color-primary">
        Pick conference from below for it's schedule
      </h4>
      {conference?.label && (
        <h3 className="mb-16 color-primary">{`CONFERENCE: ${conference?.label} `}</h3>
      )}
      <Select
        value={getValue(conferences, filter1, false)}
        options={conferences}
        name="filter1"
        styles={customSelectStyle3}
        onChange={(option) => {
          setConference(option);
          // setFilter1(option.value);
          if (option.value === filter1) {
            // If the selected value is the same, call getSessions explicitly
            getSessions();
          } else {
            // If the selected value is different, update the filter1 and allow useEffect to handle it
            setFilter1(option.value);
          }
        }}
        placeholder="Select Conference"
      />
    </>
  );
}
