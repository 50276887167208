import MaterialTextInput from "../formik/MaterialTextInput";
import BankFormIndiaPart from "./BankFormIndiaPart";
import BankFormUSPart from "./BankFormUSPart";

export default function BankFormPart2({ currency }) {
  function renderFormContent(currency) {
    switch (currency) {
      case "INR":
        return <BankFormIndiaPart />;
      case "USD":
        return <BankFormUSPart />;
      default:
        return <BankFormIndiaPart />;
    }
  }

  return (
    <div>
      <div className="mb-16">
        <MaterialTextInput
          id="nickName"
          type="text"
          label="Nick name to remember this account*"
          name="nickName"
          placeholder=" "
        />
      </div>
      <div className="mb-16">
        <MaterialTextInput
          id="nameOnAccount"
          type="text"
          label="Name on the account*"
          name="nameOnAccount"
          placeholder=" "
        />
      </div>
      <div className="mb-16">
        <MaterialTextInput
          id="accountNumber"
          type="number"
          label="Account number*"
          name="accountNumber"
          placeholder=" "
        />
      </div>
      {renderFormContent(currency)}
    </div>
  );
}
