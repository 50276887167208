export const GET_CONFERENCE = "GET_CONFERENCE";
// export const REMOVE_CONFERENCE = 'REMOVE_CONFERENCE';
export const CONFERENCE_ERROR = "CONFERENCE_ERROR";
export const CREATE_CONFERENCE = "CREATE_CONFERENCE";
export const REMOVE_NEWCONF_STATE = "REMOVE_NEWCONF_STATE";
export const LOAD_INCOMPLETE_CONFS = "LOAD_INCOMPLETE_CONFS";
export const LOAD_INCOMPLETE_CONF = "LOAD_INCOMPLETE_CONF";
export const LOAD_ALL_MY_CONFS = "LOAD_ALL_MY_CONFS";
export const CONF_SEARCH_DONE = "CONF_SEARCH_DONE";
export const CONF_SEARCH_INITIATED = "CONF_SEARCH_INITIATED";
