import DatePicker from 'react-datepicker';
export default function DateFilter({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) {
  return (
    <div className="form-type-3 grid-col-2">
      <div>
        <p className="caption-1-regular-gray3 ml-2 mb-4">From Date</p>
        <DatePicker
          selected={startDate}
          onChange={(val) => setStartDate(val)}
          isClearable
          placeholderText="From"
        />
      </div>
      <div>
        <p className="caption-1-regular-gray3 ml-2 mb-4">To Date</p>
        <DatePicker
          selected={endDate}
          onChange={(val) => setEndDate(val)}
          isClearable
          placeholderText="Until"
        />
      </div>
    </div>
  );
}
