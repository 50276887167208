export default function GroupIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2a4.25 4.25 0 1 0 0 8.5A4.25 4.25 0 0 0 8 2zM5.25 6.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0zM8 12.5a7.75 7.75 0 0 0-7.75 7.75v1.25h15.5v-1.25A7.75 7.75 0 0 0 8 12.5zM8 14a6.25 6.25 0 0 1 6.245 6H1.755A6.25 6.25 0 0 1 8 14z"
        fill="#8C5A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2a4.25 4.25 0 1 0 0 8.5A4.25 4.25 0 0 0 16 2zm-2.75 4.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0zM16 12.5a7.75 7.75 0 0 0-7.75 7.75v1.25h15.5v-1.25A7.75 7.75 0 0 0 16 12.5zm0 1.5a6.25 6.25 0 0 1 6.245 6H9.755A6.25 6.25 0 0 1 16 14z"
        fill="#8C5A2B"
      />
    </svg>
  );
}
