import { useEffect } from 'react';
import AudioCourseEarning from '../../components/audio-course/AudioCourseEarnings';
import { scrollToTop } from '../../utility/commonUtil';

export default function AudioCourseEarningsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <AudioCourseEarning />
    </>
  );
}
