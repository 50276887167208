import {
  CREATE_PODCAST,
  REMOVE_NEWPOD_STATE,
  LOAD_INCOMPLETE_PODS,
  LOAD_INCOMPLETE_POD,
  LOAD_ALL_MY_PODS,
  POD_SEARCH_INITIATED,
  GET_SINGLE_POD,
} from './podcastTypes';

const initialState = {
  isLoading: true,
  newPodcast: null,
  incompletePodcasts: null,
  myPodcasts: [],
  podcast: null,
  error: false,
  search: {
    isLoading: false,
    error: false,
    podcasts: [],
    allTotal: null,
    atVenueTotal: null,
    onlineTotal: null,
    videoTotal: null,
    audioTotal: null,

    // liveStreamVideoCourses: [],
  },
};

function podcastReducer(state = initialState, action) {
  const { type, payload } = action;
  let searchObj = state.search;

  switch (type) {
    case CREATE_PODCAST:
    case LOAD_INCOMPLETE_POD:
      return {
        ...state,
        isLoading: false,
        newPodcast: payload,
        error: false,
      };

    case GET_SINGLE_POD:
      return {
        ...state,
        isLoading: false,
        podcast: payload,
        error: false,
      };

    case REMOVE_NEWPOD_STATE:
      return {
        ...state,
        isLoading: true,
        newPodcast: {},
        error: false,
      };

    case LOAD_INCOMPLETE_PODS:
      return {
        ...state,
        incompletePodcasts: payload,
      };

    case LOAD_ALL_MY_PODS:
      return {
        ...state,
        myPodcasts: payload,
      };

    case POD_SEARCH_INITIATED:
      return {
        ...state,
        search: {
          ...searchObj,
          isLoading: true,
        },
      };

    case LOAD_ALL_MY_PODS:
      return {
        ...state,
        search: {
          isLoading: false,
          error: false,
          podcast: payload?.podcast,
          allTotal: payload?.totalConfCount,
          atVenueTotal: payload?.venueBasedConfCount,
          onlineTotal: payload?.onlineConfCount,
          videoTotal: payload?.videoConfCount,
          audioTotal: payload?.audioConfCount,
        },
      };
    default:
      return state;
  }
}

export default podcastReducer;
