import { useNavigate } from 'react-router-dom';
import { openInNewTab } from '../../utility/commonUtil';
import AddFileIcon from '../icons/AddFileIcon';

export default function ScheduleSessionColoumn({
  item,
  conference,
  isOrganiser,
  openFeedbackDetailPage,
}) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (openFeedbackDetailPage) {
      navigate(`feedback/${item?._id}`, {
        state: {
          type: 'SESSION',
          conference: item?.conference,
        },
      });
    }
  };
  return (
    <div
      key={item?._id}
      onClick={openFeedbackDetailPage ? handleClick : undefined}
    >
      <p
        style={{
          wordBreak: 'break-word',
          flex: 1,
        }}
        className="avenir-heavy-18"
      >
        {item?.title}
      </p>

      <div>
        <span
          style={{ whiteSpace: 'nowrap' }}
          className="caption-1-regular-gray3 mr-4"
        >
          Venue:
        </span>
        <span
          style={{ whiteSpace: 'nowrap' }}
          className="caption-2-regular-gray3"
        >
          {item?.venue}
        </span>
      </div>
      {item?.convenors?.length > 0 && (
        <div>
          <span
            style={{ whiteSpace: 'nowrap' }}
            className="caption-1-regular-gray3 mr-4"
          >
            Convenors:
          </span>
          <span
            style={{ whiteSpace: 'nowrap' }}
            className="caption-2-regular-gray3"
          >
            {item?.convenors
              ?.map(
                (item) =>
                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
              )
              .join(', ')}
          </span>
        </div>
      )}

      {item?.speakers?.length > 0 && (
        <div>
          <span
            style={{ whiteSpace: 'nowrap' }}
            className="caption-1-regular-gray3 mr-4"
          >
            Speakers:
          </span>
          <span
            style={{ whiteSpace: 'nowrap' }}
            className="caption-2-regular-gray3"
          >
            {item?.speakers
              ?.map(
                (item) =>
                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
              )
              .join(', ')}
          </span>
        </div>
      )}
      {(isOrganiser || (!isOrganiser && conference?.isBooked)) &&
        item?.resources?.length > 0 && (
          <div className="flex-vc">
            <div>
              <span
                style={{ whiteSpace: 'nowrap' }}
                className="caption-1-regular-gray3 mr-4"
              >
                Files:
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {item?.resources?.map((resource, index) => (
                <p
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '150px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#08415c',
                  }}
                  className="caption-2-regular-gray3"
                  title={resource?.fileNameWithExtension}
                >
                  <AddFileIcon className="icon-xs mr-4" />
                  <span onClick={() => openInNewTab(resource?.Location)}>
                    {resource?.fileNameWithExtension.length > 10
                      ? `${resource?.fileNameWithExtension.substring(0, 10)}...`
                      : resource?.fileNameWithExtension}
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}
      {(isOrganiser || (!isOrganiser && conference?.isBooked)) &&
        item?.powerPointFile?.length > 0 && (
          <div className="flex-vc">
            <div>
              <span
                style={{ whiteSpace: 'nowrap' }}
                className="caption-1-regular-gray3 mr-4"
              >
                PowerPoint:
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {item?.powerPointFile?.map((resource, index) => (
                <p
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#08415c',
                  }}
                  className="caption-2-regular-gray3"
                  title={resource?.fileNameWithExtension}
                >
                  <AddFileIcon className="icon-xs mr-4" />
                  <span onClick={() => openInNewTab(resource?.Location)}>
                    {resource?.fileNameWithExtension.length > 45
                      ? `${resource?.fileNameWithExtension.substring(0, 45)}...`
                      : resource?.fileNameWithExtension}
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}
