export default function CrossCancelIcon({ className }) {
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#08415C"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#08415C"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
