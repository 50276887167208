import { useState } from 'react';
import '../organizer-dashboard/welcome.styles.scss';
import UpcomingConfCard from './UpcomingConfCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './upcomingConfCard.scss';
import { useNavigate } from 'react-router-dom';
import UpcomingConfIcon from '../icons/UpcominConfIcon';

export default function UpcomingConfs({ upcomingConfs }) {
  const [showPotentialUserForm, setShowPotentialUserForm] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="upcomingConf-card">
      <p className="caption-1-heavy-primary mb-12 ml-16">
        Upcoming conferences
      </p>

      {upcomingConfs?.length > 0 ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={8}
          className="courseSwiper"
          breakpoints={{
            1: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 1,
            },
            980: {
              slidesPerView: 1,
            },
            1280: {
              slidesPerView: 1,
            },
          }}
        >
          {upcomingConfs?.length > 0 &&
            upcomingConfs?.map((item) => {
              return (
                <SwiperSlide key={item?._id}>
                  <UpcomingConfCard
                    key={item._id}
                    data={item}
                    potentialUserForm={setShowPotentialUserForm}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px',
          }}
        >
          <div>
            <div className="text-align-center">
              {' '}
              <UpcomingConfIcon />
            </div>

            <p
              style={{ color: ' #acacac', fontSize: '14px' }}
              className="caption-1-heavy-gray"
            >
              You have no upcoming conferences
            </p>
            <div className="text-align-center">
              <button
                onClick={() => navigate('/search-conference')}
                className="small-button-text small-button-text-green "
              >
                Explore Conferences
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
