import CloseIcon from '../../icons/CloseIcon';
export default function CourseContentHeader({ setCourseContent }) {
  return (
    <div className="flex-vc-sb vm-content-title">
      <h3
        style={{
          fontWeight: 700,
          lineHeight: 1.2,
          letterSpacing: 0,
          fonSize: '1.6rem',
        }}
      >
        Course Content
      </h3>
      <i style={{ cursor: 'pointer' }} onClick={() => setCourseContent(false)}>
        <CloseIcon fill="#000000" />
      </i>
    </div>
  );
}
