import { useEffect } from "react";
import Help from "../../components/footer/Help";

export default function HelpPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pt-64">
      <Help />
    </div>
  );
}
