import React, { Fragment } from 'react';

function CreditsIcon({ className, fill }) {
  return (
    <Fragment>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <path
                  d="M0 0L14 0 14 14 0 14z"
                  transform="translate(-114.000000, -1715.000000) translate(97.000000, 1441.000000) translate(17.000000, 274.000000)"
                />
                <path
                  fill="#08415C"
                  d="M8.75 9.333h1.75c.32 0 .583-.262.583-.583v-3.5c0-.32-.262-.583-.583-.583H8.75c-.32 0-.583.262-.583.583v3.5c0 .32.262.583.583.583zm.583-3.5h.584v2.334h-.584V5.833zm-4.083 3.5H7c.32 0 .583-.262.583-.583v-3.5c0-.32-.262-.583-.583-.583H5.25c-.32 0-.583.262-.583.583v3.5c0 .32.262.583.583.583zm.583-3.5h.584v2.334h-.584V5.833zM3.5 4.667c-.32 0-.583.262-.583.583v3.5c0 .32.262.583.583.583.32 0 .583-.262.583-.583v-3.5c0-.32-.262-.583-.583-.583zM1.167 3.5v7c0 .642.525 1.167 1.166 1.167h9.334c.641 0 1.166-.525 1.166-1.167v-7c0-.642-.525-1.167-1.166-1.167H2.333c-.641 0-1.166.525-1.166 1.167zm9.916 7H2.917c-.321 0-.584-.262-.584-.583V4.083c0-.32.263-.583.584-.583h8.166c.321 0 .584.263.584.583v5.834c0 .32-.263.583-.584.583z"
                  transform="translate(-114.000000, -1715.000000) translate(97.000000, 1441.000000) translate(17.000000, 274.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Fragment>
  );
}

export default CreditsIcon;
