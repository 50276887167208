import './welcome.styles.scss';
import './odEventChart.scss';

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function OdEventChart({ eventsCount }) {
  const data = {
    labels: [
      'Draft conferences',
      'Published conferences',
      'Draft video courses',
      'Published video courses',
      'Draft audio courses',
      'Published audio Courses',
      'Draft podcast',
      'Published podcasts',
    ],
    datasets: [
      {
        label: 'Total',
        data: [
          eventsCount?.draftConferences,
          eventsCount?.publishedConferences,
          eventsCount?.draftVideoCourses,
          eventsCount?.publishedVideoCourses,
          eventsCount?.draftAudioCourses,
          eventsCount?.publishedAudioCourses,
          eventsCount?.draftPodcast,
          eventsCount?.publishedPodcasts,
        ],
        backgroundColor: [
          '#e97f1c',
          'rgba(235, 148, 129, 0.26)',
          '#08415c',
          'rgba(93, 95, 239, 0.29)',
          '#dceef88',
          'rgba(93, 213, 239, 0.29)',
          '#ffbf9c',
          '#ffcc9c',
        ],
        borderColor: [],
        borderWidth: 0,
      },
    ],
  };

  const centerTextPlugin = {
    id: 'centerText',
    afterDraw: (chart) => {
      const { ctx, width, height } = chart;
      ctx.restore();
      //   const fontSize = (height / 214).toFixed(2);
      //   const fontSize = '32px';
      ctx.font = `${32}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = ' #08415c';
      let total =
        chart.data.datasets[0].data[0] +
        chart.data.datasets[0].data[1] +
        chart.data.datasets[0].data[2] +
        chart.data.datasets[0].data[3] +
        chart.data.datasets[0].data[4] +
        chart.data.datasets[0].data[5] +
        chart.data.datasets[0].data[6] +
        chart.data.datasets[0].data[7];

      const text = `${total ? total : 0}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 3.6);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.font = `${14}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = ' #7c8488';

      ctx.fillText('events', textX, height / 1.6);
      ctx.save();
    },
  };

  return (
    <div className="od-eventChart-welcome">
      <p className="caption-1-heavy-primary mb-12 ml-16">My events</p>
      <div
        style={{
          position: 'relative',
          width: '90%',
          height: '280px',
          marginTop: '40px',
        }}
      >
        <Doughnut
          id="myDoughnutChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            maintainAspectRatio: false,
            // aspectRatio: 1,

            cutout: 100,

            plugins: {
              legend: {
                display: true,
                position: 'right',

                labels: {
                  usePointStyle: true,
                  padding: 20,
                  // font: '14px',
                  generateLabels: (chart) => {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((value, index) => ({
                      text: `${chart.data.labels[index]}: (${value})`,
                      fillStyle: datasets[0].backgroundColor[index],
                      strokeStyle: datasets[0].backgroundColor[index],
                      // lineWidth: datasets[0].borderWidth,
                      hidden: false,
                      index,
                    }));
                  },
                },
              },
              tooltip: {
                enabled: true,
              },
            },
          }}
          plugins={[centerTextPlugin]}
          data={data}
        />
        ;
      </div>
    </div>
  );
}
