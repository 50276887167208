import { useEffect } from "react";
import ContactUs from "../../components/footer/ContactUs";

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pt-64">
      <ContactUs />
    </div>
  );
}
