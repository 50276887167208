import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import CloseIcon from "../../components/icons/CloseIcon";
import HamburgerIcon from "../../components/icons/HamburgerIcon";
import OrganizerSideNavbar from "../../components/navbar/OrganizerSideNavbar";
import { DrawerContext } from "./drawerContext";

export default function DashboardLayoutPage() {
  const [openMobileSidemenu, setOpenMobileSidemenu] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenMobileSidemenu(false);
      }
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });
    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);

  return (
    <div className="container">
      <div className="dashboard-container position-relative">
        <div className="db-hamberger-container">
          <i
            style={{ cursor: "pointer" }}
            onClick={() => setOpenMobileSidemenu(!openMobileSidemenu)}
          >
            <HamburgerIcon className="icon-lg" />
          </i>
        </div>
        <div className="sidemenu-container">
          <OrganizerSideNavbar />
        </div>
        <div className="container-offSidemenu">
          <Outlet />
        </div>
        {openMobileSidemenu && (
          <div ref={ref} className="db-mobile-sidemenu">
            <div
              className=" flex-vc-sb pt-8 pr-8"
              // onClick={() => setOpenMobileSidemenu(false)}
            >
              <div></div>
              <i
                style={{ cursor: "pointer" }}
                onClick={() => setOpenMobileSidemenu(false)}
              >
                <CloseIcon fill="#444444" className="icon-lg" />
              </i>
            </div>
            <DrawerContext.Provider
              value={{ openMobileSidemenu, setOpenMobileSidemenu }}
            >
              <OrganizerSideNavbar />
            </DrawerContext.Provider>
          </div>
        )}
      </div>
    </div>
  );
}
