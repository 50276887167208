import React, { Fragment } from 'react';

function FullStarIcon({ className }) {
  return (
    <Fragment>
      <svg
        width="24"
        height="24"
        fill="gold"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="12,2 15,9 22,9 16.5,14 18.5,21 12,17 5.5,21 7.5,14 2,9 9,9" />
      </svg>
    </Fragment>
  );
}

export default FullStarIcon;
