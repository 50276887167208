import React from "react";

function GoogleMeetIcon({ className }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="5rem"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 101.11"
      //   style="enable-background:new 0 0 122.88 101.11"
    >
      <style type="text/css"></style>
      <g>
        <polygon
          fill={"#188038"}
          points="69.51,50.56 81.49,64.25 97.6,74.54 100.41,50.65 97.6,27.28 81.18,36.32 69.51,50.56"
        />
        <path
          fill={"#1967D2"}
          d="M0,72.32v20.36c0,4.66,3.77,8.43,8.43,8.43h20.36L33,85.72l-4.21-13.4l-13.97-4.21L0,72.32L0,72.32z"
        />
        <polygon
          fill={"#EA4335"}
          points="28.79,0 0,28.79 14.82,33 28.79,28.79 32.93,15.57 28.79,0"
        />
        <polygon
          fill={"#FBBC04"}
          points="0,72.32 28.79,72.32 28.79,28.79 0,28.79 0,72.32"
        />
        <path
          fill={"#34A853"}
          d="M115.99,12.19L97.6,27.28v47.26l18.47,15.15c2.77,2.16,6.81,0.19,6.81-3.32V15.45 C122.88,11.89,118.74,9.94,115.99,12.19L115.99,12.19z M69.51,50.56v21.77H28.79v28.79h60.39c4.66,0,8.43-3.77,8.43-8.43V74.54 L69.51,50.56L69.51,50.56z"
        />
        <path
          fill={"#4285F4"}
          d="M89.18,0H28.79v28.79h40.73v21.77L97.6,27.28V8.43C97.6,3.77,93.83,0,89.18,0L89.18,0z"
        />
      </g>
    </svg>
  );
}

export default GoogleMeetIcon;
