function LikeOutlineIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.725 6.583c-.632.825-.975 1.972-.975 3.184 0 1.238.486 2.482 1.282 3.684.794 1.2 1.866 2.313 2.961 3.27A28.763 28.763 0 0 0 12 19.631H12a28.76 28.76 0 0 0 4.006-2.91c1.096-.957 2.168-2.07 2.962-3.27.796-1.202 1.282-2.446 1.282-3.684 0-1.213-.34-2.36-.97-3.184-.615-.804-1.523-1.333-2.78-1.333-1.277 0-2.238.53-2.895 1.076a5.301 5.301 0 0 0-.916.99 2.055 2.055 0 0 0-.04.06l-.007.012-.64 1.065-.642-1.062V7.39l-.004-.005-.004-.007a4.149 4.149 0 0 0-.22-.299 5.354 5.354 0 0 0-.741-.752C9.73 5.779 8.77 5.25 7.5 5.25c-1.25 0-2.159.529-2.775 1.333zM12 20.5l.364.656-.364.202-.364-.202L12 20.5zM3.534 5.671C4.421 4.513 5.762 3.75 7.5 3.75c1.717 0 3.006.721 3.85 1.423.254.212.47.423.649.616.177-.193.392-.404.646-.615.843-.703 2.132-1.424 3.855-1.424 1.743 0 3.085.762 3.97 1.922.87 1.138 1.28 2.624 1.28 4.095 0 1.632-.639 3.164-1.53 4.512-.894 1.35-2.072 2.563-3.226 3.572a30.257 30.257 0 0 1-4.621 3.3l-.006.003-.003.002L12 20.5l-.364.656-.003-.002-.006-.003a30.254 30.254 0 0 1-4.62-3.3c-1.155-1.009-2.333-2.221-3.226-3.572-.892-1.348-1.531-2.88-1.531-4.512 0-1.472.413-2.959 1.284-4.096z"
        fill="#9F9F9F"
      />
    </svg>
  );
}

export default LikeOutlineIcon;
