import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import './imgCarousal.styles.scss';

export default function ImgCarousal({ data }) {
  return (
    <div className="img-carousal-wrapper">
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="img-carousal"
      >
        {data?.map((item) => (
          <SwiperSlide key={item?.title}>
            <img src={item.Location} alt="venue image" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

ImgCarousal.propTypes = {
  data: PropTypes.array.isRequired,
};
