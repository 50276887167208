import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalX from "../../components/modal/ModalX";
import Signin from "../../components/signin/Signin";

const SigninPage = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const onDismiss = () => {
    navigate("/");
  };
  useEffect(() => {
    return () => setOpen(false);
  }, []);

  return (
    <section className="container">
      <ModalX onDismiss={onDismiss} open={open}>
        <Signin />
      </ModalX>
    </section>
  );
};

export default SigninPage;
