import CourseCard from '../cards/CourseCard';
import ConfCard from '../cards/ConfCard';

export default function MoreCourses({ media, moreMediaByHost }) {
  return (
    <div>
      <div className="avenir-18-500 mb-16">
        <span>More Courses by </span>
        <span className="avenir-16-500">
          {media?.hostedBy?.user
            ? `${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`
            : media?.hostedBy?.organization?.name}
        </span>
      </div>
      <div className="flex-vc flex-wrap mb-24" style={{ gap: 16 }}>
        {moreMediaByHost?.length > 0 &&
          moreMediaByHost?.map((item) => (
            <div key={item?._id}>
              {item?.mediaType === 'Conference' ? (
                <ConfCard conf={item} tag={item?.mediaType} />
              ) : (
                <CourseCard data={item} tag={item?.mediaType} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
