function VimeoIcon({ className, fill }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 333333 333333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      width="5rem"
    >
      <path
        d="M333333 89002c-1508 32437-24167 76941-68013 133330-45341 58886-83712 88406-115103 88406-19397 0-35883-17908-49312-53803-8978-32892-17908-65786-26911-98666-9962-35895-20659-53803-32118-53803-2485 0-11213 5274-26179 15693L0 99963c16478-14074 32748-28865 48526-43164 21945-18948 38437-28910 49442-29976 25927-2479 41964 15238 47933 53219 6443 40978 10941 66450 13489 76420 7483 33989 15693 50997 24682 50997 6961 0 17465-11010 31459-33109 13938-22006 21429-38806 22469-50351 2024-19009-5465-28597-22469-28597-8007 0-16215 1833-24684 5483 16400-53676 47669-79724 93920-78230 34209 909 50357 23113 48407 66580l31-20 128-213z"
        fill="#1ab7ea"
      />
    </svg>
  );
}

export default VimeoIcon;
