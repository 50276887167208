import { useNavigate } from 'react-router-dom';
import {
  getFormattedTimeOnyInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import AddFileIcon from '../icons/AddFileIcon';
import DateIcon from '../icons/DateIcon';

export default function EpisodeColoumn({
  episode,
  conference,
  item,
  index,
  openFeedbackDetailPage,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (openFeedbackDetailPage) {
      navigate(`feedback/${episode?._id}`, {
        state: {
          type: 'LECTURE',
          conference: item?.conference,
        },
      });
    }
  };
  return (
    <div onClick={openFeedbackDetailPage ? handleClick : undefined}>
      <p
        style={{
          flex: 1,
          wordBreak: 'break-word',
        }}
        className="avenir-heavy-18"
      >
        {`Lecture ${index + 1}: ${episode?.title}`}
      </p>

      <div>
        <DateIcon className="icon-xxs mr-12" />
        <span
          style={{ whiteSpace: 'nowrap' }}
          className="caption-2-regular-gray3"
        >
          {`${getFormattedTimeOnyInTz(
            episode?.startDate,
            conference?.timezone
          )} - ${getFormattedTimeOnyInTz(
            episode?.endDate,
            conference?.timezone
          )}`}
        </span>
      </div>

      <div>
        <span
          style={{ whiteSpace: 'nowrap' }}
          className="caption-1-regular-gray3 mr-4"
        >
          Speakers:
        </span>
        <span
          style={{ whiteSpace: 'nowrap' }}
          className="caption-2-regular-gray3"
        >
          {item?.speakers
            ?.map(
              (item) => `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
            )
            .join(', ')}
        </span>
      </div>
      {episode?.resources?.length > 0 && conference?.isBooked && (
        <div className="flex-vc">
          <div>
            <span
              style={{ whiteSpace: 'nowrap' }}
              className="caption-1-regular-gray3 mr-4"
            >
              Files:
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            {episode?.resources?.map((resource, index) => (
              <p
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '150px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#08415c',
                }}
                className="caption-2-regular-gray3"
                title={resource?.fileNameWithExtension}
              >
                <AddFileIcon className="icon-xs mr-4" />
                <span onClick={() => openInNewTab(resource?.Location)}>
                  {resource?.fileNameWithExtension.length > 10
                    ? `${resource?.fileNameWithExtension.substring(0, 10)}...`
                    : resource?.fileNameWithExtension}
                </span>
              </p>
            ))}
          </div>
        </div>
      )}
      {episode?.powerPointFile?.length > 0 && conference?.isBooked && (
        <div className="flex-vc">
          <div>
            <span
              style={{ whiteSpace: 'nowrap' }}
              className="caption-1-regular-gray3 mr-4"
            >
              PowerPoint:
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            {episode?.powerPointFile?.map((resource, index) => (
              <p
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#08415c',
                }}
                className="caption-2-regular-gray3"
                title={resource?.fileNameWithExtension}
              >
                <AddFileIcon className="icon-xs mr-4" />
                <span onClick={() => openInNewTab(resource?.Location)}>
                  {resource?.fileNameWithExtension.length > 50
                    ? `${resource?.fileNameWithExtension.substring(0, 45)}...`
                    : resource?.fileNameWithExtension}
                </span>
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
