import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import { loadIncopleteVCsAction } from "../../redux/video-course/videoCourseAction";
import { getOneIncompleteVideoCourse } from "../../utility/commonUtil";

export default function CreateVideoLanding() {
  const user = useSelector((state) => state.auth.user);
  const newVideoCourse = useSelector(
    (state) => state.video.incompleteVideoCourses
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   Get all incomplete conferences
  const getAllIncompleteVideoCourse = async (userId) => {
    const url = `/courses/videoCourse/users/${userId}/draft`;

    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadIncopleteVCsAction(response.data.data.videoCourses));
      }
    } catch (err) {
      if (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
      }
    }
  };

  const deleteIncompleteVideoCourse = async (mediaId) => {
    const url = `/courses/videoCourse/${mediaId}/users/${user?._id}/draft`;

    try {
      const response = await api.delete(url);
      if (response) {
        dispatch(loadIncopleteVCsAction(response?.data.data.videoCourses));
        dispatch(alertAction(response.data.message, "success"));
        // getAllIncompleteConfs(user._id);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };
  useEffect(() => {
    getAllIncompleteVideoCourse(user?._id);
  }, [user?._id]);

  return (
    <div>
      <div className="mb-40 body-regular-gray3">
        <h2 className="mb-32">Read before proceeding</h2>
        <p className="mb-16">
          First create Step 1 and click next button to save data before creating
          other steps.
        </p>
        <p className="mb-16">
          After Step 1 is created, any other step can be added and data can be
          saved on clicking next button. One can come back to complete remaining
          steps later.
        </p>
        <p className="mb-16">
          Incomplete Video course will show up below as well as in My video
          courses tab. Click on incomplete video courses heading to finish all
          steps before publishing the video course.
        </p>
        <p className="mb-16">One can not publish incomplete video courses.</p>
      </div>
      <button
        onClick={() => navigate("/dashboard/create-vc/step-1")}
        className="small-button small-button-green"
      >
        Proceed to create new video course
      </button>

      <h2 className="mb-16 mt-60">Incomplete Video Courses</h2>
      <div className="create-conf-table-wrap">
        <table>
          <thead></thead>
          <tbody>
            {newVideoCourse?.map((video, indx) => (
              <tr key={video._id}>
                <td>{indx + 1}</td>
                <td
                  onClick={() =>
                    getOneIncompleteVideoCourse(video._id, navigate)
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {video.title}
                </td>
                <td>
                  <button
                    onClick={() => deleteIncompleteVideoCourse(video._id)}
                    className="small-button-text small-button-text-red "
                  >
                    Delete
                  </button>
                </td>
                <td>
                  <button
                    onClick={() =>
                      getOneIncompleteVideoCourse(video._id, navigate)
                    }
                    className="small-button small-button-primary"
                  >
                    Edit{"   "}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
