import "../video-course/saved-video-courses/SavedVideoCourses.scss";
import SavedMediaCard from "../video-course/utils/SavedMediaCard/SavedMediaCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utility/api";
import { alertAction } from "../../redux/alert/alertAction";
import BannerWithGirlSketch from "../SVG-assets/BannerWithGirlSketch";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import { scrollToTop } from "../../utility/commonUtil";

const SavedAudioCourses = () => {
  const [savedCourses, setSavedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const getSavedCourses = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `/media/liked/users/${user?._id}?type=AUDIO`
      );

      setSavedCourses(response.data.data.likedMedias);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    getSavedCourses();
  }, [user?._id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const noSavedConfs = (
    <div className="nosaved-confs-wrap">
      <BannerWithGirlSketch className="sketch-md" />
      <div className="mt-28">
        <h2 className="color-primary mb-24">
          You haven't saved any video courses
        </h2>
        <button
          className="button button-primary"
          onClick={() => navigate("/search-conference")}
        >
          Explore Trending video courses
        </button>
      </div>
    </div>
  );
  const navigate = useNavigate();
  const savedConfs = (
    <>
      <h2 className="color-primary mb-40">Saved Audio Courses</h2>
      {savedCourses?.length > 0 &&
        savedCourses?.map((item) => {
          return (
            <SavedMediaCard
              key={item._id}
              data={item}
              getSaved={getSavedCourses}
            />
          );
        })}
    </>
  );

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : savedCourses?.length > 0 ? (
        savedConfs
      ) : (
        noSavedConfs
      )}
    </div>
  );
};

export default SavedAudioCourses;
