import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { createConferenceAction } from "../../redux/conference/conferenceAction";
import api from "../../utility/api";
import TextError from "../formik/TextError";
import DeleteIcon from "../icons/DeleteIcon";
import SelectFormType1 from "../reselect/SelectFormType1";
import { alertAction } from "../../redux/alert/alertAction";
import { platformOptions, websiteRegax } from "../../utility/commonUtil";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import "./createConference.styles.scss";

function liveStreamPlatformSelect({ options, field, form }) {
  return (
    <>
      <SelectFormType1
        options={options}
        value={field.value}
        onChange={(option) => {
          form.setFieldValue(field.name, option?.value);
        }}
        placeholder="Select Platform *"
        optionLabel={(option) => {
          return (
            <div className="flex-vc">
              <div className="icon-size mr-8">{option.icon}</div>
              <span>{option.label}</span>
            </div>
          );
        }}
      />
    </>
  );
}

const validationSchema = yup.object().shape({
  livestreams: yup.array().of(
    yup.object().shape({
      platform: yup.string().required("Required"),
      url: yup
        .string()
        .matches(websiteRegax, "Enter correct url")
        .required("Required"),
      otherPlatformName: yup.string().when("platform", {
        is: "other",
        then: yup.string().trim().required("Required"),
        otherwise: yup.string().notRequired(),
      }),
    })
  ),
});

export default function ConfLiveStream() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const conference = useSelector((state) => state.conference);
  const { newConference } = conference;

  // const livestreamsForEdit = newConference?.liveStreams?.map((e) => ({
  //   platform: e.platform,
  //   url: e.url,
  //   description: e.description ? e.description : "",
  //   otherPlatformName: e.otherPlatformName ? e.otherPlatformName : "",
  // }));

  const livestreams = [
    {
      platform: "",
      url: "",
      description: "",
      otherPlatformName: "",
    },
  ];
  const initialValues = {
    livestreams:
      !newConference?.liveStreams?.length > 0
        ? livestreams
        : newConference?.liveStreams,
  };

  async function onSubmit(values, actions) {
    if (!newConference?.completedStep1) {
      dispatch(alertAction("Complete step-1 first", "danger"));
      return;
    }
    const livestreamArray = values.livestreams.map((e) => {
      return {
        platform: e.platform,
        url: e.url,
        description: e.description,
        otherPlatformName: e.otherPlatformName,
      };
    });
    const formData = {
      liveStreamDetails: livestreamArray,
      conferenceId: newConference?._id,
    };

    try {
      const response = await api.post("conferences/step4", formData);
      if (response) {
        dispatch(createConferenceAction(response.data.data.conference));
        navigate("/dashboard/create-conf/step-5");
        dispatch(alertAction(response.data.message, "success"));
      }
    } catch (err) {
      if (err)
        dispatch(
          alertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
    }
  }

  return (
    <>
      <main className="conf-form-wrap">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            let myHeight = props.values.livestreams.length * 420;
            return (
              <>
                <form
                  className="form-type-1"
                  autoComplete="off"
                  onSubmit={props.handleSubmit}
                >
                  <h2>Livestream your conference</h2>
                  <div className="mb-24">
                    <p className="caption-1-regular-gray3 mb-16">
                      If you plan to live broadcast your conference, select your
                      platform of choice and add respective links.
                    </p>
                    <p className="caption-1-regular-gray3 mb-16">
                      If you marked location as online in Basic Info step,
                      provide at least one platform and it's link to live stream
                      the conference. *
                    </p>
                  </div>
                  <div>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="livestreams"
                        render={(arrayHelpers) => (
                          <div className="mb-64">
                            {arrayHelpers.form.values.livestreams.map(
                              (livestreams, index) => (
                                <motion.div
                                  key={index}
                                  className="livestram-grid mb-28"
                                  layout
                                  initial={{ scale: 0.5, opacity: 0 }}
                                  animate={{ scale: 1, opacity: 1 }}
                                  exit={{ scale: 0.5, opacity: 0 }}
                                  transition={{ type: "spring", duration: 0.5 }}
                                >
                                  <div>
                                    <div>
                                      <Field
                                        name={`livestreams[${index}].platform`}
                                        component={liveStreamPlatformSelect}
                                        options={platformOptions}
                                      />
                                    </div>
                                    <div className="mb-24">
                                      <ErrorMessage
                                        name={`livestreams[${index}].platform`}
                                        component={TextError}
                                      />
                                    </div>
                                    {props.values.livestreams[index]
                                      .platform === "other" ? (
                                      <>
                                        <div>
                                          <Field
                                            id="otherPlatformName"
                                            name={`livestreams[${index}].otherPlatformName`}
                                            type="text"
                                            placeholder="Other platform name *"
                                          />
                                        </div>
                                        <div className="mb-24">
                                          <ErrorMessage
                                            name={`livestreams[${index}].otherPlatformName`}
                                            component={TextError}
                                          />
                                        </div>
                                      </>
                                    ) : null}

                                    <div>
                                      <Field
                                        type="text"
                                        name={`livestreams.${index}.url`}
                                        placeholder="Livestream url link *"
                                      />
                                    </div>
                                    <div className="mb-24">
                                      <ErrorMessage
                                        name={`livestreams.${index}.url`}
                                        component={TextError}
                                      />
                                    </div>

                                    <div>
                                      <Field
                                        id="description"
                                        name={`livestreams[${index}].description`}
                                        as="textarea"
                                        type="text"
                                        cols="10"
                                        rows="5"
                                        placeholder="Description"
                                      />
                                    </div>
                                    <div className="mb-24">
                                      <ErrorMessage
                                        name={`livestreams[${index}].description`}
                                        component={TextError}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    {arrayHelpers.form.values.livestreams
                                      .length > 1 ? (
                                      <div
                                        style={{
                                          height: "60px",
                                        }}
                                        className="flex-vc"
                                      >
                                        <i
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <DeleteIcon />
                                        </i>
                                      </div>
                                    ) : null}
                                  </div>
                                </motion.div>
                              )
                            )}
                            <div>
                              <motion.button
                                whileTap={{ scale: 0.95 }}
                                className="button-outlined button-outlined-primary p-8"
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    platform: "",
                                    url: "",
                                    description: "",
                                    otherPlatformName: "",
                                  })
                                }
                              >
                                + Add more links
                              </motion.button>
                            </div>
                          </div>
                        )}
                      />
                    </AnimatePresence>
                  </div>

                  <div className="mb-72">
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                    />
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </main>
    </>
  );
}
