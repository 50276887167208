import MaterialTextInput from '../formik/MaterialTextInput';

export default function EmailOtp({ email }) {
  return (
    <>
      <div className="inactive-email">{email}</div>
      <div className="material-textfield input-container">
        <MaterialTextInput
          label="OTP *"
          name="emailOtp"
          type="text"
          placeholder=" "
          autoComplete="off"
        />
      </div>
    </>
  );
}
