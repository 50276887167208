import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import LocalAlert from '../alert/LocalAlert';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import MaterialTextInput from '../formik/MaterialTextInput';

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required('Required')
    .when(
      'balance',
      (balance, schema) =>
        balance &&
        schema
          .min(1, 'Amount must me greater than 0')
          .max(balance, 'Amount should less than available balance')
    ),
});

const WithdrawForm = ({
  setUserData,
  setShowWithdrawForm,
  availableBalance,
  availableAmountInNumber,
  currency,
  setTransactionCounts,
  setTransactionData,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const onSubmit = async (values) => {
    const { amount } = values;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      withdrawnDetails: {
        currency,
        amount,
        timezone,
      },
    };
    try {
      let response = await api.post(`/earnings/${user?._id}/withdrawn`, data);

      setUserData(response?.data?.data?.userData);
      setTransactionData(response.data.data.withdrawns);
      setTransactionCounts(response.data.data.allAmountArr);
      setShowWithdrawForm(false);
      dispatch(alertAction(response.data.message, 'success'));
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const initialValues = {
    amount: '',
    balance: availableAmountInNumber,
    availableBalance: availableBalance,
  };

  return (
    <>
      <div>
        <h2 className=" text-align-center color-primary mb-16">
          Withdraw Balance
        </h2>
        <LocalAlert />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="form-type-1">
                  <div className="mb-16">
                    <MaterialTextInput
                      id="amount"
                      type="text"
                      label="Available balance"
                      name="availableBalance"
                      disabled={true}
                      placeholder=" "
                    />
                  </div>
                  <div className="mb-16">
                    <MaterialTextInput
                      id="amount"
                      type="number"
                      label="Amount*"
                      name="amount"
                      placeholder=" "
                    />
                  </div>

                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      // cancelButtonClass="button button-primary"
                      fullWidth={true}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default WithdrawForm;
