export default function StreamIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.88 19.47A1 1 0 0 1 16 20H8a1 1 0 0 1-.88-.53 1 1 0 0 1 0-1l4-6a1 1 0 0 1 1.66 0l4 6a1 1 0 0 1 .1 1Z"
        fill="#4cb944"
        className="fill-464646"
      ></path>
      <path
        d="M20 16h-2v-2h2V6H4v8h2v2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        fill="#4cb944"
        className="fill-464646"
      ></path>
    </svg>
  );
}
