import { useEffect, useRef, useState } from 'react';
import '../view-course-page/viewcourse.scss';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import Loader from '../../components/loader/Loader';
import playIcon from '../../components/video-course/video-course-icons/play-button.png';
import docIcon from '../../components/video-course/video-course-icons/google-docs.png';
import queryIcon from '../../components/video-course/video-course-icons/question-sign.png';
import ReactPlayer from 'react-player';
import { alertAction } from '../../redux/alert/alertAction';
import CourseViewTabComponent from '../../components/view-course/CourseViewTabComponent/CourseViewTabComponent';
import PlayerNextButton from '../../components/view-course/PlayerNextButton/PlayerNextButton';
import CourseTitle from '../../components/view-course/CourseTitle/CourseTitle';
import CourseContentHeader from '../../components/view-course/CourseContentHeader/CourseContentHeader';
import CourseOverview from '../../components/view-course/CourseOverview/CourseOverview';
import { useNavigate, useParams } from 'react-router-dom';
import { loadSinglePodAction } from '../../redux/podcast/podcastAction';
import PodcastCourseContent from '../../components/view-course/PodcastCourseContent/PodcastCourseContent';

export default function PodcastViewPage() {
  const [video, setVideo] = useState({});
  const [loading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [toggleDrpDwn, setToggleDrpDwn] = useState({});
  const [courseContent, setCourseContent] = useState(true);
  const [toggleDrpDwnRes, setToggleDrpDwnRes] = useState({});
  const [activeTab, setActiveTab] = useState(2);
  const [episodes, setEpisodes] = useState([]);
  const purchaseId = useParams().purchaseId;

  const podcast = useSelector((state) => state.podcast.podcast);
  const videoData = useSelector((state) => state.video.duration);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playerRef = useRef();
  const imageTypeHandler = (list) => {
    if (list.type === 'doc') {
      return docIcon;
    } else if (list.type === 'query') {
      return queryIcon;
    } else {
      return playIcon;
    }
  };

  const onReady = () => {
    const timeToStart = video?.playTime ? video?.playTime : 0;

    playerRef.current.seekTo(timeToStart, 'seconds');

    // updateDuration();
  };
  const getMediaDetails = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `/podcast/purchase/${purchaseId}/users/${user?._id}`
      );

      let tempEpiArr = [];
      response.data.data.podcast?.episodes.forEach((episode) => {
        tempEpiArr.push(episode);
      });
      setVideo(response?.data?.data?.podcast?.lastPlayed);
      setEpisodes(tempEpiArr);
      setIsLoading(false);
      dispatch(loadSinglePodAction(response.data.data.podcast));
    } catch (err) {
      setIsLoading(false);
      // dispatch(alertAction(err?.response?.data?.message || "Something went wrong", 'danger'));
    }
  };

  const updatePodcastEpisodeDuration = async (
    data,
    purchaseId,
    userId,
    dispatch
  ) => {
    try {
      let filedata = JSON.parse(localStorage.getItem('duration'));
      let formData = {
        mediaDetails: {
          duration: filedata?.duration,
        },
      };

      let url = `podcast/purchase/${purchaseId}/users/${userId}/episodes/${filedata?.file?._id}`;

      let response = await api.patch(url, formData);
      if (response) {
        localStorage.removeItem('duration');
      }
    } catch (err) {}
  };
  const updateDuration = async () => {
    try {
      const currentTime = playerRef.current.getCurrentTime();
      let formData = {
        mediaDetails: {
          duration: currentTime,
        },
      };

      const response = await api.patch(
        `/podcast/purchase/${purchaseId}/users/${user._id}/episodes/${video._id}`,
        formData
      );
      dispatch(loadSinglePodAction(response?.data?.data?.podcast));
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const updateCompleteDuration = async (episode, onEnded) => {
    try {
      const currentTime = playerRef.current.getCurrentTime();

      let formData = {
        mediaDetails: {
          isCompleted: episode?.isCompleted ? false : true,
          duration: currentTime,
        },
      };

      if (onEnded) {
        formData.mediaDetails.isCompleted = true;
      }
      const response = await api.patch(
        `/podcast/purchase/${purchaseId}/users/${user._id}/episodes/${episode?._id}`,
        formData
      );

      dispatch(loadSinglePodAction(response.data.data.podcast));
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };
  const playerArrowClickHandler = (arrow) => {
    let epiIndex = 0;
    for (let i = 0; i < episodes?.length; i++) {
      if (episodes[i]._id.toString() === video?._id.toString()) {
        epiIndex = i;
        break;
      }
    }
    if (arrow === 'right') {
      epiIndex = epiIndex + 1;
    } else {
      epiIndex = epiIndex - 1;
    }

    if (arrow === 'right' && epiIndex != episodes?.length) {
      setVideo(episodes[epiIndex]);
    }

    if (arrow === 'left' && epiIndex >= 0) {
      setVideo(episodes[epiIndex]);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <PodcastCourseContent
            data={podcast}
            video={video}
            setVideo={setVideo}
            updateDuration={updateDuration}
            updateCompleteDuration={updateCompleteDuration}
            setToggleDrpDwn={setToggleDrpDwn}
            toggleDrpDwnRes={toggleDrpDwnRes}
            setToggleDrpDwnRes={setToggleDrpDwnRes}
            imageTypeHandler={imageTypeHandler}
          />
        );
      case 2:
        return <CourseOverview courseData={podcast} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getMediaDetails();
    return () => {
      console.log({ videoData });
      updatePodcastEpisodeDuration(videoData, purchaseId, user?._id);
      // dispatch(AddFileDuration(null));
    };
  }, [user?._id]);
  return (
    <div className="container pt-64">
      {loading ? (
        <Loader />
      ) : (
        <div className={courseContent ? 'vm-container' : 'vm-container-block'}>
          <div>
            <div className="vm-detail-container">
              <CourseTitle
                courseContent={courseContent}
                setCourseContent={setCourseContent}
                navigate={navigate}
                title={podcast?.title}
              />

              <div className="vm-player-container">
                <PlayerNextButton
                  playerArrowClickHandler={playerArrowClickHandler}
                />

                {video?.extension === 'mov' ||
                video?.extension === 'mpeg' ||
                video?.extension === 'webm' ||
                video?.extension === '.mov' ||
                video?.extension === '.mp4' ? (
                  <ReactPlayer
                    ref={playerRef}
                    controls={true}
                    playing={true}
                    url={video?.source?.Location}
                    width="100%"
                    height="100%"
                    config={{
                      file: { attributes: { controlsList: 'nodownload' } },
                    }}
                    onPause={updateDuration}
                    onStart={onReady}
                    onEnded={() => updateCompleteDuration(video, true)}
                    onProgress={(data) => {
                      localStorage.setItem(
                        'duration',
                        JSON.stringify({
                          duration: data?.playedSeconds,
                          file: video,
                        })
                      );
                    }}
                  />
                ) : (
                  <div className="p-16">
                    <ReactPlayer
                      ref={playerRef}
                      controls={true}
                      playing={true}
                      url={video?.source?.Location}
                      width="100%"
                      config={{
                        file: { attributes: { controlsList: 'nodownload' } },
                      }}
                      onPause={updateDuration}
                      onStart={onReady}
                      onEnded={() => updateCompleteDuration(video, true)}
                      onProgress={(data) => {
                        localStorage.setItem(
                          'duration',
                          JSON.stringify({
                            duration: data?.playedSeconds,
                            file: video,
                          })
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <CourseViewTabComponent
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            </div>
            <div className="p-16">{renderContent(activeTab)}</div>
          </div>
          <div className="vm-menu">
            {courseContent && (
              <div className="vm-menu-sections">
                {<CourseContentHeader setCourseContent={setCourseContent} />}
                <PodcastCourseContent
                  data={podcast}
                  video={video}
                  setVideo={setVideo}
                  updateDuration={updateDuration}
                  updateCompleteDuration={updateCompleteDuration}
                  setToggleDrpDwn={setToggleDrpDwn}
                  toggleDrpDwnRes={toggleDrpDwnRes}
                  setToggleDrpDwnRes={setToggleDrpDwnRes}
                  imageTypeHandler={imageTypeHandler}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
