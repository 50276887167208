import { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import CreditsIcon from '../icons/CreditsIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import PrinterIcon from '../icons/PrinterIcon';
import CloseIcon from '../icons/CloseIcon';
import LocalAlert from '../alert/LocalAlert';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
} from '../../utility/commonUtil';
import './attendeeBookingDetails.scss';

export default function AttendeeBookingDetails({
  bookingDetails,
  cancelTicket,
  showCancelButtons,
  setShowCancelButtons,
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Booking Receipt',
  });

  const getLocationString = () => {
    let locationStrig = 'Location';
    if (bookingDetails?.conference?.mode?.length > 0) {
      if (
        bookingDetails?.conference?.mode?.includes('venue') &&
        bookingDetails?.conference?.location
      ) {
        locationStrig = bookingDetails?.conference?.location;
      }

      if (bookingDetails?.conference?.mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (
        bookingDetails?.conference?.mode?.includes('venue') &&
        bookingDetails?.conference?.mode?.includes('onlineConf')
      ) {
        locationStrig = `${bookingDetails?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  const receiptComponent = (
    <div
      className="grid-table-wrap"
      style={{
        border: '.5px solid #ced9de',
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
        padding: '24px 4px 0px 4px',
      }}
    >
      <div className="px-20 mb-8">
        <h4 className="mb-24">Confemy Booking Details</h4>
        <div>
          <h4 className="mb-12">
            {bookingDetails?.conference
              ? bookingDetails?.conference?.title
              : 'Ticket title'}
          </h4>
          <div className="caption-2-regular-gray3 preview-text mb-16">
            <p>
              <span className="caption-1-heavy-cblack">Booking Date : </span>
              <span>
                {' '}
                {getFormattedDateInTz2(
                  bookingDetails?.bookingDate,
                  bookingDetails?.timezone
                )}
              </span>
            </p>
          </div>
          <div className="mb-12">
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getFormattedDateInTz(
                  bookingDetails?.conference?.startDate,
                  bookingDetails?.conference?.timezone
                )
                  ? `Start : ${getFormattedDateInTz(
                      bookingDetails?.conference?.startDate,
                      bookingDetails?.conference?.timezone
                    )}`
                  : `Ongoing event`}
              </span>
            </div>
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getFormattedDateInTz(
                  bookingDetails?.conference?.endDate,
                  bookingDetails?.conference?.timezone
                )
                  ? `End : ${getFormattedDateInTz(
                      bookingDetails?.conference?.endDate,
                      bookingDetails?.conference?.timezone
                    )}`
                  : `Ongoing event`}
              </span>
            </div>
            <div className="flex-vc  mb-6">
              <LocationIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {' '}
                {getLocationString()}
              </span>
            </div>
            <div className="flex-vc  mb-6">
              <CreditsIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {bookingDetails?.conference?.credits?.length > 0
                  ? `${bookingDetails?.conference?.credits[0]?.quantity} credits`
                  : 'Credits not offered'}
              </span>
            </div>
          </div>
          <p>
            <span className="caption-1-heavy-cblack">
              Total Booking Amount:{' '}
              {bookingDetails?.price === 0
                ? 'FREE'
                : `${bookingDetails?.conference?.currencySymbol} ${bookingDetails?.price}`}
            </span>
          </p>
        </div>
      </div>
      <div
        className="p-20 mb-8 mt-8"
        style={{
          backgroundColor: showCancelButtons ? '#ecf0f2' : null,
        }}
      >
        <div>
          {bookingDetails?.ticketBooked === 4 ? (
            <span
              style={{ color: '#4cb944' }}
              className="caption-1-heavy-cblack"
            >
              Cancelled
            </span>
          ) : (
            showCancelButtons && (
              <button
                className="small-button small-button-green"
                disabled={!showCancelButtons}
                onClick={() => {
                  cancelTicket(bookingDetails);
                }}
              >
                Cancel
              </button>
            )
          )}
        </div>
        <div className="abd-grid-item mt-8">
          <p className="mb-4">
            <span className="caption-1-heavy-cblack">First Name: </span>
            <span className="caption-2-regular-gray3">
              {bookingDetails?.user?.firstName}
            </span>
          </p>
          <p className="mb-4">
            <span className="caption-1-heavy-cblack">Last Name: </span>
            <span className="caption-2-regular-gray3">
              {bookingDetails?.user?.lastName}
            </span>
          </p>
          <p className="mb-4">
            <span className="caption-1-heavy-cblack">Email: </span>
            <span className="caption-2-regular-gray3">
              {bookingDetails?.user?.email}
            </span>
          </p>
          <p className="caption-1-heavy-cblack mb-4">Ticket Details</p>
          <p className="caption-2-regular-gray3 mb-4">
            <span>Ticket ID: </span>{' '}
            <span>{bookingDetails?.registrationNumber}</span>
          </p>
          <p className="caption-2-regular-gray3 mb-4">
            <span>Ticket Name: </span>{' '}
            <span>{bookingDetails?.ticket?.name}</span>
          </p>
          <p className="caption-2-regular-gray3 mb-4">
            <span>Ticket Price: </span>
            <span>
              {bookingDetails.price > 0
                ? `${bookingDetails?.conference?.currencySymbol}`
                : null}
            </span>
            <span>
              {' '}
              {bookingDetails.price > 0 ? bookingDetails.originalPrice : 'FREE'}
            </span>
          </p>
          <p className="caption-2-regular-gray3 mb-4">
            <span>Service Charges: </span>
            <span>
              {bookingDetails.price > 0
                ? `${bookingDetails?.conference?.currencySymbol} `
                : null}
            </span>
            <span>
              {' '}
              {bookingDetails.price > 0
                ? (bookingDetails.price - bookingDetails.originalPrice).toFixed(
                    2
                  )
                : '0'}
            </span>
          </p>
          <p>
            <span className="caption-1-heavy-cblack">Total Ticket Price: </span>
            <span className="caption-2-regular-gray3">
              {bookingDetails.price > 0
                ? `${bookingDetails?.conference?.currencySymbol}`
                : null}
            </span>
            <span className="caption-2-regular-gray3">
              {' '}
              {bookingDetails.price > 0 ? bookingDetails.price : 'FREE'}
            </span>
          </p>
        </div>
        <div
          className="caption-2-regular-gray3"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {bookingDetails?.refunded && (
            <p className="caption-1-heavy-cblack mb-4">Refunded </p>
          )}
          {bookingDetails?.refunded && bookingDetails?.refundAmount && (
            <p>
              <span>{bookingDetails?.conference?.currencySymbol}</span>
              <span>{bookingDetails?.refundAmount}</span>
            </p>
          )}
          {bookingDetails?.refundStatus === 5 && <p>Refund request denied</p>}
        </div>
      </div>
      <div></div>
    </div>
  );

  useEffect(() => {
    // return () => setBookingDetails(null);
  }, []);
  return (
    <>
      <div style={{ justifyContent: 'flex-end' }} className="flex-vc pt-4">
        <div style={{ height: 60 }} className="flex-vc">
          <button
            className="small-button-text small-button-text-green"
            onClick={() => handlePrint()}
            disabled={showCancelButtons}
          >
            <div className="flex-vc">
              <i
                style={{ position: 'relative', paddingTop: 5 }}
                className="mr-8"
              >
                <PrinterIcon className="icon-sm" />
              </i>
              <span>Print</span>
            </div>
          </button>
          <div>
            {showCancelButtons ? (
              <button
                style={{ width: 150 }}
                onClick={() => setShowCancelButtons(false)}
                className="small-button-outlined small-button-outlined-green"
              >
                Reset
              </button>
            ) : (
              <button
                style={{ width: 150 }}
                className="small-button-text small-button-text-green"
                onClick={() => setShowCancelButtons(true)}
              >
                <div className="flex-vc">
                  <i
                    style={{ position: 'relative', paddingTop: 5 }}
                    className="mr-4"
                  >
                    <CloseIcon className="icon-size" fill="#4cb944" />
                  </i>
                  <span>Cancel Ticket</span>
                </div>
              </button>
            )}
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="print-margins">
            {receiptComponent}
          </div>
        </div>
      </div>
      <LocalAlert />
      {receiptComponent}
    </>
  );
}
