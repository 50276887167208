import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import PlusIcon from '../icons/PlusIcon';
import AddChapterButton from '../create-video-course/AddChapterButton';
import AudioCourseSectionForm from './AudioCourseSectionForm';
import AudioCourseChapter from './AudioCourseChapter';
import AudioCourseEpisodeList from './AudioCourseEpisodeList';
import { useNavigate } from 'react-router-dom';

export default function AudioCourseStep3() {
  const audio = useSelector((state) => state.audio);
  const sections = audio?.newAudioCourse?.sections;
  const [mySections, setMySections] = useState(sections || []);
  const [showChapter, setShowChapter] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [chapterToEdit, setChapterToEdit] = useState({});

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/dashboard/create-ac/step-4');
  };

  return (
    <div className="create-event-wrap">
      {showChapter ? (
        <AudioCourseChapter
          setShowChapter={setShowChapter}
          selectedSection={selectedSection}
          setMySections={setMySections}
          episodeToEdit={chapterToEdit}
          setEpisodeToEdit={setChapterToEdit}
        />
      ) : (
        <>
          <p className="body-regular-gray3 mb-24" style={{ color: '#aabdc7' }}>
            All mandatory fields are makred by *
          </p>
          <h2 className="mb-32 color-primary">Course Structure</h2>
          <div>
            {mySections?.map((section, index) => (
              <div className="mb-44" key={section?.uuid}>
                <div className="mb-16">
                  <AudioCourseSectionForm
                    section={section}
                    sectionIndex={index}
                    setMySections={setMySections}
                  />
                </div>
                {section?.episodes?.length > 0 && (
                  <div className="mb-16">
                    <AudioCourseEpisodeList
                      setMySections={setMySections}
                      list={section?.episodes}
                      setChapterToEdit={setChapterToEdit}
                      setShowChapter={setShowChapter}
                    />
                  </div>
                )}
                <AddChapterButton
                  section={section}
                  setSelectedSection={setSelectedSection}
                  setShowChapter={setShowChapter}
                />
              </div>
            ))}
          </div>
          <div className="ce-section-grid mb-16">
            <div
              className="add-section-button"
              onClick={() =>
                setMySections((prev) => [
                  ...prev,
                  {
                    title: '',
                    uuid: uuid(),
                  },
                ])
              }
            >
              <i className="position-relative pt-5 mr-8">
                <PlusIcon className="icon-size" fill="#38b52f" />
              </i>
              <span>Add Section</span>
            </div>
            <div className="create-event-res">{/* leave this empty */}</div>
          </div>
          <div
            style={{
              marginBottom: 120,
            }}
          >
            <AddChapterButton />
          </div>
          <div className="submit-cancel-loader-wrap">
            <button
              onClick={() => handleNext()}
              className="button button-green "
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
