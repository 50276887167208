import { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import GoogleIcon from "../icons/GoogleIcon";
import Loader from "../loader/Loader";
import "./registerWgoogle.styles.scss";

function RegisterWGoogle({ label }) {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="caption-1-regular-cblack or-container">
        {" "}
        <span className="or-line"></span> OR <span className="or-line"></span>
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsLoading(true);
          window.location.replace("https://api.confemy.com/api/google");
        }}
        className="signin-social caption-1-medium-cblack position-relative"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <span>
              <GoogleIcon className="signin-google-icon" />
            </span>
            {label}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default RegisterWGoogle;
