import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import Dropzone from 'react-dropzone';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import SelectFormType1 from '../reselect/SelectFormType1';
import DeleteIcon from '../icons/DeleteIcon';
import PlusIcon from '../icons/PlusIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import DocumentIcon from '../icons/DocumentIcon';
import { alertAction } from '../../redux/alert/alertAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import ImageGalleryIcon from '../icons/ImageGalleryIcon';
import Loader from '../loader/Loader';
import { createAudioCourseAction } from '../../redux/audio-course/audioCourseAction';

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
  host: yup.string().required('Required. Choose who is organizing the course.'),
  organizationId: yup.string().when('host', {
    is: 'organization',
    then: yup.string().required('Required'),
  }),
  description: yup
    .string()
    .required('Required')
    .trim()
    .min(50, 'Minimum 50 characters required')
    .max(400, 'Maximum 400 characters allowed'),

  banner: yup
    .array()
    // .of(yup.object())
    .min(1, 'Required')
    .compact(),
  keypoints: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        keypoint: yup
          .string()
          // .matches(websiteRegax, "Enter correct url")
          .required('Required')
          .test(
            'isFormTest',
            'Save key point using tick icon on right',
            function (value) {
              if (this.parent.isForm) {
                return false;
              } else {
                return true;
              }
            }
          ),
        isForm: yup.bool(),
      })
    )
    .min(1, 'Add at least one key point'),
});

export default function AudioCourseStep1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFileLoading, setIsFileLoading] = useState();

  const user = useSelector((state) => state.auth.user);
  const audio = useSelector((state) => state.audio);
  const { newAudioCourse } = audio;

  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  async function onSubmit(values, actions) {
    const {
      banner,
      title,
      host,
      keypoints,
      description,
      resources,
      mediaId,
      author,
    } = values;

    const formData = {
      mediaDetails: {
        title: title,
        host: host,
        userId: user?._id,
        organizationId: values?.organizationId,
        keyPoints: keypoints,
        description: description,
        banner: banner,
        author: author ? author : user?._id,
        mediaId: mediaId,
        resources: resources,
      },
    };

    //  Submit banner image and add image url to formData object

    try {
      const response = await api.post('audioCourses/step1', formData);

      if (response) {
        dispatch(createAudioCourseAction(response.data.data.media));
        actions.resetForm({ values: initialValues });
        navigate('/dashboard/create-ac/step-2');
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  }
  // end of submit

  const initialValues = {
    title: newAudioCourse?.title || '',
    host: newAudioCourse?.host || '',
    organizationId: newAudioCourse?.hostedBy?.organization?._id || '',
    description: newAudioCourse?.description || '',
    banner: newAudioCourse?.banner || [],
    isBannerLoading: false,
    deletedBanner: [],
    author: newAudioCourse?.author?._id || '',
    mediaId: newAudioCourse?._id || '',
    keypoints:
      !newAudioCourse?.keyPoints?.length > 0 ? [] : newAudioCourse?.keyPoints,
    resources: newAudioCourse?.resources || [],
    isResourceLoading: false,
  };

  const deleteFile = async (key, fieldName, fieldValue, setFieldValue) => {
    try {
      setIsFileLoading(true);

      const response = await api.delete('courses/files', {
        data: {
          files: [
            {
              Key: key,
            },
          ],
        },
      });
      setFieldValue(
        fieldName,
        fieldValue?.filter((item) => item.Key !== response.data.data.Key)
      );

      setIsFileLoading(false);
    } catch (err) {
      setIsFileLoading(false);
      dispatch(alertAction('File failed to delet', 'danger'));
    }
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(
            response?.data?.data?.organization
          )
        );
      }
    } catch (err) {
      dispatch(alertAction(err?.response?.data?.message, 'danger'));
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user?._id);
  }, [user?._id]);
  console.log({ isFileLoading });
  return (
    <>
      <main className="create-event-wrap">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <form
                className="form-type-4"
                autoComplete="off"
                onSubmit={props.handleSubmit}
              >
                <div className="create-event-grid">
                  <div>
                    <p
                      className="body-regular-gray3 mb-24"
                      style={{ color: '#aabdc7' }}
                    >
                      All mandatory fields are makred by *
                    </p>
                    <h2 className="color-primary mb-36">Basic Information</h2>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Title *</h4>
                      <Field
                        name="title"
                        // value="title"
                        type="text"
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                      />
                      <ErrorMessage name="title" component={TextError} />
                    </div>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Hosted by *</h4>
                      <div role="group" className="mb-16">
                        <Field
                          type="radio"
                          style={{ display: 'none' }}
                          id="conf-org"
                          name="host"
                          value="organization"
                        />
                        <label htmlFor="conf-org">
                          <div
                            className={`mr-24 basicInfo-btn ${
                              props.values.host === 'organization'
                                ? 'button-outlined-active'
                                : 'button-outlined-inactive'
                            }`}
                          >
                            Organization
                          </div>
                        </label>
                        <Field
                          type="radio"
                          style={{ display: 'none' }}
                          name="host"
                          id="conf-myself"
                          value="user"
                        />
                        <label htmlFor="conf-myself">
                          <div
                            className={`basicInfo-btn ${
                              props.values.host === 'user'
                                ? 'button-outlined-active'
                                : 'button-outlined-inactive'
                            }`}
                          >
                            Myself
                          </div>
                        </label>
                        <ErrorMessage name="host" component={TextError} />
                      </div>
                      <AnimatePresence initial={false} mode="wait">
                        {props.values.host === 'organization' && (
                          <motion.div
                            key="organization"
                            layout
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.5, opacity: 0 }}
                            transition={{ type: 'spring', duration: 0.5 }}
                          >
                            <SelectFormType1
                              label="organizationId"
                              options={organizationsListForSelect}
                              name="organizationId"
                              onChange={(value) =>
                                props.setFieldValue(
                                  'organizationId',
                                  value?.value
                                )
                              }
                              placeholder="Select organization"
                              value={props.values.organizationId}
                              isDisabled={props.values.host !== 'organization'}
                            />
                            <ErrorMessage
                              name="organizationId"
                              component={TextError}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Description *</h4>
                      <Field
                        id="description"
                        name="description"
                        as="textarea"
                        type="text"
                        cols="10"
                        rows="5"
                        placeholder="Description"
                      />
                      <ErrorMessage name="description" component={TextError} />
                    </div>
                    <div className="mb-8">
                      <h4 className="avenir-20-500 mb-8">
                        Course key learnings *
                      </h4>
                      <p
                        className="body-regular-gray3"
                        style={{ color: '#aabdc7' }}
                      >
                        What user will learn from the course? Add one key point
                        per input box *
                      </p>
                    </div>

                    <div className="mb-40">
                      <AnimatePresence initial={false} mode="wait">
                        <FieldArray
                          name="keypoints"
                          render={(arrayHelpers) => {
                            const keypoints =
                              arrayHelpers.form.values.keypoints;
                            let indxOfLastItem = 0;
                            if (keypoints?.length > 0) {
                              indxOfLastItem = keypoints?.length - 1;
                            }
                            return (
                              <Fragment>
                                <div
                                  // grid for keypoints
                                  className="ce-points-grid  mb-16"
                                >
                                  {keypoints?.map((keypoint, index) => (
                                    <motion.div
                                      // grid item
                                      key={index}
                                      className={
                                        keypoint.isForm
                                          ? 'full-width-griditem'
                                          : ''
                                      }
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div className="position-relative">
                                        <Field
                                          disabled={!keypoint.isForm}
                                          style={{ paddingRight: 36 }}
                                          type="text"
                                          name={`keypoints.${index}.keypoint`}
                                          placeholder="Key points *"
                                          autoFocus
                                        />

                                        <i
                                          style={{
                                            right: 8,
                                            cursor: 'pointer',
                                          }}
                                          className="right-input-icon"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <CrossCancelIcon className="icon-size" />
                                        </i>

                                        <div
                                          className={
                                            !keypoint.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onMouseDown={() => {
                                              // props.validateField(
                                              //   "keypoints"
                                              // );
                                              // props.setTouched({
                                              //   keypoints: [
                                              //     {
                                              //       keypoint: true,
                                              //     },
                                              //   ],
                                              // });
                                              props.setFieldTouched(
                                                `keypoints.${index}.keypoint`
                                              );
                                              props.validateField('keypoints');
                                              if (keypoint.keypoint !== '') {
                                                const points = keypoints?.map(
                                                  (item) =>
                                                    item.uuid === keypoint.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );
                                                arrayHelpers.form.setFieldValue(
                                                  'keypoints',
                                                  points
                                                );
                                              }
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        name={`keypoints.${index}.keypoint`}
                                        component={TextError}
                                      />
                                    </motion.div>
                                  ))}
                                </div>
                                <div
                                  // outside kyepoints grid
                                  // this div controls size of the button
                                  style={{
                                    width: '24rem',
                                  }}
                                >
                                  <motion.button
                                    disabled={
                                      keypoints[indxOfLastItem]?.keypoint ===
                                        '' || keypoints[indxOfLastItem]?.isForm
                                    }
                                    className="ce-insert-button flex-vc"
                                    whileTap={{ scale: 0.95 }}
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        uuid: uuid(),
                                        keypoint: '',
                                        isForm: true,
                                      })
                                    }
                                  >
                                    + Add key points
                                  </motion.button>
                                </div>
                              </Fragment>
                            );
                          }}
                        />
                      </AnimatePresence>
                      {props.touched.keypoints &&
                        Boolean(props.errors.keypoints) &&
                        (typeof props.errors.keypoints === 'string' ? (
                          <TextError>{props.errors.keypoints}</TextError>
                        ) : null)}
                    </div>

                    <div className="mb-32">
                      <h4 className="avenir-20-500 mb-8">
                        Add course banner image*
                      </h4>
                      {/* className="confbanner-dropzone-container " controls
                        the size of SingleImageUploader */}
                      <div className="event-banner-dropzone-container position-relative">
                        {props.values?.banner?.length > 0 && !isFileLoading ? (
                          props.values.banner?.map((image) => (
                            <div
                              key={image.Key}
                              className="confbanner-container"
                            >
                              <div className="confbanner-wrap">
                                <img
                                  className="confbanner"
                                  alt="course banner"
                                  src={image.Location}
                                  // Revoke data uri after image is loaded
                                  // onLoad={() => {
                                  //   URL.revokeObjectURL(image.Location);
                                  // }}
                                />
                              </div>
                              <div className="confbanner-overlay"></div>
                              <div
                                onClick={() => {
                                  deleteFile(
                                    image?.Key,
                                    'banner',
                                    props.values.banner,
                                    props.setFieldValue
                                  );
                                }}
                                className="confbanner-delete-circle"
                              >
                                <DeleteIcon className="icon-size" />
                              </div>
                            </div>
                          ))
                        ) : props.values.isBannerLoading || isFileLoading ? (
                          <div className="singleimage-dropzone">
                            <Loader />
                          </div>
                        ) : (
                          <Dropzone
                            // accept="video/mp4"
                            accept={{
                              'image/*': ['.jpeg', '.png'],
                            }}
                            maxSize={10 * 1024 * 1024}
                            maxFiles={1}
                            onDrop={async (acceptedFiles, errors) => {
                              errors.forEach((err) => {
                                err?.errors.forEach((e) => {
                                  if (e.code.toString() == 'file-too-large') {
                                    dispatch(
                                      alertAction(
                                        `File is too large. Maximum size is 10MB.`,
                                        'danger'
                                      )
                                    );
                                  } else if (err.code === 'file-invalid-type') {
                                    dispatch(
                                      alertAction(
                                        `Valid file type required.`,
                                        'danger'
                                      )
                                    );
                                  }
                                });
                              });
                              if (acceptedFiles?.length > 0) {
                                const dataObj = new FormData();
                                let oldMedia = [];
                                acceptedFiles.map((item) =>
                                  !item.Key
                                    ? dataObj.append('file', item)
                                    : oldMedia.push(item)
                                );

                                if (dataObj.has('file')) {
                                  props.setFieldValue('isBannerLoading', true);
                                  try {
                                    const mediaResponse = await api.post(
                                      'media/upload',
                                      dataObj
                                    );
                                    if (mediaResponse) {
                                      props.setFieldValue(
                                        'banner',
                                        mediaResponse.data.data
                                      );
                                    }
                                    props.setFieldValue(
                                      'isBannerLoading',
                                      false
                                    );
                                  } catch (err) {
                                    props.setFieldValue(
                                      'isBannerLoading',
                                      false
                                    );
                                    dispatch(
                                      alertAction(
                                        'Media failed to save',
                                        'danger'
                                      )
                                    );
                                  }
                                }
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: 'singleimage-dropzone',
                                })}
                              >
                                <div className="text-align-center">
                                  <i>
                                    <ImageGalleryIcon className="icon-lg" />
                                  </i>
                                  <p className="caption-1-medium-primary">
                                    <u> Add file</u>
                                  </p>
                                </div>
                                <input {...getInputProps()} />
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </div>
                      <ErrorMessage name="banner" component={TextError} />
                    </div>
                    <div className="mb-60">
                      <p className="avenir-20-500 mb-12">
                        Add resources for the chapter
                      </p>
                      <div className="mb-16">
                        {props.values.resources?.map((resource) => (
                          <div
                            key={resource?.Key}
                            className="flex-vc-sb uc-uploadfile-input mb-8 "
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              className="flex-vc"
                            >
                              <i className="mr-8">
                                <DocumentIcon className="icon-sm" />
                              </i>
                              <p className="caption-1-regular-gray2">
                                {resource?.title || 'Resource file'}
                              </p>
                            </div>
                            <i
                              onClick={() =>
                                deleteFile(
                                  resource?.Key,
                                  'resources',
                                  props.values.resources,
                                  props.setFieldValue
                                )
                              }
                            >
                              <CrossCancelIcon
                                className=" icon-size"
                                // fill="#000000"
                              />
                            </i>
                          </div>
                        ))}
                        <div>
                          {props.values.isResourceLoading ? (
                            <div className="uc-uploadfile-input flex-vc position-relative">
                              <Loader />
                            </div>
                          ) : (
                            <Dropzone
                              accept={{
                                'image/*': ['.png', '.gif', '.jpeg'],
                                'application/pdf': ['.pdf'],
                                // "image/*": [], // All images
                                // "text/html": [".html", ".htm"],
                              }}
                              // accept="image/*,audio/*,video/*"
                              multiple={true}
                              // maxSize={524288000}
                              maxFiles={3}
                              onDrop={async (acceptedFiles) => {
                                // props.setFieldValue("resources", acceptedFiles);
                                if (acceptedFiles?.length > 0) {
                                  const formDataObj = new FormData();
                                  let alreadySavedOnS3 = [];

                                  acceptedFiles.map((item) =>
                                    !item.Key
                                      ? formDataObj.append('file', item)
                                      : alreadySavedOnS3.push(item)
                                  );

                                  if (formDataObj.has('file')) {
                                    props.setFieldValue(
                                      'isResourceLoading',
                                      true
                                    );
                                    try {
                                      const s3Response = await api.post(
                                        'media/upload',
                                        formDataObj
                                      );

                                      if (s3Response) {
                                        props.setFieldValue('resources', [
                                          ...alreadySavedOnS3,
                                          ...props.values.resources,
                                          ...s3Response.data.data,
                                        ]);
                                        props.setFieldValue(
                                          'isResourceLoading',
                                          false
                                        );
                                      }
                                    } catch (err) {
                                      props.setFieldValue(
                                        'isResourceLoading',
                                        false
                                      );
                                      dispatch(
                                        alertAction(
                                          'Files(s) failed to save',
                                          'danger'
                                        )
                                      );
                                    }
                                  }
                                }
                                //
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    {...getRootProps({
                                      className: 'uc-uploadfile-input flex-vc',
                                    })}
                                  >
                                    <div
                                      className="flex-vc"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflowX: 'hidden',
                                      }}
                                    >
                                      <i className="position-relative mr-8">
                                        <PlusIcon className="icon-size" />
                                      </i>
                                      <p className="caption-1-medium-primary">
                                        Add file
                                      </p>
                                    </div>
                                    <input {...getInputProps()} />
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-72">
                      <SubmitCancelButtonWithLoader
                        isSubmitting={props.isSubmitting}
                        onCancel={() => props.resetForm({})}
                        fullWidth={true}
                      />
                    </div>
                  </div>
                  <div className="create-event-res">{/* keep empty */}</div>
                </div>
              </form>
            );
          }}
        </Formik>
      </main>
    </>
  );
}
