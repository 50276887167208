import { useState } from 'react';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import PriceIcon from '../icons/PriceIcon';
import ModalX from '../modal/ModalX';
import AttendeeTickets from './AttendeeTickets';
import { openInNewTab } from '../../utility/commonUtil';
import api from '../../utility/api';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
} from '../../utility/commonUtil';

import './attendeeBookingCard.scss';
import { useNavigate } from 'react-router-dom';

export default function AttendeeBookingCard({ booking }) {
  const [openModalX, setOpenModalX] = useState(false);
  const [openTicketsModal, setOpenTicketsModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const getLocationString = () => {
    let locationStrig = 'Location';
    if (booking?.conference?.mode?.length > 0) {
      if (
        booking?.conference?.mode?.includes('venue') &&
        booking?.conference?.location
      ) {
        locationStrig = booking?.conference?.location;
      }

      if (booking?.conference?.mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (
        booking?.conference?.mode?.includes('venue') &&
        booking?.conference?.mode?.includes('onlineConf')
      ) {
        locationStrig = `${booking?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  const getBookingDetails = async (bookingId) => {
    try {
      let response = await api.get(
        `/conferences/bookings/${bookingId}/${bookingId}`
      );
      setBookingDetails(response.data.data.bookingDetails);
      setIsLoading(false);
      setOpenModalX(true);
    } catch (err) {}
  };

  const openTickets = (bookingId) => {
    getBookingDetails(bookingId);
    setOpenTicketsModal(true);
  };

  return (
    <>
      <div className="ab-card position-relative">
        <div
          className="ab-grid"
          onClick={() =>
            openInNewTab(`/search-conference/${booking?.conference?._id}`)
          }
        >
          <div className="ab-card-body">
            <h4 className="mb-24">
              {booking?.conference?.title
                ? booking?.conference?.title
                : 'Ticket title'}
            </h4>
            <div className="flex-vc  mb-4">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getFormattedDateInTz(
                  booking?.conference?.startDate,
                  booking?.conference?.timezone
                )
                  ? `Start : ${getFormattedDateInTz(
                      booking?.conference?.startDate,
                      booking?.conference?.timezone
                    )}`
                  : `Ongoing event`}
              </span>
            </div>
            <div className="flex-vc  mb-4">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getFormattedDateInTz(
                  booking?.conference?.endDate,
                  booking?.conference?.timezone
                )
                  ? `End : ${getFormattedDateInTz(
                      booking?.conference?.endDate,
                      booking?.conference?.timezone
                    )}`
                  : `Ongoing event`}
              </span>
            </div>

            <div className="flex-vc  mb-4">
              <LocationIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getLocationString()}
              </span>
            </div>

            <div className="flex-vc  mb-4">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                Booking Date:{' '}
                {getFormattedDateInTz2(
                  booking?.bookingDate,
                  booking?.timezone
                ) &&
                  getFormattedDateInTz2(
                    booking?.bookingDate,
                    booking?.timezone
                  )}
              </span>
            </div>
            <div className="flex-vc mb-32">
              <PriceIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                Amt Paid: {booking?.conference?.currency} -{' '}
                {booking?.paymentAmount > 0 ? booking?.paymentAmount : '0:00'}
              </span>
            </div>
            <div className="mb-12">
              <h5 className="caption-1-regular-gray2 mb-4">Tickets Booked</h5>
              {booking?.tickets?.map((item, indx) => {
                return (
                  <div key={item?._id}>
                    <p className="caption-2-regular-gray3">
                      {item.ticket?.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div>
              <h6 className="caption-1-regular-gray2 mb-4">Booking Number</h6>
              <p className="avenir-heavy-18">
                <span>
                  {booking?.bookingNumber > 0
                    ? booking?.bookingNumber
                    : 'Booking ID not found'}
                </span>
              </p>
            </div>
          </div>
          <div className="ab-button">
            <button
              className="small-button small-button-primary"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/conference/schedule/${booking?.conference?._id}`, {
                  state: {
                    backButton: true,
                  },
                });
              }}
            >
              Schedule
            </button>

            <button
              className="small-button small-button-green"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/conference/schedule/${booking?.conference?._id}`, {
                  state: {
                    backButton: true,
                  },
                });
              }}
            >
              Feedback
            </button>

            <button
              className="small-button small-button-primary"
              onClick={(e) => {
                e.stopPropagation();
                openTickets(booking?._id);
                // printTicket(`/print-ticket/${booking._id}`, booking._id);
              }}
            >
              Show Ticket
            </button>

            <button
              className="small-button small-button-green"
              onClick={(e) => {
                e.stopPropagation();
                openTickets(booking?._id);
                // printTicket(`/print-ticket/${booking._id}`, booking._id);
              }}
            >
              Stream Live
            </button>
          </div>
        </div>
        {isLoading && openModalX && <div className="ab-overlay"></div>}
        {isLoading && openTicketsModal && <div className="ab-overlay"></div>}
      </div>

      {openTicketsModal && !isLoading && (
        <ModalX
          onDismiss={() => setOpenTicketsModal(false)}
          open={openTicketsModal && !isLoading}
        >
          <div className="modalx-container-lg mb-32 px-24">
            <AttendeeTickets bookingDetails={bookingDetails} />
          </div>
        </ModalX>
      )}
    </>
  );
}
