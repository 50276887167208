import { useFormik } from "formik";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { alertAction, localAlertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import {
  renderRowStyle,
  getFormattedDateInTz2,
  getValue,
  openInNewTab,
  scrollToTop,
} from "../../utility/commonUtil";
import BdfoConfTitle from "../bookings-for-organizer/BdfoConfTitle";
import "../bookings-for-organizer/bdfoCounts.scss";
import BackButton from "../button/BackButton";
import DocumentIcon from "../icons/DocumentIcon";
import SearchIcon from "../icons/SearchIcon";
import ModalX from "../modal/ModalX";
import { customSelectStyle3 } from "../reselect/selectUtil";
import AddDocuments from "../upload-document/AddDocuments";
import CustomPagination from "../pagination/CustomPagination";
import UploadArrowIcon from "../icons/UploadArrowIcon";
import OrgBookingDetails from "../bookings-for-organizer/OrgBookingDetails";
import "./attendeeCredits.scss";
import PageLimitSelect from "../pagination/PageLimitSelect";

const options = [
  { label: "All", value: "all" },
  {
    label: "Approved",
    value: 1,
  },
  {
    label: "Requested",
    value: 3,
  },
];

const confirmObj = {
  All: "all",
  1: "Confirmed",
  2: "Pending",
  3: "User dropped",
  4: "Cancelled",
};

const validationSchema = yup.object().shape({
  fileName: yup.string().trim().required("Required").min(1).max(25),
  file: yup.array().min(1).required("Required"),
  type: yup.string().required("Required"),
  creditId: yup.string().when("type", {
    is: "creditCertificate",
    then: yup.string().required("Required"),
  }),
  creditCustomQuantity: yup
    .number()
    .when(
      "creditQuantity",
      (creditQuantity, schema) =>
        creditQuantity &&
        schema
          .min(1, "Quantity must me greater than 0")
          .max(
            creditQuantity,
            `Quantity should less than or equal to ${creditQuantity}`
          )
          .required("Required")
    ),
});

export default function AttendeeCredits() {
  // for filters
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("all");
  // set data
  const [apiData, setApiData] = useState([]);
  const [listForPagination, setListForPagination] = useState([]);
  const [displayList, setDisplayList] = useState([]);

  const [creditStatusCount, setCreditStatusCount] = useState({});
  const [conf, setConf] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [attendeeId, setAttendeeId] = useState("");
  const [progress, setProgress] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  //set booking details data from api
  const [bookingDetails, setBookingDetails] = useState(null);

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell
  async function onRowClick(id) {
    try {
      setDetailsLoading(true);
      const response = await api.get(`/attendees/${id}/bookingDetails`);
      setBookingDetails(response.data.data?.attendeeDetails);
      setDetailsLoading(false);
    } catch (err) {
      setShowDetails(false);
      setDetailsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const confId = useParams().confId;
  const user = useSelector((state) => state.auth.user);

  let creditOptions = conf?.credits?.map((credit) => {
    return {
      label: credit?.creditId?.name,
      quantity: credit?.quantity,
      value: credit?.creditId?._id,
    };
  });

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const viewCertificate = (certificate) => {
    openInNewTab(certificate?.location);
  };

  const closeBookingDetailsModal = () => {
    setShowDetails(false);
    setBookingDetails(null);
    getAttendees(confId);
  };

  const onSubmit = async (values, action) => {
    let formData = {
      attendeeDetails: {
        fileName: values?.fileName,
        creditId: values?.creditId,
        creditQuantity: values?.creditCustomQuantity,
        type: values?.type,
        data: [],
        userId: user?._id,
      },
    };
    setProgress(true);
    if (formik.values.file?.length > 0) {
      const fileDataObj = new FormData();
      formik.values.file.forEach((file) => fileDataObj.append("file", file));

      if (fileDataObj.has("file")) {
        try {
          const fileResponse = await api.post("/fileUploads", fileDataObj);
          if (fileResponse) {
            formData.attendeeDetails.data = fileResponse.data.data;
          }
        } catch (err) {
          setProgress(false);
          dispatch(
            localAlertAction(
              err?.response?.data?.message || "Something went wrong",
              "danger"
            )
          );
          return;
        }
      }
    }

    try {
      const response = await api.post(
        `/organizers/attendess/${attendeeId}/uploadCertificate?conferenceId=${conf?._id}`,
        formData
      );

      setApiData(response.data.data.attendees);
      setCreditStatusCount({
        approved: response.data.data.approvedRequest,
        pending: response.data.data.pendingRequest,
        left: response.data.data.leftRequest,
        requested: response.data.data.requested,
      });
      setConf(response.data.data.confDetails);
      setProgress(false);
      formik.resetForm({});
      closeModal();
    } catch (error) {
      setProgress(false);
      dispatch(localAlertAction(error.response.data.message, "danger"));
    }
  };

  const initialValues = {
    fileName: "",
    file: [],
    type: conf && conf?.isAccredited ? "creditCertificate" : "otherCertificate",
    creditOptions: creditOptions || [],
    creditQuantity: creditOptions?.[0]?.quantity || 0,
    creditCustomQuantity: creditOptions?.[0]?.quantity || 0,
    creditId: creditOptions?.[0]?.value || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const getAttendees = async (confId) => {
    try {
      const response = await api.get(
        `/organizers/conferences/${confId}/attendees`
      );

      if (response) {
        setApiData(response.data.data.attendees);
        setCreditStatusCount({
          approved: response.data.data.approvedRequest,
          pending: response.data.data.pendingRequest,
          left: response.data.data.leftRequest,
          requested: response.data.data.requested,
        });
        setConf(response.data.data.confDetails);
      }
    } catch (error) {
      dispatch(alertAction(error.response.data.message, "error"));
    }
  };

  // search filters

  const searchFilter = (list, text, filterText) => {
    let filterArr = [];
    if (filterText === "all") {
      filterArr = list;
    }
    if (filterText === 1) {
      filterArr = list?.filter((item) => item?.approvedCredits?.length > 0);
    }

    if (filterText === 3) {
      filterArr = list?.filter((item) => item?.requestedCredits?.length > 0);
    }
    let filteredresult = filterArr?.filter((item) => {
      if (
        item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
        item?.bookingNumber.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
        item?.registrationNumber.toLowerCase().indexOf(text.toLowerCase()) >= 0
      ) {
        return item;
      }
    });

    setListForPagination(filteredresult);
    setTotalRecords(filteredresult?.length);
  };

  //code for frotnend pagination
  const loadPaginationData = (data) => {
    const { currentPage, pageLimit, totalRecords } = data;
    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const refundData = listForPagination.slice(0, pageLimit);
      setDisplayList(refundData);
    } else {
      const refundData = listForPagination.slice(
        indexOfFirstElement,
        indexOflastElement
      );
      setDisplayList(refundData);
    }
  };

  const closeModal = () => {
    // getAttendees();
    formik.resetForm({});
    setShowModal(false);
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [listForPagination]);

  useEffect(() => {
    searchFilter(apiData, searchText, filter);
  }, [apiData, searchText, filter]);

  useEffect(() => {
    getAttendees(confId);
  }, [confId]);
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="bdfo-container">
      <div className="mb-40">
        <BackButton
          className="primary"
          onBackButtonClick={() => navigate("/dashboard/credit-requests")}
        />
      </div>
      <div>
        <BdfoConfTitle
          title={conf?.title}
          startDate={conf?.startDate}
          timeZone={conf.timezone}
        />
      </div>
      <div className="bdfo-counts-wrap mb-32">
        <h3 className="color-primary mb-16">Credit Requests</h3>
        <table>
          <thead>
            <tr>
              <th>Pending</th>
              <th>Approved</th>
              <th>Requested</th>
              {/* <th>Left</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{creditStatusCount?.pending}</td>
              <td>{creditStatusCount?.approved}</td>
              <td>{creditStatusCount?.requested}</td>
              {/* <td>{creditStatusCount?.left}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="bdfo-booking-table-wrap mb-60">
        <h3 className="color-primary mb-16">Max credits offered</h3>
        <table className="bdfo-booking-table max-credits-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {conf?.credits?.map((credit) => (
              <tr key={credit._id}>
                <td className="cell-hyphens">{credit?.creditId?.name}</td>
                <td>{credit?.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h3 className="color-primary mb-16">All Credit Requests</h3>
      <div className="bdfo-booking-table-sort">
        <div className="form-type-3">
          <div style={{ position: "relative" }}>
            <input
              type="text"
              id="myConfsSearchText"
              placeholder="Search Name or Tcket ID or Booking No"
              name="searchText"
              value={searchText}
              onChange={handleSearchText}
              autoComplete="off"
            />
            <i
              className={
                searchText?.length > 0
                  ? "display-none"
                  : "conf-search-input-icon"
              }
            >
              <SearchIcon width="2.4rem" height="2.4rem" />
            </i>
          </div>
        </div>
        <Select
          // key={getValue(options, props.value)}
          value={getValue(options, filter, false)}
          onChange={(option) => {
            setFilter(option.value);
            // handlFilter(option.value);
          }}
          options={options}
          placeholder="Filter"
          name="filter"
          styles={customSelectStyle3}
        />
      </div>
      <div className="grid-table-wrap position-relative">
        <ul className="grid-table-7-900">
          <li>Name</li>
          <li>Ticket ID</li>
          <li>Booking No</li>
          <li>Booking Date</li>
          <li>Ticket Status</li>
          <li>Certificates</li>
          <li>
            <div className="text-align-center">Action</div>
          </li>

          {displayList?.map((attendee, indx) => (
            <Fragment key={attendee._id}>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => {
                  setShowDetails(true);
                  onRowClick(attendee?._id);
                }}
              >
                {attendee?.name}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => {
                  setShowDetails(true);
                  onRowClick(attendee?._id);
                }}
              >
                {attendee?.registrationNumber}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => {
                  setShowDetails(true);
                  onRowClick(attendee?._id);
                }}
              >
                {attendee?.bookingNumber}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => {
                  setShowDetails(true);
                  onRowClick(attendee?._id);
                }}
              >
                {getFormattedDateInTz2(
                  attendee?.bookingDate,
                  attendee?.timezone
                )}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => {
                  setShowDetails(true);
                  onRowClick(attendee?._id);
                }}
              >
                {
                  <div
                    style={{
                      color: `${attendee?.ticketBooked == 4 ? "#4cb944" : ""}`,
                    }}
                  >
                    <p>{confirmObj[attendee?.ticketBooked]}</p>
                    {attendee?.cancelledBy?.userType && (
                      <p>
                        <span>by</span>{" "}
                        <span>
                          {attendee?.cancelledBy?.userType
                            .toString()
                            .toLowerCase()}
                        </span>
                      </p>
                    )}
                  </div>
                }
              </li>

              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                // onClick={() => { setShowDetails(true);onRowClick(attendee?._id)}}
              >
                {attendee.approvedCredits?.length > 0 &&
                  attendee.approvedCredits.map((credit) => {
                    return (
                      <div
                        key={credit?._id}
                        className="flex-vc-sb  caption-1-regular-gray2"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="flex-vc"
                          onClick={(e) => {
                            e.stopPropagation();
                            viewCertificate(credit);
                          }}
                        >
                          <i className="position-relative mr-4">
                            <DocumentIcon className="icon-sm " />
                          </i>
                          <span className="hover-bg p-4">
                            {credit?.certificateName}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                {attendee.otherCertificateUploaded && (
                  <div className="flex-vc-sb mt-8 caption-1-regular-gray2">
                    <div
                      style={{ cursor: "pointer" }}
                      className="flex-vc"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewCertificate(attendee?.otherCertificate);
                      }}
                    >
                      <i className="position-relative mr-4">
                        <DocumentIcon className="icon-sm " />
                      </i>
                      <span className="hover-bg p-4">
                        {attendee?.otherCertificate?.certificateName}
                      </span>
                    </div>
                  </div>
                )}
              </li>
              <li
                // onMouseEnter={() => handleMouseEnter(attendee?._id)}
                // onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                // onClick={() => { setShowDetails(true);onRowClick(attendee?._id)}}
              >
                <div className="text-align-center">
                  <button
                    style={{ padding: 2 }}
                    className="button-outlined button-outlined-primary hover-bg"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(true);
                      setAttendeeId(attendee?._id);
                      onRowClick(attendee?._id);
                    }}
                  >
                    <div className="flex-vc">
                      <i className="position-relative pt-6">
                        <UploadArrowIcon className="icon-xs" />
                      </i>
                      <p style={{ fontSize: 13 }}>Certificate</p>
                    </div>
                  </button>
                </div>
              </li>
            </Fragment>
          ))}
        </ul>
        {showDetails && detailsLoading && <div className="ab-overlay"></div>}
        {showModal && detailsLoading && <div className="ab-overlay"></div>}
        {showDetails && !detailsLoading && (
          <ModalX onDismiss={closeBookingDetailsModal} open={showDetails}>
            <div className="modalx-container-md pb-24">
              <OrgBookingDetails
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            </div>
          </ModalX>
        )}
        {showModal && !detailsLoading && (
          <ModalX onDismiss={closeModal} open={showModal}>
            <div className="modalx-container-lg">
              <AddDocuments
                bookingDetails={bookingDetails}
                formik={formik}
                isProgress={progress}
              />
            </div>
          </ModalX>
        )}
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => {
              loadPaginationData(data);
            }}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
}
