import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import { useState } from 'react';
import FullStarIcon from '../icons/FullStarIcon';
import EmptyStarIcon from '../icons/EmptyStarIcon';
import LocalAlert from '../alert/LocalAlert';

const validationSchema = yup.object().shape({
  title: yup.string().trim().max(250).required('Required'),
  message: yup.string().trim().max(500).required('Required'),
  rating: yup.number().required('Required'),
});
export default function AddFeedbackForm({
  type,
  data,
  setShowFeedbackForm,
  setSessions,
  viewPage,
}) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  // submit function
  const onSubmit = async (values, actions) => {
    if (isAuthenticated && user) {
      try {
        const { message, title, rating } = values;

        let formData = {
          feedbackDetails: {
            message,
            title,
            rating,
            id: data?._id,
            type: type,
            conferenceId: data?.conference,
            userId: user?._id,
          },
        };

        let url = '/feedbacks';

        if (viewPage) {
          url = '/feedbacks/view';
        }
        let response = await api.post(url, formData);
        setSessions(response.data.data.sessions);
        setShowFeedbackForm(false);
        dispatch(localAlertAction(response.data.message, 'danger'));
      } catch (err) {
        dispatch(localAlertAction(err.response?.data.message, 'danger'));
      }
    } else {
      dispatch(localAlertAction('Please login to give a feedback.', 'info'));
    }
  };

  const handleStarPress = (star) => {
    //   setSelectedRating(rating);
    formik.setFieldValue('rating', star);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      rating: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <form
      className="form-type-1"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <h2 className="color-primary text-align-center mb-16">Give Feedback</h2>
      <div className="mb-40">
        <h4
          className="color-primary mb-24 px-8 text-align-center"
          style={{ fontFamily: 'Avenir', fontWeight: 'normal' }}
        >
          {data?.title}
        </h4>
      </div>
      <LocalAlert />
      <p className="text-align-center caption-1-heavy-primary  color-primary mb-16">
        Rate this activity
      </p>
      <div style={{ justifyContent: 'center' }} className="flex-vc mb-8">
        {[1, 2, 3, 4, 5].map((star, index) => {
          return (
            <div
              key={star}
              onClick={() => handleStarPress(star)}
              style={{ cursor: 'pointer' }}
            >
              {star <= formik.values.rating ? (
                <i className="mr-4">
                  <FullStarIcon style={{ width: '40px', height: '40px' }} />
                </i>
              ) : (
                <i className="mr-4">
                  <EmptyStarIcon style={{ width: '40px', height: '40px' }} />
                </i>
              )}
            </div>
          );
        })}
      </div>
      <div className="mb-24 text-align-center">
        {formik.touched.rating && Boolean(formik.errors.rating) && (
          <TextError>{formik.errors.rating}</TextError>
        )}
      </div>
      <div className="mb-18">
        <div className="material-textfield ">
          <input
            id="hall"
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            placeholder=" "
          />
          <label>Feedback Title</label>
        </div>
        <div className="mb-8">
          {formik.touched.title && Boolean(formik.errors.title) && (
            <TextError>{formik.errors.title}</TextError>
          )}
        </div>
      </div>
      <div className="mb-20">
        <div>
          <textarea
            id="description"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            placeholder="Type your feedback here.."
          />
        </div>
        <div className="mb-10">
          {formik.touched.message && Boolean(formik.errors.message) && (
            <TextError>{formik.errors.message}</TextError>
          )}
        </div>
      </div>
      <div className="mt-16">
        <SubmitCancelButtonWithLoader
          fullWidth
          isSubmitting={formik.isSubmitting}
          onCancel={() => formik.resetForm({})}
        />
      </div>
    </form>
  );
}
