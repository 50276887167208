export default function RefundPolicy() {
  return (
    <div style={{ padding: '4% 5% 5%' }} className="container-lg">
      <h2 className="color-primary mb-32">
        Confemy a product of Portamed Private Limited.
      </h2>
      <h2 className="color-primary mb-32">
        Refund Process takes 5-7 working days.
      </h2>
      <h2 className="color-primary mb-40">Cancellation and Refund Policy</h2>
      <h4 className="color-primary mb-16">Live Activity</h4>
      <div className="caption-2-regular-gray3">
        <p className="mb-16">
          Cancellations and/or refunds of live activities can be granted only
          under the following circumstances.
        </p>
        <p className="mb-16">
          1. When cancelling a registration for a course 14 days or more before
          the course start date, a full refund (minus a Rs.1000 administrative
          fee) will be issued in the same form of payment the registration was
          received.
        </p>
        <p className="mb-16">
          2. No refunds are granted less than 14 days before the course start
          date.
        </p>
        <p className="mb-16">
          Request for cancellations must be submitted in writing to
          portamedservices@gmail.com.
        </p>
        <h4 className="color-primary mb-16">Right to Cancel or Postpone</h4>
        <p className="mb-16">
          Confemy reserves the right to cancel or postpone any course due to
          unforeseen circumstances. In the unlikely event that Confemy must
          cancel or postpone this course, the registration fee will be fully
          refunded. Confemy is not responsible for any related costs, charges or
          expenses to participants, including fees assessed by airline, travel
          or lodging agencies.
        </p>
        <h4 className="color-primary mb-16">
          Enduring Material/Online Activities
        </h4>
        <p className="mb-16">
          Product returns or cancellations of enduring material/online
          activities can be granted only under the following circumstances:{' '}
        </p>
        <p className="mb-16">
          1. Customer may cancel their participation from an online activity
          within seven days of initiating the course if no credit has been
          claimed, and a written request is made within 30 days of purchase. The
          registration fee paid, minus a 25% administrative fee (up to Rs.1000
          per online activity), will be refunded.
        </p>
        <p className="mb-16">
          2. Defective product(s) must be reported within 90 days of purchase
          for an exchange of the same title. If product was damaged during
          shipment, contact the shipper, such as UPS damaged package claims.
          Customer is not responsible for shipping and handling costs of
          replacement products, and will be required to return the defective
          product(s) before the replacement can be sent. Product was not shipped
          within 48 hours after the order was placed, business days only.
          Additional time is required for orders placed on non-business
          days.Product or activity can be returned/cancelled for any reason if
          it is unopened product (the seal must be intact or the item must be
          shrink wrapped) and no credit has been claimed. Must be requested
          within 30 days of purchase. Customer is responsible for return
          shipping fees and is not refunded initial shipping costs. All requests
          must be submitted in writing using the Contact Us Form.{' '}
        </p>
        <h4 className="color-primary mb-16">Subscription products</h4>
        <p className="mb-16">
          Subscription product cancellations refunds can be granted only under
          the following circumstances:{' '}
        </p>
        <p className="mb-16">
          1. Customer may cancel their subscription in writing within
          twenty-four hours of initiating the subscription if no credit has been
          claimed and product has not been viewed.
        </p>
        <p className="mb-16">
          2. The registration fee paid, minus a 25% administrative fee (up to
          Rs.1000 per subscription), will be refunded.
        </p>
        <p className="mb-16">
          3. All requests must be submitted in writing using the Contact Us
          Form.{' '}
        </p>
        <h4 className="color-primary mb-16">Terms and Conditions</h4>
        <p className="mb-16">
          Confemy is not responsible if the delay in the shipment was due to the
          customer providing the incorrect shipping address, weather, or was the
          fault of the shipper.{' '}
        </p>
        <p className="mb-16">
          Returns/refunds cannot be utilized if the goal is to re-purchase the
          item again as a combo set.{' '}
        </p>
        <p className="mb-16">
          The discounts for purchasing combo sets are only honored when both
          items are purchased together initially.{' '}
        </p>
        <p className="mb-16">
          Credit cannot be granted on the portion of the product that is
          returned.{' '}
        </p>
        <p className="mb-16">
          Once credit has been obtained by the customer, no refund will be
          issued.{' '}
        </p>
        <p className="mb-16">
          All requests must be submitted in writing using the Contact Us Form.{' '}
        </p>
        <p className="mb-16">
          This is a general cancellation policy. Your course may detail special
          conditions.
        </p>
      </div>
    </div>
  );
}
