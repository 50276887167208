import React from "react";
import { useNavigate } from "react-router-dom";
import HostYourConfSketch from "../icons/HostYourConfSketch";
import "./hostYourConfs.styles.scss";
function HostYourConfs(props) {
  const navigate = useNavigate();
  return (
    <div className="host-conf-section">
      <div className="host-conf-grid">
        <div className="host-conf-text">
          <h1 className="mb-24">
            Plan & Host on <br /> Confemy
          </h1>
          <button
            className="button button-green"
            onClick={() => {
              navigate("/list-conferences");
            }}
            // onClick={() => navigate("/dashboard/create-conference")}
          >
            HOST ON CONFEMY
          </button>
        </div>
        <div className="host-conf-sketch-wrap">
          <HostYourConfSketch className="host-your-conference-sketch" />
        </div>
      </div>
    </div>
  );
}

export default HostYourConfs;
