import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';
import MyFeedbacks from '../../components/my-schedule/MyFeedbacks';
export default function MyFeedbackPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <MyFeedbacks />
    </>
  );
}
