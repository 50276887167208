import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { scheduleSessionFilter } from '../../utility/commonUtil';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import '../schedule/schedule.scss';
import { alertAction } from '../../redux/alert/alertAction';
import ScheduleTimeColoumn from '../schedule/ScheduleTimeColumn';
import ScheduleSessionColoumn from '../schedule/ScheduleSessionColumn';
import EpisodeColoumn from '../schedule/EpisodeColumn';
import ScheduleFilter from '../schedule/ScheduleFilter';
import AddFeedbackForm from '../my-schedule/AddFeedbackForm';
import ModalX from '../modal/ModalX';
import CheckTickIcon from '../icons/CheckTickIcon';
import ScheduleActionBtnWithLoader from '../schedule/ScheduleActionBtnWithLoader';
import LikeRedIcon from '../icons/LikeRedIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import ScheduleHeader from '../schedule/ScheduleHeader';

export default function MyFeedbacks() {
  const [searchText, setSearchText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingId, setLoadingId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);
  const [rowData, setRowData] = useState('');
  const [splicedList, setSplicedList] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [type, setType] = useState(false);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch();

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  const getConferences = async (userId) => {
    try {
      const response = await api.get(`feedbacks/users/${userId}/confs`);

      setConferences(response.data.data.conferences);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    const url = `session/view/users/${user?._id}?conferenceId=${conference?.value}&type=MYFEEDBACK`;

    try {
      setIsLoading(true);
      const response = await api.get(url);

      if (response) {
        if (!conference?.value) {
          setFilter1(response.data.data.conference.value);
        }
        setConference(response?.data.data.conference);
        setSessions(response.data.data.sessions);

        if (response.data.data.conference.value) {
          getHalls(response.data.data.conference.value);
        }

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const unsaveSession = async (id, type) => {
    setLoadingId(id);
    const url = `/session/view/savedActivity/conference/${conference?.value}/users/${user?._id}?eventType=${type}&id=${id}`;

    try {
      const response = await api.delete(url);

      if (response) {
        setSessions(response.data.data.sessions);
        setLoadingId(null);
      }
    } catch (err) {
      setLoadingId(null);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const saveSession = async (id, type) => {
    setLoadingId(id);
    const url = `session/savedActivity/users/${user?._id}`;

    let formData = {
      eventDetails: {
        type,
        conferenceId: conference?.value,
      },
    };

    if (type === 'SESSION') {
      formData.eventDetails.sessionId = id;
    }
    if (type === 'LECTURE') {
      formData.eventDetails.episodeId = id;
    }

    try {
      const response = await api.post(url, formData);

      if (response) {
        setSessions(response.data.data.sessions);
        setLoadingId(null);
      }
    } catch (err) {
      setLoadingId(null);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getConferences(user?._id);
    getSessions();
  }, [user._id]);

  useEffect(() => {
    getSessions();
  }, [filter1]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    let finalFilterResult = scheduleSessionFilter(
      venueFilter,
      searchText,
      selectedDate
    );

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div>
      <>
        <div className="myconfs-header">
          <h2 className="color-primary mb-8">Feedback</h2>
        </div>
        <div className="mb-32">
          <ScheduleHeader
            conferences={conferences}
            conference={conference}
            setConference={setConference}
            setFilter1={setFilter1}
            filter1={filter1}
            getSessions={getSessions}
          />
        </div>

        <div className="scheduleView-sort mb-16 mt-32">
          <ScheduleFilter
            setSearchText={setSearchText}
            searchText={searchText}
            filter2={filter2}
            setFilter2={setFilter2}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            halls={halls}
          />
        </div>

        <div className="schedule-scrollbar-grid-table-wrap">
          <ul className="schedule-grid-table-2-500 schedule-gridtable">
            <li>Time</li>
            <li>Sessions</li>

            {splicedList.length > 0 &&
              splicedList?.map((item, indx) => {
                let isRowHovered =
                  isHover && rowId.toString() === item?._id.toString();
                return (
                  <Fragment key={item?._id}>
                    <li
                      onMouseEnter={() => handleMouseEnter(item?._id)}
                      onMouseLeave={() => handleMouseLeave(item?._id)}
                      style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    >
                      <ScheduleTimeColoumn
                        conference={conference}
                        item={item}
                      />
                    </li>
                    <li
                      onMouseEnter={() => handleMouseEnter(item?._id)}
                      onMouseLeave={() => handleMouseLeave(item?._id)}
                      style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    >
                      <div className="flex-sb">
                        <ScheduleSessionColoumn
                          item={item}
                          conference={conference}
                        />

                        {conference?.isBooked && (
                          <div className="flex schedule-buttons-container ">
                            <div
                              style={{
                                cursor: 'pointer',
                              }}
                              className="mr-4 schedule-submit-loader-wrap"
                            >
                              <button
                                onClick={() => {
                                  setRowData(item);
                                  setType('SESSION');
                                  setShowFeedback(true);
                                }}
                                style={
                                  isRowHovered
                                    ? { backgroundColor: '#eef1f5' }
                                    : undefined
                                }
                                className="small-button-text small-button-text-primary"
                                disabled={item?.feedbackSubmitted}
                              >
                                {' '}
                                {item?.feedbackSubmitted && (
                                  <span className="mr-4">
                                    <CheckTickIcon className="icon-xs" />
                                  </span>
                                )}
                                {item?.feedbackSubmitted
                                  ? 'Feedback'
                                  : 'Give Feedback'}
                              </button>
                            </div>

                            <div style={{ width: '100%' }}>
                              <ScheduleActionBtnWithLoader
                                handleClick={() => {
                                  setRowData(item);
                                  setType('SESSION');

                                  item?.isLiked
                                    ? unsaveSession(item?._id, 'SESSION')
                                    : saveSession(item?._id, 'SESSION');
                                }}
                                isSubmitting={loadingId === item?._id}
                                icon={
                                  item?.isLiked ? (
                                    <LikeRedIcon className="icon-xs" />
                                  ) : (
                                    <LikeInactiveIcon className="icon-xs" />
                                  )
                                }
                                item={item}
                                text={item?.isLiked ? 'Unsave' : 'Save Event'}
                                isRowHovered={isRowHovered}
                                disabled={item?.disabled}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {item?.episodes?.map((episode, index) => {
                          const isRowHovered =
                            isHover &&
                            rowId.toString() === episode.session.toString();
                          return (
                            <>
                              <div className="flex-sb ml-32 mt-12">
                                <EpisodeColoumn
                                  episode={episode}
                                  item={item}
                                  index={index}
                                  conference={conference}
                                />
                                {conference?.isBooked && (
                                  <div className="flex schedule-buttons-container">
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="mr-4 schedule-submit-loader-wrap"
                                    >
                                      <button
                                        onClick={() => {
                                          setRowData(episode);
                                          setType('LECTURE');
                                          setShowFeedback(true);
                                        }}
                                        style={
                                          isRowHovered
                                            ? { backgroundColor: '#eef1f5' }
                                            : undefined
                                        }
                                        className="small-button-text small-button-text-primary"
                                        disabled={episode?.feedbackSubmitted}
                                      >
                                        {' '}
                                        {episode?.feedbackSubmitted && (
                                          <span className="mr-4">
                                            <CheckTickIcon className="icon-xs" />
                                          </span>
                                        )}
                                        {episode?.feedbackSubmitted
                                          ? 'Feedback'
                                          : 'Give Feedback'}
                                      </button>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      <ScheduleActionBtnWithLoader
                                        handleClick={() => {
                                          setRowData(item);
                                          setType('LECTURE');
                                          episode?.isLiked
                                            ? unsaveSession(
                                                episode?._id,
                                                'LECTURE'
                                              )
                                            : saveSession(
                                                episode?._id,
                                                'LECTURE'
                                              );
                                        }}
                                        isSubmitting={loadingId === episode._id}
                                        icon={
                                          item?.isLiked ? (
                                            <LikeRedIcon className="icon-xs" />
                                          ) : episode?.isLiked ? (
                                            <LikeRedIcon className="icon-xs" />
                                          ) : (
                                            <LikeInactiveIcon className="icon-xs" />
                                          )
                                        }
                                        item={item}
                                        text={
                                          item?.isLiked
                                            ? 'Saved'
                                            : episode?.isLiked
                                            ? 'Unsave'
                                            : 'Save Event'
                                        }
                                        episode={episode}
                                        isRowHovered={isRowHovered}
                                        disabled={episode?.disabled}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </li>
                  </Fragment>
                );
              })}
          </ul>
          {showFeedback && (
            <ModalX
              onDismiss={() => setShowFeedback(false)}
              open={showFeedback}
            >
              <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
                <AddFeedbackForm
                  setSessions={setSessions}
                  setShowFeedbackForm={setShowFeedback}
                  type={type}
                  data={rowData}
                  viewPage={true}
                />
              </div>
            </ModalX>
          )}
        </div>

        <div className="flex-vc-sb mt-8">
          <div>
            <CustomPagination
              currentPageNumber={activePage}
              setCurrentPage={setActivePage}
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={(data) => loadPaginationData(data)}
            />
          </div>
          <div>
            <PageLimitSelect
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              activePage={activePage}
              totalRecords={totalRecords}
              loadPaginationData={loadPaginationData}
            />
          </div>
        </div>
      </>
    </div>
  );
}
