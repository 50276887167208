export default function TranshcanIcon({ className }) {
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 6.5H5.41667H22.75"
        stroke="#08415C"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.66699 6.50033V4.33366C8.66699 3.75902 8.89527 3.20792 9.30159 2.80159C9.70792 2.39527 10.259 2.16699 10.8337 2.16699H15.167C15.7416 2.16699 16.2927 2.39527 16.6991 2.80159C17.1054 3.20792 17.3337 3.75902 17.3337 4.33366V6.50033M20.5837 6.50033V21.667C20.5837 22.2416 20.3554 22.7927 19.9491 23.1991C19.5427 23.6054 18.9916 23.8337 18.417 23.8337H7.58366C7.00902 23.8337 6.45792 23.6054 6.05159 23.1991C5.64527 22.7927 5.41699 22.2416 5.41699 21.667V6.50033H20.5837Z"
        stroke="#08415C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.833 11.917V18.417"
        stroke="#08415C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.167 11.917V18.417"
        stroke="#08415C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
