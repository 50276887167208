import { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import SubmitButtonWithLoader from '../button/SubmitButtonWithLoader';
import PriceIcon from '../icons/PriceIcon';
import './checkoutForm.styles.scss';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ paymentData, url }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return; // Ensure Stripe is loaded

    setIsLoading(true);

    try {
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/${url}/${paymentData?.transactionId}`,
        },
        redirect: 'if_required', // Prevents auto-redirection
      });

      if (error) {
        // Improved error handling
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('Payment failed. Please try again.');
        }
        setIsLoading(false);
        return;
      } else if (paymentIntent?.status === 'succeeded') {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/${url}/${paymentData?.transactionId}`;
          setIsLoading(false);
        }, 5000);
      }
    } catch (err) {
      console.error('⚠️ Payment Processing Error:', err);
      setIsLoading(false);
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="checkout-grid-wrap">
      <div className="checkout-grid">
        <div className="flex-vchc checkout-total-wrap">
          <div className="text-align-center">
            <p className="flex-vchc mb-8">
              <span className="body-regular-gray3 mr-16">Total</span>
              <i>
                <PriceIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </p>
            <h3>
              {paymentData?.orderCurrency.toString().toUpperCase()} -{' '}
              {paymentData?.orderAmount / 100}
            </h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <div className="mt-16">
            <SubmitButtonWithLoader
              isSubmitting={isLoading}
              text="Pay Securely"
              className="small-button small-button-primary"
              disabled={!stripe || !elements}
              fullWidth={true}
            />
          </div>
          {/* Show error message to your customers */}
          {errorMessage && (
            <div
              style={{
                color: '#d8000c',
                fontSize: '1.5rem',
              }}
            >
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
