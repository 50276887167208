import { Form, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Dropzone from "react-dropzone";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import BackIcon from "../icons/BackIcon";
import DropdownIcon from "../icons/DropdownIcon";
import TextInput from "../formik/TextInput";
import CloseIcon from "../icons/CloseIcon";
import DocumentIcon from "../icons/DocumentIcon";
import PlusIcon from "../icons/PlusIcon";
import Switch from "../switch/Switch";
import ImageGalleryIcon from "../icons/ImageGalleryIcon";
import { createAudioCourseAction } from "../../redux/audio-course/audioCourseAction";

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("Required"),
});

export default function AudioCourseChapter({
  setShowChapter,
  selectedSection,
  episodeToEdit,
  setMySections,
  setEpisodeToEdit,
}) {
  const dispatch = useDispatch();

  const audio = useSelector((state) => state.audio);
  const { newAudioCourse } = audio;

  async function onSubmit(values, actions) {
    const { title, media, preview, resources } = values;

    const formData = {
      mediaDetails: {
        title,
        preview,
        resources,
        source: media,
      },
    };

    if (episodeToEdit?._id) {
      formData.mediaDetails.episodeId = episodeToEdit._id;
    }

    try {
      const response = await api.post(
        `audioCourses/${newAudioCourse?._id}/section/${
          episodeToEdit?._id ? episodeToEdit.section : selectedSection?._id
        }/episode`,
        formData
      );

      if (response) {
        setMySections(
          (prev) =>
            prev.map(
              (obj) =>
                response.data.data.media.sections.find(
                  (o) => o.uuid === obj.uuid
                ) || obj
            )
          // prev.map((item) =>
          //   item.uuid == response.data.data.media.sections.uuid
          //     ? response.data.data.media.sections
          //     : item
          // )
        );
        setShowChapter(false);
        dispatch(createAudioCourseAction(response.data.data.media));
        setEpisodeToEdit({});
        actions.resetForm({ values: initialValues });
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }
  // end of submit

  const initialValues = {
    title: episodeToEdit?.title || "",
    preview: episodeToEdit?.preview ? episodeToEdit?.preview : false,
    resources: episodeToEdit?.resources ? episodeToEdit?.resources : [],
    media: episodeToEdit?.source ? [episodeToEdit?.source] : [],
  };

  //   function to set up texteditor field value
  function setFormikFieldValue(fieldName, fieldValue, props) {
    props.setFieldValue(fieldName, fieldValue);
  }

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () =>
  //     formik.values.banner?.forEach((file) =>
  //       URL.revokeObjectURL(file.Location)
  //     );
  // }, [formik.values.banner]);

  return (
    <>
      <main className="create-e-inner-wrap">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <>
                <Form
                  className="form-type-4"
                  autoComplete="off"
                  onSubmit={props.handleSubmit}
                >
                  <p
                    className="body-regular-gray3 mb-24"
                    style={{ color: "#aabdc7" }}
                  >
                    All mandatory fields are makred by *
                  </p>
                  <div className="flex-vc mb-36">
                    <i
                      onClick={() => {
                        setEpisodeToEdit({});
                        setShowChapter(false);
                      }}
                    >
                      <BackIcon className="icon-xxl" />
                    </i>
                    <h2 className="color-primary">Create Chapter</h2>
                  </div>
                  <div className="flex-vc mb-32">
                    <h4 className="color-primary">Section : </h4>
                    <h4 className="color-primary ml-12 mr-4">Introduction</h4>
                    <i className="position-relative pt-5">
                      <DropdownIcon className="icon-lg" />
                    </i>
                  </div>

                  <div className="mb-32">
                    <p className="avenir-20-500  mb-8">Chapter title *</p>
                    <TextInput
                      name="title"
                      type="text"
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </div>
                  <div className="mb-32">
                    <p className="avenir-20-500 mb-12">Add chapter audio *</p>
                    <div className="mb-16">
                      {props.values.media?.length > 0 ? (
                        props.values.media.map((item) => (
                          <div
                            key={item?.name}
                            className="media-preview-wrap position-relative"
                          >
                            <audio
                              controls
                              style={{ width: "100%", maxWidth: "100%" }}
                            >
                              <source src={item.Location} type={item?.type} />
                            </audio>
                            <div
                              className="change-media-button"
                              onClick={() => {
                                if (props.values.media[0]?.key) {
                                  props.setFieldValue(
                                    "deteledmedia",
                                    props.values.media[0]?.key
                                  );
                                }
                                props.setFieldValue("media", []);
                              }}
                            >
                              <p className="caption-2-regular-gray3">
                                <u style={{ color: "#fff" }}>Change file</u>
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <Dropzone
                            // accept={'audio/*'}
                            accept={{
                              "audio/mpeg": [".mp3"],
                              "audio/wav": [".wav"],
                              "audio/ogg": [".ogg"],
                              "audio/mp4": [".m4a"],
                              "audio/aac": [".aac"],
                              "audio/flac": [".flac"],
                              "audio/webm": [".webm"],
                            }}
                            // accept="video/mp4"
                            multiple={false}
                            maxSize={524288000}
                            maxFiles={1}
                            onDrop={async (acceptedFiles) => {
                              // const acceptedFilesWithUrl = acceptedFiles.map(
                              //   (file) =>
                              //     Object.assign(file, {
                              //       Location: URL.createObjectURL(file),
                              //     })
                              // );
                              // props.setFieldValue(
                              //   "media",
                              //   acceptedFilesWithUrl
                              // );

                              if (acceptedFiles?.length > 0) {
                                const dataObj = new FormData();
                                let oldMedia = [];
                                acceptedFiles.map((item) =>
                                  !item.Key
                                    ? dataObj.append("file", item)
                                    : oldMedia.push(item)
                                );

                                if (dataObj.has("file")) {
                                  try {
                                    const mediaResponse = await api.post(
                                      "media/upload",
                                      dataObj
                                    );
                                    if (mediaResponse) {
                                      props.setFieldValue(
                                        "media",
                                        mediaResponse.data.data
                                      );
                                      // formData.mediaDetails.source = [
                                      //   ...oldMedia,
                                      //   ...mediaResponse.data.data,
                                      // ];
                                    }
                                  } catch (err) {
                                    dispatch(
                                      alertAction(
                                        "Media failed to save",
                                        "danger"
                                      )
                                    );
                                  }
                                }
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps({
                                    className: "media-dropzone flex-vchc",
                                  })}
                                >
                                  <div className="text-align-center">
                                    <i>
                                      <ImageGalleryIcon className="icon-lg" />
                                    </i>
                                    <p className="caption-1-medium-primary">
                                      <u> Add file</u>
                                    </p>
                                  </div>
                                  <input {...getInputProps()} />
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-32">
                    <p className="avenir-20-500 mb-12">Preview</p>
                    <div className="mb-12">
                      <Switch
                        id="preview"
                        name="preview"
                        value={props.values.preview}
                        checked={props.values.preview}
                        onChange={props.handleChange}
                        disable={false}
                      />
                    </div>
                    <p
                      className="body-regular-gray3"
                      style={{ color: "#aabdc7" }}
                    >
                      <span>
                        If preview is turned on, this chapter will be availabe
                        to view as free during course preview.
                      </span>
                    </p>
                  </div>
                  <div className="mb-8">
                    <p className="avenir-20-500 mb-12">
                      Add resources for the chapter
                    </p>
                    <div className="mb-16">
                      {props.values.resources?.length > 0 ? (
                        <>
                          <div className="flex-vc-sb uc-uploadfile-input pb-24 caption-1-regular-gray2">
                            <div
                              style={{
                                overflow: "hidden",
                                width: "90%",
                                mr: "2.5rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              className="flex-vc"
                            >
                              <i className="mr-8">
                                <DocumentIcon className="icon-sm" />
                              </i>
                              <p>
                                {props.values.resources[0]?.name || "resources"}
                              </p>
                            </div>
                            <i
                              onClick={() => {
                                if (props.values.resources[0]?.key) {
                                  props.setFieldValue(
                                    "deteledresources",
                                    props.values.resources[0]?.key
                                  );
                                }
                                props.setFieldValue("resources", []);
                              }}
                            >
                              <CloseIcon
                                style={{
                                  position: "absolute",
                                  right: "1.4rem",
                                  top: "50%",
                                  transform: "translate(0, -50%)",
                                  transformOrigin: "left top",
                                }}
                                className="icon-sm"
                                fill="#000000"
                              />
                            </i>
                          </div>
                        </>
                      ) : (
                        <div>
                          <Dropzone
                            accept={{
                              "image/*": [".png", ".gif", ".jpeg"],
                              "application/pdf": [".pdf"],
                              // "image/*": [], // All images
                              // "text/html": [".html", ".htm"],
                            }}
                            // accept="image/*,audio/*,video/*"
                            multiple={false}
                            // maxSize={524288000}
                            maxFiles={1}
                            onDrop={async (acceptedFiles) => {
                              // props.setFieldValue("resources", acceptedFiles);
                              if (acceptedFiles?.length > 0) {
                                const dataObj = new FormData();
                                let oldMedia = [];
                                acceptedFiles.map((item) =>
                                  !item.Key
                                    ? dataObj.append("file", item)
                                    : oldMedia.push(item)
                                );

                                if (dataObj.has("file")) {
                                  try {
                                    const mediaResponse = await api.post(
                                      "media/upload",
                                      dataObj
                                    );
                                    if (mediaResponse) {
                                      props.setFieldValue(
                                        "resources",
                                        mediaResponse.data.data
                                      );
                                    }
                                  } catch (err) {
                                    dispatch(
                                      alertAction(
                                        "Banner failed to save",
                                        "danger"
                                      )
                                    );
                                  }
                                }
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps({
                                    className: "uc-uploadfile-input flex-vc",
                                  })}
                                >
                                  <div
                                    className="flex-vc"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <i className="position-relative mr-8">
                                      <PlusIcon className="icon-size" />
                                    </i>
                                    <p className="caption-1-medium-primary">
                                      Add file
                                    </p>
                                  </div>
                                  <input {...getInputProps()} />
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-44">
                    <p
                      className="body-regular-gray3"
                      style={{ color: "#aabdc7" }}
                    >
                      <i>
                        You can add downloadable pdfs and image files as chapter
                        resources
                      </i>
                    </p>
                  </div>
                  <div className="mb-72">
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      fullWidth={true}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </main>
    </>
  );
}
