export default function MikeIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 2.25h-7.5v11.5h7.5V2.25zm-6 10v-8.5h4.5v8.5h-4.5z"
        fill="#08415C"
      />
      <path
        d="M5.75 12v4.25h12.5V12h1.5v5.75h-7V22h-1.5v-4.25h-7V12h1.5z"
        fill="#08415C"
      />
    </svg>
  );
}
