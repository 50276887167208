import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import api from '../../utility/api';
import { alertAction } from '../../redux/alert/alertAction';
import { useNavigate } from 'react-router-dom';
import CheckoutBox from '../../pages/attendee-booking-pages/CheckoutBox';
import { loadSinglePodAction } from '../../redux/podcast/podcastAction';
import PodcastBookingYearlyPrice from './PodcastBookingYearlyPrice';

export default function PodcastYearlyCheckout() {
  const podcast = useSelector((state) => state.podcast.podcast);

  const [isLoading, setIsLoading] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [coupon, setCoupon] = useState('');
  const [couponData, setCouponData] = useState({});

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMediaDetails = async () => {
    try {
      setIsLoading(true);
      let response = await api.get(`/podcast/${podcast._id}`);

      setIsLoading(false);
      dispatch(loadSinglePodAction(response.data.data.media));
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const applyCouponCode = async () => {
    try {
      let formData = {
        mediaDetails: {
          coupon: coupon,
          userId: user?._id,
          type: 'yearly',
        },
      };

      let response = await api.post(
        `/podcast/${podcast?._id}/coupon`,
        formData
      );

      dispatch(loadSinglePodAction(response.data.data.media));
      setCouponData(response.data.data.couponData);
      setApplyCoupon(true);
      setCoupon('');
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };
  const buyPodcast = async () => {
    setIsSubmitting(true);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let formData = {
      purchaseDetails: {
        userId: user?._id,
        timezone: timezone,
        type: 'PODCAST',
        planName: 'yearly',
        isCouponApplied: false,
      },
    };

    if (applyCoupon) {
      formData.purchaseDetails.isCouponApplied = true;
      formData.purchaseDetails.coupon = couponData.code;
    }

    let url = `/podcast/${podcast._id}/purchase`;

    try {
      setIsLoading(true);
      const response = await api.post(url, formData);
      setIsSubmitting(false);
      if (response) {
        if (!response.data.data.podcastPurchased) {
          if (
            podcast?.currency === 'INR' &&
            response?.data?.data?.paymentIntent?.success
          ) {
            window.location.href =
              response?.data?.data?.paymentIntent?.data?.instrumentResponse?.redirectInfo?.url;
          } else {
            navigate('/book-course/checkout', {
              state: {
                // clientSecret: response.data.data.clientSecret,
                paymentData: response.data.data,
                url: 'course-receipt',
              },
            });
          }
        } else {
          setIsLoading(false);
          navigate(`/course-receipt/${response?.data?.data?.transactionId}`);
        }
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsLoading(false);
      dispatch(alertAction(err.response.data?.message, 'danger'));
    }
  };

  return (
    <>
      <PodcastBookingYearlyPrice />
      <CheckoutBox
        type={'yearly'}
        podcast={true}
        media={podcast}
        applyCoupon={applyCoupon}
        setApplyCoupon={setApplyCoupon}
        setCoupon={setCoupon}
        coupon={coupon}
        getMediaDetails={getMediaDetails}
        couponData={couponData}
        applyCouponCode={applyCouponCode}
        buyCourse={buyPodcast}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
