import { UPDATE_RECENTLY_VIEWED } from "./recentlyViewedTypes";

const initialState = {
  recentlyViewed: [],
};

function recentlyViewedReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_RECENTLY_VIEWED:
      return {
        ...state,
        recentlyViewed: payload,
      };

    default:
      return state;
  }
}

export default recentlyViewedReducer;
