import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Button1.scss';

const Button1 = ({
  txt = null,
  img = '',
  bck = 'white',
  color = '#08415c',
  hovBck = 'rgba(0, 0, 0, 0.04)',
  onClick = () => {},
  imageCss = {},
  imgDir = 'left',
  extraCss = {},
  classNames = {},
  txtBoxCss = {},
  link = false,
  disableBtn = false,
}) => {
  let [style, setStyle] = useState({ backgroundColor: bck, color: color });

  let mouseOverHandler = () => {
    return setStyle({ backgroundColor: hovBck, color: color });
  };
  let mouseLeaveHandler = () => {
    return setStyle({ backgroundColor: bck, color: color });
  };
  let btn = (
    <button
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseLeaveHandler}
      style={{
        ...style,
        ...extraCss,
        ...{ cursor: disableBtn ? 'not-allowed' : 'pointer' },
      }}
      className={['course-btn', classNames]?.join(' ')}
      onClick={onClick}
      disabled={disableBtn}
    >
      <span className="course-txtBox" style={{ ...txtBoxCss }}>
        {img && imgDir === 'left' ? (
          <img src={img} alt="" className="course-btnImg" style={imageCss} />
        ) : (
          ''
        )}
        {txt ? <span className="course-txt">{txt}</span> : null}
        {img && imgDir === 'right' ? (
          <img src={img} alt="" className="course-btnImg" style={imageCss} />
        ) : (
          ''
        )}
      </span>
    </button>
  );

  if (link) {
    btn = (
      <Link
        to={link}
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        style={{
          ...style,
          ...extraCss,
          ...{ cursor: disableBtn ? 'not-allowed' : 'pointer' },
        }}
        className="course-btn"
        onClick={onClick}
        disabled={disableBtn}
      >
        <span className="course-txtBox">
          {img ? (
            <img src={img} className="course-btnImg" style={imageCss} />
          ) : (
            ''
          )}
          {txt ? <span className="course-txt">{txt}</span> : null}
        </span>
      </Link>
    );
  }

  return btn;
};

export default Button1;
