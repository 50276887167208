import {
  USER_PROFILE_LOADED,
  GET_USER_EXTERNAL_CREDITS,
  GET_USER_CREDIT_CONFERENCES,
  USER_TOTAL_CREDITS,
  GET_USER_TOTAL_EXTERNAL_CREDITS,
  GET_USER_CUSTOM_EXTERNAL_CREDITS_TYPE,
  GET_USER_CUSTOM_CONFERENCE_CREDITS_TYPE,
  USER_SPEAKERS,
} from "./userProfileTypes";

export const loadUserProfileAction = (data) => (dispatch) => {
  dispatch({
    type: USER_PROFILE_LOADED,
    payload: data,
  });
};

export const loadUserExternalCreditsAction = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_EXTERNAL_CREDITS,
    payload: data,
  });
};

export const loadUserCustomConferenceCreditsTypeAction =
  (data) => (dispatch) => {
    dispatch({
      type: GET_USER_CUSTOM_CONFERENCE_CREDITS_TYPE,
      payload: data,
    });
  };

export const loadUserCustomExternalCreditsTypeAction = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_CUSTOM_EXTERNAL_CREDITS_TYPE,
    payload: data,
  });
};

export const loadUserTotalExternalCreditsAction = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_TOTAL_EXTERNAL_CREDITS,
    payload: data,
  });
};

export const loadUserCreditConferencesAction = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_CREDIT_CONFERENCES,
    payload: data,
  });
};

export const loadUserTotalCreditsAction = (data) => (dispatch) => {
  dispatch({
    type: USER_TOTAL_CREDITS,
    payload: data,
  });
};

export const loadUserSpeakers = (data) => (dispatch) => {
  dispatch({
    type: USER_SPEAKERS,
    payload: data,
  });
};
