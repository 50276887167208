import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import { loadUserCustomExternalCreditsTypeAction } from '../../redux/user-profile/userProfileAction';
import api from '../../utility/api';
import Dialogue from '../dialogue/Dialogue';
import AddIcon from '../icons/AddIcon';
import DeleteIcon from '../icons/TrashcanIcon';
import EditIcon from '../icons/Edit2Icon';
import ModalX from '../modal/ModalX';
import ExternalCreditsTypeForm from './ExternalCreditTypeForm';

import './externalCreditTypes.scss';

export default function ExternalCreditTypes() {
  const creditTypesList = useSelector(
    (state) => state.userProfile.userCustomExternalCreditsType
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);
  const [creditToEdit, setCreditToEdit] = useState(null);
  const [showExternalCreditTypeForm, setShowExternalCreditTypeForm] =
    useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const getExternalCreditsType = async (userId) => {
    try {
      const response = await api.get(
        `/attendees/credits/external?userId=${user?._id}&&type=ownCredit`
      );
      dispatch(
        loadUserCustomExternalCreditsTypeAction(response.data.data.credits)
      );
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const deleteExternalCreditType = async (creditId) => {
    try {
      const response = await api.delete(
        `attendees/credits/${creditId}/external?userId=${user?._id}&&type=ownCredit`
      );
      if (response) {
        dispatch(
          loadUserCustomExternalCreditsTypeAction(response.data.data.credits)
        );
        setOpenDialogue(false);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getExternalCreditsType(user?._id);
  }, [user?._id]);

  return (
    <div>
      <div className="mb-32">
        <h4 className="mb-24">External Credits Type</h4>
        <div className="flex-vc">
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowExternalCreditTypeForm(true);
            }}
            className="mr-16"
          >
            <AddIcon />
          </button>
          <p className="caption-1-regular-gray3">Add external credits Type</p>
        </div>
      </div>
      <div className="grid-table-wrap">
        <ul className="grid-table-2-300">
          <li>External Credit Type</li>
          <li>Actions</li>
          {creditTypesList?.length > 0 &&
            creditTypesList?.map((credit) => {
              return (
                <Fragment key={credit?.value}>
                  <li>{credit.label}</li>
                  <li>
                    <i
                      className="mr-8"
                      onClick={() => {
                        setCreditToEdit(credit);
                        setOpenEditModal(true);
                      }}
                    >
                      <EditIcon />
                    </i>
                    <i
                      className="ml-8"
                      onClick={() => {
                        setCreditToDelete(credit);
                        setOpenDialogue(true);
                      }}
                    >
                      <DeleteIcon />
                    </i>
                  </li>
                </Fragment>
              );
            })}
        </ul>
        {showExternalCreditTypeForm && (
          <ModalX
            onDismiss={() => setShowExternalCreditTypeForm(false)}
            open={showExternalCreditTypeForm}
          >
            <ExternalCreditsTypeForm
              editMode={false}
              setShowExternalCreditForm={setShowExternalCreditTypeForm}
            />
          </ModalX>
        )}
        {openEditModal && (
          <ModalX
            onDismiss={() => {
              setCreditToEdit(null);
              setOpenEditModal(false);
            }}
            open={openEditModal}
          >
            <ExternalCreditsTypeForm
              editData={creditToEdit}
              editMode={openEditModal}
              setEditMode={setOpenEditModal}
            />
          </ModalX>
        )}

        {openDialogue && (
          <Dialogue
            msg="Are you sure you want to delete the external credit type?"
            title="Confirm Delete !!"
            closeDialogue={() => {
              setOpenDialogue(false);
              setCreditToDelete(null);
            }}
            yesAction={() => deleteExternalCreditType(creditToDelete?.value)}
          />
        )}
      </div>
    </div>
  );
}
