import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import api from "../../utility/api";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import TextError from "../formik/TextError";
import {
  loadExternalCreditListAction,
  loadGoalsCreditListAction,
} from "../../redux/list/listAction";
import {
  getExternalCreditList,
  getGoalsCreditsList,
} from "../../utility/commonUtil";

const validationSchema = yup.object().shape({
  newCreditType: yup.string().trim().required("Required"),
});
export default function NewExternalCreditForm({ setAddNewCredit, isExternal }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // submit function
  const onSubmit = async (values, actions) => {
    let url = isExternal
      ? `/attendees/credits/external?type=user&&userId=${user?._id}`
      : `attendees/goalCredits/crediTypes?userId=${user?._id}&type=user`;

    const data = {
      creditDetails: {
        name: values.newCreditType,
        user: user?._id,
      },
    };

    try {
      const response = await api.post(url, data);
      if (isExternal) {
        dispatch(loadExternalCreditListAction(response?.data?.data?.credits));
        getGoalsCreditsList(user?._id);
      } else {
        dispatch(loadGoalsCreditListAction(response?.data?.data?.creditTypes));
        getExternalCreditList(user?._id);
      }
      setAddNewCredit(false);
    } catch (err) {
      actions.setFieldError("newCreditType", err.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      newCreditType: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    setAddNewCredit(false);
  };

  return (
    <form
      className="form-type-1 mt-40"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <p className="caption-1-regular-gray3 ml-4 mb-16">
        New credit type will be added to the dropdown
      </p>
      <div className="material-textfield mb-18">
        <input
          id="newCreditType"
          type="text"
          name="newCreditType"
          value={formik.values.newCreditType}
          onChange={formik.handleChange}
          placeholder=" "
        />
        <label>New credit type</label>
      </div>
      <div className="mb-24">
        {formik.touched.newCreditType &&
          Boolean(formik.errors.newCreditType) && (
            <TextError>{formik.errors.newCreditType}</TextError>
          )}
      </div>
      <div>
        <SubmitCancelButtonWithLoader
          isSubmitting={formik.isSubmitting}
          onCancel={() => handleCancel()}
        />
      </div>
    </form>
  );
}
