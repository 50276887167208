import PropTypes from 'prop-types';
import './dialogue.styles.scss';
import ButtonWithLoader from '../button/ButtonWithLoader';

export default function DialogueWithLoader({
  closeDialogue,
  title,
  msg,
  yesAction,
  previewLoading,
}) {
  return (
    <div className="dialogue-background">
      <div className="dialogue-container">
        <div className="dialogue-title">
          <h3>{title}</h3>
        </div>
        <div className="dialogue-body">{msg}</div>
        <div className="dialogue-actions">
          <button
            className="button-text button-text-primary mr-8"
            onClick={closeDialogue}
          >
            Cancel
          </button>

          <ButtonWithLoader
            isSubmitting={previewLoading}
            text="Yes"
            className="small-button small-button-primary"
            fullWidth={false}
            handleClick={() => yesAction()}
          />
        </div>
      </div>
    </div>
  );
}

DialogueWithLoader.propTypes = {
  closeDialogue: PropTypes.func.isRequired,
  yesAction: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  title: PropTypes.string,
};
