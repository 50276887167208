import {
  LOAD_STATELIST,
  LOAD_COUNTRYLIST,
  LOAD_CITYLIST,
  LOAD_CREDIT_TYPES_LIST,
  LOAD_AMENITYLIST,
  LOAD_EXTERNAL_CREDITLIST,
  LOAD_GOALS_CREDITLIST,
} from "./listTypes";

export const loadCountryListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_COUNTRYLIST,
    payload: data,
  });
};

export const loadStateListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_STATELIST,
    payload: data,
  });
};

export const loadCityListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_CITYLIST,
    payload: data,
  });
};

export const loadCreditTypesListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_CREDIT_TYPES_LIST,
    payload: data,
  });
};

export const loadGoalsCreditListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_GOALS_CREDITLIST,
    payload: data,
  });
};
export const loadExternalCreditListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_EXTERNAL_CREDITLIST,
    payload: data,
  });
};

export const loadAmenitylistAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_AMENITYLIST,
    payload: data,
  });
};
