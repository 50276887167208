import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/button/BackButton";
import AboutUs from "../../components/footer/AboutUs";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="container pt-64">
      <div style={{ padding: "5% 5% 0px" }} className="container-lg">
        <BackButton className="green" onBackButtonClick={() => navigate(-1)} />
      </div>
      <AboutUs />
    </div>
  );
}
