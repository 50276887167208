const UpcomingConfIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 9.625h2v2h-2v-2zM8.25 14.375h-2v2h2v-2zM11 9.625h2v2h-2v-2zM13 14.375h-2v2h2v-2zM15.75 9.625h2v2h-2v-2zM17.75 14.375h-2v2h2v-2z"
        fill="#9F9F9F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25 5.25h-3v15.5h19.5V5.25h-3V2h-1.5v3.25H6.75V2h-1.5v3.25zm-1.5 1.5v12.5h16.5V6.75H3.75z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default UpcomingConfIcon;
