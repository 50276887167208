import CheckTickIcon from '../icons/CheckTickIcon';
import LikeRedIcon from '../icons/LikeRedIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import '../schedule/schedule.scss';

export default function MyScheduleAction({
  isRowHovered,
  setRowData,
  item,
  setShowFeedback,
  setType,
  type,
  unsaveSession,
  data,
}) {
  return (
    <div className="flex schedule-buttons-container">
      <div
        style={{
          cursor: 'pointer',
        }}
        className="mr-4 schedule-submit-loader-wrap"
      >
        <button
          onClick={() => {
            setRowData(item);
            setType(type);
            setShowFeedback(true);
          }}
          style={isRowHovered ? { backgroundColor: '#eef1f5' } : undefined}
          className="small-button-text small-button-text-primary"
          disabled={item?.feedbackSubmitted}
        >
          {' '}
          {item?.feedbackSubmitted && (
            <span className="mr-4 pt-8">
              <CheckTickIcon className="icon-xs" />
            </span>
          )}
          {item?.feedbackSubmitted ? 'Feedback' : 'Give Feedback'}
        </button>
      </div>
      <div
        style={{
          cursor: 'pointer',
        }}
        className="schedule-submit-loader-wrap"
      >
        <button
          onClick={() => {
            setRowData(item);
            setType(type);
            unsaveSession(item?._id, type);
          }}
          disabled={item?.disabled}
          style={{
            ...(isRowHovered && { backgroundColor: '#eef1f5' }),
            ...(item?.disabled && { cursor: 'not-allowed' }),
          }}
          // style={isRowHovered ? { backgroundColor: '#eef1f5' } : undefined}
          className="small-button-text small-button-text-primary"
        >
          <div className="flex-vchc">
            <span className="mr-4 pt-4">
              {data?.isLiked ? (
                <LikeRedIcon className="icon-xs" />
              ) : item?.isLiked ? (
                <LikeRedIcon className="icon-xs" />
              ) : (
                <LikeInactiveIcon className="icon-xs" />
              )}
            </span>
            <span>
              {data?.isLiked && type == 'LECTURE'
                ? 'Saved'
                : item?.isLiked
                ? 'Unsave'
                : 'Save Event'}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
}
