import React from "react";

function Edit2Icon({ className, fill }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_167_1334)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12.2008V21.5242H2V2.87746H12V4.74214H4.00001V19.6595H20V12.2008H22ZM9.00001 12.4898L17.022 4.99573L18.576 6.89863L10.459 14.9978H9.00001V12.4898ZM7 16.8625H11.316L21.223 6.90049L17.076 2.47656L7 11.703V16.8625Z"
          fill="#08415C"
        />
      </g>
      <defs>
        <clipPath id="clip0_167_1334">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Edit2Icon;
