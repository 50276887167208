import React, { Fragment } from 'react';

const CreditcardsSketch = ({ className }) => {
  return (
    <Fragment>
      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 133 133'
      >
        <g fill='none' fillRule='evenodd'>
          <g fillRule='nonzero'>
            <g>
              <path
                fill='#08415C'
                d='M131.743 65.871c0 12.895-3.705 24.93-10.118 35.087-1.425 2.264-2.987 4.433-4.672 6.501-1.364 1.675-2.81 3.28-4.332 4.813-10.157 10.238-23.66 17.158-38.732 18.987-1.68.204-3.382.347-5.1.42-.968.042-1.939.064-2.918.064-1.667 0-3.317-.062-4.955-.183-8.552-.637-16.648-2.907-23.98-6.496 0 0-.002 0-.005-.003-1.793-.879-3.538-1.836-5.235-2.868-.594-.36-1.183-.732-1.764-1.11-1.984-1.294-3.893-2.692-5.72-4.187-2.466-2.016-4.784-4.205-6.93-6.552C6.547 98.627 0 83.014 0 65.87c0-8.359 1.557-16.352 4.396-23.71 2.836-7.346 6.947-14.057 12.07-19.859 1.177-1.333 2.408-2.621 3.688-3.855 4.374-4.216 9.327-7.832 14.73-10.717 2.751-1.47 5.623-2.75 8.591-3.823C47.048 2.613 50.767 1.62 54.598.963 58.26.328 62.027 0 65.87 0c3.651 0 7.23.296 10.72.868 3.331.546 6.579 1.342 9.722 2.366.925.302 1.839.621 2.745.966 4.396 1.653 8.569 3.761 12.46 6.27 2.61 1.683 5.094 3.548 7.436 5.573 8.856 7.663 15.648 17.648 19.428 29.008 2.18 6.544 3.36 13.545 3.36 20.82z'
                transform='translate(-1019.000000, -958.000000) translate(1019.000000, 958.000000)'
              />
              <path
                fill='#4CB944'
                d='M121.625 100.958c-1.425 2.264-2.987 4.433-4.672 6.501-10.483 12.868-25.736 21.697-43.064 23.8-1.68.204-3.382.347-5.1.42-.968.042-1.939.064-2.918.064-13.26 0-25.603-3.918-35.939-10.66C20.071 95.217 11.558 68.908 4.396 42.16c-.648-2.42-1.285-4.845-1.912-7.272C1.323 30.387 3.56 25.71 7.79 23.786c2.525-1.15 5.063-2.266 7.609-3.35 1.58-.675 3.167-1.339 4.756-1.99 6.864-2.806 13.8-5.385 20.815-7.735C52.635 6.8 64.51 3.52 76.591.868c.876-.19 1.753-.379 2.632-.564 3.831-.81 7.681.793 9.835 3.896.403.578.747 1.212 1.022 1.89 12.472 30.978 22.99 62.599 31.545 94.868z'
                transform='translate(-1019.000000, -958.000000) translate(1019.000000, 958.000000)'
              />
              <path
                fill='#08415C'
                d='M116.953 107.459c-10.483 12.868-25.736 21.697-43.064 23.8-1.234.11-2.471.215-3.708.312-.465.037-.927.072-1.392.107-.968.043-1.939.065-2.918.065-15.806 0-30.309-5.566-41.66-14.847-4.433-31.373-7.015-62.906-7.746-94.594-.024-1.022-.046-2.04-.064-3.062-.09-4.646 3.164-8.68 7.724-9.574 2.724-.535 5.45-1.036 8.179-1.503.86-.148 1.72-.293 2.58-.433 8.156-1.34 16.337-2.38 24.543-3.122C68.36 3.8 77.322 3.34 86.313 3.234c4.24-.048 8.488-.021 12.741.086 4.644.116 8.531 3.544 9.225 8.139.231 1.527.457 3.057.675 4.584 4.398 30.325 7.066 60.798 7.999 91.416z'
                transform='translate(-1019.000000, -958.000000) translate(1019.000000, 958.000000)'
              />
              <g transform='translate(-1019.000000, -958.000000) translate(1019.000000, 958.000000) translate(17.207208, 0.537725)'>
                <path
                  fill='#4CB944'
                  d='M114.135 27.991c-.93 5.52-1.917 11.029-2.96 16.522-4.297 22.633-9.55 45.04-15.761 67.22-10.158 10.24-23.66 17.16-38.732 18.988-1.68.204-3.383.347-5.1.42-.969.042-1.94.064-2.918.064-1.667 0-3.318-.062-4.955-.183-1.062-.245-2.119-.495-3.178-.753-6.988-1.694-13.922-3.608-20.802-5.743-2.422-1.188-4.761-2.519-7.004-3.981-1.984-1.294-3.893-2.692-5.721-4.187-2.466-2.016-4.783-4.205-6.929-6.552.014-.113.03-.223.049-.336C5.982 74.71 14.072 40.477 24.399 6.775c.396-1.285 1.04-2.436 1.869-3.406C28.303.987 31.448-.32 34.724.083c.89.11 1.78.224 2.667.342 1.86.242 3.718.503 5.57.777C52.01 2.535 60.97 4.224 69.846 6.27c4.845 1.116 9.668 2.336 14.465 3.662 7.931 2.196 15.796 4.678 23.593 7.45 4.377 1.56 7.004 6.025 6.232 10.61z'
                />
                <path
                  fill='#08415C'
                  d='M69.845 6.27l-29.314 124c-6.988-1.695-13.922-3.609-20.802-5.744 0 0-.003 0-.005-.002-1.794-.88-3.538-1.837-5.235-2.869L42.962 1.202C52.009 2.535 60.97 4.224 69.845 6.27z'
                />
                <circle cx='70.443' cy='111.671' r='12.789' fill='#08415C' />
                <circle cx='74.352' cy='95.134' r='12.789' fill='#FFF' />
                <path
                  fill='#FFF'
                  d='M90.354 53.072l-3.628 15.343c-.423 1.79-2.216 2.897-4.005 2.474-1.789-.423-2.896-2.216-2.473-4.005l3.627-15.344c.423-1.789 2.216-2.896 4.005-2.473 1.79.423 2.897 2.216 2.474 4.005zM97.044 24.77l-3.627 15.344c-.423 1.79-2.216 2.897-4.005 2.474-1.79-.423-2.897-2.216-2.474-4.005l3.628-15.344c.422-1.789 2.216-2.896 4.005-2.473 1.789.423 2.896 2.216 2.473 4.005z'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Fragment>
  );
};

export default CreditcardsSketch;
