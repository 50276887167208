export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const SWITCH_TO_ORGANIZER = "SWITCH_TO_ORGANIZER";
export const SWITCH_TO_ATTENDEE = "SWITCH_TO_ATTENDEE";
