import { useEffect } from 'react';
import PodcastRefunds from '../../components/podcast/PodcastRefunds';
import { scrollToTop } from '../../utility/commonUtil';

export default function PodcastRefundsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <PodcastRefunds />
    </>
  );
}
