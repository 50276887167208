import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import TextError from '../formik/TextError';
import CloseIcon from '../icons/CloseIcon';
import DocumentIcon from '../icons/DocumentIcon';
import UploadArrowIcon from '../icons/UploadArrowIcon';
import OnlyDatepicker from '../react-datepicker/OnlyDatePicker';

import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';

import { alertAction } from '../../redux/alert/alertAction';
import {
  loadUserExternalCreditsAction,
  loadUserTotalExternalCreditsAction,
} from '../../redux/user-profile/userProfileAction';

import api from '../../utility/api';

import MaterialTextInput from '../formik/MaterialTextInput';
import ModalX from '../modal/ModalX';
import NewExternalCreditForm from './NewExternalCreditForm';

import { modalStyleformType1 } from '../reselect/selectUtil';
import Select from 'react-select';
import {
  getQuery,
  getValue,
  getExternalCreditList,
} from '../../utility/commonUtil';
import './externalCreditsForm.scss';

const validationSchema = yup.object().shape({
  conferenceName: yup.string().trim().max(250).required('Required'),
  startDate: yup.date().required('Required').nullable(),
  endDate: yup.date().required('Required').nullable(),
  creditType: yup.string().required('Required'),
  totalCredits: yup.string().required('Required'),
});

const ExternalCreditsForm = ({
  totalStartDate,
  totalEndDate,
  externalStartDate,
  externalEndDate,
  pageLimit,
  editMode,
  setEditMode,
  setShowExternalCreditForm,
  editData,
  currentPage,
  setExternalCredits,
  filter,
}) => {
  const [addNewCredit, setAddNewCredit] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const externalCreditList = useSelector(
    (state) => state.list.externalCreditList
  );

  const onSubmit = async (values) => {
    const {
      conferenceName,
      startDate,
      endDate,
      creditType,
      totalCredits,
      deteledCertificate,
    } = values;

    const formData = {
      conferenceDetails: {
        deleteCreditCertificate: deteledCertificate?.length > 0,
        creditCertificateKey: deteledCertificate,
        userId: user._id,
        title: conferenceName,
        startDate: startDate,
        endDate: endDate,
        creditId: creditType,
        quantity: totalCredits,
      },
    };

    async function apiCall() {
      const parameters = [
        { name: 'startDate', value: externalStartDate?.toISOString() },
        { name: 'endDate', value: externalEndDate?.toISOString() },
        { name: 'totalCountStartDate', value: totalStartDate?.toISOString() },
        { name: 'totalCountEndDate', value: totalEndDate?.toISOString() },
      ];
      const query = getQuery(parameters);
      try {
        let response;
        if (editMode) {
          const editBaseURL = `attendees/${user?._id}/credits/externals/${editData?._id}?page=${currentPage}&limit=${pageLimit}`;
          const editURL =
            query?.length > 0 ? editBaseURL + '&' + query : editBaseURL;
          response = await api.patch(editURL, formData);
        } else {
          const addBaseURL = `attendees/credits/externals?page=${currentPage}&limit=${pageLimit}`;
          const addURL =
            query?.length > 0 ? addBaseURL + '&' + query : addBaseURL;
          response = await api.post(addURL, formData);
        }
        if (response) {
          setExternalCredits(response.data.data.externalCreditCount);
          dispatch(
            loadUserExternalCreditsAction(response.data.data.externalCredits)
          );
          dispatch(
            loadUserTotalExternalCreditsAction(
              response.data.data.savedExternalCreditCount
            )
          );
          if (editMode) {
            setEditMode(false);
          } else {
            setShowExternalCreditForm(false);
          }
          dispatch(alertAction(response.data.message, 'success'));
        }
      } catch (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    }

    if (values.certificate?.length > 0) {
      let newFiles = [];
      values.certificate?.map((file) =>
        file.key ? null : newFiles.push(file)
      );
      if (newFiles.length > 0) {
        const fileDataObj = new FormData();
        newFiles.forEach((file) => fileDataObj.append('file', file));

        if (fileDataObj.has('file')) {
          try {
            const fileResponse = await api.post('fileUploads', fileDataObj);
            if (fileResponse) {
              formData.conferenceDetails.data = fileResponse.data.data;
              formData.conferenceDetails.data[0].name =
                values.certificate[0]?.name;
            }
          } catch (err) {
            dispatch(alertAction('File(s) failed to save', 'danger'));
            return;
          }
        }
      }
      await apiCall();
    } else {
      await apiCall();
    }
  };
  // onSubmit Ends

  let formatedStartDate;
  let formatedEndDate;
  if (editData) {
    formatedStartDate = new Date(editData?.startDate);
    formatedEndDate = new Date(editData?.endDate);
  }
  const initialValues = {
    conferenceName: editData?.conferenceTitle || '',
    startDate: formatedStartDate || null,
    endDate: formatedEndDate || null,
    creditType: editData?.credit?._id || '',
    totalCredits: editData?.quantity || '',
    certificate: (editData?.certificate && [editData?.certificate]) || [],
    deteledCertificate: '',
  };

  useEffect(() => {
    getExternalCreditList(user?._id);
  }, [user?._id]);

  return (
    <>
      <div className="ec-form-wrap">
        <div className="text-align-center mb-16">
          <p className="section-title-1">
            {!editMode ? 'Add external credit' : 'Update external credit'}
          </p>
          <p className="caption-1-regular-gray3 mb-24 mt-12">
            Add CME earned outside confemy
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="form-type-1">
                  <div className=" mb-16">
                    <MaterialTextInput
                      id="conferenceName"
                      type="text"
                      label="Conference or CME name*"
                      name="conferenceName"
                      placeholder=" "
                    />
                  </div>

                  <div className="grid-col-2 ">
                    <div className="grid-1st-col">
                      <OnlyDatepicker
                        id="startDate"
                        name="startDate"
                        selected={props.values.startDate}
                        onChange={(date) =>
                          props.setFieldValue('startDate', date)
                        }
                        maxDate={props.values.endDate}
                        placeholder="Start Date*"
                        disabled={false}
                      />
                      <div className="mb-16">
                        <ErrorMessage name="startDate" component={TextError} />
                      </div>
                    </div>

                    <div className="grid-2nd-col">
                      <OnlyDatepicker
                        id="endDate"
                        name="endDate"
                        selected={props.values.endDate}
                        onChange={(date) =>
                          props.setFieldValue('endDate', date)
                        }
                        minDate={props.values.startDate}
                        placeholder="End Date*"
                        disabled={false}
                      />
                      <div className="mb-16">
                        <ErrorMessage name="endDate" component={TextError} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-16">
                    <Select
                      label="creditType"
                      name="creditType"
                      options={externalCreditList || []}
                      value={getValue(
                        externalCreditList,
                        props.values.creditType,
                        false
                      )}
                      onChange={(value) => {
                        props.setFieldValue('creditType', value?.value);
                      }}
                      placeholder="Credit Type*"
                      menuPortalTarget={document.body}
                      styles={modalStyleformType1}
                    />
                    <div className="mb-16">
                      <ErrorMessage name="creditType" component={TextError} />
                    </div>
                  </div>
                  <div>
                    <span
                      type="button"
                      className="conf-add-newcredit-btn caption-1-heavy-primary"
                      onClick={() => {
                        setAddNewCredit(true);
                      }}
                    >
                      Add new credit
                    </span>

                    <div className="mb-16"></div>
                  </div>
                  <div className="mb-16">
                    <MaterialTextInput
                      id="totalCredits"
                      type="number"
                      name="totalCredits"
                      min="1"
                      label="Total Credits*"
                      placeholder=" "
                    />
                  </div>

                  <div className="mb-16">
                    {props.values.certificate?.length > 0 ? (
                      <>
                        <div className="flex-vc-sb uc-uploadfile-input pb-24 caption-1-regular-gray2">
                          <div
                            style={{
                              overflow: 'hidden',
                              width: '90%',
                              mr: '2.5rem',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            className="flex-vc"
                          >
                            <i className="mr-8">
                              <DocumentIcon className="icon-sm" />
                            </i>
                            <p>
                              {props.values.certificate[0]?.name ||
                                'Certificate'}
                            </p>
                          </div>
                          <i
                            onClick={() => {
                              if (props.values.certificate[0]?.key) {
                                props.setFieldValue(
                                  'deteledCertificate',
                                  props.values.certificate[0]?.key
                                );
                              }
                              props.setFieldValue('certificate', []);
                            }}
                          >
                            <CloseIcon
                              style={{
                                position: 'absolute',
                                right: '1.4rem',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                transformOrigin: 'left top',
                              }}
                              className="icon-sm"
                              fill="#000000"
                            />
                          </i>
                        </div>
                      </>
                    ) : (
                      <div>
                        <Dropzone
                          accept={{
                            'image/jpeg': ['.jpeg', '.png'],
                            'application/pdf': ['.pdf'],
                          }}
                          maxFiles={1}
                          onDrop={(acceptedFiles) => {
                            props.setFieldValue('certificate', acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                {...getRootProps({
                                  className: 'uc-uploadfile-input',
                                })}
                              >
                                <div
                                  className="flex-vc"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflowX: 'hidden',
                                  }}
                                >
                                  <i className="position-relative pt-5 mr-8">
                                    <UploadArrowIcon className="icon-sm" />
                                  </i>
                                  <p className="caption-1-regular-gray2 ml-5">
                                    Click & browse to upload credit certificate
                                  </p>
                                </div>

                                <input {...getInputProps()} />
                                {/* <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p> */}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    )}
                  </div>
                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => {
                        if (editMode) {
                          setEditMode(false);
                        } else {
                          setShowExternalCreditForm(false);
                        }
                      }}
                      cancelButtonClass="button button-green"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {addNewCredit && (
        <ModalX onDismiss={() => setAddNewCredit(false)} open={addNewCredit}>
          <div className="modalx-container-md pl-16 pr-16 pb-32">
            <NewExternalCreditForm
              setAddNewCredit={setAddNewCredit}
              isExternal={true}
            />
          </div>
        </ModalX>
      )}
    </>
  );
};

export default ExternalCreditsForm;

ExternalCreditsForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func,
  setShowExternalCreditForm: PropTypes.func,
};
