import "./welcome.styles.scss";
import "./createEventCard.scss";
import AddIcon from "../icons/AddIcon";
import { removePodcastStateAction } from "../../redux/podcast/podcastAction";
import { useDispatch } from "react-redux";
import GroupIcon from "../icons/GroupIcon";
import MikeIcon from "../icons/MikeIcon";
import VideoIcon from "../icons/VideoIcon";

export default function CreateEventCard() {
  const dispatch = useDispatch();

  return (
    <div className="ad-event-welcome">
      <div>
        <div>
          <h2 className="caption-1-medium-primary mb-16">
            Create conferences, video courses or podcast from here
          </h2>

          <div className="od-menu-wrap">
            <ul className="od-menu">
              <li className="od-menu-item">
                <div className="od-createEvent-btn">
                  <AddIcon fill={"#fff"} />
                  <a className="od-create-event-text" href="#/">
                    Create event
                  </a>
                </div>
                <ul className="od-drop-menu ">
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div
                        style={{ backgroundColor: "rgba(235, 148, 129, 0.26)" }}
                        className="round-icon-wrap"
                      >
                        <GroupIcon />
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-conf/step-1"
                      >
                        Conference
                      </a>
                    </div>
                  </li>
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div className="round-icon-wrap">
                        <VideoIcon />
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-vc/step-1"
                      >
                        Video course
                      </a>
                    </div>
                  </li>
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div
                        style={{ backgroundColor: "rgba(93, 213, 239, 0.29)" }}
                        className="round-icon-wrap"
                      >
                        <MikeIcon />
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-ac/step-1"
                      >
                        Audio course
                      </a>
                    </div>
                  </li>
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div
                        style={{ backgroundColor: " #ffcc9c" }}
                        className="round-icon-wrap"
                      >
                        <MikeIcon />
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-pod/step-1"
                        onClick={() => dispatch(removePodcastStateAction())}
                      >
                        Podcast
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
