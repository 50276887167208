import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { alertAction } from '../../redux/alert/alertAction';
import { createConferenceAction } from '../../redux/conference/conferenceAction';
import { loadCreditTypesListAction } from '../../redux/list/listAction';
import api from '../../utility/api';
import { professions, subspecialties } from '../../utility/commonUtil';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import CloseIcon from '../icons/CloseIcon';
import DeleteIcon from '../icons/DeleteIcon';
import ModalX from '../modal/ModalX';
import SelectFormType1 from '../reselect/SelectFormType1';
import NewCreditForm from './NewCreditForm';
import './createConference.styles.scss';
import { modalStyleformType1 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import Switch from '../switch/Switch';
// menuPortalTarget={document.body}
// styles={modalStyleformType1}

const validationSchema = yup.object().shape({
  professions: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose professions')
    .compact(),
  specialities: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose specialitities')
    .compact(),
  tag: yup
    .string()
    .trim()
    .when('openTagsModal', {
      is: true,
      then: yup
        .string()
        .required('Required')
        .test('matchTag', 'Can not add dupulicate Tag', function (value) {
          if (this.parent.tags?.includes(value)) {
            return false;
          } else {
            return true;
          }
        }),
    }),

  tags: yup
    .array()
    .of(yup.string())
    .min(1, 'Add Tags to imrprove searchability')
    .compact(),

  creditType: yup.string().when('openCreditsModal', {
    is: true,
    then: yup
      .string()
      .required('Required')
      .test('matchTest', 'Can not add duplicate credit type', function (value) {
        if (this.parent.credits?.find((e) => e.value === value)) {
          return false;
        } else {
          return true;
        }
      }),
  }),
  creditAmount: yup
    .number()
    .nullable()
    .when('openCreditsModal', {
      is: true,
      then: yup
        .number('Give a valid number')
        // .nullable()
        .typeError('Enter Amount')
        .required('Required')
        .positive('Enter amount more than 0'),
    }),
  mocPoint: yup.number().when('mocPointAdded', {
    is: true,
    then: yup
      .number()
      .typeError('Enter valid MOC point')
      .required('Required')
      .min(0, 'MOC point can only be 1 or more'),
  }),

  // credits: yup.array().when("isAccredited", {
  //   is: true,
  //   then: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         value: yup.string().required("Required"),
  //         quantity: yup
  //           .number()
  //           .required()
  //           .positive("Enter amount more than 0")
  //           .typeError("Enter a number"),
  //       })
  //     )
  //     .min(1, "Add Credit Type and Amount")
  //     .compact(),
  // }),
});

export default function ConfDetails1() {
  const [addNewCredit, setAddNewCredit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newConference = useSelector((state) => state.conference.newConference);
  const orgId = newConference?.hostedBy?.organization?._id;
  const userId = newConference?.hostedBy?.user?._id;
  const { creditTypesList } = useSelector((state) => state.list);

  async function onSubmit(values, actions) {
    if (newConference?.completedStep > 0) {
      const {
        professions,
        specialities,
        tags,
        credits,
        mocPoint,
        mocPointAdded,
      } = values;
      const isAccredited = Boolean(credits?.length > 0);
      const formData = {
        conferenceDetails: {
          conferenceId: newConference?._id,
          professions,
          specialities,
          tags,
          isAccredited,
          credits,
          mocPoint,
          mocPointAdded,
        },
      };

      try {
        const response = await api.post('conferences/step2', formData);
        if (response) {
          dispatch(createConferenceAction(response.data.data.conference));
          navigate('/dashboard/create-conf/step-3');
          // actions.resetForm({ values: initialValues });
          dispatch(alertAction(response.data.message, 'success'));
        }
      } catch (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    } else {
      dispatch(alertAction('Complete step-1 first', 'danger'));
    }
  }

  const formik = useFormik({
    initialValues: {
      professions: newConference?.professions || [],
      specialities: newConference?.specialities || [],
      openTagsModal: false,
      openCreditsModal: false,
      tag: '',
      tags: newConference?.tags || [],
      creditAmount: null,
      creditType: '',
      credits: newConference?.conferenceCredits || [],
      mocPoint: newConference?.mocPoint || 0,
      mocPointAdded: newConference?.mocPointAdded || false,
      deletedCourses: [],
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  // load credit types from backend
  async function getCreditTypes() {
    // const url = `conferences/credits`
    const orgGetCreditsUrl = `common/conferences/credits?type=org&orgId=${orgId}`;
    const userGetCreditsUrl = `common/conferences/credits?type=user&userId=${userId}`;
    try {
      const response = await api.get(
        newConference?.host === 'organization'
          ? orgGetCreditsUrl
          : userGetCreditsUrl
      );
      if (response) {
        dispatch(loadCreditTypesListAction(response.data.data.credits));
      }
    } catch (err) {
      if (err) dispatch(alertAction(err.response?.data?.message, 'danger'));
    }
  }

  // add credit button onClick Call
  const addCredit = async () => {
    formik.setFieldTouched('creditType');
    formik.setFieldTouched('creditAmount');
    await formik.validateField('creditType');
    await formik.validateField('creditAmount');
    if (
      Boolean(formik.errors.creditType) ||
      Boolean(formik.errors.creditAmount)
    ) {
      return;
    }
    const creditLabel = creditTypesList?.find(
      (e) => e.value === formik.values.creditType
    ).label;
    const creditObj = {
      value: formik.values.creditType,
      quantity: formik.values.creditAmount,
      label: creditLabel,
    };
    formik.setFieldValue('credits', [...formik.values.credits, creditObj]);
    formik.setFieldValue('openCreditsModal', false);
    formik.setFieldTouched('creditType', false);
    formik.setFieldTouched('creditAmount', false);
    formik.setFieldValue('creditAmount', null);
    formik.setFieldValue('creditType', '');
  };

  //  add tags button onClick
  const addTags = async () => {
    formik.setFieldTouched('tag');
    await formik.validateField('tag');
    if (Boolean(formik.errors.tag)) {
      return;
    }

    formik.setFieldValue('tags', [...formik.values.tags, formik.values.tag]);

    formik.setFieldValue('openTagsModal', false);
    formik.setFieldValue('tag', '');
    formik.setFieldTouched('tag', false);
  };

  useEffect(() => {
    if (newConference?.host) {
      getCreditTypes();
    }
  }, []);

  return (
    <main className="conf-form-wrap">
      <form
        className="form-type-1"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <p className="body-regular-gray3 mb-24" style={{ color: '#aabdc7' }}>
          All mandatory fields are makred by *
        </p>
        <div className="mb-72">
          <h2>Details 1</h2>
          <h4>Professions *</h4>
          <SelectFormType1
            options={professions}
            label="professions"
            value={formik.values.professions}
            onChange={(value) => {
              return formik.setFieldValue('professions', value);
            }}
            placeholder="Choose Professions"
            isMulti={true}
          />

          <div className="mb-24">
            {formik.touched.professions &&
              Boolean(formik.errors.professions) && (
                <TextError>{formik.errors.professions}</TextError>
              )}
          </div>
          <h4>Specialties *</h4>
          <SelectFormType1
            options={subspecialties}
            label="specialities"
            name="specialities"
            placeholder="Choose specialities"
            value={formik.values.specialities}
            onChange={(value) => formik.setFieldValue('specialities', value)}
            isMulti={true}
          />
          <div className="mb-24">
            {formik.touched.specialities &&
              Boolean(formik.errors.specialities) && (
                <TextError>{formik.errors.specialities}</TextError>
              )}
          </div>
          <h4>Improve Searchability with Tags *</h4>
          <ul className="tags-display mb-16">
            {formik.values.tags.map((tg) => (
              <li key={tg}>
                {tg}
                <i
                  onClick={(e) => {
                    formik.setFieldValue(
                      'tags',
                      formik.values.tags.filter((e) => e !== tg)
                    );
                  }}
                >
                  <CloseIcon className="xs-icon" />
                </i>
              </li>
            ))}
          </ul>

          <button
            type="button"
            onClick={() => formik.setFieldValue('openTagsModal', true)}
            className="button-outlined button-outlined-primary p-8"
          >
            + Add Tags
          </button>
          {formik.values.openTagsModal && (
            <ModalX
              onDismiss={() => {
                formik.setFieldValue('openTagsModal', false);
                formik.setFieldTouched('tag', false);
              }}
              open={formik.values.openTagsModal}
            >
              <div className="modalx-container-md pl-16 pr-16 pb-32">
                <h4 className="color-primary text-align-center">Add Tags</h4>
                <div className="material-textfield mb-18">
                  <input
                    id="tag"
                    type="text"
                    name="tag"
                    value={formik.values.tag}
                    onChange={formik.handleChange}
                    placeholder=" "
                  />
                  <label>Tag</label>
                </div>

                <div className="mb-24">
                  {formik.touched.tag && Boolean(formik.errors.tag) && (
                    <TextError>{formik.errors.tag}</TextError>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => addTags()}
                    type="button"
                    className="button button-primary add-credit-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </ModalX>
          )}

          <div className="mb-24">
            {formik.touched.tags && Boolean(formik.errors.tags) && (
              <TextError>{formik.errors.tags}</TextError>
            )}
          </div>
        </div>
        <div className="mb-72">
          <h2>Credits</h2>
          <div className="flex-vc mb-24">
            <p className="caption-1-regular-gray3 mr-16">
              If you offer credits for attending conference then add credits
              type and it's amount.
            </p>
          </div>
          {/* <ul className="tags-display mb-16">
            {formik.values.credits.map((credit) => {
              return (
                <li key={credit?.value}>
                  {credit?.label} - {credit?.quantity}
                  <i
                    onClick={(e) => {
                      formik.setFieldValue(
                        'credits',
                        formik.values.credits.filter((e) => e !== credit)
                      );
                    }}
                  >
                    <CloseIcon className="xs-icon" />
                  </i>
                </li>
              );
            })}
          </ul> */}

          {formik.values.credits?.length > 0 && (
            <div>
              <table className="conf-credits-table mb-24">
                <thead>
                  <tr>
                    <th>Type of Credit</th>
                    <th>No of Credits offered</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.credits?.map((credit) => {
                    return (
                      <tr key={credit.value}>
                        <td className="cell-hyphens">{credit.label}</td>
                        <td>{credit.quantity}</td>
                        <td>
                          <i
                            onClick={(e) => {
                              formik.setFieldValue(
                                'credits',
                                formik.values.credits.filter(
                                  (e) => e !== credit
                                )
                              );
                            }}
                          >
                            <DeleteIcon className="icon-size" fill="#757575" />{' '}
                          </i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <button
            type="button"
            onClick={() => formik.setFieldValue('openCreditsModal', true)}
            className="button-outlined button-outlined-primary p-8"
          >
            + Add Credits
          </button>

          {formik.values.openCreditsModal && !addNewCredit && (
            <ModalX
              onDismiss={() => {
                formik.setFieldValue('openCreditsModal', false);
                formik.setFieldTouched('creditType', false);
                formik.setFieldTouched('creditAmount', false);
              }}
              open={formik.values.openCreditsModal && !addNewCredit}
            >
              <div className="modalx-container-md pl-16 pr-16 pb-32">
                <h4 className="text-align-center">Add Credits</h4>
                <div className="addcredit-grid mt-40">
                  <div className="grid-1st-col">
                    <Select
                      options={creditTypesList}
                      label="Credit Type"
                      name="creditType"
                      value={getValue(
                        creditTypesList,
                        formik.values.creditType,
                        false
                      )}
                      onChange={(value) => {
                        formik.setFieldValue('creditType', value?.value);
                      }}
                      placeholder="Select Credit Type"
                      menuPortalTarget={document.body}
                      styles={modalStyleformType1}
                    />
                    <div className="mb-24">
                      {formik.touched.creditType &&
                        Boolean(formik.errors.creditType) && (
                          <TextError>{formik.errors.creditType}</TextError>
                        )}
                    </div>
                  </div>
                  <div>
                    <span
                      type="button"
                      className="conf-add-newcredit-btn caption-1-heavy-primary m-4"
                      onClick={() => {
                        setAddNewCredit(true);
                      }}
                    >
                      Add new credit
                    </span>

                    <div className="mb-24"></div>
                  </div>

                  <div className="grid-1st-col">
                    <div className="material-textfield">
                      <input
                        id="creditAmount"
                        type="number"
                        min={1}
                        name="creditAmount"
                        value={formik.values.creditAmount}
                        onChange={formik.handleChange}
                        placeholder=" "
                      />
                      <label>Choose Amount</label>
                    </div>
                    <div className="mb-24">
                      {formik.touched.creditAmount &&
                        Boolean(formik.errors.creditAmount) && (
                          <TextError>{formik.errors.creditAmount}</TextError>
                        )}
                    </div>
                  </div>
                  <div className="grid-1st-col">
                    <button
                      type="button"
                      className="button button-primary"
                      onClick={() => addCredit()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </ModalX>
          )}
          <div className="mb-24 mt-24">
            <h4 className="avenir-20-500 mb-8">MOC Point</h4>
            <p
              className="caption-1-regular-gray3 mb-8"
              style={{ color: '#aabdc7' }}
            >
              MOC points are points earned through Maintenance of Certification
              (MOC) activities to maintain board certification.
            </p>
            <Switch
              id="preview"
              name="mocPointAdded"
              value={formik.values.mocPointAdded}
              checked={formik.values.mocPointAdded}
              onChange={formik.handleChange}
              disable={false}
            />
          </div>
          {formik?.values?.mocPointAdded && (
            <div className="mb-24">
              <div className="material-textfield">
                <input
                  id="mocPoint"
                  type="number"
                  value={formik.values.mocPoint}
                  onChange={formik.handleChange}
                  name="mocPoint"
                  min={0}
                  placeholder=" "
                />
                <label>MOC Point</label>
              </div>
              <div className="mb-24">
                {formik.touched.mocPoint && Boolean(formik.errors.mocPoint) && (
                  <TextError>{formik.errors.mocPoint}</TextError>
                )}
              </div>
            </div>
          )}

          <div className="mb-24">
            {formik.touched.mocPoint && Boolean(formik.errors.mocPoint) && (
              <TextError>{formik.errors.mocPoint}</TextError>
            )}
          </div>
        </div>
        <div className="mb-72">
          <SubmitCancelButtonWithLoader
            isSubmitting={formik.isSubmitting}
            onCancel={() => formik.resetForm({})}
          />
        </div>
      </form>
      {addNewCredit && (
        <ModalX onDismiss={() => setAddNewCredit(false)} open={addNewCredit}>
          <div className="modalx-container-md pl-16 pr-16 pb-32">
            <NewCreditForm setAddNewCredit={setAddNewCredit} />
          </div>
        </ModalX>
      )}
    </main>
  );
}
