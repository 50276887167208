import { useCallback, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  loadMyOrganizationsAction,
  myOrganizationsErrorAction,
} from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import NextIcon from '../icons/NextIcon';

import './myOrganizations.styles.scss';

export default function MyOrganizations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const myOrganizations = useSelector((state) => state.myOrganizations);
  const { isLoading, isError, organizations } = myOrganizations;

  const getMyOrganizations = useCallback(async (id) => {
    const url = `organizations/users/${id}`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadMyOrganizationsAction(response.data.data.organization));
      }
    } catch (err) {
      if (err) {
        dispatch(myOrganizationsErrorAction());
      }
    }
  }, []);

  useEffect(() => {
    getMyOrganizations(user?._id);
  }, [user?._id, getMyOrganizations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="my-orgs-container">
      <div className="my-orgs-header">
        <h2 className="color-primary">My Organizations</h2>
        <button
          onClick={() => navigate('/dashboard/create-organization')}
          className="button-text button-text-primary"
        >
          + Add
        </button>
      </div>
      {!isLoading && !isError && organizations && (
        <div className="my-orgs-grid ">
          {organizations.map((item) => (
            <Fragment key={item.organization._id}>
              <div
                className="org-logo-container org-logo-hide"
                onClick={() => navigate(item.organization._id)}
              >
                {item.organization?.logo[0] ? (
                  <>
                    <img
                      src={`${item.organization?.logo[0]?.Location}`}
                      alt="Logo"
                    />
                  </>
                ) : (
                  <div className="my-orgs-add-logo">
                    <span className="caption-2-bold-cblack color-primary">
                      Add Logo
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{ justifyContent: 'flex-start' }}
                onClick={() => navigate(item.organization._id)}
                className="my-orgs-grid-item"
              >
                <span className="org-name ">{item.organization.name}</span>
              </div>
              <div
                onClick={() => navigate(item.organization._id)}
                className="my-orgs-grid-item"
              >
                <NextIcon className="icon-lg" />
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
