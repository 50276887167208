export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const GET_USER_EXTERNAL_CREDITS = "GET_USER_EXTERNAL_CREDITS";

export const GET_USER_CUSTOM_EXTERNAL_CREDITS_TYPE =
  "GET_USER_CUSTOM_EXTERNAL_CREDITS_TYPE";
export const GET_USER_CUSTOM_CONFERENCE_CREDITS_TYPE =
  "GET_USER_CUSTOM_CONFERENCE_CREDITS_TYPE";
export const GET_USER_TOTAL_EXTERNAL_CREDITS =
  "GET_USER_TOTAL_EXTERNAL_CREDITS";
export const GET_USER_CREDIT_CONFERENCES = "GET_USER_CREDIT_CONFERENCES";
export const USER_TOTAL_CREDITS = "USER_TOTAL_CREDITS";
export const USER_SPEAKERS = "USER_SPEAKERS";
