import { LOAD_DEFAULT_REFUND_POLICY, LOAD_PREMADE_BANNERS } from "./assetTypes";
const INITIAL_STATE = {
  defaultRefundPolicy: null,
  premadeBanners: [],
};

const assetReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_DEFAULT_REFUND_POLICY:
      return {
        ...state,
        defaultRefundPolicy: payload,
      };
    case LOAD_PREMADE_BANNERS:
      return {
        ...state,
        premadeBanners: payload,
      };
    default:
      return state;
  }
};

export default assetReducer;
