import Button1 from '../../video-course/utils/Buttons/Button1';
import './CourseOverview.scss';
import ProfileIcon from '../../icons/ProfileSketch';
import DocumentIcon from '../../icons/DocumentIcon';
import { openInNewTab } from '../../../utility/commonUtil';

const CourseOverview = ({ courseData }) => {
  const data = {
    ttl: courseData?.title,
    desc: courseData?.description,
    skillLevel: 'Intermediate Level',
    students: 59241,
    languages: ['English'],
    captions: 'Yes',
    lectures: 116,
    totalDur: courseData?.duration,
    crsDesc: courseData?.description,

    instructor: {
      name: courseData?.hostedBy?.organization
        ? courseData?.hostedBy?.organization?.name
        : `${courseData?.hostedBy?.user?.firstName} ${courseData?.hostedBy?.user?.lastName}`,
      img: '',
      ttl: '',
      desc: courseData?.hostedBy?.organization
        ? courseData?.hostedBy?.organization?.description
        : '',
    },
    resources: courseData?.resources,
  };

  return (
    <div className="oview-container">
      <div className="oview-box">
        <h1 className="oview-ttl">{data.ttl}</h1>
        <div className="oview-desc avenir-16-500">{data.desc}</div>
      </div>
      <div className="oview-box1">
        <div className="oview-sTtl avenir-18-500 ">By the numbers</div>
        <div className="oview-sinBox">
          <div className="oview-item">
            <span className="avenir-16-500">Lectures:</span>
            <span className="avenir-16-500">{courseData?.episodeCount}</span>
          </div>
          <div className="oview-item">
            <span className="avenir-16-500">Total:</span>
            <span className="avenir-16-500">{data.totalDur}</span>
          </div>
        </div>
      </div>
      <div className="oview-box1">
        <div className="oview-sTtl avenir-18-500">Certificates</div>
        <div className="oview-sinBox">
          <div className="avenir-16-500">
            Get certificate by completing entire course
          </div>
          <Button1
            txt="Certificate"
            disableBtn={true}
            extraCss={{ width: '50%' }}
          />
        </div>
      </div>

      <div className="oview-box1">
        <div className="oview-sTtl avenir-18-500">Description</div>
        <div className="oview-sinBox">
          <div className="avenir-16-500">{data.crsDesc}</div>
        </div>
      </div>
      <div className="oview-box1">
        <div className="oview-sTtl avenir-18-500">Hosted by</div>
        <div className="oview-sinBox">
          <div className="oview-instructorBox">
            <div className="icon-sm">
              <ProfileIcon fill={'#08415c'} />
              {/* <img src={} alt="profile" className={css.instImg} /> */}
            </div>
            <div className="oview-rightInstBox">
              <div className="avenir-16-500">{data.instructor.name}</div>
              <div className="avenir-16-500">{data.instructor.ttl}</div>
            </div>
          </div>
          <div className="oview-instDesc">{data.instructor.desc}</div>
        </div>
      </div>
      {data?.resources?.length > 0 && (
        <div className="oview-box1">
          <div className="oview-sTtl avenir-18-500">Resources</div>
          <div className="oview-sinBox">
            <div key={''} className="flex-vc-sb  caption-1-regular-gray2">
              {data?.resources?.length > 0 &&
                data?.resources.map((re) => {
                  return (
                    <div
                      key={re.Location}
                      style={{ cursor: 'pointer' }}
                      className="flex-vc"
                      onClick={(e) => {
                        e.stopPropagation();
                        openInNewTab(re.Location);
                      }}
                    >
                      <i className="position-relative mr-4">
                        <DocumentIcon className="icon-sm " />
                      </i>
                      <span className="hover-bg p-4 avenir-16-500">
                        {re?.fileNameWithExtension}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseOverview;
