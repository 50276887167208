import { formatInTimeZone } from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { alertAction, localAlertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import LocalAlert from "../alert/LocalAlert";
import TextError from "../formik/TextError";
import DateIcon from "../icons/DateIcon";
import LocationIcon from "../icons/LocationIcon";
import PriceIcon from "../icons/PriceIcon";
import Loader from "../loader/Loader";
import "./refundDetails.scss";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";

let validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required("Required")
    .when(
      "price",
      (price, schema) =>
        price &&
        schema
          .min(1, "Amount must me greater than 0")
          .max(price, "Amount should less than ticket price")
    ),
});

export default function RefundDetails({ refundId }) {
  const [refundData, setRefundData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rejectSubmit, setRejectSubmit] = useState(false);
  const [showRefundForm, setshowRefundForm] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  let initialValues = {
    price: refundData?.price,
    amount: 1,
  };

  const getBookingDetails = async (refundId) => {
    try {
      let res = await api.get(`/attendees/${refundId}/bookingDetails`);

      setRefundData(res.data.data.attendeeDetails);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const startDateObj = new Date(refundData?.conference?.startDate);
  let formattedStartDate;
  if (startDateObj && refundData?.conference?.timezone) {
    formattedStartDate = formatInTimeZone(
      startDateObj,
      refundData?.conference?.timezone,
      "MMM-dd-yyyy, HH:mm aa",
      { locale: enGB }
    );
  }
  const endtDateObj = new Date(refundData?.conference?.endDate);
  let formattedEndtDate;
  if (endtDateObj && refundData?.conference?.timezone) {
    formattedEndtDate = formatInTimeZone(
      endtDateObj,
      refundData?.conference?.timezone,
      "MMM-dd-yyyy, HH:mm aa",
      { locale: enGB }
    );
  }

  let formattedBookingDate;
  const bookingDateObj = new Date(refundData?.bookingDate);
  if (bookingDateObj && refundData?.conference?.timezone) {
    formattedBookingDate = formatInTimeZone(
      bookingDateObj,
      refundData?.conference?.timezone,
      "MMM-dd-yyyy, HH:mm aa",
      { locale: enGB }
    );
  }

  const getLocationString = () => {
    let locationStrig = "Location";
    if (refundData?.conference?.mode?.length > 0) {
      if (
        refundData?.conference?.mode?.includes("venue") &&
        refundData?.conference?.location
      ) {
        locationStrig = refundData?.conference?.location;
      }

      if (refundData?.conference?.mode?.includes("onlineConf")) {
        locationStrig = "Online";
      }

      if (
        refundData?.conference?.mode?.includes("venue") &&
        refundData?.conference?.mode?.includes("onlineConf")
      ) {
        locationStrig = `${refundData?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  async function onSubmit(values, actions) {
    try {
      setIsSubmitting(true);

      let formData = {
        refundDetails: {
          attendeeId: refundId,
          refundAmount: values?.amount,
          conferenceId: refundData?.conference?._id,
          approvedBy: user?._id,
        },
      };
      let res = await api.post(`/organizers/refunds`, formData);

      setRefundData(res.data.data.refundData);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      dispatch(
        localAlertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }
  const rejectRefund = async () => {
    try {
      setRejectSubmit(true);

      let formData = {
        refundDetails: {
          attendeeId: refundData?._id,
          conferenceId: refundData?.conference?._id,
          rejectedBy: user?._id,
        },
      };
      let res = await api.post(`/organizers/refunds/reject`, formData);

      setRefundData(res.data.data.refundData);
      setRejectSubmit(false);
    } catch (err) {
      setRejectSubmit(false);
      dispatch(
        localAlertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };
  useEffect(() => {
    getBookingDetails(refundId);
  }, [refundId]);

  return (
    <>
      {!refundData ? (
        <Loader />
      ) : (
        <div className="refund-details-wrap">
          <div>
            <LocalAlert />
            <div className="mb-40">
              <h2 className="text-align-center color-primary mb-8">
                {refundData?.name}
              </h2>
              <p className="text-align-center caption-1-heavy-cblack mb-4">
                {refundData?.email}
              </p>
              <p className="text-align-center caption-1-heavy-cblack">
                {`${refundData?.state ? refundData?.state : ""} ${
                  refundData?.city ? refundData?.city : ""
                }`}
              </p>
            </div>
            <div className="caption-2-regular-gray3 mb-8">
              <h4 className="body-regular-gray3 mb-8">
                {refundData?.conference?.title}
              </h4>
              <div className="flex-vc mb-8">
                <DateIcon className="icon-sm mr-12" />
                <span className="caption-2-regular-gray3">
                  Start Date : {formattedStartDate}
                </span>
              </div>
              <div className="flex-vc mb-8">
                <DateIcon className="icon-sm mr-12" />
                <span className="caption-2-regular-gray3">
                  End Date : {formattedEndtDate}
                </span>
              </div>
              <div className="flex-vc mb-24">
                <LocationIcon className="icon-sm mr-12" />
                <span className="caption-2-regular-gray3">
                  Location : {getLocationString()}
                </span>
              </div>
            </div>
            <div className="mb-24">
              <div className="mb-8 mr-12">
                <span className="caption-1-heavy-cblack">Ticket Name : </span>
                <span className="caption-2-regular-gray3">
                  {refundData?.ticketName}{" "}
                </span>
              </div>
              <div className="mb-8 mr-12">
                <span className="caption-1-heavy-cblack">
                  Booking number :{" "}
                </span>
                <span className="caption-2-regular-gray3">
                  {refundData?.bookingNumber}{" "}
                </span>
              </div>
              <div className="mb-8">
                <span className="caption-1-heavy-cblack ">Booking Date : </span>
                <span className="caption-2-regular-gray3">
                  {formattedBookingDate}
                </span>
              </div>
              {refundData?.refundRequestDate && (
                <div className="mb-8">
                  <span className="caption-1-heavy-cblack ">
                    Refund Requested Date :
                  </span>
                  <span className="caption-2-regular-gray3">
                    {" "}
                    {new Date(refundData?.refundRequestDate).toLocaleString()}
                  </span>
                </div>
              )}
              <div className="mb-8">
                <span className="caption-1-heavy-cblack ">
                  Max Refund Date :{" "}
                </span>
                <span
                  style={{
                    color: `${"#d8000c"}`,
                  }}
                  className="caption-2-regular-gray3"
                >
                  {new Date(refundData?.maxRefundDate).toLocaleString()}
                </span>
              </div>

              <div className="caption-2-regular-gray3 ">
                <span className="caption-1-heavy-cblack">
                  Booking status :{" "}
                </span>
                <span
                  className="caption-2-regular-gray3"
                  style={{
                    color:
                      refundData?.ticketBooked == 1 && refundData.status == 1
                        ? "#08415c"
                        : "#d8000c",
                  }}
                >
                  {`${
                    refundData?.ticketBooked == 1 && refundData.status == 1
                      ? "Booked"
                      : "Cancelled"
                  }`}{" "}
                </span>
              </div>
              {(refundData?.refundStatus === 1 ||
                refundData?.refundStatus === 2) && (
                <div className="caption-2-regular-gray3 mb-8 mt-8 mr-12">
                  <span className="caption-1-heavy-cblack">Approved By : </span>
                  <span className="caption-2-regular-gray3">
                    {`${refundData?.approvedBy?.firstName} ${refundData?.approvedBy?.lastName}`}
                  </span>
                </div>
              )}
              {refundData?.refundStatus === 5 && (
                <div className="caption-2-regular-gray3 mb-8 mt-8 mr-12">
                  <span className="caption-1-heavy-cblack">Rejected By : </span>
                  <span className="caption-2-regular-gray3">
                    {`${refundData?.rejectedBy?.firstName} ${refundData?.rejectedBy?.lastName}`}
                  </span>
                </div>
              )}
              {(refundData?.refundStatus === 1 ||
                refundData?.refundStatus === 2) && (
                <div className="caption-2-regular-gray3 mb-8 mt-8 mr-12">
                  <span className="caption-1-heavy-cblack">
                    Refunded Amount :{" "}
                  </span>
                  <span className="caption-2-regular-gray3">{`${refundData?.refundAmount}`}</span>
                </div>
              )}
              {refundData?.refundStatus && (
                <div className="caption-2-regular-gray3 mb-8 mt-8 mr-12">
                  <span className="caption-1-heavy-cblack">
                    Refund status :{" "}
                  </span>
                  {refundData?.refundStatus === 2 && (
                    <span
                      className="caption-2-regular-gray3"
                      style={{ color: "#08415c" }}
                    >
                      {"Initiated"}
                    </span>
                  )}
                  {refundData?.refundStatus === 1 && (
                    <span
                      className="caption-2-regular-gray3"
                      style={{ color: "#08415c" }}
                    >
                      {"Refunded"}
                    </span>
                  )}
                  {refundData?.refundStatus === 5 && (
                    <span
                      className="caption-2-regular-gray3"
                      style={{ color: "#d8000c" }}
                    >
                      {"Rejected"}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="flex-vc mb-32">
              <PriceIcon className="icon-sm mr-12" />
              <h4>Price : {refundData?.price}</h4>
            </div>

            {refundData?.price > 0 && showRefundForm ? (
              <div>
                {(refundData?.refundStatus != 2 ||
                  refundData?.refundStatus != 1) && (
                  <div>
                    <div>
                      {(!refundData?.refundStatus ||
                        refundData?.refundStatus === 5) && (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                          enableReinitialize={true}
                        >
                          {(props) => {
                            return (
                              <>
                                <form
                                  className="form-type-3"
                                  autoComplete="off"
                                  onSubmit={props.handleSubmit}
                                >
                                  <p className="caption-1-regular-gray3 mb-8">
                                    Enter refund amout to be approved
                                  </p>
                                  <div>
                                    <Field
                                      id="amount"
                                      name="amount"
                                      type="number"

                                      // value={props?.values?.amount}
                                    />
                                  </div>
                                  <div className="mb-24">
                                    <ErrorMessage
                                      name={"amount"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div>
                                    {(!refundData?.refundStatus ||
                                      refundData?.refundStatus === 5) && (
                                      <>
                                        <SubmitCancelButtonWithLoader
                                          onCancel={() =>
                                            setshowRefundForm(false)
                                          }
                                          // cancelButtonClass="button"
                                          isSubmitting={isSubmitting}
                                          // fullWidth={true}
                                        />
                                      </>
                                    )}
                                  </div>
                                </form>
                              </>
                            );
                          }}
                        </Formik>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : !refundData?.refundStatus ? (
              <div className="flex-vc">
                <button
                  style={{ width: "100%" }}
                  className="button button-primary mr-4"
                  onClick={() => setshowRefundForm(true)}
                >
                  Approve Refund
                </button>

                <button
                  style={{ width: "100%" }}
                  className="button button-green ml-4"
                  onClick={() => {
                    rejectRefund();
                  }}
                >
                  Reject
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
