function ManageCMECredits({ className}) {

  
    return (
        <svg className={className} width="266px" height="261px" viewBox="0 0 266 261" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Group 5@2x</title>
    <g id="Creatives" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-5">
            <g id="clickstream-copy" transform="translate(0.000000, 54.094118)" fillRule="nonzero">
                <g id="Group" transform="translate(0.633929, 45.982143)">
                    <path d="M205.080357,114.298469 L0,114.298469 L0,15.2397959 C0,6.82314238 6.80135712,0 15.1911376,0 L189.88922,0 C198.279,0 205.080357,6.82314238 205.080357,15.2397959 L205.080357,114.298469 Z" id="Path" fill="#052A3C"></path>
                    <path d="M189.88922,114.298469 L0,114.298469 L0,15.2397959 C0,6.82314238 6.80135712,0 15.1911376,0 L174.698082,0 C183.087862,0 189.88922,6.82314238 189.88922,15.2397959 L189.88922,114.298469 Z" id="Path" fill="#08415C"></path>
                    <path d="M15.1911376,133.348214 L189.88922,133.348214 C198.279,133.348214 205.080357,126.525072 205.080357,118.108418 L205.080357,114.298469 L0,114.298469 L0,118.108418 C0,126.525072 6.80135712,133.348214 15.1911376,133.348214 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
                    <path d="M174.698082,133.348214 L15.1911376,133.348214 C6.80135712,133.348214 0,126.525072 0,118.108418 L0,114.298469 L189.88922,114.298469 L189.88922,118.108418 C189.88922,126.525072 183.087862,133.348214 174.698082,133.348214 Z" id="Path" fill="#CED9DE"></path>
                    <polygon id="Path" fill="#052A3C" points="79.7534722 133.348214 113.933532 133.348214 113.933532 148.58801 79.7534722 148.58801"></polygon>
                    <polygon id="Path" fill="#08415C" points="79.7534722 140.968112 102.540179 140.968112 102.540179 148.58801 79.7534722 148.58801"></polygon>
                    <path d="M132.922454,160.017857 L56.9667659,160.017857 L56.9667659,156.207908 C56.9667659,151.999343 60.3672071,148.58801 64.5623347,148.58801 L125.326885,148.58801 C129.522013,148.58801 132.922454,151.999343 132.922454,156.207908 L132.922454,160.017857 Z" id="Path" fill="#08415C"></path>
                    <path d="M132.922454,160.017857 L110.135747,160.017857 L110.135747,156.207908 C110.135747,151.999343 113.536189,148.58801 117.731316,148.58801 L125.326885,148.58801 C129.522013,148.58801 132.922454,151.999343 132.922454,156.207908 L132.922454,160.017857 Z" id="Path" fill="#052A3C"></path>
                </g>
                <path d="M159.490132,137.026786 L30.3045113,137.026786 C26.1074035,137.026786 22.7053571,133.61872 22.7053571,129.414187 L22.7053571,7.61259921 C22.7053571,3.40806551 26.1074035,0 30.3045113,0 L159.490132,0 C163.687239,0 167.089286,3.40806551 167.089286,7.61259921 L167.089286,129.414187 C167.089286,133.61872 163.687239,137.026786 159.490132,137.026786 Z" id="Path" fill="#FFFFFF"></path>
                <path d="M167.089286,15.6339286 L22.7053571,15.6339286 L22.7053571,7.81696429 C22.7053571,3.4995572 26.1074035,0 30.3045113,0 L159.490132,0 C163.687239,0 167.089286,3.4995572 167.089286,7.81696429 L167.089286,15.6339286 Z" id="Path" fill="#08415C"></path>
                <circle id="Oval" fill="#FFFFFF" cx="38.3392857" cy="7.35714286" r="3.67857143"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="53.0535714" cy="7.35714286" r="3.67857143"></circle>
                <circle id="Oval" fill="#4CB944" cx="68.6875" cy="7.35714286" r="3.67857143"></circle>
                <path d="M148.55495,76.3303571 L40.3200497,76.3303571 C38.2104273,76.3303571 36.5,74.6146484 36.5,72.4985119 L36.5,34.1800595 C36.5,32.063923 38.2104273,30.3482143 40.3200497,30.3482143 L148.55495,30.3482143 C150.664573,30.3482143 152.375,32.063923 152.375,34.1800595 L152.375,72.4985119 C152.375,74.6146484 150.664573,76.3303571 148.55495,76.3303571 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
                <path d="M87.8930758,122.3125 L41.2051385,122.3125 C39.1145985,122.3125 37.4196429,120.624243 37.4196429,118.541964 L37.4196429,88.3776786 C37.4196429,86.2954002 39.1145985,84.6071429 41.2051385,84.6071429 L87.8930758,84.6071429 C89.9836158,84.6071429 91.6785714,86.2954002 91.6785714,88.3776786 L91.6785714,118.541964 C91.6785714,120.624243 89.9836158,122.3125 87.8930758,122.3125 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
                <path d="M148.589504,91.0446429 L101.901567,91.0446429 C99.8110271,91.0446429 98.1160714,89.3975625 98.1160714,87.3660714 C98.1160714,85.3345804 99.8110271,83.6875 101.901567,83.6875 L148.589504,83.6875 C150.680044,83.6875 152.375,85.3345804 152.375,87.3660714 C152.375,89.3975625 150.680044,91.0446429 148.589504,91.0446429 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
                <path d="M148.589504,106.678571 L101.901567,106.678571 C99.8110271,106.678571 98.1160714,105.031491 98.1160714,103 C98.1160714,100.968509 99.8110271,99.3214286 101.901567,99.3214286 L148.589504,99.3214286 C150.680044,99.3214286 152.375,100.968509 152.375,103 C152.375,105.031491 150.680044,106.678571 148.589504,106.678571 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
                <path d="M148.589504,122.3125 L101.901567,122.3125 C99.8110271,122.3125 98.1160714,120.66542 98.1160714,118.633929 C98.1160714,116.602438 99.8110271,114.955357 101.901567,114.955357 L148.589504,114.955357 C150.680044,114.955357 152.375,116.602438 152.375,118.633929 C152.375,120.66542 150.680044,122.3125 148.589504,122.3125 Z" id="Path" fillOpacity="0.345006556" fill="#08415C"></path>
            </g>
            <g id="Graph" transform="translate(94.775676, 108.094118) scale(1, -1) translate(-94.775676, -108.094118) translate(46.275676, 88.094118)">
                <rect id="Value-Copy-2" fill="#C5CFD6" x="90.7243243" y="0" width="6" height="40" rx="3"></rect>
                <rect id="Value-Copy" fill="#C5CFD6" x="75.7243243" y="0" width="6" height="40" rx="3"></rect>
                <rect id="Value" fill="#C5CFD6" x="60.7243243" y="0" width="6" height="40" rx="3"></rect>
                <rect id="Value" fill="#DDE3E9" x="45.7243243" y="0" width="6" height="29" rx="3"></rect>
                <rect id="Value" fill="#DDE3E9" x="30.7243243" y="0" width="6" height="34" rx="3"></rect>
                <rect id="Value" fill="#DDE3E9" x="15.7243243" y="0" width="6" height="29" rx="3"></rect>
                <rect id="Value" fill="#DDE3E9" x="0.724324324" y="0" width="6" height="18" rx="3"></rect>
            </g>
            <g id="Chart" transform="translate(184.000000, 0.000000)">
                <path d="M51.5657609,50.9054176 C48.9420667,53.6934176 45.2253378,55.4441176 41.0915299,55.4441176 C33.1485372,55.4441176 26.7094945,49.0194176 26.7094945,41.0941176 C26.7094945,33.1688176 33.1485372,26.7441176 41.0915299,26.7441176 L41.0915299,0.0941176471 C18.4952976,0.0941176471 0,18.5482176 0,41.0941176 C0,63.6400176 18.4952976,82.0941176 41.0915299,82.0941176 C52.9608184,82.0941176 63.6877622,77.0839176 71,69.1135176 L51.5657609,50.9054176 Z" id="Value" fill="#F2F2F2"></path>
                <path d="M41,0.0941176471 L41,26.7441176 C48.9253,26.7441176 55.35,33.1688176 55.35,41.0941176 L82,41.0941176 C82,18.5482176 63.5459,0.0941176471 41,0.0941176471 Z" id="Value" fill="#4CB944"></path>
                <path d="M55.462618,41.0941176 C55.462618,45.6367622 53.3232968,49.6746684 50,52.2818045 L66.3490686,73.0941176 C75.8657821,65.6431223 82,54.0931636 82,41.0941176 L55.462618,41.0941176 Z" id="Value" fill="#C5CFD6"></path>
            </g>
        </g>
    </g>
</svg>
       
      
    );
  }
  
  export default ManageCMECredits;
  