import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../utility/api";
import ConfCarousal from "../carousal/ConfCarousal";
import { recentlyViewedAction } from "../../redux/recently-viewed/recentlyViewedAction";

function RecentlyViewed() {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const recentlyViewed = useSelector(
    (state) => state.recentlyViewed?.recentlyViewed
  );

  const dispatch = useDispatch();

  const loadRecentlyVidwedData = async () => {
    try {
      const response = await api.get("/homePage/recentlyviewed?page=1&limit=5");
      dispatch(recentlyViewedAction(response?.data?.data?.viewedData));
    } catch (err) {}
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      loadRecentlyVidwedData();
    }
  }, [isAuthenticated, user]);

  return (
    <div className="ccah-container bg-background">
      <div className="ccah-header-wrap">
        <h2>Recently Viewed</h2>
      </div>
      {!recentlyViewed?.length > 0 ? (
        <div className="ccah-header-wrap">
          <h3 className="color-primary mt-16 mb-16"> No recent activity !</h3>
        </div>
      ) : (
        <ConfCarousal data={recentlyViewed} />
      )}
    </div>
  );
}

export default RecentlyViewed;
