import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';
import ScheduleView from '../../components/schedule/ScheduleView';

export default function ScheduleViewPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container pt-32">
      <ScheduleView />
    </div>
  );
}
