import React, { Fragment } from 'react';

function ClosedEyeIcon({ className, fill }) {
  return (
    <Fragment>
      <svg
        className={className}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>icon/hide</title>
        <g
          id="icon/hide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="visibility_off_black_24dp">
            <path
              d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z"
              id="Shape"
            ></path>

            <path
              d="M12,6.5 C14.76,6.5 17,8.74 17,11.5 C17,12.01 16.9,12.5 16.76,12.96 L19.82,16.02 C21.21,14.79 22.31,13.25 23,11.49 C21.27,7.11 17,4 12,4 C10.73,4 9.51,4.2 8.36,4.57 L10.53,6.74 C11,6.6 11.49,6.5 12,6.5 Z M2.71,3.16 C2.32,3.55 2.32,4.18 2.71,4.57 L4.68,6.54 C3.06,7.83 1.77,9.53 1,11.5 C2.73,15.89 7,19 12,19 C13.52,19 14.97,18.7 16.31,18.18 L19.03,20.9 C19.42,21.29 20.05,21.29 20.44,20.9 C20.83,20.51 20.83,19.88 20.44,19.49 L4.13,3.16 C3.74,2.77 3.1,2.77 2.71,3.16 Z M12,16.5 C9.24,16.5 7,14.26 7,11.5 C7,10.73 7.18,10 7.49,9.36 L9.06,10.93 C9.03,11.11 9,11.3 9,11.5 C9,13.16 10.34,14.5 12,14.5 C12.2,14.5 12.38,14.47 12.57,14.43 L14.14,16 C13.49,16.32 12.77,16.5 12,16.5 Z M14.97,11.17 C14.82,9.77 13.72,8.68 12.33,8.53 L14.97,11.17 Z"
              id="Shape"
              // fill="#757575"
              fill={fill}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </svg>
    </Fragment>
  );
}

export default ClosedEyeIcon;
