import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const SECOND = 1000;
const MINUTE = SECOND * 60;

export default function Timer({ expiryMinutes }) {
  //   const expiryMinutes = 2;
  const [deadline, setDeadline] = useState(
    Date.now() + expiryMinutes * 60 * 1000
  );
  const [time, setTime] = useState(expiryMinutes * 60 * 1000);

  useEffect(() => {
    const interval = setInterval(() => setTime(deadline - Date.now()), 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  return (
    <div
      style={{
        height: "40px",
        // width: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        // border: "1px solid black",
      }}
    >
      {/* <DeleteIcon className="icon-size" fill="#4cb944" /> */}
      <p className="caption-1-regular-gray3">
        <span>{`${Math.floor((time / MINUTE) % 60)}`.padStart(2, "0")}</span>{" "}
        <span>:</span>
        <span>
          {`${Math.floor((time / SECOND) % 60)}`.padStart(2, "0")}
        </span>{" "}
      </p>
    </div>
  );
}
Timer.propTypes = {
  expiryMinutes: PropTypes.number.isRequired,
};
