import { useEffect } from 'react';
import AttendeeCredits from '../../components/credit-requests/AttendeeCredits';
import { scrollToTop } from '../../utility/commonUtil';

export default function AttendeeCreditsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container pt-64">
      <AttendeeCredits />
    </div>
  );
}
