import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import NoPasses from '../SVG-assets/NoPasses';
import SearchIcon from '../icons/SearchIcon';
import Loader from '../loader/Loader';
import NothingMatches from '../search/NothingMatches';
import AttendeeBookingCard from './AttendeeBookingCard';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import { scrollToTop } from '../../utility/commonUtil';

export default function AttendeeBookings() {
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInputChange = (e) => {
    setSearchText(e.target.value);
    getBookingsData(user?._id, searchText, activePage, pageLimit);
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;

    getBookingsData(user?._id, searchText, currentPage, pageLimit);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  // show this jsx if no tickets booked yet
  const noBookings = (
    <div className="text-align-center">
      <NoPasses className="sketch-md" />
      <div className="mt-52">
        <h3 className="color-primary mb-24">
          You haven't booked any conference / cme
        </h3>
        <button
          className="button button-primary"
          onClick={() => navigate('/search-conference')}
        >
          Explore Trending Conferences
        </button>
      </div>
    </div>
  );
  // if tickets booked, show this
  const haveBookings = (
    <>
      <h2 className="color-primary mb-32">Conference / Bookings</h2>
      <div className="form-type-3 mb-40">
        <div className="position-relative">
          <input
            type="text"
            id="ab-searchtickets"
            placeholder="Search Tickets"
            name="searchText"
            value={searchText}
            onChange={onInputChange}
            autoComplete="off"
            style={{ paddingLeft: 48 }}
          />
          <i
            className={
              searchText?.length > 0 ? 'display-none' : 'conf-search-input-icon'
            }
          >
            <SearchIcon width="2.4rem" height="2.4rem" />
          </i>
        </div>
      </div>

      {filteredBookings?.length > 0 ? (
        filteredBookings?.map((booking) => (
          <div className="mb-24" key={booking?._id}>
            <AttendeeBookingCard booking={booking} />
          </div>
        ))
      ) : (
        <NothingMatches />
      )}
    </>
  );

  const getBookingsData = async (userId, searchText, activePage, pageLimit) => {
    try {
      const response = await api.get(
        `/conferences/bookings/passes/users/${userId}?searchText=${searchText}&currentPage=${activePage}&pageLimit=${pageLimit}`
      );

      if (response.data.data?.currentPage === 1) {
        setCurrentPageNumber({ currentPage: response.data.data?.currentPage });
        setCurrentPage(response.data.data?.currentPage);
      }

      setTotalRecords(response.data?.data?.totalrecords);
      setFilteredBookings(response.data?.data?.bookingDetails);
      setIsLoading(false);
    } catch (err) {
      if (err) dispatch(alertAction(err.response?.data.message));
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (bookings) {
  //     // const dataSet = bookings.filter((item) => {
  //     //   if (
  //     //     item.conference.title
  //     //       .toLowerCase()
  //     //       .indexOf(searchText.toLocaleLowerCase()) >= 0
  //     //   ) {
  //     //     return item;
  //     //   }
  //     // });

  //     getBookingsData(user._id, searchText);
  //     setFilteredBookings(response.data?.data?.bookingDetails);
  //   }
  // }, [searchText]);

  useEffect(() => {
    getBookingsData(user?._id, searchText, activePage, pageLimit);
  }, [user?._id]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {haveBookings}
          <div className="flex-vc-sb">
            <div>
              <CustomPagination
                currentPageNumber={currentPageNumber}
                setCurrentPage={setCurrentPageNumber}
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                pageNeighbours={1}
                onPageChanged={(data) => loadPaginationData(data)}
              />
            </div>
            {filteredBookings?.length > 0 && (
              <div>
                <PageLimitSelect
                  pageLimit={pageLimit}
                  setPageLimit={setPageLimit}
                  activePage={currentPageNumber}
                  totalRecords={totalRecords}
                  loadPaginationData={loadPaginationData}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
