import { useEffect, useState } from 'react';
import api from '../../utility/api';
import ConfCard from '../cards/ConfCard';
import './trendingConfs.scss';
import SeeAll from '../see-all/SeeAll';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loadUserLocationAction } from '../../redux/location/userLocationAction';

export default function TrendingConfs() {
  const [data, setData] = useState();

  const userLocation = useSelector((state) => state.userLocation.userLocation);
  const loadData = async () => {
    try {
      const response = await api.get(
        `/homePage/trendings?country=${userLocation?.country_name}`
      );
      setData(response.data.data.trendingConferences);
    } catch (err) {}
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    data?.length > 0 && (
      <div className="ccah-container bg-background">
        <div className="ccah-header-wrap">
          <h2>Trending Conferences & CME</h2>
        </div>
        <div className="tc-container mb-8 mt-16">
          <div className="home-conf-grid">
            {data?.map((item) => (
              <div key={item.conference?._id}>
                <ConfCard conf={item.conference} />
              </div>
            ))}
          </div>
        </div>
        <SeeAll />
      </div>
    )
  );
}
