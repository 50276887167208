import React, { useEffect, useState } from 'react';
import './feedbackDetails.scss';
import FullStarIcon from '../icons/FullStarIcon';
import ProfileIcon from '../icons/ProfileSketch';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../utility/api';
import { useDispatch } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import FeedbackAction from './FeedbackAction';

export default function FeedbackDetail() {
  let feedbackId = useParams().feedbackId;
  const location = useLocation();
  const dispatch = useDispatch();

  const type = location.state?.type;
  const conference = location.state?.conference;
  const [feedbacks, setFeedbacks] = useState([]);
  const [dataType, setDataType] = useState('');
  const [data, setData] = useState('');

  const getFeedbackDetails = async () => {
    let url = '';
    if (type === 'SESSION') {
      url = `/feedbacks/conference/${conference}?sessionId=${feedbackId}`;
    }
    if (type === 'LECTURE') {
      url = `/feedbacks/conference/${conference}?lectureId=${feedbackId}`;
    }

    try {
      const response = await api.get(url);

      if (response) {
        setDataType(response.data.data.type);
        setFeedbacks(response.data.data.feedbacks);

        if (response.data.data.type === 'SESSION') {
          setData(response.data.data.session);
        } else {
          setData(response.data.data.lecture);
        }
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getFeedbackDetails();
  }, []);
  return (
    <div>
      <h1 className="mb-16">Feedback</h1>
      <div className="mb-16">
        <span style={{ whiteSpace: 'nowrap' }} className="avenir-heavy-18 mr-4">
          {dataType === 'SESSION' ? 'SESSION:' : 'LECTURE:'}
        </span>
        <span
          style={{
            wordBreak: 'break-word',
            flex: 1,
          }}
          className="avenir-heavy-18"
        >
          {data?.title}
        </span>
      </div>
      {feedbacks?.length > 0 &&
        feedbacks?.map((feedback) => {
          return (
            <div className="mb-32">
              {/* User Info */}
              <div className="flex-vc mb-8">
                <i className="mr-8">
                  {' '}
                  <ProfileIcon />
                </i>

                <p className="avenir-heavy-18">{`${feedback?.user?.firstName} ${feedback?.user?.lastName}`}</p>
              </div>

              {/* Rating and Title */}
              <div className="flex-vc mb-12">
                <FeedbackAction item={{ averageRating: feedback?.rating }} />
                <p className="caption-1-heavy-cblack ml-12">
                  {feedback?.title}
                </p>
              </div>

              {/* Review Details */}
              <p className="caption-2-regular-gray3 mb-8">
                {`Feedback on ${new Date(feedback?.createdAt).toDateString()} `}
              </p>
              <p
                style={{ lineHeight: 1.3 }}
                className="caption-2-regular-gray3"
              >
                {feedback?.message}
              </p>
            </div>
          );
        })}
    </div>
  );
}
