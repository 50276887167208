import MediaIncludesCard from './MediaIncludesCard';
import './mediaIncludes.scss';

export default function MediaIncludes({ courseIncludes }) {
  console.log({ courseIncludes });
  return (
    <div>
      <p className="avenir-18-500 mb-8 include-title-centre">This includes</p>
      <div className="mi-body">
        {courseIncludes?.map((item, indx) => (
          <MediaIncludesCard text={item.text} icon={item.icon} key={indx} />
        ))}
      </div>
    </div>
  );
}
