import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import Loader from '../loader/Loader';
import ModalX from '../modal/ModalX';
import AmountsTable from './AmountsTable';
import AddBankAccountForm from './AddBankAccountForm';
import DialogueWithLoader from '../dialogue/DialogueWithLoader';
import WithdrawForm from './WithdrawForm';
import {
  getFormattedDateInTz2,
  renderRowStyle,
} from '../../utility/commonUtil';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import TranshcanIcon from '../icons/TrashcanIcon';

const Payout = () => {
  const user = useSelector((state) => state.auth.user);
  const [transactionData, setTransactionData] = useState();
  const [showAddBankAccountForm, setShowAddBankAccountForm] = useState(false);
  const [deleteDialogue, setDeleteDialogue] = useState(false);
  const [withdrawForm, setShowWithdrawForm] = useState(false);
  const [cancelWithdrawnReqDialogue, setCancelWithdrawnReqDialogue] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [bankId, setBankId] = useState('');
  const [availableAmountInNumber, setAvailableAmountInNumber] = useState();
  const [availableBalance, setAvailableBalance] = useState('');
  const [transactionCounts, setTransactionCounts] = useState({});
  const [currency, setCurrency] = useState('');
  const [userData, setUserData] = useState({});
  const [withdrawnId, setWithdrawnId] = useState('');
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  const getTransactionRecords = async (userId) => {
    try {
      setDataIsLoading(true);
      const response = await api.get(`/earnings/${userId}/withdrawn`);

      setTransactionData(response.data.data.withdrawns);
      setTransactionCounts(response.data.data.allAmountArr);
      setUserData(response.data.data.userData);
      setDataIsLoading(false);
    } catch (err) {
      setDataIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const deleteBankAccount = async (userId) => {
    try {
      setIsLoading(true);

      const response = await api.delete(
        `/earnings/${userId}/bankAccount/${bankId}`
      );
      console.log({ response });
      setTransactionData(response.data.data.withdrawns);
      setTransactionCounts(response.data.data.allAmountArr);
      setUserData(response.data.data.userData);
      setIsLoading(false);
      setDeleteDialogue(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const cancelWithdrawnReq = async (userId, withdrawnId) => {
    try {
      setIsLoading(true);

      const response = await api.patch(
        `/earnings/${userId}/withdrawn/${withdrawnId}`
      );

      setTransactionData(response.data.data.withdrawns);
      setTransactionCounts(response.data.data.allAmountArr);
      setUserData(response.data.data.userData);
      setIsLoading(false);
      setCancelWithdrawnReqDialogue(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getTransactionRecords(user?._id);
  }, [user?._id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {dataIsLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="mb-40">
            <h2 className="color-primary mb-24">Earnings</h2>
            <div className="bdfo-counts-wrap">
              {transactionCounts?.length > 0 ? (
                transactionCounts?.map((data) => {
                  return (
                    <div key={data?.currency}>
                      <AmountsTable
                        setCurrency={setCurrency}
                        setShowAddBankAccountForm={setShowAddBankAccountForm}
                        data={data}
                        setAvailableBalance={setAvailableBalance}
                        setAvailableAmountInNumber={setAvailableAmountInNumber}
                        availableAmountInNumber={availableAmountInNumber}
                        setShowWithdrawForm={setShowWithdrawForm}
                      />
                    </div>
                  );
                })
              ) : (
                <div>
                  <p className="caption-1-regular-gray3">
                    Currenlty, there are no earnings. After earnings are posted,
                    one can set up bank account(s) to withdrawl money.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mb-40">
            <h2 className="color-primary mb-24">Bank Account Details</h2>
            <div className="grid-table-wrap">
              <ul className="grid-table-4-950">
                <li>Bank Name</li>
                <li>Bank Nick Name</li>
                <li>Currency</li>
                <li>Actions</li>
                {userData?.bankDetails?.length > 0 &&
                  userData?.bankDetails?.map((data) => {
                    return (
                      <Fragment key={data?._id}>
                        <li>{data?.bank}</li>
                        <li>{data?.nickName}</li>
                        <li>{data?.currency}</li>
                        <li>
                          <i
                            onClick={() => {
                              setBankId(data?._id);
                              setDeleteDialogue(true);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <TranshcanIcon className="icon-size" />
                          </i>
                        </li>
                      </Fragment>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="color-primary mb-24">Withdrawals</h2>
            <div className="grid-table-wrap">
              <ul className="grid-table-7-950">
                <li>Payout No</li>
                <li>Request Date</li>
                <li>Amount</li>
                <li>Status</li>
                <li>Bank</li>
                <li>Remark</li>
                <li>Action</li>

                {transactionData?.length > 0 &&
                  transactionData?.map((data, indx) => {
                    return (
                      <Fragment key={data?._id}>
                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {data?.withdrawnNumber}
                        </li>
                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {getFormattedDateInTz2(
                            data?.withdrawnReqDate,
                            data?.reqTimezone
                          )}
                        </li>

                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {`${data?.bankDetails?.currencySymbol} ${data?.withdrawnAmount}`}
                        </li>

                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {data?.status === 1 && (
                            <span
                              style={{
                                color: '#4CB944',
                              }}
                            >
                              Payment complete
                            </span>
                          )}
                          {data?.status === 2 && <span>Pending</span>}
                          {data?.status === 3 && (
                            <span style={{ color: '#bb2124' }}>
                              Rejected by confemy
                            </span>
                          )}
                          {data?.status === 4 && (
                            <span style={{ color: '#bb2124' }}>
                              Cancelled by self
                            </span>
                          )}
                        </li>
                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {data?.bankDetails?.nickName}
                        </li>
                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {data?.note}
                        </li>
                        <li
                          onMouseEnter={() => handleMouseEnter(data?._id)}
                          onMouseLeave={() => handleMouseLeave(data?._id)}
                          style={renderRowStyle(
                            indx,
                            data?._id,
                            isHover,
                            rowId
                          )}
                        >
                          {data?.status === 2 && (
                            <i
                              onClick={() => {
                                setWithdrawnId(data?._id);
                                setCancelWithdrawnReqDialogue(true);
                              }}
                            >
                              <CrossCancelIcon className="icon-size" />
                            </i>
                          )}
                        </li>
                      </Fragment>
                    );
                  })}
              </ul>
            </div>
          </div>

          {showAddBankAccountForm && (
            <ModalX
              onDismiss={() => setShowAddBankAccountForm(false)}
              open={showAddBankAccountForm}
            >
              <div className="modalx-container-md pr-20 pl-20 pb-24">
                <AddBankAccountForm
                  currency={currency}
                  setUserData={setUserData}
                  setTransactionCounts={setTransactionCounts}
                  setTransactionData={setTransactionData}
                  setShowAddBankAccountForm={setShowAddBankAccountForm}
                />
              </div>
            </ModalX>
          )}

          {withdrawForm && (
            <ModalX
              onDismiss={() => setShowWithdrawForm(false)}
              open={withdrawForm}
            >
              <div className="modalx-container-sm mb-40">
                <WithdrawForm
                  currency={currency}
                  availableBalance={availableBalance}
                  availableAmountInNumber={availableAmountInNumber}
                  setUserData={setUserData}
                  setTransactionCounts={setTransactionCounts}
                  setTransactionData={setTransactionData}
                  setShowWithdrawForm={setShowWithdrawForm}
                />
              </div>
            </ModalX>
          )}
          {deleteDialogue && (
            <DialogueWithLoader
              msg="Are you sure you want to delete this bank account?"
              title="Delete Bank Account."
              closeDialogue={() => {
                setDeleteDialogue(false);
              }}
              previewLoading={isLoading}
              yesAction={() => deleteBankAccount(user?._id)}
            />
          )}

          {cancelWithdrawnReqDialogue && (
            <DialogueWithLoader
              msg="Are you sure you want to cancel this withdrawn request?"
              title="Cancel withdrawn request."
              closeDialogue={() => {
                setCancelWithdrawnReqDialogue(false);
              }}
              previewLoading={isLoading}
              yesAction={() => cancelWithdrawnReq(user?._id, withdrawnId)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Payout;
