import { useEffect } from 'react';
import AudioCourseBooking from '../../components/audio-course/AudioCourseBooking';
import { scrollToTop } from '../../utility/commonUtil';

export default function AudioCourseBookingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <AudioCourseBooking />
    </>
  );
}
