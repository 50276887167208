import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';

const validationSchema = yup.object().shape({
  hall: yup.string().trim().max(200).required('Required'),
});
export default function AddHallForm({ setShowHall, conference, setHalls }) {
  // submit function
  const onSubmit = async (values, actions) => {
    const formData = {
      hallDetails: { hallName: values?.hall },
    };
    try {
      const response = await api.post(
        `/venues/conference/${conference.value}`,
        formData
      );

      setHalls(response.data.data.halls);
      setShowHall(false);
    } catch (err) {
      actions.setFieldError('hall', err.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      hall: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <form
      className="form-type-1"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <h2 className="color-primary text-align-center mb-24">Add Hall</h2>
      <div className="material-textfield mb-18">
        <input
          id="hall"
          type="text"
          name="hall"
          value={formik.values.hall}
          onChange={formik.handleChange}
          placeholder=" "
        />
        <label>Hall</label>
      </div>
      <div className="mb-24">
        {formik.touched.hall && Boolean(formik.errors.hall) && (
          <TextError>{formik.errors.hall}</TextError>
        )}
      </div>
      <div>
        <SubmitCancelButtonWithLoader
          fullWidth
          isSubmitting={formik.isSubmitting}
          onCancel={() => formik.resetForm({})}
        />
      </div>
    </form>
  );
}
