import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import LocalAlert from '../alert/LocalAlert';
import TextInput from '../formik/TextInput';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import '../bookings-for-organizer/orgBookingDetails.scss';
import { getFormattedDateInTz2 } from '../../utility/commonUtil';

const disableStyle = {
  background: '#ecf0f2',
  color: '#aabdc7',
  borderColor: '#ecf0f2',
};

let validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required('Required')
    .when(
      'price',
      (price, schema) =>
        price &&
        schema
          .min(1, 'Amount must me greater than 0')
          .max(price, 'Amount should less than ticket price')
    ),
});

export default function PodcastRefundDetails({
  bookingDetails,
  setBookingDetails,
  currencySymbol,
  // getBookings,
}) {
  const [rejectLoading, setRejectLoading] = useState(false);
  const [showRefundForm, setshowRefundForm] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  //   formik
  let refundAmt = bookingDetails?.price;
  if (bookingDetails?.refundAmount <= bookingDetails?.price) {
    refundAmt = bookingDetails?.price - bookingDetails?.refundAmount;
  }
  let initialValues = {
    price: bookingDetails?.price,
    amount: refundAmt || 1,
  };
  async function onSubmit(values, actions) {
    try {
      let formData = {
        refundDetails: {
          purchaseId: bookingDetails?._id,
          refundAmount: values?.amount,
          approvedBy: user?._id,
        },
      };
      let res = await api.post(`/podcast/refunds/approve`, formData);

      setBookingDetails(res.data.data.refundData);
      // getBookings(res?.data?.data?.refundData?.conference?._id);
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  }
  const rejectRefund = async () => {
    try {
      setRejectLoading(true);
      let formData = {
        refundDetails: {
          purchaseId: bookingDetails?._id,
          podcastId: bookingDetails?.media?._id,
          rejectedBy: user?._id,
        },
      };
      let res = await api.post(`/podcast/refunds/reject`, formData);

      setBookingDetails(res?.data?.data?.refundData);
      // getBookings(res?.data?.data?.refundData?.conference?._id);
      setRejectLoading(false);
    } catch (err) {
      setRejectLoading(false);
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  function displayRefundForm(bookingDetails) {
    let display = true;
    if (bookingDetails?.mediaBooked === 4) {
      if (
        bookingDetails?.price === 0 ||
        bookingDetails?.refundAmount === bookingDetails?.price ||
        bookingDetails?.refundAmount > bookingDetails?.price
      ) {
        return (display = false);
      } else {
        return (display = true);
      }
    } else {
      return (display = true);
    }
  }

  function disableApproveButton(bookingDetails) {
    let disableApprove = false;
    if (bookingDetails?.mediaBooked === 4) {
      if (
        bookingDetails?.price === 0 ||
        bookingDetails?.refundAmount === bookingDetails?.price ||
        bookingDetails?.refundAmount > bookingDetails?.price
      ) {
        return (disableApprove = true);
      } else {
        return (disableApprove = false);
      }
    } else {
      return (disableApprove = false);
    }
  }

  function disableRejectButton(bookingDetails) {
    let disableReject = false;

    if (
      bookingDetails?.price === 0 ||
      bookingDetails?.refundStatus === 1 ||
      bookingDetails?.refundStatus === 2 ||
      bookingDetails?.refundStatus === 5
    ) {
      return (disableReject = true);
    } else {
      return (disableReject = false);
    }
  }

  return (
    <div className="org-details-wrap">
      <LocalAlert />
      <h2 className="text-align-center color-primary mb-40">Booking Details</h2>
      <div className="mb-60">
        <div className="mb-16">
          <p>
            <span className="caption-1-heavy-cblack">Name: </span>
            <span className="body-regular-gray3">
              {bookingDetails?.name}{' '}
            </span>{' '}
          </p>
          <p>
            <span className="caption-1-heavy-cblack">Email: </span>
            <span className="body-regular-gray3">{bookingDetails?.email}</span>
          </p>
        </div>
        <div className="mb-16">
          <p className="body-regular-gray3 mb-4">
            <span className="caption-1-heavy-cblack">Event: </span>
            <span className="caption-2-regular-gray3">
              {' '}
              {bookingDetails?.media?.title}
            </span>
          </p>
        </div>
        <div className="mb-16">
          <p className="mb-8">
            <span className="caption-1-heavy-cblack">Booking number : </span>
            <span className="caption-2-regular-gray3">
              {bookingDetails?.bookingNumber}{' '}
            </span>
          </p>
          <p className="mb-8">
            <span className="caption-1-heavy-cblack ">Booking Date : </span>
            <span className="caption-2-regular-gray3">
              {getFormattedDateInTz2(
                bookingDetails?.bookingDate,
                bookingDetails?.timezone
                  ? bookingDetails?.timezone
                  : 'Asia/kolkata'
              )}
            </span>
          </p>
          <p className="caption-2-regular-gray3 mb-8 ">
            <span className="caption-1-heavy-cblack">Booking status : </span>
            <span
              className="caption-2-regular-gray3"
              style={{
                color:
                  bookingDetails?.mediaBooked === 1 &&
                  bookingDetails.status === 1
                    ? '#08415c'
                    : '#d8000c',
              }}
            >
              {`${
                bookingDetails?.mediaBooked === 1 && bookingDetails.status === 1
                  ? 'Booked'
                  : 'Cancelled'
              }`}{' '}
            </span>
          </p>
        </div>
        <div className="mb-16">
          {/* <p className="mb-8">
            <span className="caption-1-heavy-cblack ">Refund Deadline : </span>
            <span
              style={{
                color: `${'#d8000c'}`,
              }}
              className="caption-2-regular-gray3"
            >
              {new Date(bookingDetails?.maxRefundDate).toLocaleString()}
            </span>
          </p> */}
          {bookingDetails?.refundRequestDate && (
            <p className="mb-8">
              <span className="caption-1-heavy-cblack ">
                Refund Requested Date :
              </span>
              <span className="caption-2-regular-gray3">
                {' '}
                {new Date(bookingDetails?.refundRequestDate).toLocaleString()}
              </span>
            </p>
          )}

          {bookingDetails?.refundStatus && (
            <p className="caption-2-regular-gray3 mb-8">
              <span className="caption-1-heavy-cblack">Refund status : </span>
              {bookingDetails?.refundStatus === 2 && (
                <span
                  className="caption-2-regular-gray3"
                  style={{ color: '#08415c' }}
                >
                  {'Initiated'}
                </span>
              )}
              {bookingDetails?.refundStatus === 1 && (
                <span
                  className="caption-2-regular-gray3"
                  style={{ color: '#d8000c' }}
                >
                  {'Refunded'}
                </span>
              )}
              {bookingDetails?.refundStatus === 5 && (
                <span
                  className="caption-2-regular-gray3"
                  style={{ color: '#d8000c' }}
                >
                  {'Rejected'}
                </span>
              )}
            </p>
          )}

          {(bookingDetails?.refundStatus === 1 ||
            bookingDetails?.refundStatus === 2) &&
            bookingDetails?.approvedBy && (
              <p className="caption-2-regular-gray3 mb-8">
                <span className="caption-1-heavy-cblack">Approved By : </span>
                <span className="caption-2-regular-gray3">
                  {`${bookingDetails?.approvedBy?.firstName} ${bookingDetails?.approvedBy?.lastName}`}
                </span>
              </p>
            )}
          {bookingDetails?.refundStatus === 5 && (
            <p className="caption-2-regular-gray3 mb-8">
              <span className="caption-1-heavy-cblack">Rejected By : </span>
              <span className="caption-2-regular-gray3">
                {`${bookingDetails?.rejectedBy?.firstName} ${bookingDetails?.rejectedBy?.lastName}`}
              </span>
            </p>
          )}
          {(bookingDetails?.refundStatus === 1 ||
            bookingDetails?.refundStatus === 2) && (
            <p className="caption-2-regular-gray3 mb-8">
              <span className="caption-1-heavy-cblack">Refunded Amount: </span>
              {currencySymbol && <span className="mr-4">{currencySymbol}</span>}
              <span className="caption-2-regular-gray3">{`${bookingDetails?.refundAmount}`}</span>
            </p>
          )}
        </div>
        <p>
          <span className="caption-1-heavy-cblack">Price: </span>{' '}
          {currencySymbol && (
            <span className="caption-2-regular-gray3 mr-4">
              {currencySymbol}
            </span>
          )}
          <span className="caption-2-regular-gray3">
            {bookingDetails?.price}
          </span>
        </p>
      </div>
      {displayRefundForm(bookingDetails) && showRefundForm ? (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <>
                  <form
                    className="form-type-3"
                    autoComplete="off"
                    onSubmit={props.handleSubmit}
                  >
                    <p className="caption-1-regular-gray3 mb-8">
                      Enter refund amount to be approved
                    </p>
                    <TextInput id="amount" name="amount" type="number" />
                    <div className="mt-8">
                      <SubmitCancelButtonWithLoader
                        onCancel={() => setshowRefundForm(false)}
                        cancelButtonClass="button-text button-text-primary"
                        isSubmitting={props.isSubmitting}
                        // fullWidth={true}
                      />
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className="flex-vc">
          <button
            type="button"
            style={{
              width: '100%',
              height: '54px',
              padding: '4px',
              ...(disableApproveButton(bookingDetails) && disableStyle),
            }}
            className="button button-primary mr-4"
            onClick={() => setshowRefundForm(true)}
            disabled={disableApproveButton(bookingDetails)}
          >
            {(bookingDetails?.refundStatus === 1 ||
              bookingDetails?.refundStatus === 2) &&
            bookingDetails?.refundAmount < bookingDetails?.price
              ? 'Refund More'
              : 'Refund'}
          </button>
          <button
            type="button"
            style={{
              width: '100%',
              height: '54px',
              padding: '4px',
              ...((disableRejectButton(bookingDetails) || rejectLoading) &&
                disableStyle),
            }}
            disabled={disableRejectButton(bookingDetails) || rejectLoading}
            className="button button-green ml-4"
            onClick={() => {
              rejectRefund();
            }}
          >
            Reject
          </button>
        </div>
      )}
    </div>
  );
}
