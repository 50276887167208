import { useState } from "react";

import ConfemyCredits from "../../components/user-credits/ConfemyCredits";
export default function ConfCreditsPage() {
  const [confStartDate, setConfStartDate] = useState(null);
  const [confEndDate, setConfEndDate] = useState(null);
  return (
    <div>
      <ConfemyCredits
        confStartDate={confStartDate}
        setConfStartDate={setConfStartDate}
        confEndDate={confEndDate}
        setConfEndDate={setConfEndDate}
      />
    </div>
  );
}
