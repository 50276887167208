import { v4 as uuidv4 } from 'uuid';
import {
  REMOVE_ALERT,
  REMOVE_LOCAL_ALERT,
  SHOW_ALERT,
  SHOW_LOCAL_ALERT,
} from './alertTypes';
export const alertAction =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: SHOW_ALERT,
      payload: { msg, alertType, id },
    });
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_ALERT,
          payload: id,
        }),
      timeout
    );
  };

export const localAlertAction =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: SHOW_LOCAL_ALERT,
      payload: { msg, alertType, id },
    });
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_LOCAL_ALERT,
          payload: id,
        }),
      timeout
    );
  };
