import { useEffect, useState } from 'react';

import VideoCourseCredits from '../../components/user-credits/VideoCourseCredits';
import { scrollToTop } from '../../utility/commonUtil';

export default function VideoCreditsPage() {
  const [confTotalStartDate, setConfTotalStartDate] = useState(null);
  const [confTotalEndDate, setConfTotalEndDate] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <VideoCourseCredits
        confTotalStartDate={confTotalStartDate}
        confTotalEndDate={confTotalEndDate}
      />
    </div>
  );
}
