import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';
import Loader from '../../components/loader/Loader';
import { scrollToTop } from '../../utility/commonUtil';
// import MyConfs from "../../components/conference/MyConfs";
const MyConfs = React.lazy(() => import('../../components/conference/MyConfs'));
export default function MyConfsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Suspense fallback={<Loader />}>
        <MyConfs />
      </Suspense>
    </ErrorBoundary>
  );
}
