export default function DownloadIcon({ className, fill }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ? fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 2.25v12.514l3.188-3.187 1.06 1.06-3.944 3.944.007.006L12 17.648 7.002 12.65l1.06-1.06 3.188 3.187V2.25h1.5z"
        fill="#507282"
      />
      <path d="M4.75 20.25v-5h-1.5v6.5h17.5v-6.5h-1.5v5H4.75z" fill="#507282" />
    </svg>
  );
}
