import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';
import ScheduleFeedback from '../../components/schedule/Feedback';

export default function ScheduleFeedbackPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container">
      <ScheduleFeedback />
    </div>
  );
}
