function CameraIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="70"
      viewBox="0 0 71 70"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-683.000000, -325.000000) translate(409.000000, 188.000000) translate(175.500000, 137.000000) translate(99.000000, 0.000000)">
                <path d="M0 0L70 0 70 70 0 70z" />
                <path
                  fill="#4CB944"
                  d="M8.625 23.125C8.625 24.706 9.919 26 11.5 26s2.875-1.294 2.875-2.875v-5.75h5.75c1.581 0 2.875-1.294 2.875-2.875s-1.294-2.875-2.875-2.875h-5.75v-5.75C14.375 4.294 13.081 3 11.5 3S8.625 4.294 8.625 5.875v5.75h-5.75C1.294 11.625 0 12.919 0 14.5s1.294 2.875 2.875 2.875h5.75v5.75z"
                />
                <circle cx="38" cy="41" r="9" fill="#08415C" />
                <path
                  fill="#08415C"
                  d="M61.2 17.778h-9.193l-3.596-3.9C47.338 12.693 45.772 12 44.148 12h-18.56c.493.867.812 1.82.812 2.889 0 3.178-2.61 5.778-5.8 5.778h-2.9v2.889c0 3.177-2.61 5.777-5.8 5.777-1.073 0-2.03-.317-2.9-.809v29.698C9 61.4 11.61 64 14.8 64h46.4c3.19 0 5.8-2.6 5.8-5.778V23.556c0-3.178-2.61-5.778-5.8-5.778zM38 55.333c-8.004 0-14.5-6.47-14.5-14.444 0-7.973 6.496-14.445 14.5-14.445s14.5 6.472 14.5 14.445S46.004 55.333 38 55.333z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CameraIcon;
