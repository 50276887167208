import { useEffect } from 'react';
import AudioCourseRefunds from '../../components/audio-course/AudioCourseRefund';
import { scrollToTop } from '../../utility/commonUtil';

export default function AudioCourseRefundsPage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <AudioCourseRefunds />
    </>
  );
}
