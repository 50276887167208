import { useField } from 'formik';
import { Fragment } from 'react';
import TextError from './TextError';

export default function MaterialTextInput({ label, ...props }) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)

  const [field, meta] = useField(props);
  return (
    <Fragment>
      {/* 
      label prop can be passed as well
      id prop can be passed as well for the label
      <label htmlFor={props.id || props.name}>{label}</label>
    */}
      <div className="material-textfield">
        <input {...field} {...props} />
        <label>{label}</label>
      </div>
      {meta.touched && meta.error ? (
        <TextError>
          {meta.error}
          {/*
            Other way of doing error is as follows. It works fine. 
            Import ErrorMessage from firmik, then do following.
            <ErrorMessage name={props.name} component={TextError} /> 
        */}
        </TextError>
      ) : null}
    </Fragment>
  );
}
