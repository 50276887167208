import { useEffect, useState } from 'react';
import ExternalCreditsTable from '../../components/user-credits/ExternalCreditsTable';
import { scrollToTop } from '../../utility/commonUtil';

export default function ExternalCreditsPage() {
  const [totalStartDate, setTotalStartDate] = useState(null);
  const [totalEndDate, setTotalEndDate] = useState(null);
  const [externalStartDate, setExternalStartDate] = useState(null);
  const [externalEndDate, setExternalEndDate] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <ExternalCreditsTable
        totalStartDate={totalStartDate}
        totalEndDate={totalEndDate}
        externalStartDate={externalStartDate}
        externalEndDate={externalEndDate}
        setExternalStartDate={setExternalStartDate}
        setExternalEndDate={setExternalEndDate}
      />
    </div>
  );
}
