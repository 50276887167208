import "./keyPoints.scss";

const KeyPoints = ({ keyPoints }) => {
  // const { keyPoints = ["Great", "Love it", "boss"] } = props.data;
  return (
    <div className="key-points-box">
      <div className="avenir-18-500">What you will learn</div>
      <div className="key-points-bdy">
        <ul className="key-points-ul">
          {keyPoints?.map((item) => {
            return (
              <li className="key-points-li" data-content="✓" key={item.uuid}>
                {item.keypoint}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default KeyPoints;
