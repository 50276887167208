import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';
import MySchedule from '../../components/my-schedule/MySchedule';
export default function MySchedulePage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <MySchedule />
    </>
  );
}
