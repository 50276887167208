import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { alertAction } from "../../redux/alert/alertAction";
import {
  loadIncopleteConfsAction,
  loadOneIncopleteConfAction,
} from "../../redux/conference/conferenceAction";
import api from "../../utility/api";

export default function CreateConfLanding() {
  const user = useSelector((state) => state.auth.user);
  const incompleteConfs = useSelector(
    (state) => state.conference.incompleteConfs
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   Get one incomplete conference

  const getOneIncompleteConf = async (conferenceId) => {
    const url = `conferences/${conferenceId}`;

    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadOneIncopleteConfAction(response.data.data.conferences));
        navigate("/dashboard/create-conf/step-1");
      }
    } catch (err) {
      if (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
      }
    }
  };

  //   Get all incomplete conferences
  const getAllIncompleteConfs = async (userId) => {
    const url = `conferences/users/${userId}?status=drafts&getAllOrganizationConferences=true`;

    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadIncopleteConfsAction(response.data.data.conferences));
      }
    } catch (err) {
      if (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
      }
    }
  };

  const deleteIncompleteConf = async (conferenceId) => {
    const url = `conferences/${conferenceId}/delete?userId=${user?._id}`;

    try {
      const response = await api.delete(url);
      if (response) {
        dispatch(loadIncopleteConfsAction(response?.data.data.conferences));
        dispatch(alertAction(response.data.message, "success"));
        // getAllIncompleteConfs(user._id);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };
  useEffect(() => {
    getAllIncompleteConfs(user?._id);
  }, [user?._id]);

  return (
    <div>
      <div className="mb-40 body-regular-gray3">
        <h2 className="mb-32">Read before proceeding</h2>
        <p className="mb-16">
          First create Step 1 and click next button to save data before creating
          other steps.
        </p>
        <p className="mb-16">
          After Step 1 is created, any other step can be added and data can be
          saved on clicking next button. One can come back to complete remaining
          steps later.
        </p>
        <p className="mb-16">
          Incomplete conferences will show up below as well as in My Conferences
          tab. Click on incomplete conference heading to finish all steps before
          publishing the conference.
        </p>
        <p className="mb-16">One can not publish incomplete conferences.</p>
      </div>
      <button
        onClick={() => navigate("/dashboard/create-conf/step-1")}
        className="small-button small-button-green"
      >
        Proceed to create new conference or CME
      </button>

      <h2 className="mb-16 mt-60">Incomplete Conferences</h2>
      <div className="create-conf-table-wrap">
        <table>
          <thead></thead>
          <tbody>
            {incompleteConfs?.map((conf, indx) => (
              <tr key={conf._id}>
                <td>{indx + 1}</td>
                <td
                  onClick={() => getOneIncompleteConf(conf._id)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {conf.title}
                </td>
                <td>
                  <button
                    onClick={() => deleteIncompleteConf(conf._id)}
                    className="small-button-text small-button-text-red "
                  >
                    Delete
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => getOneIncompleteConf(conf._id)}
                    className="small-button small-button-primary"
                  >
                    Edit{"   "}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
