import React from 'react';
import PodcastBookingPrice from '../../components/podcast/PodcastBookigPrice';

export default function PodcastBookingPricePage() {
  return (
    <div className="container pt-64">
      <PodcastBookingPrice />{' '}
    </div>
  );
}
