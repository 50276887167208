import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';
import FeedbackDetail from '../../components/schedule/FeedbackDetail';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../components/button/BackButton';
export default function FeedbackDetailPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  let feedbackArray = [
    {
      name: 'Wasim Reza',
      title: 'Really awesome',
      content:
        'A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback',
      date: 'Feedback on 18 November 2024',
    },
    {
      name: 'Khanderao Khot',
      title: 'Really cool',
      content:
        'A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback',
      date: 'Feedback on 18 November 2024',
    },
    {
      name: 'Balkrushna Jadhav',
      title: 'Really awesome',
      content:
        'A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback',
      date: 'Feedback on 18 November 2024',
    },
    {
      name: 'Hyshyam Hasim',
      title: 'Really awesome',
      content:
        'A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback A very long long long feedback',
      date: 'Feedback on 18 November 2024',
    },
  ];
  const navigate = useNavigate();

  let data = { title: 'AURTHOPLASTY', venue: 'HALL A' };
  return (
    <div className="container-sm">
      <div>
        <BackButton
          className="primary mb-24"
          onBackButtonClick={() => navigate(-1)}
        />
      </div>
      <FeedbackDetail feedbackArray={feedbackArray} data={data} />
    </div>
  );
}
