import React, { useEffect } from 'react';
import MyAudioCourse from '../../components/audio-course/MyAudioCourses';
import { scrollToTop } from '../../utility/commonUtil';

export default function MyAudioCoursePage() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <MyAudioCourse />;
}
