import React from 'react';

function MediaDetailCreditIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 6.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5zM10.75 9a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0z"
        fill="#08415C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25 9a6.75 6.75 0 1 1 11.5 4.796v8.368L12 20.009l-4.75 2.155v-8.368A6.73 6.73 0 0 1 5.25 9zm10 4h.15A5.25 5.25 0 1 0 8.6 13h.151v.123A5.228 5.228 0 0 0 12 14.25a5.228 5.228 0 0 0 3.25-1.127V13zm-6.5 6.836v-4.918a6.705 6.705 0 0 0 2.5.79v2.994l-2.5 1.134zm6.5-4.918a6.705 6.705 0 0 1-2.5.79v2.994l2.5 1.134v-4.918z"
        fill="#08415C"
      />
    </svg>
  );
}

export default MediaDetailCreditIcon;
