import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import { createConferenceAction } from '../../redux/conference/conferenceAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import {
  currencylist,
  loadCityList,
  loadCountryList,
  loadStateList,
  prodUrl,
  timezones,
} from '../../utility/commonUtil';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import CustomDatepicker from '../react-datepicker/CustomDatepicker';
import ReloadableSelectFormType1 from '../reselect/ReloadableSelectFormType1';
import SelectFormType1 from '../reselect/SelectFormType1';
import './createConference.styles.scss';
import Switch from '../switch/Switch';
import DeleteIcon from '../icons/DeleteIcon';
import TranshcanIcon from '../icons/TrashcanIcon';
import DocumentIcon from '../icons/DocumentIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import Loader from '../loader/Loader';
import Dropzone from 'react-dropzone';
import PlusIcon from '../icons/PlusIcon';

const validationSchema = yup.object().shape({
  title: yup.string().trim().max(250).required('Required'),
  host: yup
    .string()
    .required('Required. Choose who is organizing the conference'),
  organizationId: yup.string().when('host', {
    is: 'organization',
    then: yup.string().required('Required'),
  }),
  startDate: yup.date().required('Required').nullable(),
  // startTime: yup.date().required("Required").nullable(),
  endDate: yup.date().required('Required').nullable(),
  // endTime: yup.date().required("Required").nullable(),
  timezone: yup.string().required('Required'),
  mode: yup
    .array()
    .of(yup.string())
    .min(1, 'Choose a conference location')
    .compact(),
  venueName: yup
    .string()
    .trim()
    .when('mode', {
      is: (mode) => mode.includes('venue'),
      then: yup.string().required('Required'),
    }),
  street1: yup.string().when('mode', {
    is: (mode) => mode.includes('venue'),
    then: yup.string().trim().required('Required'),
  }),
  city: yup.string().when('mode', {
    is: (mode) => mode.includes('venue'),
    then: yup.string().required('Required'),
  }),
  state: yup.string().when('mode', {
    is: (mode) => mode.includes('venue'),
    then: yup.string().required('Required'),
  }),
  country: yup.string().when('mode', {
    is: (mode) => mode.includes('venue'),
    then: yup.string().required('Required'),
  }),
  zipcode: yup
    .string()
    .trim()
    .when('mode', {
      is: (mode) => mode.includes('venue'),
      then: yup.string().required('Required'),
    }),

  courseAdded: yup.boolean(),
  courses: yup.array().when('courseAdded', {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        title: yup.string().required('Required'),
        description: yup.string().trim().required('Required'),
        startDate: yup.date().required('Required').nullable(),
        endDate: yup.date().required('Required').nullable(),
        currency: yup.string().required('Required'),
        price: yup
          .number()
          .typeError('Enter valid amount')
          .required('Required')
          .min(0, 'Price can only be 0 or more')
          .nullable(),
        quantity: yup
          .number()
          .typeError('Enter valid quantity')
          .required('Required')
          .min(1, 'Quantity can only be 1 or more')
          .nullable(),
      })
    ),
    otherwise: yup.array().notRequired(), // If courseAdded is false, courses array validation is skipped
  }),
  // courses: yup.array().of(
  //   yup.object().shape({
  //     title: yup.string().when('courseAdded', {
  //       is: true,
  //       then: yup.string().required('Required'),
  //     }),
  //     description: yup
  //       .string()
  //       .trim()
  //       .when('courseAdded', {
  //         is: true,
  //         then: yup.string().required('Required'),
  //       }),
  //     startDate: yup.date().when('courseAdded', {
  //       is: true,
  //       then: yup.date().required('Required').nullable(),
  //     }),
  //     endDate: yup.date().when('courseAdded', {
  //       is: true,
  //       then: yup.date().required('Required').nullable(),
  //     }),
  //     currency: yup.string('Required').when('courseAdded', {
  //       is: true,
  //       then: yup.string().required('Required').nullable(),
  //     }),
  //     price: yup.number().when('courseAdded', {
  //       is: true,
  //       then: yup
  //         .number()
  //         .typeError('Enter valid amount')
  //         .required('Required')
  //         .min(0, 'Price can only be 0 or more')
  //         .nullable(),
  //     }),

  //     price: yup.number().when('courseAdded', {
  //       is: true,
  //       then: yup
  //         .number()
  //         .typeError('Enter valid amount')
  //         .required('Required')
  //         .min(1, 'quantity can only be 1 or more'),
  //     }),
  //   })
  // ),
});

export default function ConfBasicInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const conference = useSelector((state) => state.conference);
  const { newConference } = conference;
  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );
  const { countryList, stateList, cityList } = useSelector(
    (state) => state.list
  );

  async function onSubmit(values, actions) {
    const {
      title,
      host,
      organizationId,
      startDate,
      endDate,
      timezone,
      mode,
      venueName,
      street1,
      street2,
      city,
      state,
      country,
      zipcode,
      courses,
      courseAdded,
      deletedCourses,
    } = values;

    const formData = {
      conferenceDetails: {
        title,
        conferenceId: newConference?._id,
        organizationId,
        userId: user?._id,
        startDate: zonedTimeToUtc(startDate, timezone).toISOString(),
        endDate: zonedTimeToUtc(endDate, timezone).toISOString(),
        timezone,
        mode,
        host,
        venueName,
        street1,
        street2,
        city,
        state,
        country,
        zipcode,
        courses,
        courseAdded,
        deletedCourses,
      },
    };

    let tempCourseArr = [];
    if (!courseAdded && courses?.length > 0) {
      courses?.forEach((c) => {
        if (c?._id) {
          tempCourseArr.push(c);
        }
      });

      formData.conferenceDetails.deletedCourses = [...tempCourseArr];
      formData.conferenceDetails.courses = [];
    }

    try {
      const response = await api.post('conferences/step1', formData);
      if (response) {
        formik.resetForm({});
        dispatch(createConferenceAction(response.data.data.conference));
        navigate('/dashboard/create-conf/step-2');
        dispatch(alertAction(response.data.message, 'success'));
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  }

  function deleteCourse(course) {
    let courseDeleted = [];

    if (course?._id) {
      courseDeleted.push(course);
    }

    formik.setFieldValue('deletedCourses', [
      ...formik.values.deletedCourses,
      ...courseDeleted,
    ]);
  }
  let apiStartDate;
  if (newConference?.startDate && newConference?.timezone) {
    apiStartDate = utcToZonedTime(
      newConference?.startDate,
      newConference?.timezone
    );
  } else {
    apiStartDate = null;
  }

  let apiEndDate;
  if (newConference?.endDate && newConference?.timezone) {
    apiEndDate = utcToZonedTime(
      newConference?.endDate,
      newConference?.timezone
    );
  } else {
    apiEndDate = null;
  }
  if (newConference?.courses?.length > 0) {
    newConference?.courses?.forEach((course) => {
      let apiCourseEndDate;

      if (course?.endDate && newConference?.timezone) {
        apiCourseEndDate = utcToZonedTime(
          course?.endDate,
          newConference?.timezone
        );
        course.endDate = api;
      } else {
        apiCourseEndDate = null;
      }
      let apiCourseStartDate;
      if (course?.startDate && newConference?.timezone) {
        apiCourseStartDate = utcToZonedTime(
          course?.startDate,
          newConference?.timezone
        );
      } else {
        apiCourseStartDate = null;
      }

      course.startDate = apiCourseStartDate;
      course.endDate = apiCourseEndDate;
    });
  }

  const courses = [
    {
      title: '',
      startDate: null,
      endDate: null,
      currency: '',
      price: 0,
      description: '',
      isResourceLoading: false,
      quantity: 1,
      resources: [],
    },
  ];

  const formik = useFormik({
    initialValues: {
      title: newConference?.title || '',
      host: newConference?.host || '',
      organizationId: newConference?.hostedBy?.organization?._id || '',
      startDate: apiStartDate || null,
      endDate: apiEndDate || null,
      timezone: newConference?.timezone || '',
      mode: newConference?.mode || [],
      venueName: newConference?.venueName || '',
      street1: newConference?.street1 || '',
      street2: newConference?.street2 || '',
      state: newConference?.state || '',
      country: newConference?.country || '',
      city: newConference?.city || '',
      zipcode: newConference?.zipcode || '',
      courseAdded: newConference?.courseAdded || false,
      courses:
        !newConference?.courses?.length > 0 ? courses : newConference?.courses,
      deletedCourses: [],
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });
  console.log(formik.values.courseAdded);
  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user?._id);
    if (!countryList.length > 0) {
      loadCountryList();
    }
  }, [user?._id]);

  useEffect(() => {
    let myCountryId;
    if (countryList.length > 0) {
      myCountryId = countryList.find(
        (country) => country.value === newConference?.country
      )?.countryId;
    }
    if (myCountryId) {
      loadStateList(myCountryId);
    }
  }, [countryList]);

  useEffect(() => {
    let myStateId;
    if (stateList.length > 0) {
      myStateId = stateList.find(
        (state) => state.value === newConference?.state
      )?.stateId;
    }
    if (myStateId) {
      loadCityList(myStateId);
    }
  }, [stateList]);

  return (
    <>
      <main className="conf-form-wrap">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <p className="body-regular-gray3 mb-24" style={{ color: '#aabdc7' }}>
            All mandatory fields are makred by *
          </p>
          <div className="form-type-1 mb-72">
            <h2>Basic Information</h2>
            <h4>Title *</h4>
            <div className="material-textfield">
              <input
                id="title"
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Conference title</label>
            </div>
            <div className="mb-24">
              {formik.touched.title && Boolean(formik.errors.title) && (
                <TextError>{formik.errors.title}</TextError>
              )}
            </div>

            <h4>Hosted by *</h4>
            <div>
              <input
                type="radio"
                style={{ display: 'none' }}
                id="conf-org"
                name="host"
                value="organization"
                checked={formik.values.host === 'organization'}
                onChange={formik.handleChange}
                disabled={newConference?.completedStep6}
              />
              <label htmlFor="conf-org">
                <div
                  className={`mr-20 mb-18 basicInfo-btn ${
                    formik.values.host === 'organization'
                      ? 'button-outlined-active'
                      : 'button-outlined-inactive'
                  }`}
                >
                  Organization
                </div>
              </label>
              <input
                type="radio"
                style={{ display: 'none' }}
                name="host"
                id="conf-myself"
                value="user"
                checked={formik.values.host === 'user'}
                onChange={formik.handleChange}
                disabled={newConference?.completedStep6}
              />
              <label htmlFor="conf-myself">
                <div
                  className={`mr-20 basicInfo-btn ${
                    formik.values.host === 'user'
                      ? 'button-outlined-active'
                      : 'button-outlined-inactive'
                  }`}
                >
                  Myself
                </div>
              </label>
            </div>
            <div className="mb-32">
              {formik.touched.host && Boolean(formik.errors.host) && (
                <TextError>{formik.errors.host}</TextError>
              )}
            </div>
            <AnimatePresence initial={false} mode="wait">
              {formik.values.host === 'organization' && (
                <motion.div
                  key="organization"
                  layout
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.5, opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                  // initial={{ x: -300, opacity: 0 }}
                  // animate={{ x: 0, opacity: 1 }}
                  // exit={{ x: -300, opacity: 0 }}
                  // transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <SelectFormType1
                    label="organizationId"
                    options={organizationsListForSelect}
                    name="organizationId"
                    onChange={(value) =>
                      formik.setFieldValue('organizationId', value?.value)
                    }
                    placeholder="Select organization"
                    value={formik.values.organizationId}
                    isDisabled={formik.values.host !== 'organization'}
                  />
                  <div>
                    {formik.touched.organizationId &&
                      Boolean(formik.errors.organizationId) && (
                        <TextError>{formik.errors.organizationId}</TextError>
                      )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="conf-schedule mb-72">
            <h2>Conference Schedule</h2>
            <div className="basicInfo-grid">
              <div className="">
                <h4>Start Date and Time *</h4>
                <CustomDatepicker
                  id="startDate"
                  name="startDate"
                  selected={formik.values.startDate}
                  onChange={(date) => formik.setFieldValue('startDate', date)}
                  minDate={new Date()}
                  maxDate={formik.values.endDate}
                  placeholder="Pick start date and time"
                  disabled={false}
                />
                <div className="mb-24">
                  {formik.touched.startDate &&
                    Boolean(formik.errors.startDate) && (
                      <TextError>{formik.errors.startDate}</TextError>
                    )}
                </div>
              </div>

              <div className="">
                <h4>End Date and Time *</h4>
                <CustomDatepicker
                  id="endDate"
                  name="endDate"
                  selected={formik.values.endDate}
                  onChange={(date) => formik.setFieldValue('endDate', date)}
                  minDate={formik.values.startDate}
                  placeholder="Pick end date and time"
                  disabled={false}
                />
                <div className="mb-24">
                  {formik.touched.endDate && Boolean(formik.errors.endDate) && (
                    <TextError>{formik.errors.endDate}</TextError>
                  )}
                </div>
              </div>

              <div className="grid-1st-col">
                <h4>Timezone *</h4>

                <ReloadableSelectFormType1
                  label="timezone"
                  options={timezones}
                  name="timezone"
                  onChange={(value) =>
                    formik.setFieldValue('timezone', value?.value)
                  }
                  placeholder="Select conference timezone"
                  value={formik.values.timezone}
                />
                <div className="mb-24">
                  {formik.touched.timezone &&
                    Boolean(formik.errors.timezone) && (
                      <TextError>{formik.errors.timezone}</TextError>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-72">
            <h2>Location</h2>
            <h4>Pick venue or online or both *</h4>
            <div>
              <input
                style={{ display: 'none' }}
                type="checkbox"
                id="conf-venue"
                name="mode"
                value="venue"
                checked={formik.values.mode.includes('venue')}
                onChange={formik.handleChange}
              />
              <label htmlFor="conf-venue">
                <div
                  className={`mr-20 mb-18 basicInfo-btn ${
                    formik.values.mode.includes('venue')
                      ? 'button-outlined-active'
                      : 'button-outlined-inactive'
                  }`}
                >
                  Pick Venue
                </div>
              </label>

              <input
                style={{ display: 'none' }}
                type="checkbox"
                id="online-conf"
                name="mode"
                value="onlineConf"
                checked={formik.values.mode.includes('onlineConf')}
                onChange={formik.handleChange}
              />
              <label htmlFor="online-conf">
                <div
                  type="button"
                  className={`mr-20  basicInfo-btn ${
                    formik.values.mode.includes('onlineConf')
                      ? 'button-outlined-active'
                      : 'button-outlined-inactive'
                  }`}
                >
                  Online Conference
                </div>
              </label>
            </div>
            <div className="mb-24">
              {formik.touched.mode && Boolean(formik.errors.mode) && (
                <TextError>{formik.errors.mode}</TextError>
              )}
            </div>

            <div className="form-type-1">
              <AnimatePresence initial={false} mode="wait">
                {formik.values.mode.includes('venue') && (
                  <motion.div
                    // className="livestram-grid mb-28"
                    key="slow height"
                    layout
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ type: 'spring', duration: 0.5 }}
                    // initial={{
                    //   height: 0,
                    //   opacity: 0,
                    //   x: -300,
                    // }}
                    // animate={{
                    //   x: 0,
                    //   height: "auto",
                    //   opacity: 1,
                    //   transition: {
                    //     height: {
                    //       duration: 0.3,
                    //     },
                    //     opacity: {
                    //       duration: 0.3,
                    //       delay: 0.1,
                    //     },
                    //     x: {
                    //       duration: 0.5,
                    //     },
                    //   },
                    // }}
                    // exit={{
                    //   x: -300,
                    //   height: 0,
                    //   opacity: 0,
                    //   transition: {
                    //     height: {
                    //       duration: 0.5,
                    //       delay: 0.1,
                    //     },
                    //     opacity: {
                    //       duration: 0.4,
                    //     },
                    //   },
                    // }}
                  >
                    <>
                      <h4>Venue Details</h4>
                      <div className="basicInfo-grid">
                        <div style={{ gridColumn: '1/-1' }}>
                          <div className="material-textfield">
                            <input
                              id="venueName"
                              type="text"
                              name="venueName"
                              value={formik.values.venueName}
                              onChange={formik.handleChange}
                              placeholder=" "
                              disabled={!formik.values.mode.includes('venue')}
                            />
                            <label>Venue Name *</label>
                          </div>
                          <div className="mb-24">
                            {formik.touched.venueName &&
                              Boolean(formik.errors.venueName) && (
                                <TextError>{formik.errors.venueName}</TextError>
                              )}
                          </div>
                        </div>

                        <div className="">
                          <div className="material-textfield">
                            <input
                              id="street1"
                              type="text"
                              name="street1"
                              value={formik.values.street1}
                              onChange={formik.handleChange}
                              placeholder=" "
                              disabled={!formik.values.mode.includes('venue')}
                            />
                            <label>Address line 1 *</label>
                          </div>
                          <div className="mb-24">
                            {formik.touched.street1 &&
                              Boolean(formik.errors.street1) && (
                                <TextError>{formik.errors.street1}</TextError>
                              )}
                          </div>
                        </div>
                        <div className="">
                          <div className="material-textfield">
                            <input
                              id="street2"
                              type="text"
                              name="street2"
                              value={formik.values.street2}
                              onChange={formik.handleChange}
                              placeholder=" "
                              disabled={!formik.values.mode.includes('venue')}
                            />
                            <label>Address line 2</label>
                          </div>
                          <div className="mb-24">
                            {formik.touched.street2 &&
                              Boolean(formik.errors.street2) && (
                                <TextError>{formik.errors.street2}</TextError>
                              )}
                          </div>
                        </div>
                        <div className="">
                          <SelectFormType1
                            options={countryList}
                            value={formik.values.country}
                            onChange={(value) => {
                              if (formik.values.country !== value?.value) {
                                formik.setFieldValue('state', '');
                                formik.setFieldValue('city', '');
                              }
                              formik.setFieldValue('country', value?.value);
                              loadStateList(value?.countryId);
                            }}
                            placeholder="Select country *"
                            isDisabled={false}
                            name="country"
                          />
                          <div className="mb-24">
                            {formik.touched.country &&
                              Boolean(formik.errors.country) && (
                                <TextError>{formik.errors.country}</TextError>
                              )}
                          </div>
                        </div>

                        <div className="">
                          <ReloadableSelectFormType1
                            options={stateList}
                            value={formik.values.state}
                            onChange={(value) => {
                              if (formik.values.state !== value?.value) {
                                formik.setFieldValue('city', '');
                              }
                              formik.setFieldValue('state', value?.value);
                              loadCityList(value?.stateId);
                            }}
                            placeholder="Select state *"
                            isDisabled={false}
                            name="state"
                          />

                          <div className="mb-24">
                            {formik.touched.state &&
                              Boolean(formik.errors.state) && (
                                <TextError>{formik.errors.state}</TextError>
                              )}
                          </div>
                        </div>
                        <div className="">
                          <ReloadableSelectFormType1
                            options={cityList}
                            value={formik.values.city}
                            onChange={(value) => {
                              formik.setFieldValue('city', value?.value);
                            }}
                            placeholder="Select city *"
                            isDisabled={false}
                            name="city"
                          />

                          <div className="mb-24">
                            {formik.touched.city &&
                              Boolean(formik.errors.city) && (
                                <TextError>{formik.errors.city}</TextError>
                              )}
                          </div>
                        </div>
                        <div className="">
                          <div className="material-textfield">
                            <input
                              id="zipcode"
                              type="text"
                              name="zipcode"
                              value={formik.values.zipcode}
                              onChange={formik.handleChange}
                              placeholder=" "
                              disabled={!formik.values.mode.includes('venue')}
                            />
                            <label>Zip Code *</label>
                          </div>
                          <div className="mb-24">
                            {formik.touched.zipcode &&
                              Boolean(formik.errors.zipcode) && (
                                <TextError>{formik.errors.zipcode}</TextError>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="mb-24 form-type-1">
            <h2>Pre/Post Course</h2>
            <p className="avenir-20-500 mb-12">
              Do you offer pre/post courses?
            </p>
            <div className="mb-12">
              <Switch
                id="preview"
                name="courseAdded"
                value={formik.values.courseAdded}
                checked={formik.values.courseAdded}
                onChange={formik.handleChange}
                disable={false}
              />
            </div>

            {formik.values.courseAdded && (
              <AnimatePresence initial={false} mode="wait">
                {formik.values.courses.map((course, index) => (
                  <motion.div
                    key={index}
                    className="livestram-grid mb-28"
                    layout
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ type: 'spring', duration: 0.5 }}
                  >
                    <div className="mb-24">
                      <div className="material-textfield mt-12">
                        <input
                          id="title"
                          type="text"
                          name={`courses[${index}].title`}
                          value={formik?.values?.courses[index]?.title}
                          onChange={formik.handleChange}
                          placeholder=" "
                        />
                        <label>Course title</label>
                      </div>
                      <div className="mb-24">
                        {formik?.touched?.courses?.[index]?.title &&
                          Boolean(formik?.errors?.courses?.[index]?.title) && (
                            <TextError>
                              {formik?.errors?.courses?.[index]?.title}
                            </TextError>
                          )}
                      </div>

                      <p className="caption-1-regular-gray3 mb-24">
                        Describe Course in few words*.
                      </p>

                      <div>
                        <textarea
                          id="description"
                          name={`courses[${index}].description`}
                          value={formik?.values?.courses[index]?.description}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-24">
                        {formik?.touched?.courses?.[index]?.description &&
                          Boolean(
                            formik?.errors?.courses?.[index]?.description
                          ) && (
                            <TextError>
                              {formik?.errors?.courses?.[index]?.description}
                            </TextError>
                          )}
                      </div>
                      <div className="basicInfo-grid">
                        <div className="">
                          <CustomDatepicker
                            id="startDatee"
                            name={`courses[${index}].startDate`}
                            selected={formik?.values?.courses[index]?.startDate}
                            onChange={(date) => {
                              const updatedCourses = [...formik.values.courses];
                              updatedCourses[index]['startDate'] = date;
                              formik.setFieldValue('courses', updatedCourses);
                            }}
                            // minDate={new Date()}
                            // maxDate={formik.values.courses[index].endDate}
                            placeholder="Pick start date and time"
                            disabled={false}
                          />
                          <div className="mb-24">
                            {formik.touched.courses?.[index]?.startDate &&
                              Boolean(
                                formik.errors.courses?.[index]?.startDate
                              ) && (
                                <TextError>
                                  {formik.errors.courses?.[index]?.startDate}
                                </TextError>
                              )}
                          </div>
                        </div>

                        <div className="">
                          <CustomDatepicker
                            id="enddate"
                            name={`courses[${index}].endDate`}
                            selected={formik?.values?.courses[index]?.endDate}
                            onChange={(date) => {
                              const updatedCourses = [...formik.values.courses];
                              updatedCourses[index]['endDate'] = date;
                              formik.setFieldValue('courses', updatedCourses);
                            }}
                            // minDate={new Date()}
                            // maxDate={formik.values.courses[index].endDate}
                            placeholder="Pick end date and time"
                            disabled={false}
                          />
                          <div className="mb-24">
                            {formik.touched.courses?.[index]?.endDate &&
                              Boolean(
                                formik.errors.courses?.[index]?.endDate
                              ) && (
                                <TextError>
                                  {formik.errors.courses?.[index]?.endDate}
                                </TextError>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-24">
                        <ReloadableSelectFormType1
                          label="Currency"
                          options={currencylist}
                          name={`courses[${index}].currency`}
                          onChange={(value) => {
                            const updatedCourses = [...formik.values.courses];

                            updatedCourses[index]['currency'] = value.value;
                            formik.setFieldValue('courses', updatedCourses);
                          }}
                          placeholder="Select course currency"
                          value={formik.values?.courses?.[index]?.currency}
                        />
                        <div className="mb-24">
                          {formik.touched.courses?.[index]?.currency &&
                            Boolean(
                              formik.errors.courses?.[index]?.currency
                            ) && (
                              <TextError>
                                {formik.errors.courses?.[index]?.currency}
                              </TextError>
                            )}
                        </div>
                      </div>
                      <div className="mb-8">
                        <div className="material-textfield">
                          <input
                            id="price"
                            type="number"
                            name={`courses[${index}].price`}
                            min={0}
                            value={formik?.values?.courses[index]?.price}
                            onChange={formik.handleChange}
                            placeholder=" "
                          />
                          <label>Price*</label>
                        </div>
                        <div className="mb-24">
                          {formik?.touched?.courses?.[index]?.price &&
                            Boolean(
                              formik?.errors?.courses?.[index]?.price
                            ) && (
                              <TextError>
                                {formik?.errors?.courses?.[index]?.price}
                              </TextError>
                            )}
                        </div>
                      </div>
                      <div className="mb-8">
                        <div className="material-textfield">
                          <input
                            id="quantity"
                            type="number"
                            name={`courses[${index}].quantity`}
                            min={1}
                            value={formik?.values?.courses[index]?.quantity}
                            onChange={formik.handleChange}
                            placeholder=" "
                          />
                          <label>Quantity*</label>
                        </div>
                        <div className="mb-24">
                          {formik?.touched?.courses?.[index]?.quantity &&
                            Boolean(
                              formik?.errors?.courses?.[index]?.quantity
                            ) && (
                              <TextError>
                                {formik?.errors?.courses?.[index]?.quantity}
                              </TextError>
                            )}
                        </div>
                      </div>
                      <div className="mb-60">
                        <p className="avenir-20-500 mb-12">
                          Add resources for the course
                        </p>
                        <div className="mb-16">
                          {formik.values.courses?.[index].resources?.map(
                            (resource) => (
                              <div
                                key={resource?.Key}
                                className="flex-vc-sb uc-uploadfile-input mb-8 "
                              >
                                <div
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  className="flex-vc"
                                >
                                  <i className="mr-8">
                                    <DocumentIcon className="icon-sm" />
                                  </i>
                                  <p className="caption-1-regular-gray2">
                                    {resource?.title || 'Resource file'}
                                  </p>
                                </div>
                                <i
                                  onClick={async () =>
                                    // deleteFile(
                                    //   resource?.Key,
                                    //   'resources',
                                    //   formik.values.resources,
                                    //   formik.setFieldValue
                                    // )

                                    {
                                      const response = await api.delete(
                                        'courses/files',
                                        {
                                          data: {
                                            files: [
                                              {
                                                Key: resource.Key,
                                              },
                                            ],
                                          },
                                        }
                                      );

                                      if (response) {
                                        const updatedCourses = [
                                          ...formik.values.courses,
                                        ];
                                        console.log(
                                          formik.values?.courses[
                                            index
                                          ].resources?.filter(
                                            (item) =>
                                              item.Key !==
                                              response.data.data.Key
                                          )
                                        );
                                        updatedCourses[index]['resources'] =
                                          formik.values?.courses[
                                            index
                                          ].resources?.filter(
                                            (item) =>
                                              item.Key !==
                                              response.data.data.Key
                                          );

                                        formik.setFieldValue(
                                          'courses',
                                          updatedCourses
                                        );
                                      }
                                    }
                                  }
                                >
                                  <CrossCancelIcon
                                    className=" icon-size"
                                    // fill="#000000"
                                  />
                                </i>
                              </div>
                            )
                          )}
                          <div>
                            {formik.values.courses[index].isResourceLoading ? (
                              <div className="uc-uploadfile-input flex-vc position-relative">
                                <Loader />
                              </div>
                            ) : (
                              <Dropzone
                                accept={{
                                  'application/pdf': ['.pdf'],
                                }}
                                multiple={true}
                                maxFiles={1}
                                onDrop={async (acceptedFiles) => {
                                  // props.setFieldValue("resources", acceptedFiles);
                                  if (acceptedFiles?.length > 0) {
                                    const formDataObj = new FormData();
                                    let alreadySavedOnS3 = [];

                                    acceptedFiles.map((item) =>
                                      !item.Key
                                        ? formDataObj.append('file', item)
                                        : alreadySavedOnS3.push(item)
                                    );
                                    console.log(
                                      'length',
                                      formik.values?.courses[index]?.resources
                                        ?.length
                                    );

                                    if (
                                      formik.values?.courses[index]?.resources
                                        ?.length > 2
                                    ) {
                                      dispatch(
                                        alertAction(
                                          'Only three files are allowed',
                                          'danger'
                                        )
                                      );
                                      return;
                                    }
                                    if (formDataObj.has('file')) {
                                      const updatedCourses = [
                                        ...formik.values.courses,
                                      ];
                                      updatedCourses[index][
                                        'isResourceLoading'
                                      ] = true;
                                      formik.setFieldValue(
                                        'courses',
                                        updatedCourses
                                      );
                                      try {
                                        const s3Response = await api.post(
                                          'media/upload',
                                          formDataObj
                                        );
                                        console.log({ s3Response });
                                        if (s3Response) {
                                          const updatedCourses = [
                                            ...formik.values.courses,
                                          ];

                                          updatedCourses[index]['resources'] = [
                                            // ...alreadySavedOnS3,
                                            ...formik.values.courses[index]
                                              .resources,
                                            ...s3Response.data.data,
                                          ];
                                          updatedCourses[index][
                                            'isResourceLoading'
                                          ] = false;

                                          formik.setFieldValue(
                                            'courses',
                                            updatedCourses
                                          );
                                        }
                                      } catch (err) {
                                        const updatedCourses = [
                                          ...formik.values.courses,
                                        ];

                                        updatedCourses[index][
                                          'isResourceLoading'
                                        ] = false;
                                        formik.setFieldValue(
                                          'courses',
                                          updatedCourses
                                        );
                                        dispatch(
                                          alertAction(
                                            'Files(s) failed to save',
                                            'danger'
                                          )
                                        );
                                      }
                                    }
                                  }
                                  //
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps({
                                        className:
                                          'uc-uploadfile-input flex-vc',
                                      })}
                                    >
                                      <div
                                        className="flex-vc"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflowX: 'hidden',
                                        }}
                                      >
                                        <i className="position-relative mr-8">
                                          <PlusIcon className="icon-size" />
                                        </i>
                                        <p className="caption-1-medium-primary">
                                          Add file
                                        </p>
                                      </div>
                                      <input {...getInputProps()} />
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {formik.values.courses.length > 1 ? (
                        <div
                          style={{
                            height: '60px',
                          }}
                          className="flex-vc"
                        >
                          <i
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              formik.setFieldValue(
                                'courses',
                                formik.values.courses.filter(
                                  (e) => e !== course
                                )
                              );
                              deleteCourse(course);
                            }}
                          >
                            <TranshcanIcon />
                          </i>
                        </div>
                      ) : null}
                    </div>
                  </motion.div>
                ))}
                <div>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    className="button-outlined button-outlined-primary p-8"
                    type="button"
                    onClick={() => {
                      let tempArr = [...formik.values.courses, courses[0]];
                      formik.setFieldValue('courses', tempArr);
                    }}
                  >
                    + Add more courses
                  </motion.button>
                </div>
              </AnimatePresence>
            )}
          </div>
          <div className="mb-72">
            <SubmitCancelButtonWithLoader
              isSubmitting={formik.isSubmitting}
              onCancel={() => formik.resetForm({})}
            />
          </div>
        </form>
      </main>
    </>
  );
}
