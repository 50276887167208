function SetCreditGoals({ className}) {

  
    return (
        <svg className={className} width="267px" height="255px" viewBox="0 0 267 255" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>investment@1.5x</title>
        <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Track-Credit-Page" transform="translate(-908.000000, -1804.000000)" fillRule="nonzero">
                <g id="investment" transform="translate(908.000000, 1804.000000)">
                    <path d="M258,125.201954 C258,183.634171 210.54256,231 151.997329,231 C93.4579738,231 46,183.634171 46,125.201954 C46,85.6390673 67.7604536,51.1473205 99.9857359,33 L151.997329,125.201954 L258,125.201954 Z" id="Path" fill="#08415C"></path>
                    <path d="M166,230.026299 C161.261007,230.669923 156.420556,231 151.501193,231 C92.6835682,231 45,183.634171 45,125.201954 C45,85.6385341 66.8638933,51.1473205 99.2423604,33 L107.722946,47.9622609 C86.9691842,67.2683181 73.9976132,94.7292581 73.9976132,125.201954 C73.9976132,178.747534 114.040172,223.001357 166,230.026299 L166,230.026299 Z" id="Path" fill="#053247"></path>
                    <path d="M267,106 L161.006898,106 L109,13.6174582 C124.365407,4.94699436 142.108654,0 161.006898,0 C219.546845,0 267,47.4588748 267,106 Z" id="Path" fill="#4CB944"></path>
                    <path d="M161.448556,0 C166.385778,0 171.243802,0.335244463 176,0.989485676 C153.530898,4.0651776 133.284732,14.1620476 117.511374,29 L109,13.8035147 C124.495895,5.014044 142.389823,0 161.448556,0 Z" id="Path" fill="#3C9C35"></path>
                    <path d="M199.359738,143.571444 L155.588719,160.143216 C149.419729,162.478742 148.113946,170.560927 153.23799,174.691255 L161.647377,181.469433 L129.509496,221.535102 L66.8764049,172.692487 C61.2055182,168.271081 53.079461,168.823491 48.0711061,173.974184 L3.93802443,219.361049 C-1.45060342,224.902679 -1.28885353,233.726899 4.29901589,239.070936 C9.88634972,244.414972 18.7847359,244.255092 24.1728281,238.712931 L59.4873273,202.395153 L123.120375,252.017517 C125.695519,254.025846 128.758055,255 131.799167,255 C135.936108,255 140.034486,253.196169 142.8078,249.737763 L183.462724,199.053072 L189.60065,204.00033 C194.724694,208.130658 202.435664,205.21032 203.47954,198.744466 L210.887364,152.863088 C211.931775,146.398296 205.527658,141.236449 199.359738,143.571444 L199.359738,143.571444 Z" id="Path" fill="#4CB944"></path>
                    <g id="Group" transform="translate(0.000000, 143.000000)" fill="#419F3A">
                        <path d="M14.6241696,84.5335106 L58.6403722,39.0824468 C63.4714709,34.093617 71.2042203,33.4265957 76.8242987,37.3925532 L67.1920152,29.8505319 C61.5361468,25.4228723 53.4316101,25.9760638 48.436519,31.1340426 L4.42031646,76.5851064 C-0.954040506,82.1345745 -0.792718987,90.9712766 4.7803519,96.3228723 C6.83586582,98.2968085 9.34115696,99.518617 11.9538177,99.9989362 C9.64617215,94.9404255 10.5152785,88.7765957 14.6241696,84.5335106 Z" id="Path"></path>
                        <path d="M153.324886,31.8526596 L161.712003,38.6404255 L129.680066,78.737234 L139.86362,86.7106383 L171.91639,46.587766 L163.529273,39.8 C158.4188,35.6643617 159.721124,27.5702128 165.873777,25.2319149 L209.528876,8.63670213 C209.994678,8.45957447 210.461549,8.33085106 210.926284,8.23670213 C210.684301,2.62765957 204.907709,-1.43351064 199.324489,0.688297872 L155.66939,17.2835106 C149.516737,19.6228723 148.214413,27.7170213 153.324886,31.8526596 L153.324886,31.8526596 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
       
      
    );
  }
  
  export default SetCreditGoals;
  