import { Fragment } from 'react';
import Edit2Icon from '../icons/Edit2Icon';
import PlusIcon from '../icons/PlusIcon';
import TranshcanIcon from '../icons/TrashcanIcon';
import '../create-video-course/episodeList.scss';
import { createAudioCourseAction } from '../../redux/audio-course/audioCourseAction';
import { useDispatch } from 'react-redux';
import api from '../../utility/api';

export default function AudioCourseEpisodeList({
  list,
  setMySections,
  setChapterToEdit,
  setShowChapter,
}) {
  const dispatch = useDispatch();

  const deleteEpisode = async (courseId, sectionId, episodeId) => {
    const url = `audioCourses/${courseId}/section/${sectionId}/episode/${episodeId}`;
    try {
      const response = await api.delete(url);

      dispatch(createAudioCourseAction(response.data.data.media));
      setMySections(response.data.data?.media?.sections);
    } catch (err) {}
  };

  return (
    <div className="ce-episode-list">
      {list?.map((chapter) => (
        <Fragment key={chapter?._id}>
          <div className="L-indent"></div>
          <div className="form-type-4">
            <i className="left-input-icon">
              <PlusIcon className="icon-size" />
            </i>
            <input
              style={{
                paddingLeft: 48,
                paddingTop: 19,
                paddingBottom: 20,
              }}
              type="text"
              value={chapter.title}
              disabled={true}
            />
          </div>
          {/* <p className="avenir-20-500">{chapter.title}</p> */}
          <div>
            <i
              style={{ cursor: 'pointer' }}
              onClick={() =>
                deleteEpisode(chapter.media, chapter.section, chapter._id)
              }
              className="mr-12 "
            >
              <TranshcanIcon className="icon-size" />
            </i>
            <i
              onClick={() => {
                setChapterToEdit(chapter);
                setShowChapter(true);
              }}
              className=""
            >
              <Edit2Icon className="icon-size" />
            </i>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
