import SearchIcon from '../icons/SearchIcon';
import { customSelectStyleSchedule } from '../reselect/selectUtil';
import { getValue } from '../../utility/commonUtil';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

export default function ScheduleFilter({
  setSearchText,
  searchText,
  filter2,
  setFilter2,
  setSelectedDate,
  selectedDate,
  halls,
}) {
  return (
    <>
      {' '}
      <div className="form-type-session">
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            id="myConfsSearchText"
            placeholder="Search Schedules"
            name="searchText"
            value={searchText}
            onChange={(e) => {
              e.preventDefault();
              setSearchText(e.target.value);
            }}
            autoComplete="off"
          />
          <i
            className={
              searchText?.length > 0 ? 'display-none' : 'conf-search-input-icon'
            }
          >
            <SearchIcon width="2.4rem" height="3.4rem" />
          </i>
        </div>
      </div>
      <div style={{ minHeight: '58px' }}>
        <Select
          value={getValue(halls, filter2, false)}
          options={halls}
          name="filter1"
          styles={customSelectStyleSchedule}
          onChange={(option) => {
            setFilter2(option.value);
          }}
          placeholder="Select Hall"
        />
      </div>
      <div className="form-type-session">
        <div>
          <DatePicker
            selected={selectedDate}
            onChange={(val) => setSelectedDate(val)}
            isClearable
            placeholderText="Date"
          />
        </div>
      </div>
    </>
  );
}
