export default function BannerWithGirlSketch({ className }) {
  return (
    <svg
      className={className}
      width="auto"
      height="auto"
      viewBox="0 0 900 626"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 13@2x</title>
      <g
        id="Creatives"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-13" transform="translate(0.400676, 0.747541)">
          <g id="Group-16-Copy">
            <path
              d="M676.599324,16.252459 C689.301874,16.252459 699.599324,26.5499098 699.599324,39.252459 L699.599324,494.252459 C699.599324,506.955008 689.301874,517.252459 676.599324,517.252459 L44.5993243,517.252459 C31.8967751,517.252459 21.5993243,506.955008 21.5993243,494.252459 L21.5993243,39.252459 C21.5993243,26.5499098 31.8967751,16.252459 44.5993243,16.252459 L676.599324,16.252459 Z M656.5381,34.7225051 L64.6605488,34.7225051 C53.6148538,34.7225051 44.6605488,43.6768101 44.6605488,54.7225051 L44.6605488,54.7225051 L44.6605488,462.621123 C44.6605488,473.666818 53.6148538,482.621123 64.6605488,482.621123 L64.6605488,482.621123 L656.5381,482.621123 C667.583795,482.621123 676.5381,473.666818 676.5381,462.621123 L676.5381,462.621123 L676.5381,54.7225051 C676.5381,43.6768101 667.583795,34.7225051 656.5381,34.7225051 L656.5381,34.7225051 Z"
              id="Combined-Shape"
              fill="#CED9DE"
            ></path>
            <path
              d="M698.599324,0.252459016 C711.854158,0.252459016 722.599324,10.997625 722.599324,24.252459 L722.599324,509.252459 C722.599324,522.507293 711.854158,533.252459 698.599324,533.252459 L24.5993243,533.252459 C11.3444903,533.252459 0.599324324,522.507293 0.599324324,509.252459 L0.599324324,24.252459 C0.599324324,10.997625 11.3444903,0.252459016 24.5993243,0.252459016 L698.599324,0.252459016 Z M676.378877,18.7113335 L46.8197716,18.7113335 C34.6695071,18.7113335 24.8197716,28.561069 24.8197716,40.7113335 L24.8197716,40.7113335 L24.8197716,474.33471 C24.8197716,486.484975 34.6695071,496.33471 46.8197716,496.33471 L46.8197716,496.33471 L676.378877,496.33471 C688.529142,496.33471 698.378877,486.484975 698.378877,474.33471 L698.378877,474.33471 L698.378877,40.7113335 C698.378877,28.561069 688.529142,18.7113335 676.378877,18.7113335 L676.378877,18.7113335 Z"
              id="Combined-Shape-Copy"
              fill="#08415C"
            ></path>
          </g>
          <g
            id="A-Human/Standing-Copy"
            transform="translate(639.599324, 108.252459)"
          >
            <g id="Head/Front" transform="translate(83.000000, 13.000000)">
              <path
                d="M32,64.7068588 C43.4289379,64.7068588 47.5854974,51.0240838 54.0786187,43.5153483 C58.7243138,38.143002 66.6530795,39.7449442 69,32.2237762 C74.9015425,13.3111237 59.1061086,-4.54747351e-13 38.5,-4.54747351e-13 C17.8938914,-4.54747351e-13 4,11.6742524 1,29.2237762 C-2,46.7733 11.3938914,64.7068588 32,64.7068588 Z"
                id="Hair-Back"
                fill="#000000"
              ></path>
              <g
                id="Head"
                transform="translate(25.000000, 18.000000)"
                fill="#B28B67"
              >
                <path
                  d="M38.1433414,9.13393292 C43.9381521,19.9350547 43.249578,47.3329958 35.7603014,49.2634576 C32.773664,50.0333035 26.4113241,48.1475566 19.935865,44.2446031 L24,73 L0,73 L8.26160177,34.4893378 C3.65406109,29.0807313 0.535660132,22.452516 1.05677633,15.0254539 C2.55833022,-6.37502057 32.3485306,-1.66718886 38.1433414,9.13393292 Z"
                  id="Head-[skin]"
                ></path>
              </g>
              <path
                d="M64.5831606,27.1945268 C59.9933309,28.0561444 50.749454,32.438095 43.3793005,38.7237673 C42.507977,38.2620835 41.5087281,38 40.4466684,38 C37.0464589,38 34.2900445,40.6862915 34.2900445,44 C34.2900445,45.352507 34.7492372,46.6004922 35.5239753,47.6039629 C34.065081,50.0086601 33.0695654,52.5348236 32.7432388,55.1317726 C27.6870351,55.1317726 9.23358027,36 27.6167901,18 C46,-1.15463195e-13 72.566014,25.6959598 64.5831606,27.1945268 Z"
                id="Hair"
                fill="#000000"
              ></path>
            </g>
            <g
              id="Bottom/Skinny-Jeans-1"
              transform="translate(0.000000, 172.000000)"
            >
              <polygon
                id="[skin]-Leg"
                fill="#B28B67"
                points="100 15 137.630838 138.024171 155.860154 242.648304 170.153197 242.648304 150.890974 15"
              ></polygon>
              <polygon
                id="Shadow"
                fillOpacity="0.1"
                fill="#000000"
                points="100 15 137.630838 138.024171 155.860154 242.648304 170.153197 242.648304 150.890974 15"
              ></polygon>
              <g
                id="shoe"
                transform="translate(150.000000, 214.000000)"
                fill="#E4E4E4"
              >
                <path d="M2.67813181,25.4019242 C1.55937727,28.3884109 1,30.6229931 1,32.1056708 C1,33.908957 1.3004142,36.5404001 1.90124261,40 C3.99318117,40 22.7937852,40 58.3030548,40 C59.768738,35.1545073 58.9226607,32.5385816 55.7648228,32.1522232 C52.606985,31.7658647 49.9837155,31.4170139 47.8950143,31.1056708 L21.6799926,19.4188835 C21.1755635,19.1940064 20.584344,19.4206282 20.359467,19.9250573 C20.35562,19.9336867 20.3518954,19.9423702 20.3482945,19.9511052 L18.6632131,24.038695 L18.6632131,24.038695 C15.7398812,25.4026522 13.3643706,26.0846307 11.5366811,26.0846307 C10.0517269,26.0846307 8.00099246,25.4849054 5.38447792,24.2854549 L5.38448339,24.285443 C4.38038273,23.8251478 3.19325534,24.2659892 2.73296014,25.2700899 C2.71312074,25.3133681 2.69483298,25.3573409 2.67813181,25.4019242 Z"></path>
              </g>
              <polygon
                id="Bottom"
                fill="#33842D"
                points="95 15 111 77.6795201 159.870318 77.6795201 152.583554 15"
              ></polygon>
              <g
                id="Group-2"
                transform="translate(112.808154, 114.981686) rotate(-50.000000) translate(-112.808154, -114.981686) translate(21.308154, 45.481686)"
              >
                <g id="Group" transform="translate(-0.000000, -0.000000)">
                  <path
                    d="M122.537727,4.54747351e-12 C125.093705,67.5468821 124.987212,96.0496516 124.415154,102.186579 C123.843096,108.323507 111.993192,106.952929 46.6609496,77.3505001 L31.7152098,89.8914649 C156.14531,179.369956 149.088936,119.5887 152.450326,110.525715 C155.811717,101.46273 164.739403,64.6208249 179.233385,4.54747351e-12 L122.537727,4.54747351e-12 Z"
                    id="[skin]-Leg"
                    fill="#B28B67"
                  ></path>
                  <g
                    id="Left-[shoe]"
                    transform="translate(-0.000000, 65.000000)"
                    fill="#E4E4E4"
                  >
                    <path
                      d="M0.751630463,24.1288214 C-0.367124073,27.1153081 -0.926501342,29.3498903 -0.926501342,30.832568 C-0.926501342,32.6358542 -0.626087137,35.2672973 -0.025258728,38.7268972 C2.06667983,38.7268972 20.8672839,38.7268972 56.3765535,38.7268972 C57.8422366,33.8814044 56.9961593,31.2654788 53.8383215,30.8791203 C50.6804837,30.4927619 48.0572142,30.1439111 45.968513,29.832568 L19.7534913,18.1457806 C19.2490622,17.9209036 18.6578427,18.1475254 18.4329657,18.6519545 C18.4291186,18.6605839 18.4253941,18.6692674 18.4217932,18.6780023 L16.7367118,22.7655922 L16.7367118,22.7655922 C13.8133799,24.1295493 11.4378692,24.8115279 9.6101798,24.8115279 C8.12522553,24.8115279 6.07449112,24.2118026 3.45797658,23.0123521 L3.45798205,23.0123402 C2.45388139,22.552045 1.26675399,22.9928864 0.8064588,23.996987 C0.786619402,24.0402653 0.768331639,24.0842381 0.751630463,24.1288214 Z"
                      id="shoe-copy"
                      transform="translate(28.073499, 28.226897) rotate(-225.000000) translate(-28.073499, -28.226897) "
                    ></path>
                  </g>
                </g>
                <path
                  d="M122,4.54747351e-13 C116.735439,15.7805461 115.735439,36.6737195 119,62.6795201 L168.870318,62.6795201 L182.583554,4.54747351e-13 L122,4.54747351e-13 Z"
                  id="Bottom"
                  fill="#4CB944"
                ></path>
              </g>
            </g>
            <g
              id="Body/Long-Sleeve-1"
              transform="translate(28.000000, 82.000000)"
            >
              <path
                d="M34.3079281,93.0198914 L55.2053198,93.1020711 C44.4956844,127.674615 37.8763087,148.777448 35.3471926,156.410568 L35.088982,157.185464 C34.7122581,158.308737 34.4519068,159.048781 34.3079281,159.405597 C31.9939846,165.140133 36.8906002,173.873732 38.9845874,178.567103 C32.1645409,181.61887 32.8912241,170.316929 24.2879189,174.319492 C22.5428749,175.131349 20.8906295,176.089688 19.276551,177.027882 L18.7398634,177.339639 C18.5613888,177.443156 18.3833105,177.546197 18.2055535,177.648531 L17.6731714,177.953189 C12.6232753,180.822978 7.77349817,182.933781 1.38903521,178.994371 C0.273605235,178.306117 -0.948724292,175.714714 2.00457838,173.68967 C4.23420079,172.160845 6.76182276,170.284476 9.24196315,168.330709 L9.98423533,167.742526 C15.4120517,163.416125 20.4428443,158.845865 21.3979297,156.908251 C23.3515741,152.944826 27.6549068,131.648706 34.3079281,93.0198914 L34.3079281,93.0198914 Z M160.04441,89.1225115 L188.372314,116.757535 C196.61469,120.273345 203.368143,124.155511 208.632673,128.40403 C209.816587,129.832102 211.10547,132.568362 205.535214,131.343925 C204.999612,131.22619 204.460831,131.110185 203.923503,130.998275 L203.279704,130.866101 C198.460889,129.891811 193.909884,129.325933 193.003896,130.893567 C191.956977,132.705055 195.041811,135.480899 193.106974,137.851671 C191.817082,139.432186 187.479076,134.404878 180.092956,122.769747 L150.635682,105.402467 L160.04441,89.1225115 Z"
                id="Arms-[skin]"
                fill="#B28B67"
              ></path>
              <path
                d="M88.0189903,9.23199981 L96.5039418,7.00082913 C114.576175,42.7520052 143.414525,74.1501034 183.01899,101.195124 L158.061793,137.56505 C117.33882,97.6857553 90.6705706,55.1985909 88.0189903,9.23199981 Z"
                id="Coat-Back"
                fill="#042433"
                transform="translate(135.518990, 72.282940) rotate(5.000000) translate(-135.518990, -72.282940) "
              ></path>
              <path
                d="M56,114 L133,114 C115.16087,70.6251468 106.241304,33.7787236 106.241304,3.4607303 L83.014208,0 C64.7598929,29.3584068 59.6048263,65.5045499 56,114 Z"
                id="Shirt"
                fill="#DDE3E9"
              ></path>
              <path
                d="M84.0477521,0 C85.8619801,0.0624731357 88.6252821,0.175030894 92.3376583,0.337673275 L92.3376583,0.337673275 L97.9501582,16.3768801 C98.5438362,35.9324229 102.010826,75.3421183 108.351127,134.605966 L108.351127,134.605966 L57.8760675,134.60553 C58.008386,138.698782 58.2265651,142.830272 58.5306047,147 L58.5306047,147 L21,147 C26.6139164,83.6639633 47.1152373,34.6639633 82.5039627,5.68434189e-14 L82.5039627,5.68434189e-14 L82.522,0.017 L82.5315827,0 Z"
                id="Coat-Front"
                fill="#08415C"
              ></path>
              <path
                d="M72,135 L57.889057,134.999288 C57.2713571,116.643048 58.3783198,99.0548837 61.2099449,82.2347951 C62.6469231,107.748771 66.2434771,125.3371 72,135 Z"
                id="Shade"
                fill="#042433"
              ></path>
              <polygon
                id="Light"
                fillOpacity="0.2"
                fill="#FFFFFF"
                points="69.1108989 104 94 92.4292718 94 104"
              ></polygon>
            </g>
          </g>
          <g
            id="bookmark_add_black_24dp"
            transform="translate(242.599324, 148.252459)"
          >
            <rect id="Rectangle" x="0" y="0" width="238" height="238"></rect>
            <path
              d="M188.125,193.058199 C188.125,200.182101 180.870625,204.931369 174.311875,202.160963 L118.5625,178.414623 L62.813125,202.160963 C56.254375,205.030312 49,200.182101 49,193.058199 L49,49.7886164 C49,38.9048774 57.94375,30 68.875,30 L138.4375,30 C132.176875,38.3112189 128.5,48.5023563 128.5,59.6829246 C128.5,86.9912152 150.76,109.154466 178.1875,109.154466 C181.56625,109.154466 184.945,108.857636 188.125,108.165035 L188.125,193.058199 Z"
              id="Shape"
              fill="#08415C"
              fillRule="nonzero"
            ></path>
            <path
              d="M208,59.6829246 C208,65.1247941 203.528125,69.5772328 198.0625,69.5772328 L188.125,69.5772328 L188.125,79.471541 C188.125,84.9134105 183.653125,89.3658492 178.1875,89.3658492 C172.721875,89.3658492 168.25,84.9134105 168.25,79.471541 L168.25,69.5772328 L158.3125,69.5772328 C152.846875,69.5772328 148.375,65.1247941 148.375,59.6829246 C148.375,54.2410551 152.846875,49.7886164 158.3125,49.7886164 L168.25,49.7886164 L168.25,39.8943082 C168.25,34.4524387 172.721875,30 178.1875,30 C183.653125,30 188.125,34.4524387 188.125,39.8943082 L188.125,49.7886164 L198.0625,49.7886164 C203.528125,49.7886164 208,54.2410551 208,59.6829246 Z"
              id="Path"
              fill="#4CB944"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
