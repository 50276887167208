import EditIcon from '../icons/EditIcon';
import TranshcanIcon from '../icons/TrashcanIcon';

export default function ScheduleAction({
  isRowHovered,
  setRowData,
  setShowModal,
  setSessionDeleteDialogue,
  item,
}) {
  return (
    <div>
      <div
        style={{
          visibility: isRowHovered ? 'visible' : 'hidden',
        }}
        className="flex-vc"
      >
        <span
          style={{
            cursor: 'pointer',
          }}
          className="mr-4"
          onClick={() => {
            setRowData(item);
            setShowModal(true);
          }}
        >
          <EditIcon className="icon-xs" fill="#08415c" />
        </span>
        <span
          style={{
            cursor: 'pointer',
          }}
          className="mr-4 ml-12"
          onClick={() => {
            setRowData(item);
            setSessionDeleteDialogue(true);
          }}
        >
          <TranshcanIcon className="icon-xs" />
        </span>
      </div>
    </div>
  );
}
