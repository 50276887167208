import { useSelector } from 'react-redux';
import { getPrice } from '../../utility/commonUtil';

export default function PodcastBookingMonthlyPrice() {
  const podcast = useSelector((state) => state.podcast.podcast);

  return (
    <div className="cep-card-bottombox">
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18">Price</p>
        <p
          className="flex-vc caption-2-bold-cblack "
          style={{
            fontSize: 16,
            gap: '8px',
            wordBreak: 'break-word',
          }}
        >
          {getPrice(
            podcast?.currency,
            podcast?.monthlyPodcastPrice?.actualPrice,
            podcast?.monthlyPodcastPrice?.price,
            podcast?.currencySymbol
          )}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 ">Offer</p>
          <p className="avenir-heavy-18">
            {' '}
            <span>{podcast?.monthlyPodcastPrice?.discount}</span> % Off
          </p>
        </div>
      )}
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18">Service charge</p>
        <p className="avenir-heavy-18 ">
          {' '}
          {podcast.currencySymbol} {podcast?.monthlyPodcastPrice?.serviceCharge}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 ">Total</p>
          <p className="avenir-heavy-18 ">
            <span>{podcast?.currencySymbol} </span>
            <span>{podcast?.monthlyPodcastPrice?.totalPrice}</span>
          </p>
        </div>
      )}
    </div>
  );
}
