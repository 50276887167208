import { openInNewTab } from '../../utility/commonUtil';

// Bdfo = booking details for organizer
export default function BdfoLinks({ confId }) {
  return (
    <div>
      <p>
        <span
          style={{ cursor: 'pointer' }}
          className="caption-2-bold-cblack mr-16"
          onClick={() => openInNewTab(`/search-conference/${confId}`)}
        >
          Conference / CME Link
        </span>
      </p>
    </div>
  );
}
