import MaterialTextInput from "../formik/MaterialTextInput";

export default function BankFormIndiaPart() {
  return (
    <>
      <div className="mb-16">
        <MaterialTextInput
          id="ifsc"
          type="text"
          label="IFSC code*"
          name="ifscCode"
          placeholder=" "
        />
      </div>
      <div className="mb-16">
        <MaterialTextInput
          id="upi"
          type="text"
          label="UPI id"
          name="upi"
          placeholder=" "
        />
      </div>
    </>
  );
}
