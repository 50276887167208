import { Form, Formik } from 'formik';
import { useState } from 'react';
import api from '../../utility/api';
import { useNavigate, useParams } from 'react-router-dom';
import NameForm from '../../components/register/NameForm';
import SubmitButtonWithLoader from '../button/SubmitButtonWithLoader';
import { registerValSchema } from '../register/registerValSchema';
import SingleButtonDialogue from '../dialogue/SingleButtonDialogue';

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  profession: '',
};

export default function VerifyManager() {
  const [msg, setMsg] = useState('');
  const [open, setopen] = useState(false);
  const navigate = useNavigate();
  const openDialogue = () => {
    setopen(true);
  };

  const yesAction = () => {
    setopen(false);
    navigate('/signin');
  };

  const verifyToken = useParams().token;

  const [userId, setuserId] = useState('');

  async function onSubmit(values, actions) {
    const { firstName, lastName, profession, password, confirmPassword } =
      values;
    const formData = {
      user: {
        firstName,
        lastName,
        profession,
        password,
        confirmPassword,
        _id: userId,
      },
    };

    try {
      const response = await api.post(
        'organizers/step2?verifyToken=' + verifyToken,
        formData
      );
      if (response) {
        actions.setSubmitting(false);
        setMsg(response.data.message);
        openDialogue();
      }
    } catch (err) {
      setMsg(err?.response?.data?.message || 'Something went wrong');
      openDialogue();

      if (err) actions.setFieldError('email', err.response?.data.message);
    }
  }

  return (
    <>
      <div className="container-sm">
        <div className="inner-container-sm">
          <h2 className="text-align-center color-primary mb-32">Add Manager</h2>
          <div className="form-type-1">
            <Formik
              initialValues={initialValues}
              validationSchema={registerValSchema[2]}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <NameForm />
                  <SubmitButtonWithLoader
                    isSubmitting={isSubmitting}
                    text="Submit"
                    className="button button-primary"
                    fullWidth={true}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {open && (
        <SingleButtonDialogue msg={msg} title="Message" yesAction={yesAction} />
      )}
    </>
  );
}
