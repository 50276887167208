import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { scheduleSessionFilter } from '../../utility/commonUtil';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import './schedule.scss';
import { alertAction } from '../../redux/alert/alertAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../loader/Loader';
import ScheduleTimeColoumn from './ScheduleTimeColumn';
import ScheduleSessionColoumn from './ScheduleSessionColumn';
import EpisodeColoumn from './EpisodeColumn';
import ScheduleFilter from './ScheduleFilter';
import AddFeedbackForm from '../my-schedule/AddFeedbackForm';
import ModalX from '../modal/ModalX';
import BackButton from '../button/BackButton';
import CheckTickIcon from '../icons/CheckTickIcon';
import ScheduleActionBtnWithLoader from './ScheduleActionBtnWithLoader';
import LikeRedIcon from '../icons/LikeRedIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';

export default function ScheduleView() {
  const [searchText, setSearchText] = useState('');
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const [filter2, setFilter2] = useState('all');
  const [rowData, setRowData] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const confId = useParams().confId;

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);
  const [splicedList, setSplicedList] = useState([]);
  const [loadingId, setLoadingId] = useState(null);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [type, setType] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const showBackButton = location.state?.backButton;

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  const getConferences = async () => {
    try {
      const response = await api.get(`common/conferences/${confId}`);
      setConference(response.data.data.conferences);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    const url = `session/view/conferences/${confId}?userId=${user?._id}`;

    try {
      setIsLoading(true);
      const response = await api.get(url);

      if (response) {
        setConference(response?.data.data.conference);
        setSessions(response.data.data.sessions);
        if (response.data.data.conference.value) {
          getHalls(response.data.data.conference.value);
        }

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const unsaveSession = async (id, type) => {
    if (isAuthenticated && user) {
      setLoadingId(id);
      const url = `/session/view/savedActivity/conference/${confId}/users/${user?._id}?eventType=${type}&id=${id}`;

      try {
        const response = await api.delete(url);

        if (response) {
          setSessions(response.data.data.sessions);
          setLoadingId(null);
        }
      } catch (err) {
        setLoadingId(null);
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    } else {
      dispatch(alertAction('Please login to save a schedule.', 'info'));
    }
  };

  const saveSession = async (id, type) => {
    if (isAuthenticated && user) {
      setLoadingId(id);
      const url = `session/savedActivity/users/${user?._id}`;

      let formData = {
        eventDetails: {
          type,
          conferenceId: conference?.value,
        },
      };

      if (type === 'SESSION') {
        formData.eventDetails.sessionId = id;
      }
      if (type === 'LECTURE') {
        formData.eventDetails.episodeId = id;
      }

      try {
        const response = await api.post(url, formData);

        if (response) {
          setSessions(response.data.data.sessions);
          setLoadingId(null);
        }
      } catch (err) {
        setLoadingId(null);
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    } else {
      dispatch(alertAction('Please login to save a schedule.', 'info'));
    }
  };

  useEffect(() => {
    getConferences();
  }, [user?._id]);

  useEffect(() => {
    getSessions();
  }, [confId]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    let finalFilterResult = scheduleSessionFilter(
      venueFilter,
      searchText,
      selectedDate
    );

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div className="cd-container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showBackButton && (
            <div>
              <BackButton
                className="primary mb-24"
                onBackButtonClick={() => navigate(-1)}
              />
            </div>
          )}
          <div className="myconfs-header">
            <h1>Schedule</h1>
          </div>
          {conference && (
            <h3 className="mb-16 mt-16 color-primary">{`CONFERENCE: ${conference?.label} `}</h3>
          )}

          <div className="scheduleView-sort mb-16 mt-32">
            <ScheduleFilter
              setSearchText={setSearchText}
              searchText={searchText}
              filter2={filter2}
              setFilter2={setFilter2}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              halls={halls}
            />
          </div>

          <div className="schedule-scrollbar-grid-table-wrap">
            <ul className="schedule-grid-table-2-500 schedule-gridtable">
              <li>Time</li>
              <li>Sessions</li>

              {splicedList.length > 0 &&
                splicedList?.map((item, indx) => {
                  let isRowHovered =
                    isHover && rowId.toString() === item?._id.toString();
                  return (
                    <Fragment key={item?._id}>
                      <li
                        onMouseEnter={() => handleMouseEnter(item?._id)}
                        onMouseLeave={() => handleMouseLeave(item?._id)}
                        style={renderRowStyle(indx, item?._id, isHover, rowId)}
                      >
                        <ScheduleTimeColoumn
                          conference={conference}
                          item={item}
                        />
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(item?._id)}
                        onMouseLeave={() => handleMouseLeave(item?._id)}
                        style={renderRowStyle(indx, item?._id, isHover, rowId)}
                      >
                        <div className="flex-sb">
                          <ScheduleSessionColoumn
                            item={item}
                            conference={conference}
                          />

                          <div className="flex schedule-buttons-container ">
                            <div
                              style={{
                                cursor: 'pointer',
                              }}
                              className="mr-4 schedule-submit-loader-wrap"
                            >
                              <button
                                onClick={() => {
                                  setRowData(item);
                                  setType('SESSION');
                                  setShowFeedback(true);
                                }}
                                style={
                                  isRowHovered
                                    ? { backgroundColor: '#eef1f5' }
                                    : undefined
                                }
                                className="small-button-text small-button-text-primary"
                                disabled={item?.feedbackSubmitted}
                              >
                                {' '}
                                {item?.feedbackSubmitted && (
                                  <span className="mr-4">
                                    <CheckTickIcon className="icon-xs" />
                                  </span>
                                )}
                                {item?.feedbackSubmitted
                                  ? 'Feedback'
                                  : 'Give Feedback'}
                              </button>
                            </div>

                            <div style={{ width: '100%' }}>
                              <ScheduleActionBtnWithLoader
                                handleClick={() => {
                                  setRowData(item);
                                  setType('SESSION');

                                  item?.isLiked
                                    ? unsaveSession(item?._id, 'SESSION')
                                    : saveSession(item?._id, 'SESSION');
                                }}
                                isSubmitting={loadingId === item?._id}
                                icon={
                                  item?.isLiked ? (
                                    <LikeRedIcon className="icon-xs" />
                                  ) : (
                                    <LikeInactiveIcon className="icon-xs" />
                                  )
                                }
                                item={item}
                                text={item?.isLiked ? 'Unsave' : 'Save Event'}
                                isRowHovered={isRowHovered}
                                disabled={item?.disabled}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          {item?.episodes?.map((episode, index) => {
                            const isRowHovered =
                              isHover &&
                              rowId.toString() === episode.session.toString();
                            return (
                              <>
                                <div className="flex-sb ml-32 mt-12">
                                  <EpisodeColoumn
                                    episode={episode}
                                    item={item}
                                    index={index}
                                    conference={conference}
                                  />

                                  <div className="flex schedule-buttons-container">
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      className="mr-4 schedule-submit-loader-wrap"
                                    >
                                      <button
                                        onClick={() => {
                                          setRowData(episode);
                                          setType('LECTURE');
                                          setShowFeedback(true);
                                        }}
                                        style={
                                          isRowHovered
                                            ? { backgroundColor: '#eef1f5' }
                                            : undefined
                                        }
                                        className="small-button-text small-button-text-primary"
                                        disabled={episode?.feedbackSubmitted}
                                      >
                                        {' '}
                                        {episode?.feedbackSubmitted && (
                                          <span className="mr-4">
                                            <CheckTickIcon className="icon-xs" />
                                          </span>
                                        )}
                                        {episode?.feedbackSubmitted
                                          ? 'Feedback'
                                          : 'Give Feedback'}
                                      </button>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      <ScheduleActionBtnWithLoader
                                        handleClick={() => {
                                          setRowData(item);
                                          setType('LECTURE');
                                          episode?.isLiked
                                            ? unsaveSession(
                                                episode?._id,
                                                'LECTURE'
                                              )
                                            : saveSession(
                                                episode?._id,
                                                'LECTURE'
                                              );
                                        }}
                                        isSubmitting={loadingId === episode._id}
                                        icon={
                                          item?.isLiked ? (
                                            <LikeRedIcon className="icon-xs" />
                                          ) : episode?.isLiked ? (
                                            <LikeRedIcon className="icon-xs" />
                                          ) : (
                                            <LikeInactiveIcon className="icon-xs" />
                                          )
                                        }
                                        item={item}
                                        text={
                                          item?.isLiked
                                            ? 'Saved'
                                            : episode?.isLiked
                                            ? 'Unsave'
                                            : 'Save Event'
                                        }
                                        episode={episode}
                                        isRowHovered={isRowHovered}
                                        disabled={episode?.disabled}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
            {showFeedback && (
              <ModalX
                onDismiss={() => setShowFeedback(false)}
                open={showFeedback}
              >
                <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
                  <AddFeedbackForm
                    setSessions={setSessions}
                    setShowFeedbackForm={setShowFeedback}
                    type={type}
                    data={rowData}
                    viewPage={true}
                  />
                </div>
              </ModalX>
            )}
          </div>

          <div className="flex-vc-sb mt-8">
            <div>
              <CustomPagination
                currentPageNumber={activePage}
                setCurrentPage={setActivePage}
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                pageNeighbours={1}
                onPageChanged={(data) => loadPaginationData(data)}
              />
            </div>
            <div>
              <PageLimitSelect
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                activePage={activePage}
                totalRecords={totalRecords}
                loadPaginationData={loadPaginationData}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
