import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OnePlace from "../SVG-assets/OnePlace";
import Wallet from "../SVG-assets/Wallet";
import SetCreditGoals from "../SVG-assets/SetCreditGoals";
import ManageCMECredits from "../SVG-assets/ManageCMECredits";
import GetStarted from "../list-conference/GetStarted";
import "../list-conference/listConferences.scss";

export default function TrackCredits() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className="list-conf-section">
        <div className="list-conf-grid">
          <div style={{ margin: "auto 0" }}>
            <h1 className="mb-16">Keep all CME credits in one place!</h1>
            <div className="caption-2-regular-gray3 mb-32">
              <p className="mb-4">
                1. Conferences booked through Confemy will show CME credits in
                your dashboard.
              </p>
              <p className="mb-4">
                2. Add external CME credits from other conferences to your
                account.
              </p>
              <p className="mb-4">
                3. Keep all CME credits in one location for your records.
              </p>
              <p className="mb-4">
                4. Request CME credits to organizer directly through Confemy.
              </p>
              <p className="mb-4">5. Save CME certificates for future needs.</p>
            </div>
            <button
              className="button button-green"
              onClick={() => navigate("/user-profile/credits")}
            >
              Track CME Credits
            </button>
          </div>
          <div style={{ margin: "auto 0" }}>
            <OnePlace className="list-conf-sketch" />
          </div>
        </div>
      </div>
      <div className="easy-setup-section bg-shade-2">
        <div className="list-conf-grid">
          <div className="easy-setup-text">
            <h1 className="mb-16">Earn CME Credits</h1>
            <div className="caption-2-regular-gray3 mb-32">
              <p className="mb-4">
                Attend conferences to earn more credits. Keep all your credit
                certificates in one place.
              </p>
              <p className="mb-4">
                Add all your credits and keep a record in your account for any
                board requirements.
              </p>
            </div>
          </div>
          <div className="easy-setup-svg-wrap">
            <Wallet className="easy-setup-svg" />
          </div>
        </div>
      </div>
      <div className="easy-setup-section ">
        <div className="list-conf-grid">
          <div className="smart-ticketing-svg-wrap">
            <ManageCMECredits className="smart-ticketing-svg" />
          </div>
          <div className="easy-setup-text-wrap">
            <div>
              <div className="caption-2-regular-gray3 ">
                <h1 className="mb-16">Request CME Credits</h1>
                <p className="mb-4">
                  Request CME credits after attending the conference through
                  attendee dashboard.
                </p>
                <p className="mb-4">
                  Once organizer approves the credits, receive certificate right
                  here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="easy-setup-section bg-shade-2">
        <div className="list-conf-grid">
          <div className="easy-setup-text">
            <h1 className="mb-16">Set Credit Goals</h1>
            <div className="caption-2-regular-gray3 mb-32">
              <p className="mb-4">
                Set credit goals for different credit types. Achieve your goals
                in time by attending conferences on confemy.
              </p>
              <p className="mb-4">
                Keep track of all credits and certificates for records in one
                place in confemy.
              </p>
            </div>
          </div>
          <div className="easy-setup-svg-wrap">
            <SetCreditGoals className="easy-setup-svg" />
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
}
