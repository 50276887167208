import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceIcon from "../../components/icons/PriceIcon";
import WorldIcon from "../../components/icons/WorldIcon";
import DbBookingTable from "../../components/organizer-dashboard/DbBookingTable";
import DbConfsTable from "../../components/organizer-dashboard/DbConfsTable";
import { alertAction } from "../../redux/alert/alertAction";
import { loadAllMyConfsAction } from "../../redux/conference/conferenceAction";
import api from "../../utility/api";
import { addConfStatus } from "../../utility/commonUtil";
import "./organizerDashboard.styles.scss";
import CreditsIcon from "../../components/icons/CreditsIcon";
import CountCard from "../../components/attendee-dashboard/CountCard";
import CreateEventCard from "../../components/organizer-dashboard/CreateEventCard";
import OdEventChart from "../../components/organizer-dashboard/OdEventChart";
import PopularEvent from "../../components/organizer-dashboard/PopularEvent";

export default function OrganizerDashboardPage() {
  const [dashboardCounts, setDashboardCounts] = useState(null);
  const [graphCounts, setGraphCounts] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [events, setEvents] = useState(null);
  const [trendingEvents, setTrendingEvents] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // data for welcome chart

  // let PublishedCount = 0;
  // let DraftCount = 0;
  // let UnpublishedCount = 0;
  // let ExpiredCount = 0;

  // if (myConfs?.length > 0) {
  //   for (const obj of myConfs) {
  //     if (obj.status === 'Published') PublishedCount++;
  //     if (obj.status === 'Draft') DraftCount++;
  //     if (obj.status === 'Unpublished') UnpublishedCount++;
  //     if (obj.status === 'Expired') ExpiredCount++;
  //   }
  // }

  const getGrapsCounts = async (userId) => {
    try {
      const response = await api.get(
        `/organizers/dashboard/counts/users/${userId}`
      );

      setGraphCounts(response.data.data.counts);
    } catch (err) {
      // dispatch(alertAction(error.response.data.message, "error"));
    }
  };

  const getEvents = async (userId) => {
    try {
      const response = await api.get(
        `/organizers/dashboard/events/users/${userId}`
      );

      setEvents(response.data.data.counts);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "error"
        )
      );
    }
  };

  const getDashboardCounts = async (userId) => {
    try {
      const response = await api.get(`homePage/users/${userId}/dashboard`);

      setDashboardCounts(response.data.data.dashboardCounts);
    } catch (err) {
      // dispatch(alertAction(error.response.data.message, "error"));
    }
  };

  const getAllConfs = async (userId) => {
    const url = `/conferences/users/${userId}?getAllOrganizationConferences=true`;
    try {
      const response = await api.get(url);
      if (response) {
        const myConfsWithStatus = response.data.data.conferences.map((conf) =>
          addConfStatus(conf)
        );
        dispatch(loadAllMyConfsAction(myConfsWithStatus));
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const getBookings = async (userId) => {
    const url = `organizers/users/${userId}/bookingDetails?type=org`;
    try {
      const response = await api.get(url);

      setBookings(response.data.data.bookingDetails);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const getTrendingEvents = async () => {
    const url = `/organizers/dashboard/events/trending`;
    try {
      const response = await api.get(url);

      setTrendingEvents(response.data.data.trendingData);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    getDashboardCounts(user?._id);
    getAllConfs(user?._id);
    getBookings(user?._id);
    getGrapsCounts(user?._id);
    getEvents(user?._id);
    getTrendingEvents();
  }, [user?._id]);

  return (
    <>
      <div className="od-content-wrapper mb-20">
        {/* <Welcome /> */}
        <div className="od-content-wrapper2">
          <CreateEventCard />
          <PopularEvent popularEvent={trendingEvents} />
        </div>
        <OdEventChart eventsCount={graphCounts} />
      </div>
      <div className="od-namecard-grid mb-20">
        <div>
          <CountCard
            header="Conferences"
            num={dashboardCounts?.conference?.confCount}
            icon={<WorldIcon className="icon-lg" color="secondary" />}
            path="/dashboard/create-conf/step-1"
            subHeader="Add new"
          />
        </div>
        <div>
          <CountCard
            header="Video Courses"
            num={dashboardCounts?.videoCourse?.videoCount}
            icon={<PriceIcon className="icon-lg" color="warning" />}
            path="/dashboard/create-vc/step-1"
            subHeader="Add new"
          />
        </div>
        <div>
          <CountCard
            header="Audio Course"
            num={dashboardCounts?.audioCourse?.audioCount}
            icon={<PriceIcon className="icon-lg" color="warning" />}
            path="/dashboard/create-ac/step-1"
            subHeader="Add new"
          />
        </div>
        <div>
          <CountCard
            header="Podcast"
            num={dashboardCounts?.podcast?.podcastCount}
            icon={<CreditsIcon className="icon-xl" color="primary" />}
            path="/dashboard/create-pod/step-1"
            subHeader="Add new"
          />
        </div>
      </div>
      <div className="od-section3-grid ">
        <DbConfsTable confs={events} />
        <DbBookingTable bookings={bookings} />
      </div>
    </>
  );
}
