import React, { Fragment } from 'react';

function HalfStarIcon({ className }) {
  return (
    <Fragment>
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="half-grad">
            <stop offset="50%" stopColor="gold" />
            <stop offset="50%" stopColor="gray" />
          </linearGradient>
        </defs>
        <polygon
          points="12,2 15,9 22,9 16.5,14 18.5,21 12,17 5.5,21 7.5,14 2,9 9,9"
          fill="url(#half-grad)"
        />
      </svg>
    </Fragment>
  );
}

export default HalfStarIcon;
