import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import './userTickets.styles.scss';
import { useParams } from 'react-router-dom';
import PrinterIcon from '../icons/PrinterIcon';

import ConferenceReceiptPrint from '../attendee-profile/ConferenceReceiptPrint';
import html2pdf from 'html2pdf.js';
import DownloadIcon from '../icons/DownloadIcon';

const ConferenceReceipt = () => {
  const bookingId = useParams().bookingId;
  const componentRef = useRef();
  const dispatch = useDispatch();

  const [bookingDetails, setBookingDetails] = useState();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Print The Ticket',
  });

  const generateAndSavePDF = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF

    const options = {
      margin: 1,
      filename: 'myDocument.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    await html2pdf().from(element).set(options).save();
  };

  const getBookingDetails = async () => {
    try {
      let response = await api.get(
        `/conferences/bookings/${bookingId}/${bookingId}`
      );
      console.log(response.data.data.bookingDetails);
      setBookingDetails(response.data.data.bookingDetails);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getBookingDetails(bookingId);
  }, [bookingId]);

  return (
    <>
      <div className="container p-64">
        <div className="container-lg ">
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="mt-8 mr-8"
          >
            <div className="flex-vc">
              <button
                className="small-button-text small-button-text-green"
                onClick={() => {
                  handlePrint();
                }}
              >
                <div>
                  <i style={{ paddingTop: 5 }} className="mr-8">
                    <PrinterIcon className="icon-sm" />
                  </i>
                  <span>Print</span>
                </div>
              </button>
              <button
                className="small-button-text small-button-text-green"
                onClick={() => {
                  setTimeout(function () {
                    generateAndSavePDF();
                  }, 5000);
                }}
              >
                <div>
                  <i
                    style={{ paddingTop: 5, color: '#4cb944' }}
                    className="mr-8"
                  >
                    <DownloadIcon className="icon-sm " />
                  </i>
                  <span>Download</span>
                </div>
              </button>
            </div>
            <div style={{ display: 'none' }}>
              <div ref={componentRef} className="print-margins">
                {<ConferenceReceiptPrint bookingDetails={bookingDetails} />}
              </div>
            </div>
          </div>
          {<ConferenceReceiptPrint bookingDetails={bookingDetails} />}
        </div>
      </div>
    </>
  );
};

export default ConferenceReceipt;
