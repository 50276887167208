export default function AmountsTable({
  data,
  setShowAddBankAccountForm,
  setCurrency,
  setAvailableBalance,
  setShowWithdrawForm,
  setAvailableAmountInNumber,
}) {
  return (
    <table key={data?.currency}>
      <thead>
        <tr>
          <th>Currency</th>
          <th>Net Income</th>
          <th>Withdrawn</th>
          <th>Available for withdrawl</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data?.currency}</td>
          <td>{data?.netIncome || 0}</td>
          <td>{data?.withdrawnAmount || 0}</td>
          <td>
            <div> {data?.availableAmount || 0}</div>
            {data?.bankOnboarded ? (
              <div className="mt-4">
                {" "}
                <button
                  onClick={() => {
                    setCurrency(data?.currency);
                    setAvailableAmountInNumber(data?.availableAmountInNumber);
                    setAvailableBalance(data?.availableAmount);
                    setShowWithdrawForm(true);
                  }}
                  style={{
                    cursor:
                      data?.availableAmountInNumber <= 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={data?.availableAmountInNumber <= 0}
                  className="small-button small-button-green"
                >
                  Withdraw
                </button>{" "}
              </div>
            ) : (
              <div className="mt-4">
                <button
                  onClick={() => {
                    setCurrency(data?.currency);
                    setShowAddBankAccountForm(true);
                  }}
                  className="small-button small-button-primary"
                >
                  Add bank to withdraw
                </button>{" "}
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
