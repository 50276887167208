import React from 'react';

function PassesIcon({ className }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 24 24'
    >
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <path
                  d='M0 0L24 0 24 24 0 24z'
                  transform='translate(-1139.000000, -357.000000) translate(1115.000000, 64.000000) translate(0.000000, 272.000000) translate(24.000000, 21.000000)'
                />
                <path
                  fill='#08415C'
                  d='M20 12c0-.76.43-1.42 1.06-1.76.6-.33.94-1.01.94-1.7V6c0-1.1-.9-2-2-2H4c-1.1 0-1.99.89-1.99 1.99v2.55c0 .69.33 1.37.94 1.69C3.58 10.58 4 11.24 4 12s-.43 1.43-1.06 1.76c-.6.33-.94 1.01-.94 1.7V18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-2.54c0-.69-.34-1.37-.94-1.7-.63-.34-1.06-1-1.06-1.76zm-5.5 4.1L12 14.5l-2.5 1.61c-.38.24-.87-.11-.75-.55l.75-2.88-2.3-1.88c-.35-.29-.17-.86.29-.89l2.96-.17 1.08-2.75c.17-.42.77-.42.93 0l1.08 2.76 2.96.17c.45.03.64.6.29.89l-2.3 1.88.76 2.86c.12.45-.37.8-.75.55z'
                  transform='translate(-1139.000000, -357.000000) translate(1115.000000, 64.000000) translate(0.000000, 272.000000) translate(24.000000, 21.000000)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PassesIcon;
