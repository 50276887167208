import { useNavigate } from 'react-router-dom';
import { sidemenuOptions } from './navbarUtil';
import MenuItem from './MenuItem';
import './organizersNavbar.styles.scss';

export default function OrganizerSideNavbar({}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="sidemenu-inner pb-64">
        <h2
          onClick={() => {
            navigate('/dashboard/organizer-dashboard');
          }}
          className="mb-30 p-8"
          style={{
            backgroundColor: '#dceef8',
            fontSize: '18px',
            fontWeight: 900,
            lineHeight: 1.44,
            textAlign: 'center',
            color: '#08415c',
            padding: '25px 12px 25px',
            borderRadius: '6px',
            backgroundColor: '#dceef8',
          }}
        >
          Organizer's Dashboard
        </h2>

        <ul>
          {sidemenuOptions.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </ul>
      </div>
    </>
  );
}

// const MenuItem = ({ item }) => {
//   const Component = hasChildren(item) ? MultiLevel : SingleLevel;
//   return <Component item={item} />;
// };

// const SingleLevel = ({ item }) => {
//   const navigate = useNavigate();
//   const value = useContext(DrawerContext);
//   const setOpenMobileSidemenu = value?.setOpenMobileSidemenu;
//   const openMobileSidemenu = value?.openMobileSidemenu;

//   return (
//     <li
//       onClick={() => {
//         if (openMobileSidemenu) {
//           setOpenMobileSidemenu(false);
//         }
//       }}
//     >
//       <NavLink to={item.path}>
//         {({ isActive }) => (
//           <div
//             className="sidenav-item"
//             style={{
//               ...(isActive && { backgroundColor: "#ecf0f2" }),
//             }}
//           >
//             <i>{item.icon}</i>
//             <div>{item.text}</div>
//           </div>
//         )}
//       </NavLink>
//     </li>
//   );
// };

// const MultiLevel = ({ item }) => {
//   const { items: children } = item;
//   const [open, setOpen] = useState(false);

//   const handleClick = () => {
//     setOpen((prev) => !prev);
//   };

//   return (
//     <>
//       <li onClick={handleClick} className="sidenav-item">
//         <i>{item.icon}</i>
//         <div>{item.text}</div>
//         {open ? (
//           <DropdownIcon className="icon-size" />
//         ) : (
//           <NextIcon className="icon-size" />
//         )}
//       </li>
//       <div className={open ? null : "display-none"}>
//         <ul style={{ paddingLeft: "16px" }}>
//           {children.map((child, key) => (
//             <MenuItem key={key} item={child} />
//           ))}
//         </ul>
//       </div>
//     </>
//   );
// };
