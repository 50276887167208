import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { scheduleSessionFilter } from '../../utility/commonUtil';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import './schedule.scss';
import TranshcanIcon from '../icons/TrashcanIcon';
import { customSelectStyle3 } from '../reselect/selectUtil';
import { getValue } from '../../utility/commonUtil';
import Select from 'react-select';
import ModalX from '../modal/ModalX';
import AddScheduleForm from './AddScheduleForm';
import AddLectureForm from './AddLectureForm';
import ButtonWithLoader from '../button/ButtonWithLoader';
import { alertAction } from '../../redux/alert/alertAction';
import EditIcon from '../icons/EditIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import AddFileIcon from '../icons/AddFileIcon';
import DialogueWithLoader from '../dialogue/DialogueWithLoader';
import PrinterIcon from '../icons/PrinterIcon';
import DownloadIcon from '../icons/DownloadIcon';
import PrintSchedule from './PrintSchedule';
import html2pdf from 'html2pdf.js';
import ScheduleTimeColoumn from './ScheduleTimeColumn';
import ScheduleSessionColoumn from './ScheduleSessionColumn';
import ScheduleAction from './ScheduleAction';
import EpisodeColoumn from './EpisodeColumn';
import ScheduleFilter from './ScheduleFilter';
import ScheduleHeader from './ScheduleHeader';
import Loader from '../loader/Loader';
import { useLocation } from 'react-router-dom';

export default function Schedule() {
  const [searchText, setSearchText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [selectedDate, setSelectedDate] = useState(null);
  const { state } = useLocation();

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLectureForm, setShowLectureForm] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState('');
  const [rowData, setRowData] = useState('');
  const [splicedList, setSplicedList] = useState([]);
  const [sessionDeleteDialogue, setSessionDeleteDialogue] = useState(false);
  const [isDialogueLoading, setIsDialogueLoading] = useState(false);
  const [lectureDeleteDialogue, setLectureDeleteDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch();

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };
  const componentRef = useRef();

  const generateAndSavePDF = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF
    // setIsLoading(true);
    const options = {
      margin: [25.4, 25.4, 25.4, 25.4],
      filename: 'schedule.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2, // Increase scale for better resolution
        scrollX: 0, // Prevent horizontal scroll clipping
        scrollY: 0, // Prevent vertical scroll clipping
        useCORS: true, // Enable CORS for external assets like images
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    await html2pdf().from(element).set(options).save();
    setIsDeleteLoading(false);
  };

  const printData = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF

    const options = {
      margin: [25.4, 10, 25.4, 10], // [top, left, bottom, right] in mm
      filename: 'schedule.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2, // Increase scale for better resolution
        scrollX: 0, // Prevent horizontal scroll clipping
        scrollY: 0, // Prevent vertical scroll clipping
        useCORS: true, // Enable CORS for external assets like images
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        pdf.autoPrint();
        window.open(pdf.output('bloburl'), '_blank');
      });
  };

  const getConferences = async (userId) => {
    try {
      const response = await api.get(`session/allConferences/users/${userId}`);

      setConferences(response.data.data.conferences);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const deleteData = async (url) => {
    try {
      setIsDialogueLoading(true);
      const response = await api.delete(url);

      setSessions(response.data.data.sessions);
      setIsDialogueLoading(false);
      setSessionDeleteDialogue(false);
      setLectureDeleteDialogue(false);
      dispatch(alertAction('Session deleted successfully', 'success'));
      setRowData('');
    } catch (err) {
      setIsDialogueLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    setIsLoading(true);

    const url = `session/conferences/users/${user?._id}?conferenceId=${
      conference?.value ? conference?.value : state?.confId
    }`;

    try {
      const response = await api.get(url);

      if (response) {
        setConference(response.data.data.conference);
        if (!conference?.value) {
          setFilter1(response.data.data.conference.value);
        }
        setSessions(response.data.data.sessions);

        if (response.data.data.conference.value) {
          getHalls(response.data.data.conference.value);
        }

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const changeScheduleStatus = async () => {
    const url = `session/conference/${conference?.value}`;
    try {
      let formData = {
        conferenceDetails: {
          status: !conference?.publishSchedule,
        },
      };
      const response = await api.patch(url, formData);

      if (response) {
        setConference(response.data.data.conference);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getConferences(user?._id);

    getSessions(filter1);
  }, [user._id]);

  useEffect(() => {
    getSessions();
  }, [filter1]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    let finalFilterResult = scheduleSessionFilter(
      venueFilter,
      searchText,
      selectedDate
    );

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);
  return (
    <>
      <div className="myconfs-header mb-16">
        <h1>Schedules</h1>
      </div>
      <div className="mb-32">
        <ScheduleHeader
          conferences={conferences}
          conference={conference}
          setConference={setConference}
          setFilter1={setFilter1}
          filter1={filter1}
          getSessions={getSessions}
        />
      </div>
      <div className="schedule-sort mb-16 mt-64">
        <ScheduleFilter
          setSearchText={setSearchText}
          searchText={searchText}
          filter2={filter2}
          setFilter2={setFilter2}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          halls={halls}
        />

        <ButtonWithLoader
          // isSubmitting={previewLoading}
          text="+ Create Schedule"
          className="button button-green"
          fullWidth={true}
          // icon={<PlusIcon className="icon-xs mt-2" fill="#fff" />}
          handleClick={() => setShowModal(true)}
        />

        <div>
          <ButtonWithLoader
            // isSubmitting={previewLoading}
            text={
              conference?.publishSchedule
                ? 'Unpublish Schedule'
                : 'Publish Schedule'
            }
            className="button button-primary"
            fullWidth={true}
            handleClick={() => changeScheduleStatus()}
          />
        </div>
      </div>
      <div className="flex-sb">
        <div className=" flex-vc mb-4">
          {!conference?.publishSchedule ? (
            <>
              <i className="mr-4">
                <AddFileIcon className="icon-xs" />
              </i>
              <p className="caption-2-regular-gray3">
                To publish the schedule use publish schedule button on top right
                corner of the table.
              </p>
            </>
          ) : (
            <>
              <i className="mr-4">
                <CheckTickIcon className="icon-xs" />
              </i>
              <p className="caption-2-regular-gray3">
                This schedule is PUBLISHED. Any changes you make will be
                published automatically.
              </p>
            </>
          )}
        </div>

        <div className="flex-vc">
          <button
            className="small-button-text small-button-text-green"
            onClick={() => {
              printData();
            }}
          >
            <div className="flex">
              <i className="mr-8">
                <PrinterIcon className="icon-sm " />
              </i>
              <span>Print</span>
            </div>
          </button>

          <div className="schedule-submit-loader-wrap">
            <button
              className="small-button-text small-button-text-green"
              onClick={() => {
                setIsDeleteLoading(true);
                setTimeout(function () {
                  generateAndSavePDF();
                }, 5000);
              }}
            >
              {!isDeleteLoading && (
                <div className="flex">
                  <i style={{ color: '#4cb944' }} className="mr-8">
                    <DownloadIcon className="icon-sm" fill="#3c9436" />
                  </i>
                  <span>Download</span>
                </div>
              )}
            </button>

            {isDeleteLoading && <Loader />}
          </div>
        </div>
      </div>
      <div className="schedule-scrollbar-grid-table-wrap">
        <ul className="schedule-grid-table-2-500 schedule-gridtable">
          <li>Time</li>
          <li>Sessions</li>

          {splicedList.length > 0 &&
            splicedList?.map((item, indx) => {
              let isRowHovered =
                isHover && rowId.toString() === item?._id.toString();
              return (
                <Fragment key={item?._id}>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                  >
                    <ScheduleTimeColoumn conference={conference} item={item} />
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                  >
                    <div className="flex-sb">
                      <ScheduleSessionColoumn
                        item={item}
                        conference={conference}
                        setRowData={setRowData}
                        setShowModal={setShowModal}
                        setSessionDeleteDialogue={setSessionDeleteDialogue}
                        isRowHovered={isRowHovered}
                      />
                      <ScheduleAction
                        item={item}
                        setRowData={setRowData}
                        setShowModal={setShowModal}
                        setSessionDeleteDialogue={setSessionDeleteDialogue}
                        isRowHovered={isRowHovered}
                      />
                    </div>

                    <div>
                      {item?.episodes?.map((episode, index) => {
                        const isRowHovered =
                          isHover &&
                          rowId.toString() === episode.session.toString();

                        return (
                          <>
                            <div className="flex-sb ml-32 mt-12">
                              <EpisodeColoumn
                                episode={episode}
                                item={item}
                                index={index}
                                conference={conference}
                              />
                              <div>
                                <div className="flex-vc">
                                  <i
                                    style={{
                                      cursor: 'pointer',
                                      visibility: 'visible',
                                      visibility: isRowHovered
                                        ? 'visible'
                                        : 'hidden',
                                    }}
                                    className="mr-4"
                                    onClick={() => {
                                      setRowData(episode);
                                      setSession(item);
                                      setShowLectureForm(true);
                                    }}
                                  >
                                    <EditIcon
                                      className="icon-xs"
                                      fill="#08415c"
                                    />
                                  </i>
                                  <i
                                    style={{
                                      cursor: 'pointer',
                                      visibility: isRowHovered
                                        ? 'visible'
                                        : 'hidden',
                                    }}
                                    className="mr-4 ml-12"
                                    onClick={() => {
                                      setRowData(episode);
                                      setSession(item);
                                      setLectureDeleteDialogue(true);
                                    }}
                                  >
                                    <TranshcanIcon className="icon-xs" />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div className="ml-32 mt-16 mb-16">
                        <button
                          onClick={() => {
                            setSession(item);
                            setShowLectureForm(true);
                          }}
                          className="small-button-outlined small-button-outlined-primary"
                        >
                          + Add Lecture
                        </button>
                      </div>
                    </div>
                  </li>
                </Fragment>
              );
            })}
        </ul>
        {showModal && (
          <ModalX onDismiss={() => setShowModal(false)} open={showModal}>
            <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
              <AddScheduleForm
                setShowModal={setShowModal}
                page={activePage}
                setCount={setTotalRecords}
                filter={setFilter1}
                searchText={searchText}
                conference={conference}
                setSessions={setSessions}
                rowData={rowData}
                setRowData={setRowData}
              />
            </div>
          </ModalX>
        )}

        {showLectureForm && (
          <ModalX
            onDismiss={() => setShowLectureForm(false)}
            open={showLectureForm}
          >
            <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
              <AddLectureForm
                editMode={false}
                setShowModal={setShowModal}
                page={activePage}
                setCount={setTotalRecords}
                filter={setFilter1}
                searchText={searchText}
                conference={conference}
                setSessions={setSessions}
                session={session}
                setShowLectureForm={setShowLectureForm}
                rowData={rowData}
                setRowData={setRowData}
              />
            </div>
          </ModalX>
        )}
      </div>
      {sessionDeleteDialogue && (
        <DialogueWithLoader
          msg="Are you sure you want to delete this session?"
          title="Delete session"
          closeDialogue={() => {
            setSessionDeleteDialogue(false);
          }}
          previewLoading={isDialogueLoading}
          yesAction={() => deleteData(`/session/${rowData?._id}`)}
        />
      )}
      {lectureDeleteDialogue && (
        <DialogueWithLoader
          msg="Are you sure you want to delete this lecture?"
          title="Delete lecture"
          closeDialogue={() => {
            setLectureDeleteDialogue(false);
          }}
          previewLoading={isDialogueLoading}
          yesAction={() =>
            deleteData(`/session/${session?._id}/episodes/${rowData?._id}`)
          }
        />
      )}
      <div className="flex-vc-sb mt-8">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="print-margins">
            {<PrintSchedule sessions={sessions} conference={conference} />}
          </div>
        </div>
      </div>
    </>
  );
}
