export default function PrinterIcon({ className, fill }) {
  return (
    <svg
      enableBackground="new 0 0 50 50"
      className={className}
      width="50px"
      height="50px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect fill="none" height="50" width="50" />
      <circle cx="43" cy="21" r="2" fill="#4cb944" />
      <path
        d="M40,15V1H10v14"
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M40,29v20H10V29H40z"
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M10,40H3  c-1.104,0-2-0.896-2-2V17c0-1.104,0.896-2,2-2h44c1.104,0,2,0.896,2,2v21c0,1.104-0.896,2-2,2h-7"
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="35"
        x2="15"
        y1="35"
        y2="35"
      />
      <line
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="31"
        x2="15"
        y1="39"
        y2="39"
      />
      <line
        fill="none"
        stroke="#4cb944"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="35"
        x2="15"
        y1="43"
        y2="43"
      />
    </svg>
  );
}
