export default function EmptyGoalIcon({ className, fill }) {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 12 9.5-9.5" stroke="#9F9F9F" stroke-width="1.6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.33 5.841A6.97 6.97 0 0 0 12 5a7 7 0 1 0 6.159 3.67l-1.197 1.196a5.4 5.4 0 1 1-2.828-2.828L15.33 5.84z"
        fill="#9F9F9F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.515 3.657A9.958 9.958 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10a9.955 9.955 0 0 0-1.657-5.515l-1.159 1.16A8.354 8.354 0 0 1 20.4 12 8.4 8.4 0 1 1 12 3.599a8.358 8.358 0 0 1 4.356 1.216l1.159-1.16z"
        fill="#9F9F9F"
      />
    </svg>
  );
}
