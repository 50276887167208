import React from 'react';

function MediaDetailVideoIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m15.75 8.387 6-3.465V19.07l-6-3.465v2.141H2.25v-11.5h13.5v2.14zm0 1.732v3.754l4.5 2.599V7.52l-4.5 2.599zm-12 6.127v-8.5h10.5v8.5H3.75z"
        fill="#08415C"
      />
    </svg>
  );
}

export default MediaDetailVideoIcon;
