function SmartTicketing({ className }) {
  return (
    <svg
      className={className}
      width="271px"
      height="206px"
      viewBox="0 0 271 206"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>plane-ticket@2x</title>
      <g
        id="Creatives"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="plane-ticket" fillRule="nonzero">
          <path
            d="M241.934441,139.739228 C241.865236,140.444946 241.824591,141.162899 241.824591,141.891974 C241.824591,152.793626 250.260518,161.709366 260.880262,162.309422 C261.080739,162.320544 261.481142,162.327217 261.835957,162.330554 C262.203954,162.333335 262.5,162.636421 262.5,163.009023 L262.5,186.571893 C262.5,197.301703 253.909185,206 243.31196,206 L27.6885894,206 C17.0913647,206 8.5,197.301703 8.5,186.571893 L8.5,164.852009 C8.5,163.477277 9.5067748,162.325549 10.850789,162.135356 C11.853719,161.993545 12.8330313,161.777213 13.7843319,161.490255 C22.1340267,158.975473 28.2186155,151.157516 28.2186155,141.892531 C28.2186155,136.845727 26.4148793,132.231031 23.4242143,128.671853 C23.4126801,128.654613 23.3956534,128.631256 23.3725849,128.61346 C20.2539448,124.915252 15.8478632,122.358761 10.8573799,121.64915 C9.51061955,121.457288 8.5,120.30834 8.5,118.930827 L8.5,100.428107 C8.5,89.6982974 17.0908154,81 27.6880402,81 L243.316903,81 C253.911382,81 262.5,89.6955168 262.5,100.422546 L262.5,120.779932 C262.5,121.150865 262.19956,121.454507 261.83376,121.453951 C261.711827,121.453951 261.607469,121.453951 261.55584,121.455063 C251.338696,121.690303 242.984607,129.607805 241.934441,139.739228 L241.934441,139.739228 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <path
            d="M262.5,101.057536 L262.5,121.029642 C262.5,121.395252 262.204504,121.692105 261.836506,121.695379 C261.470706,121.698653 261.052178,121.705747 260.842913,121.717752 C250.950925,122.283629 242.96044,129.927598 241.934991,139.637533 L43.9727462,196.250814 C33.7852617,199.164777 23.159476,193.319935 20.2264823,183.204009 L13.7843319,160.979857 C22.1340267,158.512265 28.2186155,150.841012 28.2186155,141.749884 C28.2186155,136.797784 26.4148793,132.269682 23.4242143,128.777292 C23.4126801,128.760375 23.3956534,128.737456 23.3725849,128.719995 C20.2539448,125.091183 15.8478632,122.582665 10.8573799,121.886369 C9.51061955,121.698108 8.5,120.57072 8.5,119.219056 L8.5,101.063539 C8.5,90.535074 17.0908154,82 27.6880402,82 L243.316903,82 C253.911382,82 262.5,90.5323456 262.5,101.057536 Z"
            id="Path"
            fill="#052636"
          ></path>
          <path
            d="M256.570779,120.196258 L194.757095,138.050927 L186.811354,140.345658 L48.686589,180.240806 C38.4706352,183.193545 27.8083533,177.271479 24.8658941,167.019836 L19.0228534,146.649257 C18.591403,145.145241 19.3060789,143.540587 20.7205532,142.881474 C29.6179106,138.732711 34.4272334,128.591104 31.626938,118.83656 C28.8338058,109.089758 19.4008548,103.064291 9.67365776,104.286858 C8.1291422,104.480942 6.66342284,103.565261 6.23252338,102.064562 L1.25458775,84.7080993 C-1.6823612,74.4564561 4.21357761,63.7513961 14.4295314,60.804187 L152.554296,20.9090384 L160.500038,18.614307 L222.313722,0.759638322 C232.529675,-2.19310033 243.191957,3.72343592 246.134416,13.9750791 L251.427537,32.4280368 C251.686517,33.3309998 251.195005,34.2804103 250.308962,34.5834254 C249.773919,34.766451 249.228958,34.9633002 248.891732,35.1114901 C239.726027,39.1369465 234.722193,49.448861 237.566019,59.355465 C240.516193,69.6330967 250.843453,75.7752355 261.113958,73.6463883 C261.207632,73.6270351 261.328857,73.5988348 261.46551,73.565105 C262.382963,73.3395025 263.316394,73.8775203 263.577579,74.7882245 L269.745172,96.2917925 C272.682672,106.543989 266.786733,117.243519 256.570779,120.196258 Z"
            id="Path"
            fill="#4CB944"
          ></path>
          <path
            d="M157.113112,21 L191.5,140.260582 L48.8678665,181.242587 C38.6140784,184.188724 27.9089666,178.279334 24.9576632,168.043495 L19.0932199,147.703775 C18.6601482,146.201995 19.3775096,144.59973 20.7972989,143.941597 C29.728642,139.798454 34.5554832,129.671935 31.7468778,119.929696 C28.9399317,110.195186 19.4720885,104.181996 9.70889378,105.399983 C8.15857456,105.593227 6.68734762,104.678909 6.25538211,103.180442 L1.25874144,85.8509057 C-1.69256193,75.6150667 4.22719069,64.928694 14.4809788,61.9825568 L157.113112,21 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <g
            id="Group"
            transform="translate(42.500000, 63.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M54.7518037,43.2652103 L52.8554833,76.7682557 C52.7881291,77.9549396 53.900294,78.8535397 55.0294342,78.5244593 L64.2323108,75.8447253 C64.8127611,75.6757531 65.2612412,75.2087248 65.4118298,74.6175989 L74.7225824,38.0697286 L54.7518037,43.2652103 Z"
              id="Path"
            ></path>
            <path
              d="M51.8194341,32.9584561 L32.6087168,5.60156914 C31.9280566,4.63261014 32.3978929,3.27362991 33.5270331,2.9445495 L42.7299097,0.264815552 C43.31036,0.0958432856 43.9351656,0.249857351 44.373789,0.669794984 L71.464394,26.617287 L51.8194341,32.9584561 Z"
              id="Path"
            ></path>
            <path
              d="M13.1723905,43.5926287 C13.1723905,43.5926287 75.0144504,21.8639033 82.3719328,19.7215565 C89.7294152,17.5792098 97.9948096,25.0921046 97.9948096,25.0921046 C97.9948096,25.0921046 94.9419692,35.9013436 87.5844868,38.0436903 C80.2270044,40.186037 16.4300314,55.0439623 16.4300314,55.0439623 L10.0998367,57.2411558 C9.33156129,57.5076334 8.48443229,57.1890791 8.07428386,56.4799496 L0.455051196,43.2918027 C-0.310486195,41.9666169 0.887103415,40.3660894 2.3557524,40.7516786 L13.1723905,43.5926287 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M164.161905,117.457479 L172.5,145.455906 L148.976022,152 L140.637927,124.001573 C140.101326,122.199321 141.177943,120.317324 143.042676,119.798702 L159.813895,115.133306 C161.678629,114.614684 163.625303,115.655227 164.161905,117.457479 L164.161905,117.457479 Z"
            id="Path"
            fill="#4CB944"
          ></path>
          <path
            d="M231.485727,188 L192.514273,188 C190.849277,188 189.5,186.631862 189.5,184.943591 L189.5,175.056409 C189.5,173.368138 190.849277,172 192.514273,172 L231.485727,172 C233.150179,172 234.5,173.368138 234.5,175.056409 L234.5,184.943591 C234.5,186.631862 233.150179,188 231.485727,188 Z"
            id="Path"
            fill="#4CB944"
          ></path>
          <g
            id="Group"
            transform="translate(152.500000, 19.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M43.4749797,119.425739 L35.3900015,121.715924 L33.7640351,116.147738 C33.1192552,113.951367 34.4088149,111.655663 36.6403137,111.026551 C38.8662057,110.391922 41.2042334,111.661181 41.8434064,113.857552 L43.4749797,119.425739 Z"
              id="Path"
            ></path>
            <path
              d="M36.2590526,94.7579545 L33.1753231,84.2010258 C32.5305432,82.0046548 30.1981224,80.7353952 27.9722303,81.370025 C25.7407315,81.9991363 24.4511719,84.2948406 25.0903449,86.4912116 L28.1796812,97.0481402 C28.7067186,98.8637333 30.3887529,100.044697 32.2165635,100.044697 C32.6034314,100.044697 32.9959061,99.9895114 33.382774,99.879141 C35.6142728,99.2500297 36.9038324,96.9543254 36.2590526,94.7579545 L36.2590526,94.7579545 Z M27.5909692,65.1014282 L24.5016329,54.5444995 C23.8568531,52.3481285 21.530039,51.0843875 19.2985401,51.7134987 C17.0670413,52.3481285 15.7774817,54.6383143 16.4222615,56.8346853 L19.505991,67.391614 C20.0386352,69.207207 21.7206695,70.3881703 23.5428733,70.3881703 C23.9297412,70.3881703 24.3222159,70.3329851 24.7090838,70.2226147 C26.9405826,69.5935034 28.2301422,67.2977991 27.5909692,65.1014282 Z M18.917279,35.4449019 L15.8279427,24.8934917 C15.1887697,22.6971208 12.850742,21.4278612 10.62485,22.0569724 C8.39335113,22.6916022 7.1037915,24.9873065 7.74857131,27.1836775 L10.8323009,37.7350877 C11.364945,39.5506807 13.0469793,40.731644 14.8747899,40.731644 C15.256051,40.731644 15.6485257,40.6819773 16.0353936,40.5716069 C18.2668924,39.9369771 19.5564521,37.6412728 18.917279,35.4449019 L18.917279,35.4449019 Z"
              id="Shape"
            ></path>
            <path
              d="M7.36731021,10.9150806 C6.98044232,11.025451 6.58796765,11.0751177 6.20109976,11.0751177 C4.37328916,11.0751177 2.69125487,9.89415444 2.16421746,8.0840799 L0.532644193,2.51589325 L8.61762237,0.225707467 L10.2435888,5.79389411 C10.8883687,7.99026506 9.59880904,10.2804508 7.36731021,10.9150806 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M221.837808,100 C219.95288,100 218.217798,98.8739277 217.670487,97.1443018 C217.007464,95.0494917 218.33698,92.8631224 220.640202,92.2600949 L243.959958,86.1550994 C246.257973,85.552072 248.666491,86.7607579 249.329513,88.8560942 C249.992536,90.9509044 248.66302,93.1372736 246.359798,93.7403011 L223.040042,99.8452966 C222.639683,99.9500108 222.235852,100 221.837808,100 L221.837808,100 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <path
            d="M225.837808,115 C223.95288,115 222.217798,113.873928 221.670487,112.143776 C221.007464,110.049492 222.33698,107.862596 224.640202,107.259569 L247.959958,101.155099 C250.258551,100.552072 252.666491,101.760758 253.329513,103.856094 C253.992536,105.950378 252.66302,108.137274 250.359798,108.740301 L227.040042,114.84477 C226.639104,114.950011 226.234695,115 225.837808,115 L225.837808,115 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <path
            d="M45.6742841,160 C43.8604134,160 42.1907393,158.840086 41.6640598,157.05848 C41.0260316,154.900715 42.3054285,152.648639 44.5218248,152.027489 L76.1688026,143.159567 C78.380745,142.538959 80.6984687,143.783428 81.3359402,145.941735 C81.9739684,148.0995 80.6945715,150.351576 78.4781752,150.972726 L46.8311974,159.840647 C46.4453741,159.948508 46.056767,160 45.6742841,160 L45.6742841,160 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <path
            d="M169.303952,184 L124.696048,184 C122.378151,184 120.5,182.209067 120.5,180 C120.5,177.790933 122.378151,176 124.696048,176 L169.303952,176 C171.621849,176 173.5,177.790933 173.5,180 C173.5,182.209067 171.621849,184 169.303952,184 Z"
            id="Path"
            fill="#4CB944"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SmartTicketing;
