import { useNavigate } from "react-router-dom";

export default function SeeAll() {
  const navigate = useNavigate();
  return (
    <div className="see-all-button">
      <button
        onClick={() => {
          navigate("/search-conference");
        }}
        className="button button-primary"
      >
        See all
      </button>
    </div>
  );
}
