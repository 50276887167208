export default function RadioFilledIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      //   width="24"
      //   height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-38.000000, -189.000000) translate(38.000000, 189.000000)">
            <path d="M0 0L24 0 24 24 0 24z" />
            <path
              fill="#08415C"
              fillRule="nonzero"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
            <circle cx="12" cy="12" r="5" fill="#08415C" fillRule="nonzero" />
          </g>
        </g>
      </g>
    </svg>
  );
}
