import { useState } from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { hasChildren } from './navbarUtil';
import DropdownIcon from '../icons/DropdownIcon';
import NextIcon from '../icons/NextIcon';
import { DrawerContext } from '../../pages/layout-pages/drawerContext';

const SingleLevel = ({ item }) => {
  const value = useContext(DrawerContext);
  const setOpenMobileSidemenu = value?.setOpenMobileSidemenu;
  const openMobileSidemenu = value?.openMobileSidemenu;

  return (
    <li
      onClick={() => {
        if (openMobileSidemenu) {
          setOpenMobileSidemenu(false);
        }
      }}
    >
      <NavLink to={item.path}>
        {({ isActive }) => (
          <div
            className="sidenav-item"
            style={{
              ...(isActive && { backgroundColor: '#ecf0f2' }),
            }}
          >
            <i>{item.icon}</i>
            <div>{item.text}</div>
          </div>
        )}
      </NavLink>
    </li>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <li onClick={handleClick} className="sidenav-item">
        <i>{item.icon}</i>
        <div>{item.text}</div>
        {open ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </li>
      <div className={open ? null : 'display-none'}>
        <ul style={{ paddingLeft: '16px' }}>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </ul>
      </div>
    </>
  );
};

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

export default MenuItem;
