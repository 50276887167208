import Loader from '../loader/Loader';

export default function ScheduleActionBtnWithLoader({
  isSubmitting,
  text,
  handleClick,
  icon,
  item,
  disabled,
  isRowHovered,
}) {
  return (
    <div className="schedule-submit-loader-wrap">
      <button
        onClick={() => handleClick()}
        disabled={disabled}
        style={{
          ...(isRowHovered && {
            backgroundColor: '#eef1f5',
          }),
          ...(disabled && {
            cursor: 'not-allowed',
          }),
        }}
        className="small-button-text small-button-text-primary"
      >
        {!isSubmitting && (
          <div className="flex-vchc">
            <i
              style={{
                ...(icon && {
                  marginRight: 4,
                  position: 'relative',
                  paddingTop: 5,
                }),
              }}
            >
              {icon}
            </i>
            <span>{text}</span>
          </div>
        )}
      </button>
      {isSubmitting && <Loader />}
    </div>
  );
}
