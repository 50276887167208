import AttendeeBookings from "../../components/attendee-profile/AttendeeBookings";

export default function AttendeeBookingsPage() {
  return (
    // No need for container, layout has those
    <>
      <AttendeeBookings />
    </>
  );
}
