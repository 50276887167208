import { useState } from "react";
import PropTypes from "prop-types";
import "./pagination.scss";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};
export default function PaginationForSearch(props) {
  const { currentPageNumber, setCurrentPage } = props;
  //   const [currentPageNumber, setCurrentPage] = useState({ currentPage: 1 });

  let totalRecordsCount = null;
  let totalPagesCount = null;
  let pageNeighboursCount = null;
  let pageLimitCount = null;
  let { totalRecords, pageLimit = 9, pageNeighbours = 0 } = props;

  pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
  pageLimitCount = pageLimit;
  totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

  totalRecordsCount = totalRecords;
  // pageNeighbours can be: 0, 1 or 2
  pageNeighbours =
    typeof pageNeighbours === "number"
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0;
  pageNeighboursCount = pageNeighbours;

  let totalPages = Math.ceil(totalRecords / pageLimit);
  totalPagesCount = totalPages;

  const gotoPage = (page) => {
    const { onPageChanged = (f) => f } = props;
    const currentPageCount = Math.max(0, Math.min(page, totalRecordsCount));
    const paginationData = {
      currentPage: currentPageCount,
      totalPages: totalPagesCount,
      pageLimit: pageLimitCount,
      totalRecords: totalRecordsCount,
    };

    setCurrentPage({ currentPage: currentPageCount });
    onPageChanged(paginationData);
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPageNumber.currentPage - pageNeighboursCount * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPageNumber.currentPage + pageNeighboursCount * 2 + 1);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const { currentPage } = currentPageNumber;
  const fetchPageNumbers = () => {
    const totalPages = totalPagesCount;
    const currentPage = currentPageNumber.currentPage;
    const pageNeighbours = pageNeighboursCount;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;

    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecordsCount || totalPagesCount === 1) return null;
  // if (!totalRecordsCount) return null;

  const pages = fetchPageNumbers();

  return (
    <div className="pagination caption-2-regular-gray3">
      {pages.map((page, index) => {
        if (page === LEFT_PAGE)
          return (
            <span key={page} onClick={handleMoveLeft}>
              &laquo;
            </span>
          );

        if (page === RIGHT_PAGE)
          return (
            <span key={page} onClick={handleMoveRight}>
              &raquo;
            </span>
          );

        return (
          <span
            className={`page-item${currentPage === page ? " active" : ""}`}
            onClick={(e) => handleClick(page, e)}
            key={page}
          >
            {page}
          </span>
        );
      })}
    </div>
  );
}

PaginationForSearch.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};
