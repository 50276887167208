import React, { Fragment } from 'react';

function GlobeSketch({ className }) {
  return (
    <Fragment>
      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 94 135'
      >
        <g fill='none' fillRule='evenodd'>
          <g fillRule='nonzero'>
            <g>
              <path
                fill='#08415C'
                d='M88 98.537c.004 1.522-.737 2.947-1.979 3.803-7.826 5.428-16.917 8.686-26.369 9.45v.023c-1.518.117-3.06.187-4.602.187-1.541 0-3.083-.07-4.601-.21-29.426-2.59-51.632-28.2-50.4-58.126C1.28 23.739 25.515.094 55.05 0h2.301c2.542 0 4.602 2.09 4.602 4.667s-2.06 4.666-4.602 4.666h-2.3c-19.745-.015-37.298 12.744-43.626 31.71C5.097 60.008 11.404 80.954 27.1 93.1c15.697 12.145 37.284 12.784 53.652 1.587 1.41-1.005 3.255-1.127 4.781-.316 1.527.81 2.479 2.418 2.467 4.166z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#08415C'
                d='M91.353 49.066H82.75c-2.55-.007-4.617-2.074-4.625-4.625v-3.608c-.004-1.993-1.279-3.76-3.168-4.394-2.252-.747-3.772-2.854-3.77-5.227v-8.488C66.147 20.266 60.61 18.993 55.002 19c-1.547-.005-3.092.096-4.625.301v8.257c0 2.637-1.048 5.167-2.913 7.032-1.864 1.865-4.394 2.912-7.03 2.912-2.188-.014-4.133 1.39-4.81 3.47-.697 2.076-2.644 3.473-4.834 3.469H19.851c-4.567 13.897-.52 29.181 10.328 38.995 10.847 9.815 26.457 12.316 39.827 6.384C83.376 83.887 91.996 70.633 92 56.004c-.004-2.327-.22-4.65-.647-6.938zm-24.79 9.922c.002 1.077-.251 2.14-.74 3.099l-7.33 14.686c-.893 1.773-2.885 2.702-4.817 2.245-1.93-.457-3.296-2.18-3.3-4.165v-11.91c0-1.278-1.036-2.313-2.313-2.313-3.831 0-6.937-3.106-6.937-6.938s3.106-6.939 6.937-6.939h11.563c3.831 0 6.937 3.107 6.937 6.939v5.296z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#4CB944'
                d='M50 20v8.21c0 5.463-4.375 9.89-9.773 9.89-2.15-.014-4.061 1.382-4.727 3.45-.685 2.065-2.598 3.454-4.75 3.45H20c4.392-13.496 16.07-23.227 30-25zM91 50h-8.532c-2.53-.008-4.58-2.126-4.587-4.741v-3.698c-.005-2.042-1.269-3.854-3.142-4.504-2.234-.765-3.742-2.925-3.739-5.357V23c10.415 5.234 17.829 15.242 20 27z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#08415C'
                d='M59 112.024V122h-9v-10c1.485.147 2.992.22 4.5.22s3.015-.073 4.5-.196z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#08415C'
                d='M76 132.667c0 1.288-1.045 2.333-2.333 2.333H36.333c-1.288 0-2.333-1.045-2.333-2.333-.01-3.097 1.217-6.07 3.407-8.26 2.19-2.19 5.163-3.416 8.26-3.407h18.666C70.777 121 76 126.223 76 132.667z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#08415C'
                d='M63.75 121H57c6.213 0 11.25 5.223 11.25 11.667 0 1.288-1.007 2.333-2.25 2.333h6.75c1.243 0 2.25-1.045 2.25-2.333C75 126.223 69.963 121 63.75 121z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <path
                fill='#4CB944'
                d='M66 54.071v5.398c.002 1.098-.247 2.18-.727 3.158l-7.205 14.968c-.877 1.806-2.835 2.753-4.733 2.287-1.898-.465-3.24-2.22-3.244-4.244V63.5c0-1.301-1.018-2.357-2.273-2.357-3.765 0-6.818-3.166-6.818-7.07C41 50.165 44.053 47 47.818 47h11.364C62.947 47 66 50.166 66 54.071z'
                transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000)'
              />
              <g>
                <path
                  d='M.904 40.42c0 21.817 17.466 39.504 39.01 39.504 21.545 0 39.01-17.687 39.01-39.504C78.925 18.602 61.46.916 39.915.916 18.38.94.928 18.612.904 40.42zM33.03 6.26v5.577c.005 1.51-.436 2.987-1.266 4.241-1.403 2.14-3.77 3.427-6.306 3.428-3.16-.02-5.97 2.032-6.95 5.075-.38 1.137-1.435 1.9-2.62 1.896h-7.5C12.88 16.092 22.065 8.557 33.03 6.26zm6.884 69.016c-10.76.035-20.913-5.045-27.425-13.72-6.511-8.675-8.627-19.941-5.715-30.431h9.115c3.169.004 5.983-2.05 6.98-5.096.368-1.13 1.415-1.888 2.589-1.876 3.225 0 6.319-1.297 8.6-3.607 2.28-2.31 3.562-5.442 3.562-8.709V5.682c.762-.051 1.519-.119 2.294-.119 4.74 0 9.43.998 13.769 2.93v7.016c-.01 3.38 2.125 6.386 5.294 7.45.944.318 1.583 1.21 1.59 2.217V28.8c0 3.85 3.082 6.971 6.884 6.971h6.542c1.403 9.975-1.555 20.073-8.1 27.66-6.545 7.588-16.03 11.912-25.979 11.844zm33.14-44.151h-5.603c-1.267 0-2.295-1.04-2.295-2.324v-3.625c-.008-3.004-1.907-5.668-4.72-6.623-1.295-.434-2.168-1.662-2.164-3.044v-4.524c7.22 4.628 12.487 11.805 14.783 20.14z'
                  transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000) translate(15.000000, 16.000000)'
                />
                <path
                  d='M44.504 28.8H33.03c-5.069 0-9.179 4.162-9.179 9.296 0 5.133 4.11 9.295 9.18 9.295v11.958c-.001 3.076 2.088 5.749 5.044 6.455 2.957.707 6.005-.738 7.363-3.49l7.276-14.737c.636-1.29.967-2.713.969-4.155v-5.326c0-5.134-4.11-9.295-9.18-9.295zm4.59 14.622c-.001.721-.167 1.432-.485 2.077l-7.276 14.735c-.406.824-1.318 1.256-2.203 1.045-.884-.21-1.51-1.01-1.51-1.93V47.391c0-2.567-2.055-4.648-4.59-4.648-2.534 0-4.59-2.08-4.59-4.647s2.056-4.648 4.59-4.648h11.474c2.535 0 4.59 2.081 4.59 4.648v5.326z'
                  transform='translate(-309.000000, -958.000000) translate(309.000000, 958.000000) translate(15.000000, 16.000000)'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Fragment>
  );
}

export default GlobeSketch;
