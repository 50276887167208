import { useNavigate } from 'react-router-dom';
import CourseCardWithOptions from '../attendee-video-courses/CourseCardWithOptions';
import '../attendee-video-courses/purchasevideolist.scss';
import { scrollToTop } from '../../utility/commonUtil';
import { useEffect } from 'react';
// import css from "./MyVideoCourses.module.css";
// import shareIcon from "./video-course-icons/share.png";
// import starIcon from "./video-course-icons/star-b.png";

export default function PurchasedAudioCourses({ courses, type }) {
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="purchase-video-con flex flex-wrap" style={{ gap: '2rem' }}>
      {courses.map((course) => {
        return (
          <CourseCardWithOptions
            Key={course._id}
            data={course}
            isOptions={true}
            openPage={() => navigate(`/view/audio-courses/${course._id}`)}
            id={course?._id}
            type={type}
          />
        );
      })}
    </div>
  );
}
