import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LocationIcon from "../icons/LocationIcon";
import api from "../../utility/api";
import "./locationDropdown.styles.scss";
import { initialFilterValues, initialDisplay } from "../../utility/commonUtil";
import VideoIcon from "../icons/VideoIcon";
import MikeIcon from "../icons/MikeIcon";

export default function LocationDropdown() {
  const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
  const [topCities, setTopCities] = useState([]);
  const [searchText, setSearchText] = useState("");
  const userLocation = useSelector((state) => state.userLocation.userLocation);

  const location = useLocation();

  const onDropdownClick = () => {
    setOpenLocationDropdown(!openLocationDropdown);
  };

  const getTopCities = async () => {
    const url = `venues/locations?country=${userLocation?.country_name}`;

    try {
      const res = await api.get(url);
      if (res) {
        setTopCities(res.data.data.venues);
      }
    } catch (err) {}
  };

  const ref = useRef();
  const navigate = useNavigate();

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) {
        return;
      }
      setOpenLocationDropdown(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });
    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    if (userLocation?.country_name) {
      getTopCities();
    }
  }, [userLocation?.country_name]);

  return (
    <div ref={ref}>
      <div
        className="flex-vc"
        onClick={() => {
          if (location.pathname !== "/search-conference") onDropdownClick();
        }}
      >
        <LocationIcon className="icon-sm " />
        <span className="location-text">Location</span>
      </div>
      <div
        className={openLocationDropdown ? "location-dropdown" : "display-none"}
      >
        <div className="mb-12 mt-12">
          <div
            style={
              location.pathname === "/search-conference"
                ? { cursor: "default" }
                : { cursor: "pointer" }
            }
            className="location-item flex-vc"
            onClick={() => {
              setOpenLocationDropdown(false);
              if (location.pathname !== "/search-conference") {
                // loadSearchResults("", initialFilterValues, "livestreamConfs", 1);
                window.localStorage.setItem("CS_MODE", JSON.stringify("video"));
                navigate(
                  "/search-conference"
                  // {
                  //   reset: true,
                  //   resetMode: true,
                  //   state: { mode: "livestreamConfs" },
                  // }
                );
              }
            }}
          >
            <i className="round-icon-wrap mr-16">
              <VideoIcon className="icon-sm" />
            </i>
            <span className="body-regular-gray3">Video Courses</span>
          </div>
          <div
            onClick={() => {
              setOpenLocationDropdown(false);
              if (location.pathname !== "/search-conference") {
                window.localStorage.setItem("CS_MODE", JSON.stringify("audio"));
                navigate("/search-conference");
              }
            }}
            className="location-item flex-vc"
            style={
              location.pathname === "/search-conference"
                ? { cursor: "default" }
                : { cursor: "pointer" }
            }
          >
            <i
              className="round-icon-wrap mr-16"
              style={{ backgroundColor: "rgba(93, 213, 239, 0.29)" }}
            >
              <MikeIcon />
            </i>
            <span className="body-regular-gray3">Audio Courses</span>
          </div>
          <div
            onClick={() => {
              setOpenLocationDropdown(false);
              if (location.pathname !== "/search-conference") {
                window.localStorage.setItem(
                  "CS_MODE",
                  JSON.stringify("podcast")
                );
                navigate("/search-conference");
              }
            }}
            className="location-item flex-vc"
            style={
              location.pathname === "/search-conference"
                ? { cursor: "default" }
                : { cursor: "pointer" }
            }
          >
            <i
              className="round-icon-wrap mr-16"
              style={{ backgroundColor: " #ffcc9c" }}
            >
              <MikeIcon />
            </i>
            <span className="body-regular-gray3">Podcasts</span>
          </div>
        </div>
        <div className="ld-topcities">Conferences in Top Cities</div>
        <div className="ld-searchbox">
          <div className="form-type-2">
            <input
              type="text"
              name="searchText"
              value={searchText}
              onChange={onSearchChange}
              placeholder="Enter city"
            />
          </div>
        </div>
        <div className="ld-citieswrap">
          {topCities
            .filter((city) =>
              city?.label?.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((city) => {
              return (
                <div
                  onClick={() => {
                    setOpenLocationDropdown(false);
                    if (location.pathname !== "/search-conference") {
                      window.localStorage.setItem(
                        "CS_FILTERS",
                        JSON.stringify({
                          ...initialFilterValues,
                          location: city,
                        })
                      );
                      window.localStorage.setItem(
                        "CS_DISPLAY",
                        JSON.stringify({
                          ...initialDisplay,
                          openLocation: true,
                        })
                      );

                      navigate("/search-conference");
                    }
                  }}
                  style={
                    location.pathname === "/search-conference"
                      ? { cursor: "default" }
                      : { cursor: "pointer" }
                  }
                  className="location-item"
                  key={city.value}
                >
                  {city.label}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
