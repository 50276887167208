import leftArrowIcon from '../../video-course/video-course-icons/videoPlayer/left-arrow.png';
import rightArrowIcon from '../../video-course/video-course-icons/videoPlayer/right-arrow.png';

import '../../../pages/view-course-page/viewcourse.scss';
const PlayerNextButton = ({ playerArrowClickHandler }) => {
  return (
    <div>
      <>
        <div
          className="vm-leftArrow"
          id="leftArrow"
          onClickCapture={() => playerArrowClickHandler('left')}
        >
          <img src={leftArrowIcon} alt="left arrow" className="vm-arrowIcon" />
        </div>
        <div
          className="vm-rightArrow"
          id="rightArrow"
          onClickCapture={() => playerArrowClickHandler('right')}
        >
          <img
            src={rightArrowIcon}
            alt="right arrow"
            className="vm-arrowIcon"
          />
        </div>
      </>
    </div>
  );
};

export default PlayerNextButton;
